import React, {Component} from 'react';
import {Button, Col, Row} from 'antd';

import DefaultLayout from 'layouts/DefaultLayout';

import './PrivacyPolicy.scss';
import {Helmet} from "react-helmet";

// This renders either itself or Logged out Homepage.
class CookiePolicy extends Component<any, any> {

  public render(): React.ReactElement {
    return (
      <DefaultLayout>
        <Helmet>
          <title>Rascal - Cookie Policy</title>
        </Helmet>
        {this.renderCookieSection()}
      </DefaultLayout>
    );
  }

  private renderCookieSection() {
    return (
      <div className='policy--pages'>
        <Row>
          <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:17, offset: 4 }} lg={{ span:18, offset: 3 }} xl={{ span:16, offset: 4 }} xxl={{ span:12, offset: 6 }} className='aboutpage--section-text'>
            <h1>Cookie Policy</h1>
            <div>
              <h2 className={"custom-list-title"}>
                1. Introduction
              </h2>
              <ul className={'no-decoration'}>
                <li className={"custom-list"}>
                  Rascal Entertainment Limited, a New Zealand registered company of 94C Tinakori Road, Thorndon,
                  Wellington, 6011 , New Zealand (<b>Rascal</b>) is committed to protecting your privacy.
                </li>
                <li className={"custom-list"}>
                  This Cookie Policy explains what cookies are, what types of cookies are placed on your device
                  when you visit Rascal’s website rascalkids.app (<b>Website</b>) or use Rascal’s related mobile app
                  (<b>App</b>), (together, the <b>Service</b>) and how Rascal uses them.
                </li>
                <li className={"custom-list"}>
                  This Cookie Policy does not address how Rascal deals with your personal information and data
                  generally. To learn more about how Rascal processes your personal information, please see Rascal’s
                  <a href={'https://rascalkids.app/privacy-policy'}>Privacy Policy</a>.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                2. What are cookies?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  Cookies are small text files that are sent to or accessed from your web browser or your device's
                  memory. A cookie typically contains the name of the domain (internet location) from which the cookie
                  originated, the "lifetime" of the cookie (i.e., when it expires) and a randomly generated unique
                  number or similar identifier. A cookie also may contain information about your device, such as user
                  settings, browsing history and activities conducted while using the Service.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                3. Are there different types of cookies?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  <b>First-party and third-party cookies</b>
                  <br />
                  ‍There are first-party cookies and third-party cookies. First-party cookies are placed on your
                  device directly by us. For example, Rascal uses first-party cookies to adapt the Website to your
                  browser's language preferences and to better understand your use of the Website. Third-party
                  cookies are placed on your device by our partners and service providers. For example, Rascal
                  uses third-party cookies to measure user numbers on the Website.
                </li>
                <li className={"custom-list"}>
                  <b>Session and persistent cookies</b>
                  There are session cookies and persistent cookies. Session cookies only last until you close your
                  browser. Rascal uses session cookies for a variety of reasons, including to learn more about
                  your use of the Website during one single browser session and to help you to use the Website,
                  App and Service more efficiently. Persistent cookies have a longer lifespan and aren't automatically
                  deleted when you close your browser. These types of cookies are primarily used to help you quickly
                  sign-in to the Website again and for analytical purposes.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                4. What about other tracking technologies, like web beacons?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  Other technologies such as web beacons (also calls pixel tags or clear gifs), tracking URLs or
                  software development kits (<b>SDKs</b>) are used for similar purposes. Web beacons are tiny graphics
                  files that contain a unique identifier that enable us to recognize when someone has visited the
                  Website or App or opened an e-mail that Rascal has sent them. Tracking URLs are custom generated
                  links that help us understand where the traffic to our web pages comes from. SDKs are small pieces
                  of code included in apps, which function like cookies and web beacons.
                </li>
                <li className={"custom-list"}>
                  For simplicity, Rascal also refers to these technologies as "cookies" in this Cookie Policy.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                5. What does Rascal use cookies for?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  Like most providers of online services, Rascal uses cookies to provide, secure and improve the
                  Website and App; including by remembering your preferences and recognizing you when you visit the
                  Website. To accomplish these purposes, Rascal also may link information from cookies with other
                  personal information Rascal holds about you.
                </li>
                <li className={"custom-list"}>
                  When you visit Rascal’s Website, some or all of the following types of cookies may be set on your device.
                  <br />
                  <br />
                  <b>Essential website cookies</b>
                  These cookies are strictly necessary to provide you with services available through the Website and
                  to use some of its features, such as access to secure areas.
                  <br />
                  <br />
                  <b>Analytics cookies</b>
                  These cookies help us to understand how the Website is being used, how effective marketing campaigns
                  are, and help us customize and improve the Website for you.
                  <br />
                  <br />
                  <b>Social networking cookies</b>
                  These cookies are used to enable you to share pages and content that you find interesting on Rascal’s
                  Website through third-party social networking and other websites. These cookies may also be used for
                  advertising purposes too.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                6. How can you control cookies?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  There are several cookie management options available to you. Please note that changes you make to
                  your cookie preferences may make browsing the Website or using the App a less satisfying experience.
                  In some cases, you may even find yourself unable to use all or part of the Website or App.
                </li>
                <li className={"custom-list"}>
                  <b>Browser and devices controls</b>
                  <ol className={"ol-letters"}>
                    <li>
                      Some web browsers provide settings that allow you to control or reject cookies or to alert you
                      when a cookie is placed on your computer. The procedure for managing cookies is slightly different
                      for each internet browser. You can check the specific steps in your particular browser help menu.
                    </li>
                    <li>
                      You also may be able to reset device identifiers by activating the appropriate setting on your
                      mobile device. The procedure for managing device identifiers is slightly different for each device.
                      You can check the specific steps in the help or settings menu of your particular device.
                    </li>
                  </ol>
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                7. Social Cookies
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  To allow you to share content on social media, some features of this Website and the App use social
                  media plugins (e.g., Facebook™ "Share to Facebook"; or LinkedIn™ "in" buttons). Depending on your social
                  media account settings, Rascal automatically receives information from the social media platform when
                  you use the corresponding button on Rascal’s Website or Service.
                </li>
                <li className={"custom-list"}>
                  To learn more about social media cookies, Rascal suggests you refer to your social media platform's cookie policy and privacy policy.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                8. Google Analytics
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal uses Google Analytics, which is a Google service that uses cookies and other data collection
                  technologies to collect information about your use of the Website and App in order to report website trends.
                </li>
                <li className={"custom-list"} >
                  You can opt out of Google Analytics by visiting www.google.com/settings/ads or by downloading the
                  Google Analytics opt-out browser add-on at https://tools.google.com/dlpage/gaoptout.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                9. How to Contact Us
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  If you have questions about this Cookie Policy, here's how you can reach us:
                  <br />
                  <b>Email:</b> support@rascalkids.app <br />
                  <b>By Post:</b> David Sowry, 94C Tinakori Road, Thorndon, 6011, New Zealand
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CookiePolicy;
