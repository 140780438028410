import {ComponentWithStore} from "models/RootStore";
import {makeObservable, observable} from "mobx";
import {User} from "models/user/User";
import AppInfo from "models/AppInfo";
import DefaultLayout from "layouts/DefaultLayout";
import {Col, Row} from "antd";
import React from "react";

class StaticManageData extends ComponentWithStore<any, any> {

    @observable private user: User = new User(this.store.UserProvider);


    constructor(props) {
        super(props)
    }

    componentDidMount() {

    }

    render() {
        return (
            <DefaultLayout>
                <Row>
                    <Col  xs={24} sm={{ span:22, offset:1 }} md={{ span:17, offset: 4 }} lg={{ span:18, offset: 3 }} xl={{ span:16, offset: 4 }} xxl={{ span:12, offset: 6 }} >
                        <h1>Rascal – Manage Account Data</h1>
                        <p>
                            We collect data to understand the content that is enjoyed and platforms people use.
                            We are building out a way for you to see your data, so we can provide insights into how your family is interacting with our content.
                        </p>
                        <h3>Data Collection</h3>
                        <p>
                            The current data we collect is around user sessions.
                            <ul>
                                <li>When you start and stop viewing</li>
                                <li>If you completed the story</li>
                                <li>Device type used</li>
                            </ul>
                        </p>
                        <h3>Delete My Account</h3>
                        <p>
                            You can delete your account completely (on your own) by
                            <ul>
                                <li>Navigating to your <a href={'/account'}>account page</a></li>
                                <li>Selecting <b>delete my account</b>.</li>
                                <li>Entering your password to confirm deletion</li>
                            </ul>
                            This will permanently close your account and delete all identifiable information except for any billing details we are legally obligated to maintain.
                            You can read about our processing and handling of data further in our <a href={'/privacy-policy'}>Privacy Policy</a>
                        </p>
                        <h3>Manually Request – Data or Account Deletion</h3>
                        <p>
                            You can also request that your account or personal data is deleted by emailing <a href={'mailto:support@rascalkids.app?subject=Delete my data'}>support@rascalkids.app</a>.
                            We will ask some questions to confirm your identity before fulfilling your request.
                        </p>
                    </Col>
                </Row>
            </DefaultLayout>
        )
    }
}

export default StaticManageData;
