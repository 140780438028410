import React from "react";
import DefaultLayout from "layouts/DefaultLayout";
import {Link} from "react-router-dom";
import {Col, Row} from "antd";

class NotFound extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <DefaultLayout>
                <Row>
                    <Col xs={{offset: 2, span: 10}}>
                        <h1>404</h1>
                        <p>The page you were looking for was not found.</p>
                        <br/>
                        <p><Link to='/'>Take me home</Link></p>
                    </Col>
                </Row>
            </DefaultLayout>
        );
    }
}

export default NotFound;
