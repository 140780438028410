import React, {Component} from "react";

class TickIcon extends Component {
  render() {
    return (
      <svg className={'rk-icon rk-tick-icon'} xmlns="http://www.w3.org/2000/svg" width="44.656" height="34.74" viewBox="0 0 44.656 34.74">
        <path d="M730.138,533.264a4.591,4.591,0,0,0-6.494,0l-20.522,20.784-8.862-7.81a4.3,4.3,0,0,0-5.954.379c-1.747,1.9-1.973,4.488-.532,5.812l15.433,14.229,26.931-26.9A4.591,4.591,0,0,0,730.138,533.264Z" transform="translate(-686.828 -531.918)" fill="currentColor"/>
      </svg>
    );
  }
}

export default TickIcon;
