import {action, makeObservable, observable} from 'mobx';
import _ from 'lodash';

import {Hash} from 'types/hash';
import {RootStore} from 'models/RootStore';
import {InMemoryStore, IStoreData} from 'models/InMemoryStore';
import {InvoicePermanentStore} from 'models/invoice/InvoicePermanentStore';
import {IInvoiceApiData} from 'models/invoice/IInvoiceApiData';

export class InvoiceMemoryStore extends InMemoryStore<IInvoiceApiData[]> {
    @observable private _invoices: Hash<IStoreData<IInvoiceApiData[]>> = {};

    public constructor(rootStore: RootStore) {
        super(rootStore, 'Invoice');
        makeObservable(this);
    }

    protected get PermanentStore(): InvoicePermanentStore {
        return this.InvoicePermanentStore;
    }

    public resetAll(): void {
        super.resetAll();
        this._invoices = {};
    }

    @action
    public loadFromPermanentStore(userId: number): Promise<void> {
        return this.PermanentStore.read(userId)
            .then((invoices: IInvoiceApiData[] | null) => {
                if (invoices) {
                    this.set(invoices, userId);
                }
            });
    }

    protected getIStoreData(userId: number): IStoreData<IInvoiceApiData[]> {
        let data: IStoreData<IInvoiceApiData[]> = this._invoices[userId.toString()];
        if (!data) {
            data = new IStoreData<IInvoiceApiData[]>(userId, null, this);
            this._invoices[userId.toString()] = data;
        }

        return data;
    }

    @action
    protected set(value: IInvoiceApiData[], userId: number): void {
        this.getIStoreData(userId).setData(value);
    }

    @action
    public storeOne(item: IInvoiceApiData, userId: number): Promise<void> {
        return this.PermanentStore.read(userId)
            .then((data: IInvoiceApiData[] | null) => !data ? [] : data)
            .then((data: IInvoiceApiData[]) => {
                let index: number = _.findIndex(data, (storedItem: IInvoiceApiData) => storedItem && item && storedItem.id === item.id);
                if (index < 0) {
                    // Item does not exist, add it
                    data.push(item);
                } else {
                    // Item already exists, delete it and replace it with the new one
                    data.splice(index, 1, item);
                }
                this.set(data, userId);
                return this.PermanentStore.store(data, userId);
            });
    }

    @action
    public store(data: IInvoiceApiData[], userId: number): Promise<void> {
        this.set(data, userId);
        return this.PermanentStore.store(data, userId);
    }

    public read(userId: number): Promise<IInvoiceApiData[] | null> {
        return this.getIStoreData(userId).LoadData;
    }
}
