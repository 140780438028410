import {action, makeObservable, observable} from 'mobx';

import {Hash} from 'types/hash';
import {RootStore} from 'models/RootStore';
import {InMemoryStore, IStoreData} from 'models/InMemoryStore';
import {StoryLocalisationPermanentStore} from 'models/storyLocalisation/StoryLocalisationPermanentStore';
import {IStoryLocalisationApiData} from 'models/storyLocalisation/IStoryLocalisationApiData';

export class StoryLocalisationMemoryStore extends InMemoryStore<IStoryLocalisationApiData[]> {
  @observable private _localisations: Hash<IStoreData<IStoryLocalisationApiData[]>> = {};

  public constructor(rootStore: RootStore) {
    super(rootStore, 'Localisation');
    makeObservable(this);
  }

  protected get PermanentStore(): StoryLocalisationPermanentStore {
    return this.StoryLocalisationPermanentStore;
  }

  public resetAll(): void {
    super.resetAll();
    this._localisations = {};
  }

  @action
  public loadFromPermanentStore(storyId: number): Promise<void> {
    return this.PermanentStore.read(storyId)
      .then((localisations: IStoryLocalisationApiData[] | null) => {
        if (localisations) {
          this.set(localisations, storyId);
        }
      });
  }

  protected getIStoreData(storyId: number): IStoreData<IStoryLocalisationApiData[]> {
    let data: IStoreData<IStoryLocalisationApiData[]> = this._localisations[storyId.toString()];
    if (!data) {
      data = new IStoreData<IStoryLocalisationApiData[]>(storyId, null, this);
      this._localisations[storyId.toString()] = data;
    }
    return data;
  }

  @action
  protected set(value: IStoryLocalisationApiData[], storyId: number): void {
    this.getIStoreData(storyId).setData(value);
  }

  @action
  public store(data: IStoryLocalisationApiData[], storyId: number): Promise<void> {
    this.set(data, storyId);
    return this.PermanentStore.store(data, storyId);
  }

  public read(storyId: number): Promise<IStoryLocalisationApiData[]> {
    let data = this.getIStoreData(storyId);

    return data.LoadData;
  }
}
