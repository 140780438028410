import React, {Component, ComponentType} from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import {AssetBundleProvider} from 'models/assetBundle/AssetBundleProvider';
import {AssetBundleIDB} from 'models/assetBundle/AssetBundleIDB';

import {BillingAddressProvider} from 'models/billingAddress/BillingAddressProvider';
import {BillingAddressMemoryStore} from 'models/billingAddress/BillingAddressMemoryStore';
import {BillingAddressPermanentStore} from 'models/billingAddress/BillingAddressPermanentStore';

import {CampaignProvider} from "models/campaign/CampaignProvider";

import {CollectionProvider} from 'models/collection/CollectionProvider';
import {CollectionMemoryStore} from 'models/collection/CollectionMemoryStore';
import {CollectionPermanentStore} from 'models/collection/CollectionPermanentStore';

import {InvoiceProvider} from 'models/invoice/InvoiceProvider';
import {InvoiceMemoryStore} from 'models/invoice/InvoiceMemoryStore';
import {InvoicePermanentStore} from 'models/invoice/InvoicePermanentStore';

import {SessionProvider} from 'models/session/SessionProvider';

import {SettingsProvider} from 'models/settings/SettingsProvider';
import {SettingsMemoryStore} from 'models/settings/SettingsMemoryStore';
import {SettingsPermanentStore} from 'models/settings/SettingsPermanentStore';

import {StoryLocalisationProvider} from 'models/storyLocalisation/StoryLocalisationProvider';
import {StoryLocalisationMemoryStore} from 'models/storyLocalisation/StoryLocalisationMemoryStore';
import {StoryLocalisationPermanentStore} from 'models/storyLocalisation/StoryLocalisationPermanentStore';

import {StoryProvider} from 'models/story/StoryProvider';
import {StoryMemoryStore} from 'models/story/StoryMemoryStore';
import {StoryPermanentStore} from 'models/story/StoryPermanentStore';

import {StoryReleaseProvider} from 'models/storyRelease/StoryReleaseProvider';
import {StoryReleaseMemoryStore} from 'models/storyRelease/StoryReleaseMemoryStore';
import {StoryReleasePermanentStore} from 'models/storyRelease/StoryReleasePermanentStore';

import {StorySessionProvider} from 'models/storySession/StorySessionProvider';
import {StorySessionMemoryStore} from 'models/storySession/StorySessionMemoryStore';
import {StorySessionPermanentStore} from 'models/storySession/StorySessionPermanentStore';

import {SubscriptionProvider} from 'models/subscription/SubscriptionProvider';
// import {SubscriptionMemoryStore} from 'models/subscription/SubscriptionMemoryStore';
// import {SubscriptionPermanentStore} from 'models/subscription/SubscriptionPermanentStore';
import {SubscriptionPlanProvider} from 'models/subscriptionPlan/SubscriptionPlanProvider';
import {SubscriptionPlanMemoryStore} from 'models/subscriptionPlan/SubscriptionPlanMemoryStore';
import {SubscriptionPlanPermanentStore} from 'models/subscriptionPlan/SubscriptionPlanPermanentStore';

import {UniverseProvider} from 'models/universe/UniverseProvider';

import {UserProvider} from 'models/user/UserProvider';
import {UserMemoryStore} from 'models/user/UserMemoryStore';
import {UserPermanentStore} from 'models/user/UserPermanentStore';

import {WebglProvider} from 'models/webgl/WebglProvider';
import {WebglIDB} from 'models/webgl/WebglIDB';
import CouponProvider from "models/coupon/CouponProvider";

declare global {
    interface Window {
        store: RootStore;
    }
}

export class RootStore {
    private _assetBundleProvider: AssetBundleProvider;
    private _assetBundleIDB: AssetBundleIDB;

    private _billingAddressProvider: BillingAddressProvider;
    private _billingAddressMemoryStore: BillingAddressMemoryStore;
    private _billingAddressPermanentStore: BillingAddressPermanentStore;

    private _campaignProvider: CampaignProvider;

    private _collectionProvider: CollectionProvider;
    private _collectionMemoryStore: CollectionMemoryStore;
    private _collectionPermanentStore: CollectionPermanentStore;

    private _couponProvider: CouponProvider;

    private _invoiceProvider: InvoiceProvider;
    private _invoiceMemoryStore: InvoiceMemoryStore;
    private _invoicePermanentStore: InvoicePermanentStore;

    private _sessionProvider: SessionProvider;

    private _settingsProvider: SettingsProvider;
    private _settingsMemoryStore: SettingsMemoryStore;
    private _settingsPermanentStore: SettingsPermanentStore;

    private _localisationProvider: StoryLocalisationProvider;
    private _localisationMemoryStore: StoryLocalisationMemoryStore;
    private _localisationPermanentStore: StoryLocalisationPermanentStore;

    private _storyProvider: StoryProvider;
    private _storyMemoryStore: StoryMemoryStore;
    private _storyPermanentStore: StoryPermanentStore;

    private _storyReleaseProvider: StoryReleaseProvider;
    private _storyReleaseMemoryStore: StoryReleaseMemoryStore;
    private _storyReleasePermanentStore: StoryReleasePermanentStore;

    private _storySessionProvider: StorySessionProvider;
    private _storySessionMemoryStore: StorySessionMemoryStore;
    private _storySessionPermanentStore: StorySessionPermanentStore;

    private _subscriptionProvider: SubscriptionProvider;
    // private _subscriptionMemoryStore: SubscriptionMemoryStore;
    // private _subscriptionPermanentStore: SubscriptionPermanentStore;

    private _subscriptionPlanProvider: SubscriptionPlanProvider;
    private _subscriptionPlanMemoryStore: SubscriptionPlanMemoryStore;
    private _subscriptionPlanPermanentStore: SubscriptionPlanPermanentStore;

    private _universeProvider: UniverseProvider;

    private _userProvider: UserProvider;
    private _userMemoryStore: UserMemoryStore;
    private _userPermanentStore: UserPermanentStore;

    private _webglProvider: WebglProvider;
    private _webglIDB: WebglIDB;

    public get AssetBundleProvider(): AssetBundleProvider {
        if (!this._assetBundleProvider) {
            this._assetBundleProvider = new AssetBundleProvider(this);
        }
        return this._assetBundleProvider;
    }

    public get AssetBundleIDB(): AssetBundleIDB {
        if (!this._assetBundleIDB) {
            this._assetBundleIDB = new AssetBundleIDB(this);
        }
        return this._assetBundleIDB;
    }

    public get BillingAddressProvider(): BillingAddressProvider {
        if (!this._billingAddressProvider) {
            this._billingAddressProvider = new BillingAddressProvider(this);
        }
        return this._billingAddressProvider;
    }

    public get BillingAddressMemoryStore(): BillingAddressMemoryStore {
        if (!this._billingAddressMemoryStore) {
            this._billingAddressMemoryStore = new BillingAddressMemoryStore(this);
        }
        return this._billingAddressMemoryStore;
    }

    public get BillingAddressPermanentStore(): BillingAddressPermanentStore {
        if (!this._billingAddressPermanentStore) {
            this._billingAddressPermanentStore = new BillingAddressPermanentStore(this);
        }
        return this._billingAddressPermanentStore;
    }

    public get CampaignProvider(): CampaignProvider {
        if (!this._campaignProvider) {
            this._campaignProvider = new CampaignProvider(this);
        }
        return this._campaignProvider;
    }

    public get CollectionProvider(): CollectionProvider {
        if (!this._collectionProvider) {
            this._collectionProvider = new CollectionProvider(this);
        }
        return this._collectionProvider;
    }

    public get CollectionMemoryStore(): CollectionMemoryStore {
        if (!this._collectionMemoryStore) {
            this._collectionMemoryStore = new CollectionMemoryStore(this);
        }
        return this._collectionMemoryStore;
    }

    public get CollectionPermanentStore(): CollectionPermanentStore {
        if (!this._collectionPermanentStore) {
            this._collectionPermanentStore = new CollectionPermanentStore(this);
        }
        return this._collectionPermanentStore;
    }

    public get CouponProvider(): CouponProvider {
        if (!this._couponProvider) {
            this._couponProvider = new CouponProvider(this);
        }
        return this._couponProvider;
    }

    public get InvoiceProvider(): InvoiceProvider {
        if (!this._invoiceProvider) {
            this._invoiceProvider = new InvoiceProvider(this);
        }
        return this._invoiceProvider;
    }

    public get InvoiceMemoryStore(): InvoiceMemoryStore {
        if (!this._invoiceMemoryStore) {
            this._invoiceMemoryStore = new InvoiceMemoryStore(this);
        }
        return this._invoiceMemoryStore;
    }

    public get InvoicePermanentStore(): InvoicePermanentStore {
        if (!this._invoicePermanentStore) {
            this._invoicePermanentStore = new InvoicePermanentStore(this);
        }
        return this._invoicePermanentStore;
    }

    public get SessionProvider(): SessionProvider {
        if (!this._sessionProvider) {
            this._sessionProvider = new SessionProvider(this);
        }
        return this._sessionProvider;
    }

    public get SettingsProvider(): SettingsProvider {
        if (!this._settingsProvider) {
            this._settingsProvider = new SettingsProvider(this);
        }
        return this._settingsProvider;
    }

    public get SettingsMemoryStore(): SettingsMemoryStore {
        if (!this._settingsMemoryStore) {
            this._settingsMemoryStore = new SettingsMemoryStore(this);
        }
        return this._settingsMemoryStore;
    }

    public get SettingsPermanentStore(): SettingsPermanentStore {
        if (!this._settingsPermanentStore) {
            this._settingsPermanentStore = new SettingsPermanentStore(this);
        }
        return this._settingsPermanentStore;
    }

    public get StoryLocalisationProvider(): StoryLocalisationProvider {
        if (!this._localisationProvider) {
            this._localisationProvider = new StoryLocalisationProvider(this);
        }
        return this._localisationProvider;
    }

    public get StoryLocalisationMemoryStore(): StoryLocalisationMemoryStore {
        if (!this._localisationMemoryStore) {
            this._localisationMemoryStore = new StoryLocalisationMemoryStore(this);
        }
        return this._localisationMemoryStore;
    }

    public get StoryLocalisationPermanentStore(): StoryLocalisationPermanentStore {
        if (!this._localisationPermanentStore) {
            this._localisationPermanentStore = new StoryLocalisationPermanentStore(this);
        }
        return this._localisationPermanentStore;
    }

    public get StoryProvider(): StoryProvider {
        if (!this._storyProvider) {
            this._storyProvider = new StoryProvider(this);
        }
        return this._storyProvider;
    }

    public get StoryMemoryStore(): StoryMemoryStore {
        if (!this._storyMemoryStore) {
            this._storyMemoryStore = new StoryMemoryStore(this);
        }
        return this._storyMemoryStore;
    }

    public get StoryPermanentStore(): StoryPermanentStore {
        if (!this._storyPermanentStore) {
            this._storyPermanentStore = new StoryPermanentStore(this);
        }
        return this._storyPermanentStore;
    }

    public get StoryReleaseProvider(): StoryReleaseProvider {
        if (!this._storyReleaseProvider) {
            this._storyReleaseProvider = new StoryReleaseProvider(this);
        }
        return this._storyReleaseProvider;
    }

    public get StoryReleaseMemoryStore(): StoryReleaseMemoryStore {
        if (!this._storyReleaseMemoryStore) {
            this._storyReleaseMemoryStore = new StoryReleaseMemoryStore(this);
        }
        return this._storyReleaseMemoryStore;
    }

    public get StoryReleasePermanentStore(): StoryReleasePermanentStore {
        if (!this._storyReleasePermanentStore) {
            this._storyReleasePermanentStore = new StoryReleasePermanentStore(this);
        }
        return this._storyReleasePermanentStore;
    }

    public get StorySessionProvider(): StorySessionProvider {
        if (!this._storySessionProvider) {
            this._storySessionProvider = new StorySessionProvider(this);
        }
        return this._storySessionProvider;
    }

    public get StorySessionMemoryStore(): StorySessionMemoryStore {
        if (!this._storySessionMemoryStore) {
            this._storySessionMemoryStore = new StorySessionMemoryStore(this);
        }
        return this._storySessionMemoryStore;
    }

    public get StorySessionPermanentStore(): StorySessionPermanentStore {
        if (!this._storySessionPermanentStore) {
            this._storySessionPermanentStore = new StorySessionPermanentStore(this);
        }
        return this._storySessionPermanentStore;
    }

    public get SubscriptionProvider(): SubscriptionProvider {
        if (!this._subscriptionProvider) {
            this._subscriptionProvider = new SubscriptionProvider(this);
        }
        return this._subscriptionProvider;
    }

    /*
     public get SubscriptionMemoryStore(): SubscriptionMemoryStore {
     if (!this._subscriptionMemoryStore) {
     this._subscriptionMemoryStore = new SubscriptionMemoryStore(this);
     }
     return this._subscriptionMemoryStore;
     }

     public get SubscriptionPermanentStore(): SubscriptionPermanentStore {
     if (!this._subscriptionPermanentStore) {
     this._subscriptionPermanentStore = new SubscriptionPermanentStore(this);
     }
     return this._subscriptionPermanentStore;
     }
     */

    public get SubscriptionPlanProvider(): SubscriptionPlanProvider {
        if (!this._subscriptionPlanProvider) {
            this._subscriptionPlanProvider = new SubscriptionPlanProvider(this);
        }
        return this._subscriptionPlanProvider;
    }

    public get SubscriptionPlanMemoryStore(): SubscriptionPlanMemoryStore {
        if (!this._subscriptionPlanMemoryStore) {
            this._subscriptionPlanMemoryStore = new SubscriptionPlanMemoryStore(this);
        }
        return this._subscriptionPlanMemoryStore;
    }

    public get SubscriptionPlanPermanentStore(): SubscriptionPlanPermanentStore {
        if (!this._subscriptionPlanPermanentStore) {
            this._subscriptionPlanPermanentStore = new SubscriptionPlanPermanentStore(this);
        }
        return this._subscriptionPlanPermanentStore;
    }

    public get UniverseProvider(): UniverseProvider {
        if (!this._universeProvider) {
            this._universeProvider = new UniverseProvider(this);
        }
        return this._universeProvider;
    }

    public get UserProvider(): UserProvider {
        if (!this._userProvider) {
            this._userProvider = new UserProvider(this);
        }
        return this._userProvider;
    }

    public get UserMemoryStore(): UserMemoryStore {
        if (!this._userMemoryStore) {
            this._userMemoryStore = new UserMemoryStore(this);
        }
        return this._userMemoryStore;
    }

    public get UserPermanentStore(): UserPermanentStore {
        if (!this._userPermanentStore) {
            this._userPermanentStore = new UserPermanentStore(this);
        }
        return this._userPermanentStore;
    }

    public get WebglProvider(): WebglProvider {
        if (!this._webglProvider) {
            this._webglProvider = new WebglProvider(this);
        }
        return this._webglProvider;
    }

    public get WebglIDB(): WebglIDB {
        if (!this._webglIDB) {
            this._webglIDB = new WebglIDB(this);
        }
        return this._webglIDB;
    }


    public static getStore(): RootStore {
        const {store} = window;

        if (!store) {
            window.store = new RootStore();
        }

        return window.store;
    }
}

// Store helpers
export const StoreContext: React.Context<RootStore> = React.createContext<RootStore>(new RootStore());

export const StoreProvider = ({children, store}): React.ReactElement => (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);

export const useStore = () => React.useContext(StoreContext);

export type TWithStoreHOC = <P extends object>(Component: ComponentType<P>) => (props: P) => React.ReactElement;


export const withStore: TWithStoreHOC = (WrappedComponent) => (props) => {
    const ComponentWithStore: () => React.ReactElement = () => {
        const store = useStore();
        return <WrappedComponent {...props} store={store}/>;
    };

    ComponentWithStore["defaultProps"] = {...WrappedComponent.defaultProps};
    ComponentWithStore["displayName"] = `WithStores(${
        WrappedComponent["name"] || WrappedComponent.displayName
    })`;

    hoistNonReactStatics(ComponentWithStore, WrappedComponent);

    return <ComponentWithStore/>;
}

export class ComponentWithStore<P, S> extends Component<P, S> {
    protected store: RootStore;

    constructor(props: P) {
        super(props);
        this.store = RootStore.getStore();
    }
}
