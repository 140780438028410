import _ from 'lodash';

import Api from 'models/Api';
import {ApiResponseData, ApiResponseDataWithResult} from 'models/ApiResponseData';
import {ISubscriptionApiData, ISubscriptionUpdateApiData} from 'models/subscription/ISubscriptionApiData';
import {ISubscriptionSearchApiData} from 'models/subscription/ISubscriptionSearchApiData';
import {IUserApiData} from 'models/user/IUserApiData';

import {SortOrder} from 'antd/lib/table/interface';
import {SearchApi} from "models/SearchApi";
import {UserInfo, UserInfoResult} from "components/public/pages/subscriptions/BulkSubscribe";

export type ISubscriptionApiSearchOrdering = 'id' | 'active' | 'created_at';

export interface ISubscriptionApiSearchFilterParams {
    id?: string;
    active?: boolean;
    created_at?: string;
    order_by?: ISubscriptionApiSearchOrdering;
    order?: SortOrder;
}

export interface ISubscriptionApiSearchParams {
    subscription_plan_ids?: number[];
    page?: number;
    page_size?: number;
    filters?: ISubscriptionApiSearchFilterParams
}

export class SubscriptionApi {
    public static get(id: number): Promise<ISubscriptionApiData> {
        return Api.get(`/api/v1/subscriptions/${id}`)
            .then(response => response.json())
            .then(json => json.subscription);
    }

    public static getAll() {
        return Api.get('/api/v1/subscriptions')
            .then(response => response.json())
            .then(json => json.subscriptions);
    }

    public static hasValidSubscription(user_id: number): Promise<boolean> {
        if (user_id == null) {
            return Promise.resolve<boolean>(false);
        }
        return Api.get(`/api/v1/users/${user_id}/valid_subscription`)
            .then(response => response.json())
            .then(json => json.valid);
    }

    public static userSubscriptions(user_id: number): Promise<ISubscriptionApiData[]> {
        return Api.get(`/api/v1/users/${user_id}/subscriptions`)
            .then(response => response.json())
            .then(json => json.subscriptions);
    }

    public static createSubscription(body: {
        user_id: number,
        subscription_plan_id: number,
        subscription_plan_key?: string,
        billing_address_id?: number,
        stripe_setup_intent?: string,
        credit_card_id?: number
    }): Promise<any> {
        return Api.post('/api/v1/subscriptions', {
            body: JSON.stringify({
                user_id: body.user_id.toString(),
                subscription_plan_id: body.subscription_plan_id.toString(),
                subscription_plan_key: body.subscription_plan_key,
                stripe_setup_intent: body.stripe_setup_intent,
                credit_card_id: body.credit_card_id ? body.credit_card_id.toString() : undefined,
                billing_address_id: body.billing_address_id ? body.billing_address_id.toString() : undefined,
            })
        })
            .then(response => response.json());
    }

    public static getSubscribers(subscription_id: number): Promise<IUserApiData[]> {
        return Api.get(`/api/v1/subscriptions/${subscription_id}/subscribers`)
            .then(response => response.json())
            .then(json => json.subscribers);
    }

    public static update(subscription_id: number, params: ISubscriptionUpdateApiData): Promise<ISubscriptionApiData> {
        return Api.post(`/api/v1/subscriptions/${subscription_id}`, {
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(json => json.subscription);
    }

    public static cancelSubscription(subscription_id: number): Promise<ApiResponseData> {
        return Api.post(`/api/v1/subscriptions/${subscription_id}/cancel`)
            .then(response => response.json());
    }

    public static resumeSubscription(subscription_id: number): Promise<ApiResponseData> {
        return Api.post(`/api/v1/subscriptions/${subscription_id}/resume`)
            .then(response => response.json());
    }

    public static promoteToManager(subscription_id: number, body: { user_id: number }): Promise<ApiResponseData> {
        return Api.post(`/api/v1/subscriptions/${subscription_id}/promote`, {
            body: JSON.stringify(body)
        })
            .then(response => response.json());
    }

    public static addSubscribersToSubscription(subscription_id: number, body: { subscriber_ids: string[] }): Promise<ApiResponseData> {
        return Api.post(`/api/v1/subscriptions/${subscription_id}/add_subscribers`, {
            body: JSON.stringify(body)
        })
            .then(response => response.json());
    }

    public static bulkCreateSubscribers(subscription_id: number, body: { users: UserInfo[] }): Promise<ApiResponseDataWithResult<UserInfoResult>> {
        return Api.post(`/api/v1/subscriptions/${subscription_id}/create_subscribers`, {
            body: JSON.stringify(body)
        }).then (response => response.json());
    }

    public static leaveSubscription(subscription_id: number): Promise<ApiResponseData> {
        return Api.post(`/api/v1/subscriptions/${subscription_id}/leave`)
            .then(response => response.json());
    }

    public static kickSubscriber(subscription_id: number, body: { subscriber_ids: number[] }): Promise<ApiResponseData> {
        return Api.post(`/api/v1/subscriptions/${subscription_id}/kick`, {
            body: JSON.stringify(body)
        })
            .then(response => response.json());
    }

    public static countSubscriptions(searchParams?: { subscription_plan_ids?: number[] }): Promise<number[]> {
        let query = '';
        if (searchParams) {
            query += '?';
            if (searchParams.subscription_plan_ids && searchParams.subscription_plan_ids.length > 0) {
                query += `subscription_plan_ids=${searchParams.subscription_plan_ids.join(',')}`;
            }
        }
        return Api.get(`/api/v1/subscriptions/count${query}`)
            .then(response => response.json());
    }

    public static getSubscriptionSearch(searchParams?: ISubscriptionApiSearchParams): Promise<ISubscriptionSearchApiData> {
        let query: string[] = [];
        let queryStr = ''
        if (searchParams) {
            const {subscription_plan_ids} = searchParams;
            if (subscription_plan_ids && subscription_plan_ids.length > 0) {
                query.push(`plan_ids=${searchParams.subscription_plan_ids.join(',')}`);
            }
            queryStr = SearchApi.buildQueryFromParams(searchParams, query);
        }

        return Api.get(`/api/v1/subscriptions/search${queryStr}`)
            .then(response => response.json());
    }

    public static getSubscriptions(subscription_ids: number[]) {
        let query: string[] = [];
        let queryStr = ''
        if (subscription_ids) {
            query.push(`ids=${subscription_ids.join(',')}`);
        }
        queryStr = `?${query.join('&')}`;
        return Api.get(`/api/v1/subscriptions${queryStr}`)
            .then(response => response.json());
    }
}
