import React from 'react';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Row} from 'antd';

import {ComponentWithStore, withStore} from 'models/RootStore';
import {BillingAddress} from 'models/billingAddress/BillingAddress';

import DefaultLayout from 'layouts/DefaultLayout';
import BillingAddresses from '../billing_addresses/_BillingAddresses';

import './register/styles.scss';

interface IBillingAddressProps {
}

interface IBillingAddressState {
    editBillingAddress: boolean
}

class Billing extends ComponentWithStore<IBillingAddressProps, IBillingAddressState> {
    @observable private billingAddresses: BillingAddress[];
    @observable private currentBillingAddress: BillingAddress;

    public constructor(props: IBillingAddressProps) {
        super(props);

        makeObservable(this);

        this.state = {
            editBillingAddress: false
        };

        this.stopEditingBillingAddress = this.stopEditingBillingAddress.bind(this);
        this.editBillingAddress = this.editBillingAddress.bind(this);
    }

    public componentDidMount(): void {
        this.store.SessionProvider.authOnly();
        this.readModeFromParams()
        this.loadUserBillingAddresses()
            .then(_ignored => {});
    }

    private readModeFromParams(): void {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        if (params.has('new')) {
            this.newBillingAddress();
        }
    }

    public loadUserBillingAddresses(force: boolean = false): Promise<void> {
        return this.store.BillingAddressProvider
            .getAll(this.store.SessionProvider.userId(), force)
            .then((billingAddresses: BillingAddress[]) => {
                this.billingAddresses = billingAddresses;
            });
    }

    public stopEditingBillingAddress(): void {
        // TODO: Think of a smarter way to ensure that we are not showing unsaved edits
        this.loadUserBillingAddresses(true)
            .then(() => this.setState({editBillingAddress: false}))
    }

    public editBillingAddress(billingAddress: BillingAddress): void {
        this.currentBillingAddress = billingAddress;
        this.setState({editBillingAddress: true});
    }

    public newBillingAddress(): void {
        this.editBillingAddress(new BillingAddress(this.store.BillingAddressProvider).withCurrentUser());
    }

    private hasBillingAddresses(): boolean {
        return this.billingAddresses != null && this.billingAddresses.length > 0;
    }

    private renderButton() {
        if (this.state.editBillingAddress) {
            return (
                <Row justify='center'>
                    <Button className='rk-btn rk-btn-secondary rounded submit-form'
                            type='primary'
                            onClick={() => this.stopEditingBillingAddress()}>
                        Cancel
                    </Button>
                </Row>
            );
        } else {
            return (
                <Row justify='center'>
                    {/*
                    <Button className='rk-btn rk-btn-secondary rounded submit-form'
                            type='primary'
                            onClick={() => this.newBillingAddress()}>
                        Add a new billing address
                    </Button>
*/}
                </Row>
            );
        }
    }

    private renderEditBillingAddress(): React.ReactElement {
        return (
            <BillingAddresses.SetupForm onSave={this.stopEditingBillingAddress}
                                        billingAddress={this.currentBillingAddress}/>
        );
    }

    private renderNoBillingAddresses(): React.ReactElement {
        return (
            <React.Fragment>
                <div>
                    You have no billing addresses. Please add at least one.
                </div>
            </React.Fragment>
        );
    }

    private renderBillingAddresses(): React.ReactElement {
        if (!this.hasBillingAddresses()) return this.renderNoBillingAddresses();

        return (
            <React.Fragment>
                <Row>
                    {this.billingAddresses.map((billingAddress: BillingAddress, index: number) => {
                        return (
                            <Col key={index} span={24}>
                                <BillingAddresses.Preview billingAddress={billingAddress}
                                                          onEdit={this.editBillingAddress}/>
                            </Col>
                        );
                    })}
                </Row>
            </React.Fragment>
        );
    }

    public renderContent(): React.ReactElement {
        let billingView;
        if (this.state.editBillingAddress) {
            billingView = this.renderEditBillingAddress();
        } else {
            billingView = this.renderBillingAddresses();
        }

        return (
            <React.Fragment>
                <div>
                    {billingView}
                    <br/>
                    {this.renderButton()}
                </div>
            </React.Fragment>
        );
    }

    public render(): React.ReactElement {
        return (
            <DefaultLayout>
                <div className='rk-register-content'>
                    <div className='rk-form-container'>
                        {this.renderContent()}
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

export default withStore(observer(Billing));
