import React, {Component, MouseEventHandler} from 'react';
import {Space} from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import './PageTitle.scss'

interface IPageTitleProps {
  to?: string;
  onClick?: (event: any) => void;
  title: string;
  subtitle?: string;
}

class PageTitle extends Component<IPageTitleProps, any> {
  public render(): React.ReactElement {
    const {to, onClick, title, subtitle} = this.props;
    return (
      <div className='rk-page-title'>
        <div className='rk-page-title--content'>
          {to && <Link to={to} className='rk-page-title--back-arrow'><h1 className='rk-page-title--title'><ArrowLeftOutlined/></h1></Link>}
          {onClick && <div className='rk-page-title--back-arrow'><h1 onClick={onClick} className='rk-page-title--title'><ArrowLeftOutlined/></h1></div>}
          <h1 className='rk-page-title--title'>{title}</h1>
          {subtitle && <h2 className='rk-page-title--subtitle'>{subtitle}</h2>}
        </div>
      </div>
    );
  }
}

export default PageTitle;
