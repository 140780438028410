import {action, makeObservable, observable} from 'mobx';
import _ from 'lodash';

import {Hash} from 'types/hash';
import {RootStore} from 'models/RootStore';
import {InMemoryStore, IStoreData} from 'models/InMemoryStore';
import {SettingsPermanentStore} from 'models/settings/SettingsPermanentStore';
import {ISettingsApiData} from 'models/settings/ISettingsApiData';

export class SettingsMemoryStore extends InMemoryStore<ISettingsApiData> {
    @observable private _settings: Hash<IStoreData<ISettingsApiData>> = {};

    public constructor(rootStore: RootStore) {
        super(rootStore, 'Settings');
        makeObservable(this);
    }

    protected get PermanentStore(): SettingsPermanentStore {
        return this.SettingsPermanentStore;
    }

    public resetAll(): void {
        super.resetAll();
        this._settings = {};
    }

    @action
    public loadFromPermanentStore(userId: number): Promise<void> {
        return this.PermanentStore.read(userId)
            .then((settings: ISettingsApiData | null) => {
                if (settings) {
                    this.set(settings, userId);
                }
            });
    }

    protected getIStoreData(userId: number): IStoreData<ISettingsApiData> {
        let data: IStoreData<ISettingsApiData> = this._settings[userId.toString()];
        if (!data) {
            data = new IStoreData<ISettingsApiData>(userId, null, this);
            this._settings[userId.toString()] = data;
        }

        return data;
    }

    @action
    protected set(value: ISettingsApiData, userId: number): void {
        this.getIStoreData(userId).setData(value);
    }

    @action
    public store(data: ISettingsApiData, userId: number): Promise<void> {
        this.set(data, userId);
        return this.PermanentStore.store(data, userId);
    }

    public read(userId: number): Promise<ISettingsApiData | null> {
        return this.getIStoreData(userId).LoadData;
    }
}
