import _ from "lodash";

export class SearchApi {
  public static buildQueryFromParams(searchParams, query) {
    const {page, page_size, filters} = searchParams;
    if (page) {
      query.push(`p=${page}`);
    }
    if (page_size) {
      query.push(`s=${page_size}`);
    }
    if (filters) {
      _.map(filters, (value, key) => {
        if (value) {
          query.push(`${key}=${value}`);
        }
      });
    }
    return `?${query.join('&')}`;
  }
}
