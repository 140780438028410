import Api from 'models/Api';
import {IStoryLocalisationApiData} from 'models/storyLocalisation/IStoryLocalisationApiData';

export class StoryLocalisationApi {

  public static getAll(storyId: number): Promise<IStoryLocalisationApiData[]> {
    return Api.get(`/api/v1/stories/${storyId}/localisations`)
      .then(response => response.json())
      .then(json => json.story_localisations);
  }
}
