import {computed} from "mobx";

class AppInfo {
  @computed
  public static isAdmin(): boolean {
    return false;
  }

  public static isMobile() {
    return /Android|iPhone|iPad|iPod|iOS/i.test(window.navigator.userAgent);
  }

  public static isIOS() {
    return /iPhone|iPad|iPod|iOS/i.test(window.navigator.userAgent);
  }

  public static isAndroid() {
    return /Android/i.test(window.navigator.userAgent);
  }
}

export default AppInfo;
