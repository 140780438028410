import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {BillingAddress} from 'models/billingAddress/BillingAddress';
import {IBillingAddressApiData} from 'models/billingAddress/IBillingAddressApiData';
import {UserApi} from 'models/user/UserApi';
import {BillingAddressApi} from 'models/billingAddress/BillingAddressApi';

export class BillingAddressProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'BillingAddressProvider');

        this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public get(id: number): Promise<BillingAddress | null> {
        return BillingAddressApi.get(id).then(this.apiDataToModel);
    }

    public getAll(userId: number, force: boolean = false): Promise<BillingAddress[]> {
        if (userId == null) return Promise.reject('No user id specified for loading all Billing Addresses.');

        if (force) return this.getAllFromApiAndUpdateStore(userId);

        return this.BillingAddressMemoryStore.read(userId)
            .then((billingAddresses: IBillingAddressApiData[] | null) => {
                return billingAddresses
                    ? this.apiDataArrayToModelArray(billingAddresses)
                    : this.getAllFromApiAndUpdateStore(userId);
            });
    }

    public getActive(userId: number): Promise<BillingAddress | null> {
        if (userId == null) return Promise.reject('No user id specified for loading all Billing Addresses.');

        return this.getActiveFromApiAndUpdateStore(userId);
    }

    public create(param: BillingAddress): Promise<IBillingAddressApiData> {
        return BillingAddressApi.create(param)
            .then((response: IBillingAddressApiData) => this.BillingAddressMemoryStore.storeOne(response, param.user_id)
                .then(() => response));
    }

    public update(param: BillingAddress): Promise<IBillingAddressApiData> {
        return BillingAddressApi.update(param)
            .then((response: IBillingAddressApiData) => this.BillingAddressMemoryStore.storeOne(response, param.user_id)
                .then(() => response));
    }

    private getAllFromApiAndUpdateStore(userId: number): Promise<BillingAddress[]> {
        return UserApi.getBillingAddresses(userId)
            .then((billingAddresses: IBillingAddressApiData[]) => this.BillingAddressMemoryStore.store(billingAddresses, userId)
                .then(() => this.apiDataArrayToModelArray(billingAddresses)));
    }

    private getActiveFromApiAndUpdateStore(userId: number): Promise<BillingAddress> {
        return UserApi.getActiveBillingAddress(userId)
            .then((billingAddress: IBillingAddressApiData) => {
                if (!billingAddress) return null;

                return this.BillingAddressMemoryStore.storeOne(billingAddress, userId)
                    .then(() => this.apiDataToModel(billingAddress));
            });
    }

    private apiDataArrayToModelArray(billingAddresses: IBillingAddressApiData[]): BillingAddress[] {
        return billingAddresses.map(this.apiDataToModel);
    }

    private apiDataToModel(billingAddress: IBillingAddressApiData): BillingAddress {
        return new BillingAddress(this).withData(billingAddress);
    }
}
