import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { EmblaCarouselType } from 'embla-carousel-react';

import { ComponentWithStore, withStore } from 'models/RootStore';
import { Story } from 'models/story/Story';

import StoryPanel from 'components/public/pages/home/StoryPanel';
import Chevron from 'components/shared/icons/Chevron';
// @ts-ignore
import EmblaCarousel from 'components/shared/EmblaCarousel';

import './Catalogue.scss';

interface ICatalogueProps {
  title: string;
  stories?: Story[];
  storyIds?: number[];
  admin?: boolean;
  collectionId?: number;
  showMessage?: boolean;
  layout?: 'centered' | 'medium' | 'full';
  align?: 'left' | 'right';
  panelLayout?: 'tall' | 'short';
  maxVisibleSlides?: number;
  homepage?: boolean;
}

interface ICatalogueState {
  scrollItem: number;
}

class Catalogue extends ComponentWithStore<ICatalogueProps, ICatalogueState> {
  @observable private stories: Story[];
  @observable private splashStory: Story;
  @observable private multiPanelStory: Story;

  private emblaRef: EmblaCarouselType;

  public constructor(props: ICatalogueProps) {
    super(props);

    makeObservable(this);

    if ( props.stories ) this.setStories(props.stories);

    this.state = {
      scrollItem: 0
    };

    this.setStories = this.setStories.bind(this);
  }

  public componentDidMount(): void {
    if ( this.props.storyIds ) {
      Promise.all(_.map(this.props.storyIds, id => this.store.StoryProvider.get(id)))
        .then(this.setStories);
    }
  }

  @action
  private setStories(stories: Story[]) {
    this.stories = stories;
  }

  private moveLeft(): void {
    if ( this.emblaRef === undefined ) return;
    this.emblaRef.scrollPrev();
  }

  private moveRight(): void {
    if ( this.emblaRef === undefined ) return;
    this.emblaRef.scrollNext();
  }

  private getStories(): Story[] | null {
    let { maxVisibleSlides } = this.props;
    let stories: Story[] = this.stories;

    if ( !(stories && stories.length > 0) ) {
      return null;
    }

    while ( stories.length <= (maxVisibleSlides || 8) ) {
      stories = stories.concat(stories);
    }

    return stories;
  }

  private renderCatalogueView(): React.ReactElement {
    const { admin, layout, panelLayout, showMessage, homepage, align } = this.props;

    let stories: Story[] | null = this.getStories();

    if ( !stories ) return <React.Fragment/>

    return (
      <Col xs={ 24 } lg={ (layout === 'medium') ? 20 : 24 }>
        <Row className={ `rk-catalogue-row ${ layout || '' } ${ panelLayout || '' } ${ align || ''}` }>
          <Button className='rk-catalogue-row-btn-left'
                  onClick={ (e) => this.moveLeft() }>
            <Chevron direction='left'/>
          </Button>
          <EmblaCarousel options={ { align: 'start', loop: true, dragFree: true } }
                         onRender={ (emblaRef: EmblaCarouselType) => this.emblaRef = emblaRef }>
            { stories.map((story: Story, index: number): React.ReactElement => (
              <StoryPanel story={ story }
                          key={ index }
                          admin={ admin }
                          layout={ panelLayout }
                          showMessage={ showMessage }
                          homepage={ homepage }/>
            )) }
          </EmblaCarousel>
          <Button className='rk-catalogue-row-btn-right'
                  onClick={ (e) => this.moveRight() }>
            <Chevron direction='right'/>
          </Button>
        </Row>
      </Col>
    );
  }

  public render(): React.ReactElement {
    const { admin, collectionId, title } = this.props;


    return (
      <React.Fragment>
        <Col className='rk-catalogue--title-section' xs={24} md={{ span: 20, offset: 2 }}>
          <h2 className='rk-catalogue--header'>{ title }</h2>
          { admin === true && collectionId &&
            <Link className='rk-catalogue--header' to={ `/admin/collections/${ collectionId }` }>Edit</Link> }
        </Col>
        <Row className='rk-catalogue' justify='center'>
          { this.renderCatalogueView() }
        </Row>
      </React.Fragment>
    );
  }
}

export default withStore(observer(Catalogue));
