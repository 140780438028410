import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {IStoryApiData} from 'models/story/IStoryApiData';

export class StoryPermanentStore extends PermanentStore implements IExposedPermanentStore<IStoryApiData> {
    protected get TableName(): string {
        return PermanentStore.TABLE_NAMES.StoryPermanentStore;
    }

    constructor(rootStore: RootStore) {
        super(rootStore);
    }

    public getKey(id: number): string {
        return `api_stories_${id}`;
    }

    public store(data: IStoryApiData): Promise<void> {
        return this.doStore<IStoryApiData>(this.TableName, this.getKey(data.id), data);
    }

    public read(id: number): Promise<IStoryApiData | null> {
        return this.doRead<IStoryApiData>(this.TableName, this.getKey(id));
    }
}
