import {action, computed, makeObservable, observable} from 'mobx';

import Value from 'helpers/Value';
import {IBillingAddressApiData} from 'models/billingAddress/IBillingAddressApiData';
import {BillingAddressProvider} from 'models/billingAddress/BillingAddressProvider';

export class BillingAddress {
    private readonly provider: BillingAddressProvider;

    @observable public id: number;
    @observable public name: string;
    @observable public city: string;
    @observable public country: string;
    @observable public line1: string;
    @observable public line2: string;
    @observable public state: string;
    @observable public zip: string;
    @observable public user_id: number;

    constructor(provider: BillingAddressProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
    }

    public withId(id: number): BillingAddress {
        this.id = id;
        return this;
    }

    public withData(data: IBillingAddressApiData): BillingAddress {
        this.load(data);
        return this;
    }

    @action.bound
    public load(data: IBillingAddressApiData) {
        this.id = data.id;
        this.name = data.name;
        this.city = data.city;
        this.country = data.country;
        this.line1 = data.line1;
        this.line2 = data.line2;
        this.state = data.state;
        this.zip = data.zip;

        this.user_id = data.user_id;
    }

    @action.bound
    public setValue(field: string, value: any) {
        this[field] = value;
    }

    @computed
    public get hasRequiredFields() {
        return (
            !Value.isNullOrEmptyString(this.user_id) &&
            !Value.isNullOrEmptyString(this.name) &&
            !Value.isNullOrEmptyString(this.line1) &&
            !Value.isNullOrEmptyString(this.country) &&
            !Value.isNullOrEmptyString(this.zip)
        );
    }

    @computed
    public get hasInvalidFields() {
        return this.user_id == null || this.name == null;
    }

    public withCurrentUser(): BillingAddress {
        this.user_id = this.provider.SessionProvider.userId();
        return this;
    }

    public async save(): Promise<void> {
        if (this.id == null) {
            return this.create();
        } else {
            return this.update();
        }
    }

    public fetchData(): Promise<BillingAddress> {
        return this.provider.get(this.id).then(this.withData);
    }

    private create(): Promise<void> {
        if (this.user_id == null) {
            this.withCurrentUser();
        }

        return this.provider.create(this)
            .then(json => this.load(json));
    }

    private update(): Promise<void> {
        return this.provider.update(this)
            .then(json => this.load(json));
    }
}
