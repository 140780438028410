import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {StorySession} from 'models/storySession/StorySession';
import {StorySessionApi} from 'models/storySession/StorySessionApi';
import {
    IStorySessionApiDataGetResponse,
    IStorySessionApiDataUpdateRequest
} from 'models/storySession/IStorySessionApiData';

export class StorySessionProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'StorySessionProvider');

        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public start(storyId: number, data?: IStorySessionApiDataUpdateRequest): Promise<StorySession> {
        return StorySessionApi.start(storyId, data)
            .then((response: IStorySessionApiDataGetResponse) => {
                return this.StorySessionMemoryStore.store(response, storyId)
                    .then(() => this.apiDataToModel(response));
            });
    }

    public async finish(storyId: number): Promise<StorySession> {
        let now = new Date();

        let storySession = await this.getApiDataForStory(storyId);
        storySession.changed_at = now;
        storySession.finished_at = now;
        storySession.changed_locally = true;
        await this.StorySessionMemoryStore.store(storySession, storyId)
        if (storySession?.id) {
            let response = await StorySessionApi.finish(storyId, storySession.id, storySession.changed_at);
            await this.StorySessionMemoryStore.store(response, storyId);
            return this.apiDataToModel(response);
        }
    }

    public exit(storyId: number): Promise<StorySession> {
        return this.getForStory(storyId)
            .then(storySession => {
                if (storySession?.remote_id) {
                    return this.exitById(storyId, storySession.remote_id);
                }
            });
    }

    private exitById(storyId: number, storySessionId: number): Promise<StorySession> {
        return StorySessionApi.exit(storyId, storySessionId)
            .then((response: IStorySessionApiDataGetResponse) => {
                return this.StorySessionMemoryStore.store(response, storyId)
                    .then(() => this.apiDataToModel(response));
            });
    }

    public update(storyId: number, data: IStorySessionApiDataUpdateRequest): Promise<StorySession> {
        return this.getForStory(storyId)
            .then(storySession => !storySession?.remote_id
                ? StorySessionApi.start(storyId, data)
                : StorySessionApi.update(storyId, storySession.remote_id, data))
            .then((response: IStorySessionApiDataGetResponse) => {
                return this.StorySessionMemoryStore.store(response, storyId)
                    .then(() => this.apiDataToModel(response));
            });
    }

    public async getForStory(storyId: number | null = null, force: boolean = false): Promise<StorySession | null> {
        let storySession = await this.getApiDataForStory(storyId, force);
        if (storySession) return this.apiDataToModel(storySession);
        return null;
    }

    private async getApiDataForStory(storyId: number | null = null, force: boolean = false): Promise<IStorySessionApiDataGetResponse | null> {
        try {
            if (!force) {
                let session = await this.StorySessionMemoryStore.read(storyId);
                if (session) return session;
            }
            return await this.getCurrentSessionForStoryFromApiAndUpdateStore(storyId);
        } catch (e) {
            return null;
        }
    }

    private async getCurrentSessionForStoryFromApiAndUpdateStore(storyId: number | null = null): Promise<IStorySessionApiDataGetResponse | null> {
        let storySession = await StorySessionApi.getCurrentSessionForStory(storyId);
        if (storySession != null) await this.StorySessionMemoryStore.store(storySession, storyId);
        return storySession;
    }

    private apiDataToModel(storySession: IStorySessionApiDataGetResponse): StorySession {
        return new StorySession(this).withData(storySession);
    }
}
