import React, {RefObject} from 'react';
import {Button, Col, Form, Input, Row} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {FormInstance} from 'antd/es';
import {ComponentWithStore, withStore} from 'models/RootStore';

import './styles.scss';


interface IUserEditNameProps {
    userId: number;
    callback: (nameIsMissing: boolean) => void;
}

interface IUserEditNameState {
    loaded: boolean;
    submitting: boolean;
    errorMessage: { name: string, message: string } | null;
}

class UserEditName extends ComponentWithStore<IUserEditNameProps, IUserEditNameState> {
    private formRef: RefObject<FormInstance> = React.createRef<FormInstance>();

    public constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            submitting: false,
            errorMessage: null
        };

        this.onFinish = this.onFinish.bind(this);
    }

    public onFinish(values): void {
        const {userId} = this.props;

        if (!userId) {
            console.log('Trying to save user without an id'); // How did you get here
            return;
        }

        this.setState({submitting: true});

        this.store.UserProvider.update(userId, {
            first_name: values.first_name,
            last_name: values.last_name
        })
            .then(result => {
                this.setState({submitting: false});
                if (result.success) {
                    this.props.callback(false);
                }
            })
            .catch(() => {
                this.setState({submitting: false});
            });
    }

    public onFinishFailed(errorInfo): void {
        console.log('Failed:', errorInfo);
    }

    private isSubmitDisabled(): boolean {
        const {submitting} = this.state;
        let formInstance: FormInstance = this.formRef.current;

        if (!formInstance) return true;

        return !formInstance.isFieldsTouched(['first_name', 'last_name'], true) ||
            submitting ||
            formInstance.getFieldsError().filter(({errors}) => errors.length).length > 0;
    }

    private renderRegisterStep(number: number): React.ReactElement {
        return (
            <Row justify='center'>
                <p className='rk-register--step'>STEP {number} OF 4</p>
            </Row>
        );
    }

    private renderButton(): React.ReactElement {
        const {submitting} = this.state;
        return (
            <Button className='rk-btn rk-btn-dark rounded submit-form' type='primary' htmlType='submit'
                    disabled={this.isSubmitDisabled()}>
                {submitting ? <LoadingOutlined spin/> : 'Next'}
            </Button>
        );
    }

    private renderForm(): React.ReactElement {
        return (
            <div className={'rk-register-form'}>
                <h1>Sign up</h1>

                <Form.Item name='first_name' rules={[{required: true, message: 'Please input your first name'}]}>
                    <Input className='rk-input' placeholder='First Name' type='text'/>
                </Form.Item>

                <Form.Item name='last_name' rules={[{required: true, message: 'Please input your last name'}]}>
                    <Input className='rk-input' placeholder='Last Name' type='text'/>
                </Form.Item>

                <Row justify='center'>
                    {this.renderButton()}
                </Row>
            </div>
        );
    }

    public render(): React.ReactElement {
        let formInstance: FormInstance = this.formRef.current;

        return (
            <Form ref={this.formRef}
                  onChange={() => this.setState({})}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}>
                {this.renderRegisterStep(1)}

                <Row justify='space-around' align='middle'>
                    <Col xs={24}>
                        {this.renderForm()}
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default withStore(UserEditName);
