import React, { Component } from "react";
import {ComponentWithStore} from "models/RootStore";
import {Invoice} from "models/invoice/Invoice";
import {LoadingOutlined} from "@ant-design/icons";
import DefaultLayout from "layouts/DefaultLayout";
import {loadStripe} from "@stripe/stripe-js/pure";
import {PaymentIntent, SetupIntent, Stripe, StripeElements, StripeElementsOptions} from "@stripe/stripe-js";
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {observable, runInAction} from "mobx";
import {observer} from "mobx-react";
import {Navigate} from "react-router-dom";
import StripePaymentForm from "components/public/credit_cards/StripePaymentForm";
import {BillingAddress} from "models/billingAddress/BillingAddress";
import {elements} from "chart.js";
import BillingAddresses from "components/public/billing_addresses/_BillingAddresses";
import {Button, Row} from "antd";
import {CreditCardApi} from "models/creditCard/CreditCardApi";

interface IAuthorizePaymentProps {
  routeParams: { invoiceId: string };
}

interface IAuthorizePaymentState {
  invoice: Invoice | null;
  stripePromise: any;
  options: StripeElementsOptions;
  paid: boolean;
  previously_paid: boolean;
  counter: number;
  error: any;
  editing_billing: boolean;
  hasBillingAddress: boolean;
  submitting: boolean;
}

const MemoStripePaymentForm = React.memo(StripePaymentForm)

class AuthorizePayment extends ComponentWithStore<IAuthorizePaymentProps, IAuthorizePaymentState> {

  private confirmed: boolean = false;

  @observable private paymentIntent: PaymentIntent | null = null;

  @observable private billingAddress: BillingAddress;

  constructor(props: IAuthorizePaymentProps) {
    super(props);

    this.saveBillingAddress = this.saveBillingAddress.bind(this);
    this.editBillingAddress = this.editBillingAddress.bind(this);
    this.saveBilling = this.saveBilling.bind(this);

    this.state = {
      invoice: null,
      stripePromise: null,
      options: null,
      paid: false,
      previously_paid: false,
      counter: 3,
      error: null,
      editing_billing: false,
      hasBillingAddress: false,
      submitting: false
    };
  }

  componentDidMount() {
    const {invoice, stripePromise} = this.state;
    let id: number = Number(this.props.routeParams.invoiceId);

    if (!stripePromise) {
      const stripePromise = loadStripe(process.env.STRIPE_KEY)
      this.setState({
        stripePromise: stripePromise
      });
    }

    if (!invoice) {
      this.store.InvoiceProvider.getInvoice(id).then((invoice) => {
        this.setState({
          invoice: invoice,
          previously_paid: invoice.isPaid()
        });
        this.confirmInvoicePayment(invoice);
      }).catch((error) => {
        if (error.status === 401) {
          window.location.href = '/sign_in';
        }
      });
    } else {
      console.log('Invoice already loaded', invoice);
    }
  }

  public confirmInvoicePayment(invoice: Invoice) {
    if (invoice.stripe_error_code === 'authentication_required') {
      invoice.getPaymentIntent().then((intent: PaymentIntent) => {
        const previously_paid = intent.status === 'succeeded';
        this.setState({
          previously_paid: previously_paid,
          options: {clientSecret: intent.client_secret}
        });
        this.paymentIntent = intent;

        if (previously_paid) {
          this.fireRedirectCountdown();
        }
      });
    } else {
      invoice.getBillingAddress(this.store.BillingAddressProvider).then((billingAddress) => {
        this.billingAddress = billingAddress;
        this.setState({
          hasBillingAddress: true
        });
      });

      // Check that we have not already paid the invoice
      invoice.getPaymentIntent().then((intent: PaymentIntent) => {
        if (intent.status === 'succeeded') {
          this.setState({
            previously_paid: true
          });
          this.fireRedirectCountdown();
        }
      });

      // Get the stripe setup info so we can setup billing again.
      CreditCardApi.newIntent(this.store.SessionProvider.userId())
        .then(newIntentJson => this.setState({options: {clientSecret: newIntentJson.client_secret}}));
    }
  }

  public confirmCardPayment(stripe, invoice, intent) {
    if (!invoice || !intent || this.confirmed) {
      return;
    }

    stripe.confirmCardPayment(intent.client_secret, {
      payment_method: intent.payment_method || intent.last_payment_error?.payment_method?.id
    }).then((result) => {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        console.log(result.error.message);
        this.setState({
          error: result.error
        });
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          this.confirmed = true;
          this.setState({
            paid: true
          });
          this.fireRedirectCountdown();
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
      }
    });
  }

  private saveBilling() {
    this.setState({
      submitting: true
    });
    this.billingAddress.save().then(() => {
      this.setState({
        editing_billing: false,
        submitting: false
      });
    });
  }

  private saveBillingAddress(stripeSetupIntentId: string, creditCardId: number): Promise<any> {
    const invoice = this.state.invoice;
    return invoice.pay({
      stripe_setup_intent: stripeSetupIntentId,
      credit_card_id: creditCardId,
      billing_address_id: this.billingAddress.id
    }).then(() => {
      const success = invoice.isPaid();
      if (success) {
        this.setState({
          paid: success
        });
        this.confirmed = true;
        this.fireRedirectCountdown();
      } else {
        this.state.stripePromise.then((stripe: Stripe) => {
          invoice.getPaymentIntent().then((intent: PaymentIntent) => {
            this.paymentIntent = intent;
            if (invoice.stripe_error_message === 'authentication_required') {
              this.confirmCardPayment(stripe, invoice, intent);
            } else {
              const error_message = intent.last_payment_error?.message || 'An unknown error occurred. Please try again later.';
              this.setState({
                error: error_message,
                options: {clientSecret: intent.client_secret}
              });
            }
          });
        });
      }
    });
  }

  private editBillingAddress(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    this.setState({
      editing_billing: true
    });
  }

  render(): React.ReactElement {
    const {invoice} = this.state;
    let body;
    if (!invoice) {
      body = this.renderLoading();
    } else {
      body = this.renderPayment();
    }

    if (this.state.counter == 0) {
      return <Navigate to="/" replace={true} />
    }

    return (
      <DefaultLayout>
        {body}
      </DefaultLayout>
    );
  }

  renderPayment(): React.ReactElement {
    return (
      <div>
        <h1>Authorize Payment</h1>
        <div>
          {this.renderStripeAuthorizePayment()}
        </div>
      </div>
    );
  }

  renderStripeAuthorizePayment(): React.ReactElement {
    const {stripePromise, options, invoice, paid, previously_paid} = this.state;
    if (!stripePromise || !options || !invoice) {
      return this.renderLoading();
    }
    if (previously_paid) {
      return (
        <div>
          <h2>Invoice is already paid</h2>
          <p>We will automatically redirect you to the app... {this.state.counter}</p>
        </div>
      );
    }
    if (paid) {
      return (
        <div>
          <h2>Payment Confirmed</h2>
          <p>Thank you for your payment.</p>
          <p>We will automatically redirect you to the app... {this.state.counter}</p>
        </div>
      );
    }
    return (
      <Elements stripe={stripePromise} options={options}>
        <ElementsConsumer>
          {({stripe, elements}) => {
            return (
              <div>
                <p>{invoice.paymentErrorMessage()}</p>
                <h1>{invoice.header()}</h1>
                <h2>Rascal {invoice.paymentPeriod()} Subscription</h2>
                <p>Amount: $ {invoice.amount} {invoice.currency.toLocaleUpperCase()}</p>
                {this.renderPaymentDetails(stripe, elements)}
                {this.renderErrorMessage()}
              </div>
            );
          }}
        </ElementsConsumer>
      </Elements>
    );
  }

  renderLoading(): React.ReactElement {
    return (
      <div>
        <LoadingOutlined spin={true} />
      </div>
    );
  }

  private renderErrorMessage() {
    const {error} = this.state;
    if (error) {
      return (
        <div>
          <p>{error.message}</p>
        </div>
      )
    } else {
      return null;
    }
  }

  private fireRedirectCountdown() {
    setTimeout(() => {
      if (this.state.counter > 0) {
        this.setState({
          counter: this.state.counter - 1
        });
        this.fireRedirectCountdown();
      }
    }, 1000);
  }

  private confirmPayment(stripe: Stripe) {
    const {invoice} = this.state;
    if (!invoice || !stripe) return;
    this.confirmCardPayment(stripe, invoice, this.paymentIntent);
  }

  private renderPaymentDetails(stripe: Stripe, elements: StripeElements) {
    const {invoice, options, editing_billing, submitting} = this.state;
    if (invoice.stripe_error_code === 'authentication_required') {
      return (
        <div>
          <button disabled={!stripe || !this.paymentIntent} onClick={() => this.confirmPayment(stripe)}>Confirm Payment</button>
        </div>
      );
    } else if (editing_billing) {
      return (
        <div>
          <h1>Update Billing Address</h1>
          <BillingAddresses.Form billingAddress={this.billingAddress}/>
          <Row justify='center'>
                    {this.billingAddress.hasRequiredFields ?
                        <Button className='rk-btn rk-btn-dark rounded submit-form'
                                type='primary'
                                htmlType='submit'
                                onClick={this.saveBilling}>
                            { (submitting) ? <LoadingOutlined spin /> : 'Next' }
                        </Button>
                        :
                        <Button className='rk-btn rk-btn-dark rounded submit-form'
                                type='primary'
                                htmlType='submit'
                                disabled>
                            Next
                        </Button>
                    }
                </Row>
        </div>
      )
    } else {
      return (
        <div>
          <MemoStripePaymentForm stripe={stripe}
                                 elements={elements}
                                 options={options}
                                 billingAddress={this.billingAddress}
                                 hideHeader={true}
                                 onSave={this.saveBillingAddress}
                                 editBillingAddress={this.editBillingAddress}
                                 buttonText={'Pay Now'}
          />
        </div>
      )
    }
  }
}

export default observer(AuthorizePayment);

