import React, { Component, createRef } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Col, Row } from 'antd';
import ExpandOutlined from '@ant-design/icons/lib/icons/ExpandOutlined';

import AppInfo from 'models/AppInfo';
import { Story } from 'models/story/Story';
import { Settings } from 'models/settings/Settings';
import { ComponentWithStore } from 'models/RootStore';

import LanguageSelection from 'components/shared/LanguageSelection';
import LoadingOverlay from 'components/public/pages/webgl_app/LoadingOverlay';
import { StoryDetailsEvents } from 'components/public/pages/webgl_app/StoryDetailsEvents';
import StoryDetailsToggle from 'components/public/pages/webgl_app/StoryDetailsToggle';
import SoundIcon from 'components/shared/icons/SoundIcon';
import RestartIcon from 'components/shared/icons/RestartIcon';
import PlayIcon from 'components/shared/icons/PlayIcon';
import PauseMenuBackIcon from 'components/public/pages/webgl_app/PauseMenuBackIcon';
import PauseMenuCogIcon from 'components/public/pages/webgl_app/PauseMenuCogIcon';
import PauseMenuPlayIcon from 'components/public/pages/webgl_app/PauseMenuPlayIcon';
import PauseMenuForwardIcon from 'components/public/pages/webgl_app/PauseMenuForwardIcon';

interface IUnityWrapperProps {
    story: Story;
    onGoToNext: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    onGoToPrevious: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    onChangeLanguage: (value: string) => void;
    onChangeMusic: (enabled: boolean, event?: React.MouseEvent<HTMLButtonElement>) => void;
    onChangeNarration: (enabled: boolean, event?: React.MouseEvent<HTMLButtonElement>) => void;
    onRestart: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    onToggleFullscreen: () => void;
    checkPausedState: (showMenu: boolean) => void;
    children: React.ReactNode;
}

interface IUnityWrapperState {
    showPause: boolean;
    showPauseBackground: boolean;
    showMenu: boolean;
    fullscreen: boolean;
    loadingState: string;
}

export class UnityWrapper extends ComponentWithStore<IUnityWrapperProps, IUnityWrapperState> {
    private clicks: number = 0;
    private static TOUCHED_DELAY: number = 500;
    private static TAP_SPEED: number = 200;
    private lastTouchedAt: number = Date.now();

    private timeout = null;

    private wrapperRef: React.RefObject<HTMLDivElement> = createRef();

    private mounted: boolean = false;

    @observable private settings: Settings = new Settings(this.store.SettingsProvider).withDefaultValues(this.store.SessionProvider.userId());

    constructor(props: IUnityWrapperProps) {
        super(props);
        this.onSetLoadingState = this.onSetLoadingState.bind(this);
        this.onSetPauseState = this.onSetPauseState.bind(this);
        this.setPauseState = this.setPauseState.bind(this);
        this.captureTap = this.captureTap.bind(this);
        this.setFullscreen = this.setFullscreen.bind(this);
        this.requestFullscreen = this.requestFullscreen.bind(this);
        this.cancelFullscreen = this.cancelFullscreen.bind(this);
        this.isFullscreen = this.isFullscreen.bind(this);
        this.stopPropagation = this.stopPropagation.bind(this);
        this.dismiss = this.dismiss.bind(this);
        this.dismissMenuOr = this.dismissMenuOr.bind(this);
        this.resume = this.resume.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.restart = this.restart.bind(this);

        this.state = {
            showPause: false,
            showPauseBackground: false,
            showMenu: false,
            fullscreen: false,
            loadingState: ''
        };
    }

    public componentDidMount() {
        this.mounted = true;
        this.store.SettingsProvider.localSettings().then(settings => {
            this.settings = settings;
        });
        window.addEventListener(StoryDetailsEvents.SET_LOADING_STATE_EVENT, this.onSetLoadingState);
        window.addEventListener(StoryDetailsEvents.SET_PAUSED_STATE_EVENT, this.onSetPauseState);
    }

    public componentWillUnmount() {
        window.removeEventListener(StoryDetailsEvents.SET_LOADING_STATE_EVENT, this.onSetLoadingState);
        window.removeEventListener(StoryDetailsEvents.SET_PAUSED_STATE_EVENT, this.onSetPauseState);
        this.mounted = false;
    }

    private onSetLoadingState(event: Event): void {
        this.setState({ loadingState: event['detail'] });
    }

    private onSetPauseState(event: Event): void {
        this.setPauseState(event['detail']);
    }

    public isFullscreen() {
        return this.state.fullscreen;
    }

    public setFullscreen(fullscreen: boolean): void {
        if (this.state.fullscreen !== fullscreen && this.mounted) {
            this.setState({ fullscreen: fullscreen });
        }
    }

    public requestFullscreen(): Promise<void> {
        return this.wrapperRef?.current?.requestFullscreen();
    }

    public cancelFullscreen(): Promise<void> {
        if (document.fullscreenElement !== null) {
            return document.exitFullscreen();
        } else {
            return Promise.resolve();
        }
    }

    private captureTap(event: React.MouseEvent<HTMLDivElement>) {
        this.handleTap(event);
        return false;
    }

    private setPauseState(pause: boolean) {
        this.setState({
            showPause: pause,
            showMenu: false
        });
        setTimeout(() => {
            this.setState({ showPauseBackground: pause });
        }, UnityWrapper.TOUCHED_DELAY);
        this.props.checkPausedState(pause);
    }

    private handleTap(event: React.MouseEvent<HTMLDivElement>) {
        const { showMenu } = this.state;
        if (showMenu) {
            if (!this.recentlyTouched()) {
                this.setPauseState(false);
            }
            return;
        }

        this.clicks += 1;
        this.lastTouchedAt = Date.now();
        if (this.clicks >= 3) {
            this.setPauseState(true);
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.clicks = 0;
        }, UnityWrapper.TAP_SPEED);
    }

    private recentlyTouched(): boolean {
        return this.lastTouchedAt + UnityWrapper.TOUCHED_DELAY > Date.now();
    }

    private stopPropagation(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        return true;
    }

    private dismiss(e: React.MouseEvent<HTMLDivElement>) {
        const { showMenu } = this.state;
        if (!showMenu) {
            this.setPauseState(false);
        } else {
            this.setState({ showMenu: false });
        }
    }

    private dismissMenuOr(f: Function) {
        const { showMenu } = this.state;
        if (showMenu) {
            this.setState({ showMenu: false });
        } else {
            f();
        }
    }

    private resume(e: React.MouseEvent<HTMLDivElement>) {
        this.dismissMenuOr(() => {
            e.stopPropagation();
            this.setPauseState(false);
        });
    }

    private prev(e: React.MouseEvent<HTMLDivElement>) {
        this.dismissMenuOr(() => {
            e.stopPropagation();
            this.setPauseState(false);
            this.setState({ loadingState: 'loading' });
            this.props.onGoToPrevious();
        });
    }

    private next(e: React.MouseEvent<HTMLDivElement>) {
        this.dismissMenuOr(() => {
            e.stopPropagation();
            this.setPauseState(false);
            this.setState({ loadingState: 'loading' });
            this.props.onGoToNext();
        });
    }

    private restart(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        this.setPauseState(false);
        this.setState({ loadingState: 'loading' });
        this.props.onRestart();
    }

    private renderMenu(): React.ReactElement {
        const { story, onChangeLanguage, onChangeNarration, onToggleFullscreen, onChangeMusic } = this.props;
        const { showMenu, fullscreen } = this.state;

        return (
            <div className={`rk-webgl__popup-menu__container ${showMenu ? 'visible' : ''}`}>
                <div className={`rk-webgl__popup-menu ${showMenu ? 'visible' : ''}`} onMouseDown={this.stopPropagation}>
                    <div>
                        <h3>{story.title()}</h3>
                    </div>
                    <div className='rk-webgl__popup-menu__content'>
                        <div className='rk-webgl__popup-menu__row two-columns'>
                            <div className='rk-webgl__popup-menu__column'>
                                <div>Language</div>
                            </div>
                            <div className='rk-webgl__popup-menu__column column-right'>
                                <LanguageSelection defaultLanguage={this.settings.language} onChange={onChangeLanguage} />
                            </div>
                        </div>
                        <div className='rk-webgl__popup-menu__row toggle'>
                            <StoryDetailsToggle label='Music'
                                                icon={<SoundIcon />}
                                                cursor='pointer'
                                                isOn={() => this.settings.is_music_on}
                                                getValue={(settings: Settings) => settings.is_music_on}
                                                onChangeSwitch={onChangeMusic} />
                        </div>
                        <div className='rk-webgl__popup-menu__row toggle'>
                            <StoryDetailsToggle label='Narration'
                                                icon={<SoundIcon />}
                                                cursor='pointer' isOn={() => this.settings.is_narration_on}
                                                getValue={(settings: Settings) => settings.is_narration_on}
                                                onChangeSwitch={onChangeNarration} />
                        </div>
                        <div className='rk-webgl__popup-menu__row'>
                            <div className='rk-webgl__popup-menu__column' onClick={this.restart}>
                                <div className='rk-popup-option link-pointer'>
                                    <RestartIcon /> Restart Story
                                </div>
                            </div>
                        </div>
                        <div className='rk-webgl__popup-menu__row'>
                            <div className='rk-webgl__popup-menu__column' onClick={onToggleFullscreen}>
                                <div className='rk-popup-option link-pointer'>
                                    <ExpandOutlined /> {fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderPopup(): React.ReactElement {
        const { story } = this.props;
        const { showPause, showPauseBackground, showMenu } = this.state;

        return (
            <div className='rk-webgl__popup'>
                <div className={`rk-webgl__popup-buttons ${showPause ? 'visible' : ''}`} onMouseDown={this.stopPropagation}>
                    <div className={`rk-webgl__popup-buttons__background ${showPauseBackground ? 'visible' : ''}`} onClick={this.dismiss} />
                    <div className='rk-webgl__popup-buttons__story-title' onClick={this.dismiss}>
                        <h1 onClick={this.dismiss}>{story.title()}</h1>
                    </div>
                    <div className={`rk-webgl__popup-buttons__cog-icon ${showMenu ? '' : 'pointer'}`} onClick={() => this.setState({ showMenu: !showMenu })}>
                        <PauseMenuCogIcon />
                    </div>
                    <Row className='rk-webgl__popup-buttons__row'>
                        <Col span={8} className='rk-webgl__popup-buttons__col'>
                            <div className={`rk-webgl__popup-buttons__icon ${showMenu ? '' : 'pointer'}`} onClick={this.prev}>
                                <PauseMenuBackIcon />
                            </div>
                        </Col>
                        <Col span={8} className='rk-webgl__popup-buttons__col'>
                            <div className={`rk-webgl__popup-buttons__icon ${showMenu ? '' : 'pointer'}`} onClick={this.resume}>
                                <PauseMenuPlayIcon />
                            </div>
                        </Col>
                        <Col span={8} className='rk-webgl__popup-buttons__col'>
                            <div className={`rk-webgl__popup-buttons__icon ${showMenu ? '' : 'pointer'}`} onClick={this.next}>
                                <PauseMenuForwardIcon />
                            </div>
                        </Col>
                    </Row>
                    {this.renderMenu()}
                </div>
            </div>
        );
    }

    public render(): React.ReactElement {
        const { fullscreen, loadingState } = this.state;

        if (AppInfo.isMobile()) {
            return <div className='rk-webgl-app--container'></div>;
        }

        return (
            <div ref={this.wrapperRef} className={`rk-webgl-app--container ${fullscreen ? 'rk-fullscreen' : ''}`} onMouseDown={this.captureTap}>
                {loadingState === 'loading' && <LoadingOverlay />}
                {this.renderPopup()}
                {this.props.children}
            </div>
        );
    }
}

export default observer(UnityWrapper);
