import {action, computed, makeObservable, observable} from 'mobx';

import Format from 'helpers/Format';
import Value from 'helpers/Value';

import {IInvoiceApiData} from 'models/invoice/IInvoiceApiData';
import {InvoiceProvider} from 'models/invoice/InvoiceProvider';
import {InvoiceApi} from "models/invoice/InvoiceApi";
import {PaymentIntent, SetupIntent} from "@stripe/stripe-js";
import {BillingAddress} from "models/billingAddress/BillingAddress";
import {BillingAddressProvider} from "models/billingAddress/BillingAddressProvider";

export class Invoice {
    private readonly provider: InvoiceProvider;
    @observable public id: number;

    @observable public reference: string;
    @observable public stripe_setup_intent: string;

    @observable public stripe_customer_id: string;

    @observable public customer_name: string;
    @observable public email: string;
    @observable public line1: string;
    @observable public line2: string;
    @observable public city: string;
    @observable public state: string;
    @observable public zip: string;
    @observable public country: string;

    @observable public plan_name: string;
    @observable public period_start: Date | null;
    @observable public period_end: Date | null;

    @observable public currency: string;
    @observable public amount: number;
    @observable public amount_charged: number;
    @observable public stripe_credit_card_id: string;
    @observable public last_four_digits: string;

    @observable public paid_at: Date | null;
    @observable public cancelled_at: Date | null;
    @observable public deleted_at: Date | null;

    @observable public stripe_error_code: string;
    @observable public stripe_error_message: string;

    @observable public next_revision_id: number | null;
    @observable public prev_revision_id: number | null;

    @observable public user_id: number | null;
    @observable public subscription_id: number | null;
    @observable public billing_address_id: number | null;

    constructor(provider: InvoiceProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
    }

    public withId(id: number): Invoice {
        this.id = id;
        return this;
    }

    public withData(data: IInvoiceApiData): Invoice {
        this.load(data);
        return this;
    }

    @action.bound
    public load(data: IInvoiceApiData) {
        this.id = data.id;
        this.reference = data.reference;
        this.stripe_setup_intent = data.stripe_setup_intent;

        this.stripe_customer_id = data.stripe_customer_id;
        this.customer_name = data.customer_name;
        this.email = data.email;
        this.line1 = data.line1;
        this.line2 = data.line2;
        this.city = data.city;
        this.state = data.state;
        this.zip = data.zip;
        this.country = data.country;

        this.plan_name = data.plan_name;
        this.period_start = Value.dateOrNull(data.period_start);
        this.period_end = Value.dateOrNull(data.period_end);

        this.currency = data.currency;
        this.amount = data.amount;
        this.amount_charged = data.amount_charged;
        this.stripe_credit_card_id = data.stripe_credit_card_id;
        this.last_four_digits = data.last_four_digits;

        this.paid_at = Value.dateOrNull(data.paid_at);
        this.cancelled_at = Value.dateOrNull(data.cancelled_at);
        this.deleted_at = Value.dateOrNull(data.deleted_at);

        this.stripe_error_code = data.stripe_error_code;
        this.stripe_error_message = data.stripe_error_message;

        this.next_revision_id = data.next_revision_id;
        this.prev_revision_id = data.prev_revision_id;

        this.user_id = data.user_id;
        this.subscription_id = data.subscription_id;
        this.billing_address_id = data.billing_address_id;
    }

    public save(): Promise<Invoice> {
        return this.provider.editInvoice(this.id, {
            reference: this.reference,
            stripe_setup_intent: this.stripe_setup_intent,

            stripe_customer_id: this.stripe_customer_id,
            customer_name: this.customer_name,
            email: this.email,
            line1: this.line1,
            line2: this.line2,
            city: this.city,
            state: this.state,
            zip: this.zip,
            country: this.country,

            plan_name: this.plan_name,
            period_start: this.period_start,
            period_end: this.period_end,

            currency: this.currency,
            amount_charged: this.amount_charged,
            stripe_credit_card_id: this.stripe_credit_card_id,
            last_four_digits: this.last_four_digits,

            paid_at: this.paid_at,
            cancelled_at: this.cancelled_at,
            deleted_at: this.deleted_at,

            stripe_error_code: this.stripe_error_code,
            stripe_error_message: this.stripe_error_message,

            user_id: this.user_id,
            subscription_id: this.subscription_id,
            billing_address_id: this.billing_address_id
        });
    }

    public fetchData(): Promise<Invoice> {
        return this.provider.get(this.id).then(this.withData);
    }

    @computed
    public get Price() {
        return Format.money({amount: this.amount_charged, currency: this.currency})
    }

    public paymentErrorMessage() {
        if (!this.stripe_error_message) {
            return 'Unfortunately we ran into an error, while processing your payment. Please check your details and authorize payment.';
        } else {
            switch (this.stripe_error_code) {
                case 'authentication_required':
                    return 'You bank has requested authentication. Please confirm the payment.';
                case 'card_declined':
                    return 'Your card was declined. Please check your details and ensure that funds are available.';
                case 'expired_card':
                    return 'Your card has expired. Please update your card details.';
                case 'incorrect_cvc':
                    return 'Your card\'s security code is incorrect. Please update your card details.';
                case 'incorrect_number':
                    return 'Your card number is incorrect. Please update your card details.';
                case 'incorrect_zip':
                    return 'Your card\'s zip code is incorrect. Please update your billing details.';
                case 'invalid_cvc':
                    return 'Your card\'s security code is invalid. Please update your card details.';
                case 'invalid_expiry_month':
                    return 'Your card\'s expiration month is invalid. Please update your card details.';
                case 'invalid_expiry_year':
                    return 'Your card\'s expiration year is invalid. Please update your card details.';
                case 'invalid_number': // This is a generic error code, so we need to check the message
                    if (this.stripe_error_message.includes('Your card number is incorrect.')) {
                        return 'Your card number is incorrect. Please update your card details.';
                    } else {
                        return 'Unfortunately we ran into an error, while processing your payment. Please check your details and authorize payment.';
                    }
                case 'processing_error':
                    return 'Unfortunately we ran into an error, while processing your payment. Please check your details and authorize payment.';
                default:
                    return 'Unfortunately we ran into an error, while processing your payment. Please check your details and authorize payment.';
            }
        }
    }

    public getPaymentIntent(): Promise<PaymentIntent> {
        return InvoiceApi.getPaymentIntent(this.id).then((json) => {
            return json.payment_intent;
        });
    }

    public header(): string {
        if (this.period_start == null) {
            return `Invoice #${this.id}`;
        }
        const month = this.period_start?.toLocaleString('default', {month: 'long'}) || '';
        const year = this.period_start?.getFullYear() || '';
        return `Invoice ${month} ${year}`;
    }

    public paymentPeriod(): string {
        const diffDays = Math.ceil((this.period_end?.getTime() || 0) - (this.period_start?.getTime() || 0)) / (1000 * 3600 * 24);
        if (diffDays < 360) { // 1 year (it is close enough)
            return 'Monthly'
        } else {
            return 'Yearly'
        }
    }

    public pay(param: { billing_address_id: number; stripe_setup_intent: string; credit_card_id: number }): Promise<void> {
        return InvoiceApi.pay(this.id, param).then((json) => {
            this.load(json.invoice);
        });
    }

    public isPaid(): boolean {
        return this.paid_at != null;
    }

    public getBillingAddress(billingAddressProvider: BillingAddressProvider): Promise<BillingAddress> {
        return billingAddressProvider.get(this.billing_address_id);
    }
}
