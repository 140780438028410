import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {IUserApiData} from 'models/user/IUserApiData';


export class UserPermanentStore extends PermanentStore implements IExposedPermanentStore<IUserApiData> {
    protected get TableName(): string {
        return PermanentStore.TABLE_NAMES.UserPermanentStore;
    }

    constructor(rootStore: RootStore) {
        super(rootStore);
    }

    public getKey(id: number): string {
        return `api_users_${id}`;
    }

    public store(data: IUserApiData): Promise<void> {
        return this.doStore<IUserApiData>(this.TableName, this.getKey(data.id), data);
    }

    public read(id: number): Promise<IUserApiData | null> {
        return this.doRead<IUserApiData>(this.TableName, this.getKey(id));
    }
}
