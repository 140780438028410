import React, {Component} from 'react';
import {Switch} from 'antd';
import './StoryDetailsToggle.scss'
import {ComponentWithStore} from "models/RootStore";
import {ISettingsListener} from "models/settings/ISettingsListener";
import {Settings} from "models/settings/Settings";

export interface IStoryDetailsToggleProps {
    label: string;
    icon?: React.ReactElement;
    cursor: 'pointer' | 'progress' | 'not-allowed'
    isOn: () => boolean;
    color?: 'white' | 'black';
    getValue: (settings: Settings) => boolean;
    onChangeSwitch: (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface IStoryDetailsToggleState {
    value: boolean;
}

class StoryDetailsToggle extends ComponentWithStore<IStoryDetailsToggleProps, IStoryDetailsToggleState> implements ISettingsListener {

    constructor(props: IStoryDetailsToggleProps) {
        super(props);
        this.state = {
            value: this.props.isOn()
        }
    }

    componentDidMount() {
        this.store.SettingsProvider.registerUpdateListener(this);
    }

    componentWillUnmount() {
        this.store.SettingsProvider.unregisterUpdateListener(this);
    }

    onSettingsUpdate(settings: Settings): void {
        this.setState({ value: this.props.getValue(settings)});
    }

    public render(): React.ReactElement {
        const {onChangeSwitch, label, icon, cursor} = this.props;
        const {value} = this.state;
        const color = this.props.color ? this.props.color : 'black';

        return (
            <label className='story-view--toggle'
                   style={{cursor: cursor}}>
                <div className='story-view--toggle-container'
                     style={{cursor: cursor}}>
                    {icon ? icon : <React.Fragment />}
                    <span className='story-view-button--text'
                          style={{cursor: cursor}}>
                        {label}
                    </span>
                    <Switch className={`rk-switch ${color}`}
                            defaultChecked={value}
                            checked={value}
                            onChange={onChangeSwitch}/>
                </div>
            </label>
        );
    }
}

export default StoryDetailsToggle;
