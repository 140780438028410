import React from 'react';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {loadStripe} from '@stripe/stripe-js/pure';
import {StripeElementsOptions} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';

import {ComponentWithStore, withStore} from 'models/RootStore';
import {Subscription} from 'models/subscription/Subscription';
import {CreditCardApi} from 'models/creditCard/CreditCardApi';
import {BillingAddress} from 'models/billingAddress/BillingAddress';

import SimpleCreditCardForm from 'components/public/credit_cards/SimpleCreditCardForm';
import DefaultLayout from 'layouts/DefaultLayout';

import '../stripe.scss'
import './style.scss'

loadStripe.setLoadParameters({advancedFraudSignals: false});

interface IStripeCreditCardProps {
    routeParams: { subscriptionId: string };
}

interface IStripeCreditCardState {
    stripePromise: any;
    options: StripeElementsOptions;
    loaded: boolean;
    selectedCreditCardId: number | null;
}

class CreditCardNew extends ComponentWithStore<IStripeCreditCardProps, IStripeCreditCardState> {
    @observable private subscription: Subscription;
    @observable private billingAddress: BillingAddress;
    @observable private selectedCreditCardId: number | null;

    constructor(props: IStripeCreditCardProps) {
        super(props);

        this.state = {
            stripePromise: null,
            options: null,
            loaded: false,
            selectedCreditCardId: null
        };
    }

    public componentDidMount(): void {
        const {stripePromise} = this.state;

        let id: number = Number(this.props.routeParams.subscriptionId);
        this.subscription = new Subscription(this.store.SubscriptionProvider).withId(id);
        this.subscription.fetchData()
            .then(() => {
                this.billingAddress = new BillingAddress(this.store.BillingAddressProvider).withId(this.subscription.billing_address_id)
                return this.billingAddress.fetchData();
            })
            .then(() => this.setState({loaded: true}));

        CreditCardApi.newIntent(this.store.SessionProvider.userId())
            .then(newIntentJson => this.setState({options: {clientSecret: newIntentJson.client_secret}}));

        if (!stripePromise) {
            this.setState({stripePromise: loadStripe(process.env.STRIPE_KEY)});
        }
    }

    public render(): React.ReactElement {
        const {stripePromise, options, loaded, selectedCreditCardId} = this.state;

        if (stripePromise === null || options === null || !loaded) {
            return (
                <div>
                    Loading...
                </div>
            );
        }

        return (
            <DefaultLayout>
                <div className='rk-credit-card-content'>
                    <div className='rk-form-container'>
                        <Elements stripe={stripePromise} options={options}>
                            <ElementsConsumer>
                                {({stripe, elements}) => (
                                    <SimpleCreditCardForm stripe={stripe}
                                                          elements={elements}
                                                          options={options}
                                                          subscription={this.subscription}
                                                          billingAddress={this.billingAddress}
                                                          selectedCreditCardId={selectedCreditCardId}
                                                          onCreditCardSelection={(id) => this.setState({
                                                              selectedCreditCardId: id
                                                          })}
                                    />
                                )}
                            </ElementsConsumer>
                        </Elements>
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

export default observer(CreditCardNew);
