import React, {Component} from 'react';

class PauseMenuCogIcon extends Component<any, any> {
    public render(): React.ReactElement {
        return (
            <div className='rk-chapter-icon-wrapper'>
                <div className='rk-chapter-icon-container'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265.73 265.73">
                        <path fill='#FFFFFF' d="M220.67,156.4l16.26-6c1.96-.72,3.27-2.6,3.27-4.69v-27.1c0-2.09-1.3-3.97-3.27-4.69l-16.82-6.21c-2.36-.87-4.25-2.69-5.21-5.02l-2.47-6.01c-.95-2.31-.9-4.92.15-7.19l8.75-18.98c.88-1.9.48-4.15-1.01-5.63l-20.19-20.19c-1.48-1.48-3.73-1.88-5.63-1.01l-19.38,8.93c-2.24,1.03-4.81,1.1-7.1.19l-5.43-2.16c-2.37-.95-4.23-2.85-5.11-5.24l-6.39-17.31c-.72-1.96-2.6-3.27-4.69-3.27h-27.1c-2.09,0-3.97,1.3-4.69,3.27l-6.39,17.31c-.88,2.39-2.74,4.3-5.11,5.24l-5.43,2.16c-2.29.91-4.86.85-7.1-.19l-19.38-8.93c-1.9-.88-4.15-.48-5.63,1.01l-20.19,20.19c-1.48,1.48-1.88,3.73-1.01,5.63l8.75,18.98c1.05,2.27,1.1,4.87.15,7.19l-2.47,6.01c-.96,2.33-2.85,4.15-5.21,5.02l-16.82,6.21c-1.96.72-3.27,2.6-3.27,4.69v27.1c0,2.09,1.3,3.97,3.27,4.69l16.26,6c2.4.89,4.31,2.76,5.26,5.14l2.72,6.89c.9,2.29.83,4.84-.2,7.07l-8.44,18.3c-.88,1.9-.48,4.15,1.01,5.63l20.19,20.19c1.48,1.48,3.73,1.88,5.63,1.01l17.89-8.24c2.27-1.05,4.87-1.1,7.18-.15l6.9,2.83c2.33.96,4.16,2.85,5.03,5.21l6.41,17.36c.72,1.96,2.6,3.27,4.69,3.27h27.1c2.09,0,3.97-1.3,4.69-3.27l6.41-17.36c.87-2.36,2.7-4.25,5.03-5.21l6.9-2.83c2.31-.95,4.91-.89,7.18.15l17.89,8.24c1.9.88,4.15.48,5.63-1.01l20.19-20.19c1.48-1.48,1.88-3.73,1.01-5.63l-8.44-18.3c-1.03-2.23-1.1-4.79-.2-7.07l2.72-6.89c.94-2.38,2.85-4.25,5.26-5.14ZM132.86,183.77c-28.07,0-50.91-22.84-50.91-50.91s22.84-50.91,50.91-50.91,50.91,22.84,50.91,50.91-22.84,50.91-50.91,50.91Z"/>
                    </svg>
                </div>
            </div>
        );
    }
}

export default PauseMenuCogIcon;