import {Hash} from "../../../types/hash";
import React, {Component} from "react";
import {Select} from "antd";
import countryList  from 'country-list';

const { Option } = Select;

interface ICountrySelectProps {
  country: string,
  onCountryChange: (string) => void
}

class CountrySelect extends Component<ICountrySelectProps> {
  public static countryListNames = countryList.getNames();

  render() {
    const { country, onCountryChange } = this.props;
    return (
        <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a country"
            optionFilterProp="children"
            defaultValue={country}
            onChange={onCountryChange}
            filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
          {CountrySelect.countryListNames.sort().map((name) => {
            const code = countryList.getCode(name);
            return (
                <Option value={code} key={code}>{name}</Option>
            );
          })}
        </Select>
    );
  }
}

export default CountrySelect;
