import React from 'react';
import {observer} from 'mobx-react';
import {Button, Row} from 'antd';

import {ComponentWithStore, withStore} from 'models/RootStore';
import {BillingAddress} from 'models/billingAddress/BillingAddress';

interface IPreviewProps {
    billingAddress: BillingAddress,
    onEdit?: (billingAddress: BillingAddress) => void
}

interface IPreviewState {
}

class Preview extends ComponentWithStore<IPreviewProps, IPreviewState> {

    constructor(props: IPreviewProps) {
        super(props);
    }

    private renderEditButton(): React.ReactElement {
        const {billingAddress, onEdit} = this.props;

        if (!onEdit) return <React.Fragment/>

        return (
            <Row justify='center'>
                <Button className='rk-btn rk-btn-dark rounded submit-form'
                        type='primary'
                        onClick={() => onEdit(billingAddress)}>
                    Edit
                </Button>
            </Row>
        );
    }

    public render(): React.ReactElement {
        const {billingAddress} = this.props;

        return (
            <React.Fragment>
                <div>
                    <p>{billingAddress.name}</p>
                    <p>{billingAddress.line1}</p>
                    <p>{billingAddress.line2}</p>
                    <p>{billingAddress.city}</p>
                    <p>{billingAddress.state}</p>
                    <p>{billingAddress.zip}</p>
                    <p>{billingAddress.country}</p>
                </div>
                {this.renderEditButton()}
            </React.Fragment>
        );
    }
}

export default withStore(observer(Preview));
