import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {IStoryLocalisationApiData} from 'models/storyLocalisation/IStoryLocalisationApiData';

export class StoryLocalisationPermanentStore extends PermanentStore implements IExposedPermanentStore<IStoryLocalisationApiData[]> {
    protected get TableName(): string {
        return PermanentStore.TABLE_NAMES.StoryLocalisationPermanentStore;
    }

    constructor(rootStore: RootStore) {
        super(rootStore);
    }

    public getKey(id: number): string {
        return `api_story_localisation_${id}`;
    }

    public store(data: IStoryLocalisationApiData[], id: number): Promise<void> {
        return this.doStore<IStoryLocalisationApiData[]>(this.TableName, this.getKey(id), data);
    }

    public read(id: number): Promise<IStoryLocalisationApiData[] | null> {
        return this.doRead<IStoryLocalisationApiData[]>(this.TableName, this.getKey(id));
    }
}
