import * as _ from 'lodash';
import logger from 'loglevel';
import promiseRetry from 'promise-retry';

import Api from 'models/Api';
import {AbstractStore} from 'models/AbstractStore';
import {AssetBundleApi, AssetUrlEntity} from 'models/assetBundle/AssetBundleApi';
import {Story} from 'models/story/Story';
import {StoryRelease} from 'models/storyRelease/StoryRelease';
import {AssetBundle, ProgressState} from 'models/storyRelease/AssetBundleManifest';
import {ISceneBundle, IStoryBundle} from 'models/storyRelease/IAssetBundleManifest';
import {StorySession} from 'models/storySession/StorySession';
import {RootStore} from 'models/RootStore';
import {StoryLocalisation} from 'models/storyLocalisation/StoryLocalisation';
import { release } from 'os';

export class AssetBundleProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'AssetBundleProvider');
    }

    public getObjectUrl(storyId: number): Promise<string> {
        return this.AssetBundleIDB.open()
            .then(() => this.AssetBundleIDB.readObjectUrl(storyId));
    }

    public getAllAssetBundles(storyBundle: IStoryBundle): AssetBundle[] {
        let sceneBundles: ISceneBundle[] = storyBundle.scene_bundles;
        return Array.from(new Set(sceneBundles.flatMap(s => s.asset_bundles)));
    }

    private getAllDependentAssetBundles(
        assetBundle: AssetBundle,
        assetBundles: AssetBundle[],
        orderedAssetBundles: AssetBundle[]
    ): void {
        assetBundle.dependencies.forEach(name => {
            let dependencyBundle = assetBundles.find(bundle => bundle.name == name);
            if (dependencyBundle != null && !orderedAssetBundles.includes(dependencyBundle)) {
                this.getAllDependentAssetBundles(dependencyBundle, assetBundles, orderedAssetBundles)
            }
        });
        orderedAssetBundles.push(assetBundle);
    }

    private getOrderedBundles(storyBundle: IStoryBundle): AssetBundle[] {
        let sceneBundles: ISceneBundle[] = storyBundle.scene_bundles;
        let assetBundles =  Array.from(new Set(sceneBundles.flatMap(s => s.asset_bundles)));
        let sortedAssetBundleScenes = assetBundles.filter(bundle => bundle.is_scene)
            .sort((bundle1: AssetBundle, bundle2: AssetBundle) => bundle1.index - bundle2.index);
        let orderedAssetBundles: AssetBundle[] = [];

        sortedAssetBundleScenes.forEach(assetBundleIsScene => this.getAllDependentAssetBundles(
            assetBundleIsScene,
            assetBundles,
            orderedAssetBundles
        ));

        return orderedAssetBundles;
    }

    public calculateInitialSceneBundles(storyBundle: IStoryBundle): AssetBundle[] {
        let sceneBundles: ISceneBundle[] = storyBundle.scene_bundles;
        let firstScenes: ISceneBundle[] = sceneBundles.slice(0, 2)
        if (storyBundle.current_scene_bundle) {
            let i: number = sceneBundles.indexOf(sceneBundles.find(bundle => bundle.name == storyBundle.current_scene_bundle));
            if (i > -1) {
                firstScenes.push(sceneBundles[i])
                if (i + 1 < sceneBundles.length) firstScenes.push(sceneBundles[i + 1])
            }
        }

        return Array.from(new Set(firstScenes.flatMap(s => s.asset_bundles)));
    }

    public async downloadInitialSceneBundles(story: Story, storySession: StorySession): Promise<string[]> {
        let release = await story.getLatestCompatibleStoryRelease(true);
        if (!release) throw new Error('Compatible version could not be found');

        story.current_release = release;
        if (!story.current_release.unity_asset_manifest) throw new Error('No manifest provided');

        story.current_release.unity_asset_manifest.setReleaseId(story.current_release.id);

        let storyBundle: IStoryBundle = this.readStoryBundleFromWindow();
        storyBundle.current_scene_bundle = storySession?.current_asset_bundle || '';
        let bundles: AssetBundle[] = this.calculateInitialSceneBundles(storyBundle);

        return Promise.all(_.map(bundles, assetBundle => this.downloadOrLoadBundle(story, assetBundle)
            .then(blob => this.storeDataInWindow(blob, assetBundle))));
    }

    public async downloadRemainingScenes(story: Story, storySession: StorySession, force: boolean, callback: Function) {
        let release = story.current_release;
        if (!release) throw new Error('Compatible version could not be found');
        if (!story.current_release.unity_asset_manifest) throw new Error('No manifest provided');

        let storyBundle: IStoryBundle = this.readStoryBundleFromWindow();
        let orderedAssetBundles = this.getOrderedBundles(storyBundle);

        for (const assetBundle of orderedAssetBundles) {
            let blob = await this.downloadOrLoadBundle(story, assetBundle)
            await this.storeDataInWindow(blob, assetBundle);
            if (assetBundle.is_scene) await callback();
        }
    }

    public downloadSceneBundle(story: Story, name: string): Promise<string | string[]> {
        let storyBundle: IStoryBundle = this.readStoryBundleFromWindow();

        let sceneBundle = _.find(storyBundle.scene_bundles, (value: ISceneBundle) => value.name === name);

        if (!sceneBundle) {
            throw new Error(`Scene bundle not found ${name}`);
        }

        return Promise.all(_.map(sceneBundle.asset_bundles, bundle =>
            this.downloadOrLoadBundle(story, bundle)
                .then(blob => {
                    if (!blob) {
                        logger.debug(`Blob is null for ${bundle.name}, assuming it is already stored in the window`);
                        // Assume it is already stored in the window
                        return bundle.name;
                    }

                    return this.storeDataInWindow(blob, bundle);
                })));

    }

    public async awaitDownloadChapter(story: Story, sceneBundle: ISceneBundle): Promise<string[]> {
        let localisation: StoryLocalisation = story.default_localisation;
        let storyTitle: String = localisation?.title ?? 'Untitled';
        story.current_release.unity_asset_manifest.setReleaseId(story.current_release.id);

        return await Promise.all(_.map(sceneBundle.asset_bundles, async bundle => {
            let bundleName = bundle.name;
            let blob = await this.downloadOrLoadBundle(story, bundle);
            if (!blob) {
                logger.debug(`Blob is null for ${bundle.name}, assuming it is already stored in the window`);
                // Assume it is already stored in the window
                return bundle.name;
            }
            return this.storeDataInWindow(blob, bundle);
        }));
    }

    private async downloadOrLoadBundle(story: Story, bundle: AssetBundle): Promise<Blob> {
        switch (bundle.progress_state) {
            default:
                bundle.progress_state = ProgressState.IN_PROGRESS;

                if (!bundle.release_id) {
                    // bundle.progress_state = ProgressState.ERROR;
                    // throw new Error('Release not provided');
                    // TODO: Fix this earlier
                    bundle.release_id = story.current_release.id;
                }

                try {
                    let blob = await this.doDownloadOrLoadBundle(story, bundle);
                    bundle.progress_state = ProgressState.COMPLETED;
                    return blob;
                } catch (e) {
                    bundle.progress_state = ProgressState.ERROR;
                    throw e;
                }
            case ProgressState.IN_PROGRESS:
                logger.debug('Download already in progress');
                // return backOff(() => this.downloadOrLoadBundle(story,bundle), )
                return Promise.resolve(null);
            case ProgressState.COMPLETED:
                let buffer: ArrayBuffer = this.readDataFromWindow(bundle);
                if (!buffer) {
                    logger.debug(`Download already complete for ${story.id} but no file - restarting`);
                    bundle.progress_state = ProgressState.NOT_STARTED;
                    return this.downloadOrLoadBundle(story, bundle);
                } else {
                    logger.debug(`Download complete for ${story.id} - setting download percentage to 100`);
                    // TODO: Set downloading percentage to 100
                    return Promise.resolve(new Blob([buffer]));
                }
        }
    }

    private async doDownloadOrLoadBundle(story: Story, bundle: AssetBundle): Promise<Blob> {
        try {
            await this.AssetBundleIDB.open();
        } catch (e) {
            logger.error('Asset: Failed to open database', e);
            let assetUrlEntity: AssetUrlEntity = await this.getAssetUrlWithName(bundle.release_id, bundle.name);
            return this.downloadAndDecode(assetUrlEntity.asset_url)
        }

        let assetUrl: string = await this.getAssetUrlWithNameAndStore(story, bundle.release_id, bundle.name);
        let requiresUpdate = await this.AssetBundleIDB.requiresUpdate(story, story.current_release);
        let blob: Blob;
        if (!requiresUpdate) {
            blob = await this.AssetBundleIDB.readBlob(story, bundle);
            if (blob) return blob;
        }
        try {
            blob = await this.downloadAndDecode(assetUrl);
        } catch (error) {
            logger.error('AssetBundleProvider Failed', error, `... Retrying downloadAndDecode(${assetUrl})`);
            return this.downloadAndDecode(assetUrl);
        }
        return this.AssetBundleIDB.storeAll(story, story.current_release, blob, bundle);
    }

    private getAssetUrl(story: Story): Promise<string> {
        return this.AssetBundleIDB.open()
            .then(() => this.AssetBundleIDB.readAssetUrl(story.id))
            .then((url: string | null) => {
                if (url) return url;

                return AssetBundleApi.getAssetUrl(story.current_release.id)
                    .then(entity => this.AssetBundleIDB.storeAssetUrl(story, entity.asset_url));
            });
    }

    private getAssetUrlWithName(release_id: number, name: string): Promise<AssetUrlEntity> {
        return AssetBundleApi.getAssetUrlWithName(release_id, name);
    }

    private async getAssetUrlWithNameAndStore(story: Story, release_id: number, name: string): Promise<string> {
        let entity = await this.getAssetUrlWithName(release_id, name);
        return await this.AssetBundleIDB.storeAssetUrl(story, entity.asset_url, name);
    }


    private downloadAndDecode(assetUrl: string): Promise<Blob> {
        return promiseRetry(
            (retry, attemptNumber) => {
                if (attemptNumber > 1) logger.debug(`Attempt number ${attemptNumber}`);
                return fetch(assetUrl)
                    .then(Api.checkStatus)
                    .catch((response: Response) => Api.doRetry(response, retry));
            })
            .then((response: Response) => response.blob());
    }

    private async storeDataInWindow(blob: Blob, bundle: AssetBundle): Promise<string> {
        let binary: ArrayBuffer = await blob.arrayBuffer();
        window[bundle.DataPath] = binary;
        return bundle.name;
    }

    private readDataFromWindow(bundle: AssetBundle): ArrayBuffer {
        return window[bundle.DataPath];
    }

    public storeStoryBundleInWindow(storyBundle: IStoryBundle): void {
        window['story_bundle_data'] = storyBundle;
    }

    public readStoryBundleFromWindow(): IStoryBundle {
        return window['story_bundle_data'];
    }
}
