import React, { Component } from 'react';
import { Select } from 'antd';
import { ComponentWithStore } from 'models/RootStore';
import { ISettingsListener } from 'models/settings/ISettingsListener';
import { Settings } from 'models/settings/Settings';
import { Language } from 'models/settings/Language';

interface ILanguageSelectionProps {
    options?: string[];
    color?: 'white' | 'black';
    defaultLanguage: Language;
    onChange: (value: string) => void;
}

interface ILanguageSelectionState {
    currentLanguage: string;
}

class LanguageSelection extends ComponentWithStore<ILanguageSelectionProps, ILanguageSelectionState> implements ISettingsListener {
    constructor(props: ILanguageSelectionProps) {
        super(props);
        this.state = {
            currentLanguage: props.defaultLanguage
        };
    }

    public componentDidMount() {
        this.store.SettingsProvider.registerUpdateListener(this);
    }

    public componentWillUnmount() {
        this.store.SettingsProvider.unregisterUpdateListener(this);
    }

    public onSettingsUpdate(settings: Settings): void {
        this.setState({ currentLanguage: settings.language });
    }

    public render() {
        const { onChange, color } = this.props;
        const { currentLanguage } = this.state;
        let options = this.props.options;
        if (!options || options.length === 0) {
            options = [Language.English, Language.TeReo];
        }

        return (
            <Select className={`story-view--language-selection ${color ? color : 'black'}`}
                    value={currentLanguage}
                    defaultValue={currentLanguage}
                    onChange={onChange}
                    variant="borderless">
                {options.map(o => (
                    <Select.Option key={o} value={o}>
                        {o}
                    </Select.Option>
                ))}
            </Select>
        );
    }
}

export default LanguageSelection;
