import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {Col, Dropdown, MenuProps, Row, Space} from 'antd';

import {ComponentWithStore} from 'models/RootStore';
import AppInfo from 'models/AppInfo';

import RascalLogo from 'layouts/headers/RascalLogo';
import BurgerMenu from 'components/shared/icons/BurgerMenu';

interface IDefaultHeaderProps {
    childContent?: React.ReactElement;
    customAccountBanner?: React.ReactElement;
}

interface IDefaultHeaderState {
    displayChildContent: boolean;
}

class DefaultHeader extends ComponentWithStore<IDefaultHeaderProps, IDefaultHeaderState> {
    private mounted: boolean = false;

    public constructor(props: IDefaultHeaderProps) {
        super(props);

        this.state = {
            displayChildContent: false
        }

        this.scrollListener = this.scrollListener.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    public componentDidMount(): void {
        this.mounted = true;
        window.addEventListener('scroll', this.scrollListener);
    }

    public componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('scroll', this.scrollListener)
    }

    private createMenuItems(): MenuProps['items'] {
        let items: MenuProps['items'];

        if (AppInfo.isAdmin()) {
            items = [
                {label: <a href='/admin'>Admin</a>, key: 0, danger: true},
                {label: <a href='/account'>Settings</a>, key: 1,},
                {type: 'divider'},
                {label: <a onClick={this.signOut}>Sign Out</a>, key: 2,}
            ];
        } else {
            items = [
                {label: <a href='/account'>Settings</a>, key: 0,},
                {type: 'divider',},
                {label: <a onClick={this.signOut}>Sign Out</a>, key: 1,}
            ];
        }
        return items;
    }

    private scrollListener() {
        this.setState({displayChildContent: window.scrollY > 100});
    }

    private signOut(e: React.MouseEvent<HTMLElement>): void {
        e.preventDefault();
        e.stopPropagation();
        this.store.SessionProvider.signOut()
            .catch(() => 'doesnt matter we are signing out anyway.')
            .then(() => window.location.href = '/');
    }

    public renderAccountBanner(): React.ReactElement {
        let items: MenuProps['items'] = this.createMenuItems();

        if (this.store.SessionProvider.hasCredentials()) {
            // Logged In User
            return (
                <Dropdown
                    menu={{items}}
                    trigger={['click']}
                    placement='bottomRight'
                    overlayStyle={{width: '200px'}}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space className='rk-nav--account-menu-dropdown'>
                            Account
                            <svg className={`account-chevron down`} xmlns='http://www.w3.org/2000/svg'
                                 viewBox='0 0 35.53 22.404'
                                 width='16.999' height='9.611'>
                                <path xmlns='http://www.w3.org/2000/svg' className='st0' fill='#1C2039'
                                      d='M1.5,5c-0.9-1-0.9-2.5,0-3.5C2,1,2.6,0.8,3.3,0.8C3.9,0.8,4.5,1,5,1.5l12.8,13.1l12.8-13   c0.5-0.5,1.1-0.7,1.8-0.7s1.3,0.3,1.8,0.7c1,1,1,2.5,0,3.5L17.8,21.3L1.5,5z'/>
                                <path xmlns='http://www.w3.org/2000/svg' className='st1' fill='#F2F2F2'
                                      d='M3.3,1.5c0.5,0,0.9,0.2,1.2,0.5l12.2,12.6l1.1,1.1l1.1-1.1L31.1,2.1c0.3-0.3,0.8-0.5,1.2-0.5   c0.5,0,0.9,0.2,1.2,0.5l0.1,0.1c0.6,0.7,0.6,1.7,0,2.4L17.8,20.3L2,4.5C1.4,3.8,1.4,2.7,2,2.1C2.4,1.7,2.8,1.5,3.3,1.5L3.3,1.5    M3.3,0C2.4,0,1.6,0.4,0.9,1c-1.2,1.3-1.2,3.3,0,4.5l16.8,16.9L34.6,5.7c1.3-1.3,1.3-3.3,0-4.6c0,0,0,0,0,0   c-0.6-0.6-1.5-0.9-2.3-0.9S30.6,0.4,30,1.1L17.8,13.5L5.5,0.9C4.9,0.3,4.1,0,3.3,0L3.3,0z'/>
                            </svg>
                        </Space>
                        <Space className='rk-nav--account-burger-dropdown' align={'center'}>
                            <BurgerMenu/>
                        </Space>
                    </a>
                </Dropdown>
            );
        } else {
            if (window.location.href.includes('/register')) {
                return (
                    <Link to='/sign_in' rel='noopener noreferrer'> Login </Link>
                );
            }
            // Not logged in.
            return (
                <Link to='/register' rel='noopener noreferrer'> Sign up </Link>
            );
        }
    }

    public render(): React.ReactElement {
        return (
            <React.Fragment>
                <div className='rk-default-nav'>
                    <Row align='middle'>
                        <Col xs={8} lg={{span: 10, offset: 2}}>
                            <RascalLogo/>
                        </Col>
                        <Col xs={16} lg={10}>
                            {this.props.customAccountBanner ? this.props.customAccountBanner :
                                <Row className='rk-nav-link' justify='end' align='middle'>
                                    <Col hidden={!this.state.displayChildContent}>
                                        {this.props.childContent}
                                    </Col>
                                    <Col>
                                        {this.renderAccountBanner()}
                                    </Col>
                                </Row>}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default observer(DefaultHeader);
