import React from 'react';
import Api from 'models/Api';

export interface AssetUrlEntity {
    asset_url: string;
}

export class AssetBundleApi {
    public static getAssetUrl(story_release_id: number | null): Promise<AssetUrlEntity | null> {
        if (story_release_id == null) {
            return Promise.resolve<AssetUrlEntity | null>(null);
        }

        return Api.get(`/api/v1/story_releases/${story_release_id}/asset_url`)
            .then(response => response.json());
    }

    public static getAssetUrlWithName(story_release_id: number | null, name: string): Promise<AssetUrlEntity | null> {
        if (story_release_id == null) {
            return Promise.resolve<AssetUrlEntity | null>(null);
        }

        return Api.get(`/api/v1/story_releases/${story_release_id}/${name}/asset_url`)
            .then(response => response.json());
    }
}
