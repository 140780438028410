import React, {Component} from 'react';
import {CheckOutlined, CloseOutlined, LineOutlined} from '@ant-design/icons';
import './TernarySlider.scss';

interface ITernarySliderProps {
  handleStateChange?: (state: boolean | null) => void;
}

interface ITernarySliderState {
  checked: boolean | null;
}

class TernarySlider extends Component<ITernarySliderProps, ITernarySliderState> {
  constructor(props: ITernarySliderProps) {
    super(props);
    this.state = {
      checked: null
    };
  }

  private handleStateChange(checked: boolean | null): void {
    this.setState({checked: checked});
    this.props.handleStateChange?.(checked);
  }

  public render() {
    const {checked} = this.state;

    return (
      <div className='rk-switch-ternary'>

        <input id='on' name='state-d' type='radio' checked={checked === true}
               onChange={() => this.handleStateChange(true)}/>
        <label htmlFor='on' onClick={() => this.handleStateChange(true)}><CheckOutlined/></label>

        <input id='na' name='state-d' type='radio' checked={checked === null}
               onChange={() => this.handleStateChange(null)}/>
        <label htmlFor='na' onClick={() => this.handleStateChange(null)}><LineOutlined/></label>

        <input id='off' name='state-d' type='radio' checked={checked === false}
               onChange={() => this.handleStateChange(false)}/>
        <label htmlFor='off' onClick={() => this.handleStateChange(false)}><CloseOutlined/></label>

      </div>
    );
  }
}

export default TernarySlider;