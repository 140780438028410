import React, {Component} from 'react';
import {Layout} from 'antd';
import {Content} from 'antd/lib/layout/layout';

import DefaultFooter from 'layouts/footers/DefaultFooter';
import DefaultHeader from 'layouts/headers/DefaultHeader';
import AdminSideBar from 'components/admin/pages/AdminSideBar';

interface IAdminLayoutProps {
  childContent?: React.ReactElement;
  children?: any;
}

interface IAdminLayoutState {
}

class AdminLayout extends Component<IAdminLayoutProps, IAdminLayoutState> {

  constructor(props: IAdminLayoutProps) {
    super(props);
  }

  public render(): React.ReactElement {
    return (
      <div className='rk-admin-layout'>
        <div className='rk-header'>
          <DefaultHeader childContent={this.props.childContent}/>
        </div>
        <div className='rk-content'>
          <Layout>
            <AdminSideBar/>
            <Content>
              <div className={'rk-content-section'}>
                <div className={'rk-content-expand'}>
                  {this.props.children}
                </div>
                <div className='rk-footer'>
                  <DefaultFooter/>
                </div>
              </div>
            </Content>
          </Layout>
        </div>
      </div>
    );
  }
}

export default AdminLayout;
