import React from 'react';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Col, Row} from 'antd';

import Format from 'helpers/Format';
import {ComponentWithStore, withStore} from 'models/RootStore';
import {Invoice} from 'models/invoice/Invoice';

import DefaultLayout from 'layouts/DefaultLayout';
import PageTitle from 'components/shared/PageTitle';

import './InvoiceIndex.scss';

interface IInvoiceProps {
    routeParams: { invoiceId: string }
}

interface IInvoiceState {
    loaded: boolean;
    userId: number | null;
}

class InvoiceShow extends ComponentWithStore<IInvoiceProps, IInvoiceState> {
    @observable private invoice: Invoice;

    public constructor(props: IInvoiceProps) {
        super(props);

        makeObservable(this);

        let userId: number | null = this.store.SessionProvider.userId();

        this.state = {
            loaded: false,
            userId: userId
        };
    }

    public componentDidMount(): void {
        let invoiceId: number = Number(this.props.routeParams.invoiceId);
        this.invoice = new Invoice(this.store.InvoiceProvider).withId(invoiceId);
        this.invoice.fetchData().then(() => this.setState({loaded: true}));
    }

    private renderInvoiceDetails(): React.ReactElement {
        if (!this.invoice) return <React.Fragment/>

        return (
            <Row>
                <div style={{width: '100%'}}>
                    <Row>
                        <Col span={6}>
                            Period
                        </Col>
                        <Col>
                            From {this.invoice.period_start && Format.longDate(this.invoice.period_start)} to {this.invoice.period_end && Format.longDate(this.invoice.period_end)}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            Amount Charged ({this.invoice.currency})
                        </Col>
                        <Col>
                            {this.invoice.amount_charged}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            Paid At
                        </Col>
                        <Col>
                            {this.invoice.paid_at ? Format.longDate(this.invoice.paid_at) : '—'}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            Payment Method
                        </Col>
                        <Col>
                            Card ending with {this.invoice.last_four_digits}
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={6}>
                            Customer Name
                        </Col>
                        <Col>
                            {this.invoice.customer_name}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            Email
                        </Col>
                        <Col>
                            {this.invoice.email}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            Address
                        </Col>
                        <Col>
                            {this.invoice.line1 && <span>{this.invoice.line1} <br/></span>}
                            {this.invoice.line2 && <span>{this.invoice.line2} <br/></span>}
                            {this.invoice.state && <span>{this.invoice.state} <br/></span>}
                            {this.invoice.zip && <span>{this.invoice.zip} <br/></span>}
                            {this.invoice.country && <span>{this.invoice.country} <br/></span>}
                        </Col>
                    </Row>
                </div>
            </Row>
        );
    }

    private renderHeader(): React.ReactElement {
        return (
            <Row className='rk-title'>
                <PageTitle to='/invoices'
                           title='Invoice'
                           subtitle={this.invoice?.plan_name}/>
            </Row>
        );
    }

    public render(): React.ReactElement {
        return (
            <DefaultLayout>
                <div className='rk-invoices'>
                    {this.renderHeader()}
                    {this.renderInvoiceDetails()}
                </div>
            </DefaultLayout>
        );
    }
}

export default withStore(observer(InvoiceShow));
