import {action, makeObservable, observable} from 'mobx';
import {UserProvider} from 'models/user/UserProvider';
import {IUserApiData, IUserApiDataStatsResponse} from 'models/user/IUserApiData';
import {ISubscriptionApiData} from 'models/subscription/ISubscriptionApiData';

export class User {
    private readonly provider: UserProvider | null;

    @observable public id: number;

    @observable public key: number;
    @observable public email: string;
    @observable public first_name: string;
    @observable public last_name: string;
    @observable public created_at: Date;

    constructor(provider: UserProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
    }

    public withId(id: number) {
        this.id = id;
        this.key = id;
        return this;
    }

    public withData(data: IUserApiData) {
        this.load(data);
        return this;
    }

    @action
    public withSubscriptionData(data: ISubscriptionApiData.ISubscriberData): User {
        this.id = data.id;
        this.key = data.id;
        this.first_name = data.first_name;
        this.last_name = data.last_name;


        return this;
    }

    public get FullName(): string {
        return `${this.first_name || ''} ${this.last_name || ''}`;
    }

    @action.bound
    public load(data: IUserApiData) {
        this.id = data.id;
        this.key = data.id;
        this.email = data.email;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.created_at = data.created_at;
    }

    public fetch(force: boolean = false): Promise<User> {
        return this.provider.user(this.id, force)
            .then((data: IUserApiData) => this.withData(data))
    }

    public getStats(): Promise<IUserApiDataStatsResponse> {
        return this.provider.getStats(this.id)
    }
}
