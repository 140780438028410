import {StoryLocalisation} from 'models/storyLocalisation/StoryLocalisation';
import {StoryLocalisationProvider} from 'models/storyLocalisation/StoryLocalisationProvider';

class Value {
  public static defaultFalse(field: boolean): boolean {
    return field == null ? false : field;
  }

  public static defaultTrue(field: boolean): boolean {
    return field == null ? true : field;
  }

  public static defaultZero(field: number): number {
    return field == null ? 0 : field;
  }

  public static default<T>(field: T | null, defaultResponse: T): T {
    return field == null ? defaultResponse : field;
  }

  public static nullIfEmptyString(value: any): string {
    if (value != null && value instanceof String && value.length == 0) {
      return null
    }
    return value;
  }

  public static isNullOrEmptyString(value: any): boolean {
    return value == null || ((typeof value === 'string' || value instanceof String) && value.length == 0);
  }

  public static dateOrNull(value: any): Date | null {
    if (value == null) {
      return null;
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'string') {
      return new Date(value);
    }
    return null;
  }

  public static localisationOrNull(provider: StoryLocalisationProvider, value: any): StoryLocalisation | null {
    if (value == null || !(value instanceof Object)) {
      return null
    }
    return new StoryLocalisation(provider).withData(value);
  }

  public static stripArrayIfSingle(value: any): any {
    if (value instanceof Array && value.length === 1) {
      return value[0];
    }
    return value;
  }

  static numberOrNull(value: any): number | null {
    if (typeof value === "number" || !isNaN(value)) {
      return value;
    }
    return null;
  }

  static ifDefined<T>(value: T | undefined, newValue: T): T {
    if (newValue !== null && newValue !== undefined) {
      return newValue;
    }
    return value;
  }

  static ifDefinedCallback<S,T>(value: S | undefined, callback: () => T): T | undefined {
    if (value !== undefined && value !== null) {
      return callback();
    }
    return null;
  }
}

export default Value;
