import React, { Component } from 'react';

import { Story } from 'models/story/Story';

import './StoryPanel.scss';

interface IStoryPanelProps {
  story: Story;
  admin?: boolean;
  layout?: 'tall' | 'short';
  showMessage?: boolean;
  homepage?: boolean;
}

interface IStoryPanelState {
}

class StoryPanel extends Component<IStoryPanelProps, IStoryPanelState> {
  private SEPARATOR: string = ' • ';

  public constructor(props: IStoryPanelProps) {
    super(props);
  }

  public render(): React.ReactElement {
    const { story, admin, layout, showMessage, homepage } = this.props;

    let localisation = story.default_localisation;
    return (
      <div
        className={ `rk-story-panel rk-story-panel--${ layout || 'tall' } ${ homepage ? 'rk-story-panel--padding-left'
          : '' }` } key={ `${ story.id }` }>
        <button className='rk-story-panel--image__button'
                onClick={ () => window.location.href =
                  admin === true ? `/admin/stories/${ story.id }` : `/story/${ story.id }` }>
          <img className='rk-story-panel--image' alt={ story.title() }
               src={ layout === 'short' ? localisation?.image_url : localisation?.portrait_image_url }/>
          { story.hasCompatibleOfflineStoryRelease() &&
            <div className='rk-story-panel--downloaded-icon-container'>
                <img className='rk-story-panel--downloaded-icon'
                     src='images/mobile-download.png'
                     alt='downloaded'/>
            </div>
          }
          { !story.has_been_read && showMessage &&
            <div className='rk-story-panel--message-container rk-story-panel--new-stories-message'>
                NEW STORIES
            </div>
          }
          { story.coming_soon && showMessage &&
            <div className='rk-story-panel--message-container rk-story-panel--coming-soon-message'>
                COMING SOON
            </div>
          }
        </button>
        <div className='rk-story-panel--description'>
          <p className='rk-story-panel--genre-text-container'>
            { story.genre }
          </p>
          <p className='rk-story-panel--title-text-container'>
            { story.title() }
          </p>
          <p className='rk-story-panel--description-text-container'>
            { localisation?.biography }
          </p>
        </div>
      </div>
    );
  }
}

export default StoryPanel;
