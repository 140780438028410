import {SortOrder} from 'antd/lib/table/interface';

import Api from 'models/Api';
import {SearchApi} from 'models/SearchApi';
import {IStoryReleaseSearchApiData} from 'models/storyRelease/IStoryReleaseSearchApiData';
import {
    IStoryReleaseApiDataCreateRequest,
    IStoryReleaseApiDataGetResponse,
    IStoryReleaseApiDataUpdateRequest
} from 'models/storyRelease/IStoryReleaseApiData';

export type IStoryReleaseApiSearchOrdering = 'id' | 'active' | 'created_at';

export interface IStoryReleaseApiSearchFilterParams {
    id?: string;
    active?: boolean;
    created_at?: string;
    order_by?: IStoryReleaseApiSearchOrdering;
    order?: SortOrder;
}

export interface IStoryReleaseApiSearchParams {
    story_ids?: number[];
    page?: number;
    page_size?: number;
    filters?: IStoryReleaseApiSearchFilterParams
}

export class StoryReleaseApi {

    public static create(storyRelease: IStoryReleaseApiDataCreateRequest): Promise<IStoryReleaseApiDataGetResponse> {
        return Api.post('/api/v1/story_releases', {
            body: JSON.stringify({
                story_release: storyRelease
            })
        })
            .then(response => response.json())
            .then(json => json.story_release);
    }

    public static getAll(storyId: number | null = null): Promise<IStoryReleaseApiDataGetResponse[]> {
        let request: Promise<Response>;
        if (storyId) {
            request = Api.get(`/api/v1/stories/${storyId}/story_releases`)
        } else {
            request = Api.get(`/api/v1/story_releases`)
        }

        return request
            .then(response => response.json())
            .then(json => json.story_releases);
    }

    public static getStoryReleaseSearch(searchParams?: IStoryReleaseApiSearchParams): Promise<IStoryReleaseSearchApiData> {
        let query: string[] = [];
        let queryStr = ''
        if (searchParams) {
            const {story_ids} = searchParams;
            if (story_ids && story_ids.length > 0) {
                query.push(`story_ids=${searchParams.story_ids.join(',')}`);
            }
            queryStr = SearchApi.buildQueryFromParams(searchParams, query);
        }

        return Api.get(`/api/v1/story_releases/search${queryStr}`)
            .then(response => response.json());
    }

    public static update(id: number, data: IStoryReleaseApiDataUpdateRequest): Promise<IStoryReleaseApiDataGetResponse> {
        return Api.put(`/api/v1/story_releases/${id}`, {
            body: JSON.stringify({
                story_release: data
            })
        })
            .then(response => response.json())
            .then(json => json.story_release);
    }
}
