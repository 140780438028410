import React, {RefObject} from 'react';
import {Link} from 'react-router-dom';
import Turnstile from 'react-turnstile';
import Cookies from 'universal-cookie';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Checkbox, Col, Form, Input, Row} from 'antd';
import {FormInstance} from 'antd/es';
import {LoadingOutlined} from '@ant-design/icons';

import Value from 'helpers/Value';
import {ComponentWithStore, withStore} from 'models/RootStore';
import Api from 'models/Api';

import DefaultLayout from 'layouts/DefaultLayout';
import SubscriptionNew from 'components/public/pages/subscriptions/SubscriptionNew';
import RegisterSetName from 'components/public/pages/register/UserEditName';

import './styles.scss';
import {ApiResponseData} from "models/ApiResponseData";

const turnstileSiteKey: string = process.env.TURNSTILE_SITE_KEY;

interface IRegisterProps {

}

interface IRegisterState {
    loaded: boolean;
    registered: boolean;
    nameIsMissing: boolean;
    submitting: boolean;
    acceptedTerms: boolean;
    newsletter: boolean;
    userId: number | null;
    campaign_code: string | boolean;
    showToken: boolean;
    errorMessage: ApiResponseData | null;
}

class Register extends ComponentWithStore<IRegisterProps, IRegisterState> {
    private static COOKIE_NAME: string = 'campaign';
    @observable private captchaResponse: string;
    private cookies: Cookies = new Cookies();
    private subscriptionKey: String;
    @observable private defaultToken: string | null = null;

    private formRef: RefObject<FormInstance> = React.createRef<FormInstance>();

    public constructor(props) {
        super(props);
        makeObservable(this);

        let userId = this.store.SessionProvider.userId();

        this.state = {
            loaded: false,
            registered: false,
            nameIsMissing: false,
            submitting: false,
            acceptedTerms: false,
            newsletter: false,
            userId: userId,
            campaign_code: this.cookies.get(Register.COOKIE_NAME),
            showToken: false,
            errorMessage: null
        };

        this.onFinish = this.onFinish.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.errorCallback = this.errorCallback.bind(this);
        this.expiredCallback = this.expiredCallback.bind(this);
        this.showToken = this.showToken.bind(this);
        this.updateSubscriptionKey = this.updateSubscriptionKey.bind(this);
    }

    public componentDidMount(): void {
        const {userId} = this.state;


        Promise.all([
            this.checkIfRegistered(),
            this.checkIfNameIsMissing(userId)
        ]).finally(() => {
            this.setState({
                showToken: this.showToken(),
                loaded: true
            })
        });


        this.readParams();
    }

    private checkIfNameIsMissing(userId: number): Promise<void> {
        if (!userId) return Promise.resolve();

        return this.store.UserProvider.user(userId)
            .then(user => {
                if (user) {
                    this.setState({nameIsMissing: !user.first_name && !user.last_name})
                }
            })
    }

    private checkIfRegistered(): Promise<void> {
        // TODO: Re enable check so logged in people can't do this again.
        return this.store.SessionProvider.hasValidCredentials()
            .then((result: boolean) => {
                if (result) {
                    this.setState({registered: true});
                }
            });
    }

    public onFinish(values): void {
        const {newsletter, campaign_code, showToken} = this.state;

        this.setState({submitting: true, errorMessage: null});
        values.newsletter = newsletter;
        values.campaign_code = campaign_code;
        if (showToken) {
           values.subscription_key = this.subscriptionKey;
        }
        // TODO: Use UserProvider
        Api.post('api/v1/users', {
            body: JSON.stringify({
                user: values,
                'cf-turnstile-response': this.captchaResponse
            })
        })
            .then(response => response.json())
            .then(json => this.store.SessionProvider.store(json))
            .then(result => {
                this.setState({submitting: false});
                if (result.success) {
                    if (showToken) {
                        // Try and load the homepage, worst case we will redirect back to the subscription page.
                        window.location.href = '/'
                    } else {
                        this.setState({registered: true});
                    }
                } else {
                    this.setState({errorMessage: result});
                }
            })
            .catch((response) => {
                if (response?.status == 402) {
                    response.json().then((json) => {
                        this.setState({errorMessage: {success: false, message: json.message }})
                    });
                } else if (response?.status) {
                    response.json().then((json) => {
                        this.setState({errorMessage: json});
                    });
                }
                this.setState({submitting: false});
            });
    }

    public updateSubscriptionKey(key: string): void {
        this.subscriptionKey = key;
    }

    public onFinishFailed(errorInfo): void {
        console.log('Failed:', errorInfo);
    }

    public errorCallback(): void {
    }

    public verifyCallback(response: string): void {
        this.captchaResponse = response;
    }

    public expiredCallback(): void {
        this.captchaResponse = null;
    }

    private readParams() {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        if (params.has('email')) {
            const form = this.formRef?.current;
            if (form) {
                form.setFields([{
                    name: 'email',
                    value: params.get('email')
                }]);
            } else {
                setTimeout(() => {
                    const form = this.formRef?.current;
                    if (form) {
                        form.setFields([{
                            name: 'email',
                            value: params.get('email')
                        }]);
                    }
                }, 100);
            }
        }
    }

    private showToken() {
        const windowUrl = window.location;
        const params = new URLSearchParams(windowUrl.search);
        if (windowUrl.href.includes('subscribe')) {
            if (params.has('token')) {
                this.defaultToken = params.get('token');
                this.subscriptionKey ||= this.defaultToken;
            }
            return true;
        }
        return false;
    }

    private isSubmitDisabled(): boolean {
        const {acceptedTerms, submitting} = this.state;
        let formInstance: FormInstance = this.formRef.current;

        if (!formInstance) return true;

        return this.captchaResponse == null ||
            !acceptedTerms ||
            !formInstance.isFieldsTouched(['first_name', 'last_name', 'password'], true) ||
            Value.isNullOrEmptyString(formInstance.getFieldValue('email')) ||
            submitting ||
            formInstance.getFieldsError().filter(({errors}) => errors.length).length > 0;
    }

    private toggleAcceptTerms() {
        const {acceptedTerms} = this.state;
        this.setState({
            acceptedTerms: !acceptedTerms
        });
    }

    private toggleJoinNewsletter() {
        const {newsletter} = this.state;
        this.setState({
            newsletter: !newsletter
        });
    }

    private renderRegisterStep(number: number): React.ReactElement {
        return (
            <Row justify='center'>
                <p className='rk-register--step'>STEP {number} OF 4</p>
            </Row>
        );
    }

    private renderButton(): React.ReactElement {
        const {submitting} = this.state;
        return (
            <Button className='rk-btn rk-btn-dark rounded submit-form' type='primary' htmlType='submit'
                    disabled={this.isSubmitDisabled()}>
                {submitting ? <LoadingOutlined spin/> : 'Next'}
            </Button>
        );
    }

    private renderForm(): React.ReactElement {
        const {showToken} = this.state;
        return (
            <div className={'rk-register-form'}>
                <h1>Sign up</h1>

                <Form.Item name='first_name' rules={[{required: true, message: 'Please input your first name'}]}>
                    <Input className='rk-input' placeholder='First Name' type='text'/>
                </Form.Item>

                <Form.Item name='last_name' rules={[{required: true, message: 'Please input your last name'}]}>
                    <Input className='rk-input' placeholder='Last Name' type='text'/>
                </Form.Item>

                <Form.Item name='email' rules={[{required: true, message: 'Please input your email'}]}>
                    <Input className='rk-input' placeholder='Email' type='email'/>
                </Form.Item>

                <Form.Item name='password' rules={[{required: true, message: 'Please input your password'}]}>
                    <Input className='rk-input' placeholder='Password' type='password'/>
                </Form.Item>

                {showToken &&
                    <React.Fragment>
                        <p className={'rk-register-form__token'}>Subscription Key</p>
                        <Form.Item name='subscription_key'>
                            <Input className='rk-input' placeholder='Subscription key' type='text'
                                   defaultValue={this.defaultToken} onChange={(evt) => this.updateSubscriptionKey(evt.target.value)}/>
                        </Form.Item>
                    </React.Fragment>
                }

                <Form.Item name='newsletter' className={'rk-form-item__padding_tight'}>
                    <label>
                        <Checkbox onChange={() => this.toggleJoinNewsletter()}>Sign me up to your
                            newsletter!</Checkbox>
                    </label>
                </Form.Item>

                <Form.Item>
                    <label>
                        <Checkbox onChange={() => this.toggleAcceptTerms()}>I accept the <a className={'rk-link'}
                                                                                            href={'/privacy'}>Privacy
                            Policy</a> and <a className={'rk-link'} href={'/terms'}>Terms of Service</a>.</Checkbox>
                    </label>
                </Form.Item>

                <Row className='rk-captcha'>
                    <Col xs={24}>
                        <Turnstile
                            theme={'light'}
                            sitekey={turnstileSiteKey}
                            onError={this.errorCallback}
                            onVerify={this.verifyCallback}
                            onExpire={this.expiredCallback}
                            retry={'auto'}
                            retryInterval={8000}
                        />
                    </Col>
                </Row>

                <Row justify='center'>
                    {this.renderErrorMessage()}
                    {this.renderButton()}
                </Row>

                {/*<Col xs={24}>*/}
                {/*  <p className='rk-help-link'><a href=''>Need Help</a>?</p>*/}
                {/*</Col>*/}

                <div className='rk-redirect-link'>
                    <p>Already have an account? <strong><Link to='/sign_in'>Login here</Link>.</strong></p>
                </div>
            </div>
        );
    }

    private renderErrorMessage(): React.ReactElement {
        const {errorMessage} = this.state;
        let error = ""
        if (errorMessage) {
            error = errorMessage.message;
        }
        return <Col xs={24} className='rk-register-form__error-message'>{error}</Col>;
    }

    private renderNewSubscriptionFlow() {
        return (
            <SubscriptionNew nested={true}/>
        );
    }

    private renderRegisterPage() {
        return (
            <Form ref={this.formRef}
                  onChange={() => this.setState({})}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}>
                {this.renderRegisterStep(1)}

                <Row justify='space-around' align='middle'>
                    <Col xs={24}>
                        {this.renderForm()}
                    </Col>
                </Row>
            </Form>
        )
    }

    private renderPages(): React.ReactElement {
        const {loaded, registered, nameIsMissing, userId} = this.state;
        if (!loaded) {
            return <Row justify='center'><LoadingOutlined spin/></Row>;
        } else if (registered && nameIsMissing) {
            return <RegisterSetName userId={userId} callback={isMissing => this.setState({nameIsMissing: isMissing})}/>
        } else if (registered) {
            return this.renderNewSubscriptionFlow();
        }
        return this.renderRegisterPage();
    }

    public render(): React.ReactElement {
        return (
            <DefaultLayout>
                <div className='rk-register-content'>
                    <div className='rk-form-container'>
                        {this.renderPages()}
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

export default withStore(observer(Register));
