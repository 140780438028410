import React from 'react';
import {Link} from 'react-router-dom';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Form, Input, Row} from 'antd';
import {Lego} from 'lottie/lottie-assets';
import {Lottie} from '@rascality/react-lottie';

import ScriptLoader from 'helpers/ScriptLoader';
import {ComponentWithStore, withStore} from 'models/RootStore';
import DefaultLayout from 'layouts/DefaultLayout';
import Turnstile from "react-turnstile";

const turnstileSiteKey: string = process.env.TURNSTILE_SITE_KEY;

class ResetPassword extends ComponentWithStore<any, any> {
  @observable private captchaResponse: string;
  @observable private tokenError: boolean = false;
  @observable private finished: boolean = false;

  @observable private email: string;
  @observable private token: string;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.onFinish = this.onFinish.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
    this.setError = this.setError.bind(this);
  }

  public componentDidMount(): void {
    let params = new URLSearchParams(window.location.search);
    this.email = params.get('email');
    this.token = params.get('token');
    this.store.UserProvider.resetPasswordTokenIsValid(this.email, this.token).then(result => {
      if (!result) {
        this.setError(true);
      } else {
        this.setError(false);
      }
    })
  }

  public onFinish(values) {
    values['email'] = this.email;
    values['token'] = this.token;
    values['cf-turnstile-response'] = this.captchaResponse;
    this.store.UserProvider.resetPassword(values)
      .then(result => {
        if (result) {
          this.finished = true;
        }
      });
  };

  public onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  };

  public errorCallback() {
  }

  public verifyCallback(response: string) {
    this.captchaResponse = response;
  }

  public expiredCallback() {
    this.captchaResponse = null;
  }

  public setError(tokenError: boolean) {
    this.tokenError = tokenError;
  }

  private renderFormOrError(): React.ReactElement {
    if (this.finished) {
      return (
        <React.Fragment>
          <p>Successfully changed password.</p>
          <p><Link to='/'>Login here</Link></p>
        </React.Fragment>
      );
    }
    if (!this.tokenError) {
      return this.renderForm();
    }
    return (
      <React.Fragment>
        <div style={{color: 'red'}}>
          <p>Unfortunately this password reset request has expired.</p>
          <p>Please open a new request using <Link to='/forgot_password'>forgot password</Link>.</p>
        </div>
      </React.Fragment>
    );
  }

  private renderForm(): React.ReactElement {
    return (
      <React.Fragment>
        <div>
          <p>Change is natural. Enter your new password twice and we will update it for you.</p>
        </div>
        <div>
          <Form.Item
            name='password'
            rules={[{required: true, message: 'Please input your password'}]}
          >
            <Input className='rk-input' placeholder='Password' type='password'/>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name='password_confirm'
            rules={[{required: true, message: 'Passwords must match.'}]}
          >
            <Input className='rk-input' placeholder='Password Again' type='password'/>
          </Form.Item>
        </div>
        <div className='rk-captcha'>
          <Row>
            <Col xs={12}>
              <Turnstile
                theme={'light'}
                sitekey={turnstileSiteKey}
                onError={this.errorCallback}
                onVerify={this.verifyCallback}
                onExpire={this.expiredCallback}
                retry={'auto'}
                retryInterval={8000}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Button className='rk-btn' type='primary' htmlType='submit'
                  disabled={this.captchaResponse == null}>Submit</Button>
        </div>
        <div className='rk-redirect-link'>
          <p>Feeling lost? <strong><Link to='/sign_in'>Sign In here</Link>.</strong></p>
        </div>
      </React.Fragment>
    );
  }

  public render(): React.ReactElement {
    return (
      <DefaultLayout>
        <div className='rk-register-content'>
          <Form onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
            <Row justify='space-around' align='middle'>
              <Col xs={24} sm={12} xl={{span: 9, offset: 3}}>
                <Row>
                  <Col xs={24}>
                    <div>
                      <h1>Reset Password</h1>
                    </div>
                    {this.renderFormOrError()}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <div>
                  <Lottie speed={0.6} config={{animationData: Lego, loop: true}}/>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </DefaultLayout>
    );
  }
}

export default withStore(observer(ResetPassword));
