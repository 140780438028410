import React from 'react';
import promiseRetry from 'promise-retry';
import _ from 'lodash';

namespace Api {
  export function retryFetch(url, params = {}, format = 'json'): Promise<any> {
    addAuthHeaders(params);
    addFormatHeaders(format, params);
    return promiseRetry(
      (retry: Function, attemptNumber: number) => {
        if ( attemptNumber > 1 ) console.log(`Attempt number ${ attemptNumber }`);
        return window.fetch(url, params)
          .then(checkStatus)
          .catch((response: Response) => doRetry(response, retry));
      });
  }

  export function fetch(url, params = {}, format = 'json'): Promise<any> {
    addAuthHeaders(params);
    addFormatHeaders(format, params);
    return window.fetch(url, params)
      .then(checkStatus);
  }

  export function get(url, params = {}, format = 'json'): Promise<any> {
    params['method'] = 'GET';
    return retryFetch(url, params, format);
  }

  export function post(url, params = {}, format = 'json', retry = true): Promise<any> {
    params['method'] = 'POST';
    if ( !retry ) return fetch(url, params, format)
    return retryFetch(url, params, format);
  }

  export function put(url, params = {}, format = 'json'): Promise<any> {
    params['method'] = 'PUT';
    return retryFetch(url, params, format);
  }

  export function del(url, params = {}, format = 'json'): Promise<any> {
    params['method'] = 'DELETE';
    return retryFetch(url, params, format);
  }

  function addAuthHeaders(params): void {
    _.set(params, 'credentials', 'include');
  }

  function addFormatHeaders(format, params): void {
    switch ( format ) {
      case 'json':
        _.set(params, 'headers.Accept', 'application/json');
        _.set(params, 'headers.Content-Type', 'application/json');
        break;
      default:
        throw `Format: '${ format }' does not have supported headers.`;
    }
  }

  export function checkStatus(response: Response): Promise<any> {
    return isError(response) ? Promise.reject(response) : Promise.resolve(response);
  }

  export function doRetry(response: Response, retry) {
    if ( !isClientError(response) ) {
      return retry(response);
    }
    return response.status === 422 ? Promise.resolve(response) : Promise.reject(response);
  }

  export function isError(response: Response): boolean {
    return response.status >= 400;
  }

  export function isClientError(response: Response): boolean {
    return response.status >= 400 && response.status < 500;
  }
}

export default Api;
