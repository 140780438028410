import React, { Component } from "react";

import "./BurgerMenu.scss"

interface IBurgerMenuProps {
}

interface IBurgerMenuState {

}

class BurgerMenu extends Component<IBurgerMenuProps, IBurgerMenuState> {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
        <g id="Group_102" data-name="Group 102" transform="translate(-77 -7)">
          <g id="Union_6" data-name="Union 6" transform="translate(77 7)" fill="#1c2039" strokeLinecap="round">
            <path
              d="M 17.64722061157227 4.5 L 2.352659940719604 4.5 C 1.331099987030029 4.5 0.5 3.602550029754639 0.5 2.49943995475769 C 0.5 1.396950006484985 1.331099987030029 0.5 2.352659940719604 0.5 L 17.64722061157227 0.5 C 18.66884994506836 0.5 19.5 1.396950006484985 19.5 2.49943995475769 C 19.5 3.602550029754639 18.66884994506836 4.5 17.64722061157227 4.5 Z"
              stroke="none"/>
            <path
              d="M 17.64722061157227 4 C 18.39315032958984 4 19 3.326849937438965 19 2.49943995475769 C 19 1.67264997959137 18.39315032958984 1 17.64722061157227 1 L 2.352659940719604 1 C 1.606799960136414 1 1 1.67264997959137 1 2.49943995475769 C 1 3.326849937438965 1.606799960136414 4 2.352659940719604 4 L 17.64722061157227 4 M 17.64722061157227 5 L 2.352659940719604 5 C 1.055660009384155 5 0 3.878520011901855 0 2.49943995475769 C 0 1.121479988098145 1.055660009384155 0 2.352659940719604 0 L 17.64722061157227 0 C 18.94433975219727 0 20 1.121479988098145 20 2.49943995475769 C 20 3.878520011901855 18.94433975219727 5 17.64722061157227 5 Z"
              stroke="none" fill="#f2f2f2"/>
          </g>
          <g id="Union_8" data-name="Union 8" transform="translate(77 16)" fill="#1c2039" strokeLinecap="round">
            <path
              d="M 17.64722061157227 4.5 L 2.352659940719604 4.5 C 1.331099987030029 4.5 0.5 3.602550029754639 0.5 2.49943995475769 C 0.5 1.396950006484985 1.331099987030029 0.5 2.352659940719604 0.5 L 17.64722061157227 0.5 C 18.66884994506836 0.5 19.5 1.396950006484985 19.5 2.49943995475769 C 19.5 3.602550029754639 18.66884994506836 4.5 17.64722061157227 4.5 Z"
              stroke="none"/>
            <path
              d="M 17.64722061157227 4 C 18.39315032958984 4 19 3.326849937438965 19 2.49943995475769 C 19 1.67264997959137 18.39315032958984 1 17.64722061157227 1 L 2.352659940719604 1 C 1.606799960136414 1 1 1.67264997959137 1 2.49943995475769 C 1 3.326849937438965 1.606799960136414 4 2.352659940719604 4 L 17.64722061157227 4 M 17.64722061157227 5 L 2.352659940719604 5 C 1.055660009384155 5 0 3.878520011901855 0 2.49943995475769 C 0 1.121479988098145 1.055660009384155 0 2.352659940719604 0 L 17.64722061157227 0 C 18.94433975219727 0 20 1.121479988098145 20 2.49943995475769 C 20 3.878520011901855 18.94433975219727 5 17.64722061157227 5 Z"
              stroke="none" fill="#f2f2f2"/>
          </g>
        </g>
      </svg>
    )
  }
}

export default BurgerMenu;
