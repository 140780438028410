import EditValueInPlace, {IEditValueInPlaceProps} from "components/public/modules/value_editor/EditValueInPlace";

export interface IEditableParagraphProps extends IEditValueInPlaceProps {
  onChange: (value: string) => void;
}

class EditableParagraph extends EditValueInPlace<IEditableParagraphProps> {
  constructor(props: IEditableParagraphProps) {
    super(props);

    this.sanitizeParagraph = this.sanitizeParagraph.bind(this);

    this.format = 'string';
    this.className = 'rk-edit-paragraph-in-place'
    this.onChangeCallback = this.sanitizeParagraph;
  }

  public sanitizeParagraph(value: string) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }


}

export default EditableParagraph;
