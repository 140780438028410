import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {StoryLocalisation} from 'models/storyLocalisation/StoryLocalisation';
import {IStoryLocalisationApiData} from 'models/storyLocalisation/IStoryLocalisationApiData';
import {StoryLocalisationApi} from 'models/storyLocalisation/StoryLocalisationApi';

export class StoryLocalisationProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'StoryLocalisationProvider');

        this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public getAll(storyId: number, force: boolean = false): Promise<StoryLocalisation[]> {
        if (storyId == null) return Promise.resolve([]);
        if (force) return this.getAllFromApiAndUpdateStore(storyId);

        return this.StoryLocalisationMemoryStore.read(storyId)
            .then((localisations: IStoryLocalisationApiData[] | null) => {
                return localisations
                    ? this.apiDataArrayToModelArray(localisations)
                    : this.getAllFromApiAndUpdateStore(storyId);
            });
    }

    private getAllFromApiAndUpdateStore(storyId: number): Promise<StoryLocalisation[]> {
        return StoryLocalisationApi.getAll(storyId)
            .then((localisation: IStoryLocalisationApiData[]) => this.StoryLocalisationMemoryStore.store(localisation, storyId)
                .then(() => this.apiDataArrayToModelArray(localisation)));
    }

    private apiDataArrayToModelArray(storyLocalisations: IStoryLocalisationApiData[]): StoryLocalisation[] {
        return storyLocalisations.map(this.apiDataToModel);
    }

    private apiDataToModel(localisation: IStoryLocalisationApiData): StoryLocalisation {
        return new StoryLocalisation(this).withData(localisation);
    }
}
