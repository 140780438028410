import React from "react";

class DownloadIcon extends React.Component {
  render() {
    return (
      <svg className={'rk-icon rk-download-icon'} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g transform="translate(-305.859 -105.235)">
          <path d="M318.3,122.164c.384.364.684.439,1.08.108q1.8-1.5,3.585-3.028a.574.574,0,0,0,.077-.823.565.565,0,0,0-.864-.081c-.309.253-.83.668-1.32,1.056v-7.8a2,2,0,0,0-4,0v7.6c-.314-.294-.635-.589-.885-.808a.609.609,0,0,0-.929-.008.626.626,0,0,0,.126.925C315.525,119.637,317.635,121.533,318.3,122.164Z" fill="currentcolor"/>
          <path d="M325.573,122.6a.679.679,0,0,0-.61-.723.668.668,0,0,0-.623.735c0,.356,0,.37,0,.727H313.389a6.551,6.551,0,0,1-.021-.714c-.013-.444-.264-.743-.618-.745s-.6.289-.606.744,0,.939,0,1.408c0,.707.187.9.892.9h4.6q3.548,0,7.094,0c.629,0,.845-.222.848-.853C325.576,123.582,325.579,123.091,325.573,122.6Z" fill="currentcolor"/>
        </g>
      </svg>
    );
  }
}
export default DownloadIcon;

