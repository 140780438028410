import React, { Component } from "react";

import "./Chevron.scss"

interface IChevronProps {
  direction: 'left' | 'right' | 'up' | 'down';
}

interface IChevronState {

}

class Chevron extends Component<IChevronProps, IChevronState> {
  render() {
    const { direction } = this.props;
    if (direction === 'left' || direction === 'right') {
      return (
        <svg className={`rk-chevron ${direction}`} xmlns="http://www.w3.org/2000/svg" width="12.611" height="19.999">
          <path className={'chevron-path'}
                d="M.608.533a1.821 1.821 0 0 0 0 2.576L7.602 10 .533 16.89a1.822 1.822 0 0 0 2.576 2.576l9.5-9.466L3.181.534A1.821 1.821 0 0 0 .608.533Z"
                fill="currentColor"/>
        </svg>
      )
    } else {
      return (
        <svg className={`rk-chevron ${direction}`} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 35.53 22.404' width="19.999" height="12.611">
          <path className="chevron-path" d="M34.583 1.08a3.236 3.236 0 0 0-4.576 0L17.765 13.506 5.524.947A3.236 3.236 0 0 0 .948 5.523l16.817 16.881L34.583 5.655a3.234 3.234 0 0 0 0-4.575Z" fill="currentColor"/>
        </svg>
      )
    }
  }
}

export default Chevron;
