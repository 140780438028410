export class StoryDetailsEvents {
    public static readonly TOGGLE_CREDITS_VISIBILITY_EVENT: string = 'TOGGLE_CREDITS_VISIBILITY';
    public static readonly UNITY_STARTUP_COMPLETE_EVENT: string = 'UNITY_STARTUP_COMPLETE';
    public static readonly START_STORY_EVENT: string = 'START_STORY';
    public static readonly SET_LOADING_STATE_EVENT: string = 'SET_LOADING_STATE';
    public static readonly SET_STORY_SESSION_EVENT: string = 'SET_STORY_SESSION';
    public static readonly SET_ERROR_MESSAGE_EVENT: string = 'SET_ERROR_MESSAGE';
    public static readonly SET_PAUSED_STATE_EVENT: string = 'SET_PAUSED_STATE';
    public static readonly EXIT_EVENT: string = 'EXIT';
}
