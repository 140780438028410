import AccountSetting from 'components/public/pages/account_settings/AccountSettings';
import AppStoreRedirect from 'components/public/pages/AppStoreRedirect';
import AuthorizePayment from 'components/public/pages/subscriptions/AuthorizePayment';
import Billing from 'components/public/pages/Billing';
import Catalogue from 'components/public/pages/home/Catalogue';
import ChangeEmail from 'components/public/pages/change_email/ChangeEmail';
import CreditCardNew from 'components/public/credit_cards/CreditCardNew';
import ForgotPassword from 'components/public/pages/forgot_password/ForgotPassword';
import Home from 'components/public/pages/home/Home';
import InvoiceIndex from 'components/public/pages/invoices/InvoiceIndex';
import InvoiceShow from 'components/public/pages/invoices/InvoiceShow';
import NotFound from 'components/public/pages/NotFound';
import PlayStoreRedirect from 'components/public/pages/PlayStoreRedirect';
import Register from 'components/public/pages/register/Register';
import ResetPassword from 'components/public/pages/reset_password/ResetPassword';
import SignIn from 'components/public/pages/sign_in/SignIn';
import StoryDetails from 'components/public/pages/webgl_app/StoryDetails';
import SubscriptionNew from 'components/public/pages/subscriptions/SubscriptionNew';
import SubscriptionManage from "components/public/pages/subscriptions/SubscriptionManage";
import SummaryPage from 'components/public/pages/subscriptions/SummaryPage';
import withRouteParams from "components/hooks/withRouteParams";
import BulkSubscribe from "components/public/pages/subscriptions/BulkSubscribe";
import ManageData from "components/public/pages/account_settings/ManageData";
import StaticManageData from "components/public/pages/static/StaticManageData";

const Page = {
    AccountSetting: withRouteParams(AccountSetting),
    AccountManageData: withRouteParams(ManageData),
    AppStoreRedirect: AppStoreRedirect,
    AuthorizePayment: withRouteParams(AuthorizePayment),
    BillingAddress: Billing,
    Catalogue: Catalogue,
    ChangeEmail: ChangeEmail,
    CreditCardNew: withRouteParams(CreditCardNew),
    ForgotPassword: ForgotPassword,
    Home: Home,
    InvoiceIndex: InvoiceIndex,
    InvoiceShow: withRouteParams(InvoiceShow),
    NotFound: NotFound,
    PlayStoreRedirect: PlayStoreRedirect,
    Register: Register,
    ResetPassword: ResetPassword,
    SignIn: SignIn,
    StaticManageData: StaticManageData,
    StoryDetails: withRouteParams(StoryDetails),
    SubscriptionManage: withRouteParams(SubscriptionManage),
    BulkSubscribe: withRouteParams(BulkSubscribe),
    SubscriptionNew: SubscriptionNew,
    Summary: SummaryPage,
};

export default Page;
