import React, { createRef, useCallback, useEffect, useState } from 'react';
import { Unity, UnityConfig, useUnityContext } from 'react-unity-webgl';
import logger from 'loglevel';
import { ExpandOutlined, PauseOutlined } from '@ant-design/icons';

import Config from 'config/Config';
import { QueueSet } from 'helpers/QueueSet';
import { RootStore } from 'models/RootStore';
import { Settings } from 'models/settings/Settings';
import { Story } from 'models/story/Story';
import { Collection } from 'models/collection/Collection';
import { StorySession } from 'models/storySession/StorySession';
import { StoryRelease } from 'models/storyRelease/StoryRelease';
import { AssetBundle, AssetBundleManifest } from 'models/storyRelease/AssetBundleManifest';
import { ISceneBundle, IStoryBundle, JsonStoryBundle } from 'models/storyRelease/IAssetBundleManifest';
import { ProgressState } from 'models/storyRelease/AssetBundleManifest';

import StoryDetailsButton from 'components/public/pages/webgl_app/StoryDetailsButton';
import StoryDetailsToggle from 'components/public/pages/webgl_app/StoryDetailsToggle';
import { StoryDetailsEvents } from 'components/public/pages/webgl_app/StoryDetailsEvents';
import { UnityEvents } from 'components/public/pages/webgl_app/UnityEvents';
import UnityWrapperElement, { UnityWrapper } from 'components/public/pages/webgl_app/UnityWrapper';
import LanguageSelection from 'components/shared/LanguageSelection';
import RestartIcon from 'components/shared/icons/RestartIcon';
import PlayIcon from 'components/shared/icons/PlayIcon';
import { Language } from 'models/settings/Language';

enum DownloadProgressStateEnum {
    NotStarted,
    Started,
    Downloading,
    Unpacking,
    Loading,
    Completed
}

const REMOTE_UNITY_CONFIG: UnityConfig = {
    dataUrl: '/webgl_app/data',
    frameworkUrl: '/webgl_app/framework',
    loaderUrl: '/webgl_app/loader',
    codeUrl: '/webgl_app/wasm'
};

const LOCAL_UNITY_CONFIG: UnityConfig = {
    dataUrl: '/app/webgl_app/Build/webgl_app.data',
    frameworkUrl: '/app/webgl_app/Build/webgl_app.framework.js',
    loaderUrl: '/app/webgl_app/Build/webgl_app.loader.js',
    codeUrl: '/app/webgl_app/Build/webgl_app.wasm'
};

interface IUnityContextCreatorProps {
    story: Story;
    release: StoryRelease;
    collections: Collection[];
    setPlay: (callback: () => void) => void;
    config?: UnityConfig;
    revokeConfigUrlsCallback: Function;
    settings: Settings;
    store: RootStore;
}

export function WebglApp(props: IUnityContextCreatorProps): React.ReactElement {
    const { story, release, setPlay, config, revokeConfigUrlsCallback, settings, store } = props;
    const [storySession, setStorySession] = useState<StorySession>(null);
    const [startupCompleteCalled, setStartupCompleteCalled] = useState<boolean>(false);
    const [isWaiting, setIsWaiting] = useState<boolean>(false);
    const [paused, setPaused] = useState<boolean>(false);
    const unityWrapper: React.RefObject<UnityWrapper> = createRef<UnityWrapper>();
    const _waitingForQueue = new QueueSet<string>();

    const { unityProvider, requestFullscreen, addEventListener, removeEventListener, sendMessage } = useUnityContext(config || (Config.useLocalWebGLBuild ? LOCAL_UNITY_CONFIG : REMOTE_UNITY_CONFIG));

    function updateStorySession(storySession: StorySession): void {
        setStorySession(storySession);
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_STORY_SESSION_EVENT, { detail: storySession }));
    }

    useEffect(() => {
        store.StorySessionProvider.getForStory(story.id).then(storySession => updateStorySession(storySession));
    }, []);

    function invokeStoryControlsMethod(method: string, param: string | number | undefined = undefined) {
        sendMessage('Story Controls(Clone)', method, param);
    }

    function invokeStartupMethod(method: string, param: string | number | undefined = undefined) {
        sendMessage('Startup', method, param);
    }

    const doPlay = useCallback((): void => {
        invokeStartupMethod('Play');
    }, []);

    useEffect(() => {
        setPlay && setPlay(doPlay);
    }, [setPlay, doPlay]);

    function isMusicOn(): boolean {
        return settings ? settings.is_music_on : true;
    }

    function isNarrationOn(): boolean {
        return settings ? settings.is_narration_on : true;
    }

    function getLanguage(): Language {
        return settings ? settings.language : Language.English;
    }

    function getLanguageKey(value: Language): string {
        const i = Object.values(Language).indexOf(value);
        if (i < 0) return 'English';
        return Object.keys(Language)[i];
    }

    function onFullscreenChange() {
        invokeStoryControlsMethod('OnResize');
        setTimeout(() => invokeStoryControlsMethod('OnResize'), 100);
    }

    // This is called when an event is received from the window.
    function onRemoteFullscreenChange() {
        unityWrapper.current?.setFullscreen(document.fullscreenElement !== null);
        onFullscreenChange();
    }

    function onToggleFullscreen() {
        const isFullscreen = document.fullscreenElement !== null;
        if (isFullscreen) {
            unityWrapper.current?.cancelFullscreen();
        } else {
            unityWrapper.current?.requestFullscreen();
            unityWrapper.current?.setFullscreen(true);
        }
    }

    function onStartupComplete(response: string): string {
        if (startupCompleteCalled) return response;
        setStartupCompleteCalled(true);

        revokeConfigUrlsCallback?.();

        let storyId = story?.id ?? -1;
        let releaseId = release?.id ?? -1;
        let watchTutorial = false;
        // TODO: let watchTutorial = CatalogueViewModelActor.getInstance().getWatchTutorial();
        logger.debug(`RKStartupComplete: called with story = ${storyId} and watchTutorial = ${watchTutorial}`);

        if (watchTutorial) {
            logger.debug(`RKStartupComplete: ${response} watching tutorial`);
            // TODO: startTutorial()
        } else if (storyId < 0) {
            logger.debug(`RKStartupComplete: ${response} without story key provided`);
        } else if (releaseId < 0) {
            logger.debug(`RKStartupComplete: ${response} without release key provided`);
        } else {
            logger.debug(`RKStartupComplete: ${response} setting story key ${storyId}`);

            // TODO: setInitialValuesForSession(storyId)
            window.dispatchEvent(new Event(StoryDetailsEvents.UNITY_STARTUP_COMPLETE_EVENT));
            invokeStoryControlsMethod('SetIsMusicOn', isMusicOn() ? 1 : 0);
            invokeStoryControlsMethod('SetIsNarrationOn', isNarrationOn() ? 1 : 0);
            invokeStoryControlsMethod('SetLanguage', getLanguageKey(getLanguage()));

            let blob = createJsonBlob();
            console.log(`invokeStartupMethod(RKSetStoryData, ${JSON.stringify(blob)})`);
            invokeStartupMethod('RKSetStoryData', JSON.stringify(blob));
            // sendDownloadedChapters();

            window.addEventListener('resize', (): void => invokeStoryControlsMethod('OnResize'));
            document.addEventListener('webkitfullscreenchange', onRemoteFullscreenChange);
            document.addEventListener('mozfullscreenchange', onRemoteFullscreenChange);
            document.addEventListener('fullscreenchange', onRemoteFullscreenChange);
            document.addEventListener('MSFullscreenChange', onRemoteFullscreenChange);
            window.addEventListener('keyup', (e: KeyboardEvent): void => {
                if (e.key === 'Esc' || e.key === 'Escape') onRemoteFullscreenChange();
            });
        }

        return response;
    }

    function createJsonBlob() {
        let storyBundle: IStoryBundle = release.unity_asset_manifest.getStoryBundle();
        storyBundle.current_scene_bundle = storySession?.current_asset_bundle;
        store.AssetBundleProvider.storeStoryBundleInWindow(storyBundle);

        let bundleJson: JsonStoryBundle = AssetBundleManifest.toJsonStoryBundle(storyBundle);
        let paths: Set<string> = new Set<string>();
        bundleJson.scene_bundles.forEach(scene => {
            scene.asset_bundles.forEach(bundle => {
                paths.add(bundle);
            });
        });

        if (paths.size != release.unity_asset_manifest?.asset_bundles?.length) {
            logger.debug(`Sanity check failed: ${paths.size} != ${release.unity_asset_manifest?.asset_bundles?.length} we need to re-get release data from api`);
            // TODO: re-get release data from api
            // let releases = await this.story.getStoryReleases();
            // release = StoryRelease.filterForLatest(releases);
        }

        return { story: story.toJSON(), release: release.toJSON(), bundle: bundleJson };
    }

    function doStartStory(event: Event): void {
        sendDownloadedChapters();

        let bundle: IStoryBundle = store.AssetBundleProvider.readStoryBundleFromWindow();
        invokeStartupMethod('Play', bundle.current_scene_bundle);

        downloadRemainingScenes();
    }

    function downloadRemainingScenes(): void {
        setIsWaiting(true);

        store.AssetBundleProvider.downloadRemainingScenes(story, storySession, false, async () => {
                sendDownloadedChapters();
                await downloadWaitingFor();
            })
            .catch(e => {
                logger.error(e);
            })
            .finally(() => {
                setIsWaiting(false);
                sendDownloadedChapters();
                return downloadWaitingFor();
            });
    }

    useEffect(() => {
        window.addEventListener(StoryDetailsEvents.START_STORY_EVENT, doStartStory);
        return () => window.removeEventListener(StoryDetailsEvents.START_STORY_EVENT, doStartStory);
    }, [window.addEventListener, window.removeEventListener, doStartStory]);

    function dequeueWaitingFor(): string | null {
        return _waitingForQueue.dequeue();
    }

    function enqueueWaitingFor(chapterName: string): void {
        _waitingForQueue.enqueue(chapterName);
    }

    function sendDownloadedChapters() {
        let storyBundle: IStoryBundle = store.AssetBundleProvider.readStoryBundleFromWindow();
        let assetBundles: AssetBundle[] = Array.from(new Set(storyBundle.scene_bundles.flatMap(scene => scene.asset_bundles)));
        let downloadedAssetBundles = assetBundles.filter(bundle => bundle.isDownloadComplete());

        let downloadedInfo = new Set();
        downloadedAssetBundles.forEach(bundle => downloadedInfo.add(bundle.name));
        downloadedAssetBundles.forEach(bundle => downloadedInfo.add(bundle.url));

        let downloadedChapters: ISceneBundle[] = [];
        storyBundle.scene_bundles.forEach(scene => {
            let add = true;
            if (downloadedInfo.has(scene.name)) {
                scene.asset_bundles.forEach(bundle => {
                    if (!downloadedInfo.has(bundle.url)) {
                        add = false;
                    }
                });
            } else {
                add = false;
            }
            if (add) {
                downloadedChapters.push(scene);
            }
        });

        invokeStoryControlsMethod('rkSetDownloadedChapters', JSON.stringify(downloadedChapters.map(chapter => chapter.name)));
    }

    async function sendDownloadedChapter(chapter: ISceneBundle) {
        invokeStoryControlsMethod('rkAddDownloadedChapter', chapter.name);
    }

    async function downloadWaitingFor(): Promise<void> {
        if (!release) return;
        // Do nothing if there are no elements in the queue
        let chapterName = dequeueWaitingFor();
        if (!chapterName) return;

        let storyBundle: IStoryBundle = store.AssetBundleProvider.readStoryBundleFromWindow();
        let chapter: ISceneBundle = storyBundle.scene_bundles.find(scene => scene.name === chapterName);
        if (!chapter) {
            logger.debug(`downloadWaitingFor ${chapterName} no chapter found with name`);
            return;
        }

        let success: boolean = false;

        if (story) {
            let _ = await store.AssetBundleProvider.awaitDownloadChapter(story, chapter);
            let allComplete = chapter.asset_bundles.every(bundle => bundle.progress_state === ProgressState.COMPLETED);
            if (allComplete) {
                await sendDownloadedChapter(chapter);
                success = true;
            }
        }

        if (!success) {
            logger.debug(`downloadWaitingFor ${chapterName} did not successfully download or load`);
            await new Promise(resolve => setTimeout(resolve, 2000));
            logger.debug(`downloadWaitingFor ${chapterName} reinserting in queue at head`);
            _waitingForQueue.insertAtHead(chapterName);
        }

        // Recur until all elements have been dequeued
        return await downloadWaitingFor();
    }

    useEffect(() => {
        addEventListener(UnityEvents.STARTUP_COMPLETE_EVENT, onStartupComplete);
        return () => removeEventListener(UnityEvents.STARTUP_COMPLETE_EVENT, onStartupComplete);
    }, [addEventListener, removeEventListener, onStartupComplete]);

    useEffect(() => {
        addEventListener(UnityEvents.WAITING_FOR_CHAPTER_TO_DOWNLOAD_EVENT, onWaitingForChapterToDownload);
        return () => removeEventListener(UnityEvents.WAITING_FOR_CHAPTER_TO_DOWNLOAD_EVENT, onWaitingForChapterToDownload);
    }, [addEventListener, removeEventListener, onWaitingForChapterToDownload]);

    useEffect(() => {
        addEventListener(UnityEvents.WAITING_FOR_CHAPTER_TO_LOAD_EVENT, onWaitingForChapterToLoad);
        return () => removeEventListener(UnityEvents.WAITING_FOR_CHAPTER_TO_LOAD_EVENT, onWaitingForChapterToLoad);
    }, [addEventListener, removeEventListener, onWaitingForChapterToLoad]);

    useEffect(() => {
        addEventListener(UnityEvents.FINISHED_WAITING_FOR_EVENT, onFinishedWaitingFor);
        return () => removeEventListener(UnityEvents.FINISHED_WAITING_FOR_EVENT, onFinishedWaitingFor);
    }, [addEventListener, removeEventListener, onFinishedWaitingFor]);

    useEffect(() => {
        addEventListener(UnityEvents.START_STORY_SESSION_EVENT, onStartStorySession);
        return () => removeEventListener(UnityEvents.START_STORY_SESSION_EVENT, onStartStorySession);
    }, [addEventListener, removeEventListener, onStartStorySession]);

    useEffect(() => {
        addEventListener(UnityEvents.STARTING_STORY_EVENT, onStartingStory);
        return () => removeEventListener(UnityEvents.STARTING_STORY_EVENT, onStartingStory);
    }, [addEventListener, removeEventListener, onStartingStory]);

    useEffect(() => {
        addEventListener(UnityEvents.UPDATE_BUNDLE_EVENT, onUpdateBundle);
        return () => removeEventListener(UnityEvents.UPDATE_BUNDLE_EVENT, onUpdateBundle);
    }, [addEventListener, removeEventListener, onUpdateBundle]);

    useEffect(() => {
        addEventListener(UnityEvents.UPDATE_SCENE_EVENT, onUpdateScene);
        return () => removeEventListener(UnityEvents.UPDATE_SCENE_EVENT, onUpdateScene);
    }, [addEventListener, removeEventListener, onUpdateScene]);

    useEffect(() => {
        addEventListener(UnityEvents.FINISH_STORY_SESSION_EVENT, onFinishStory);
        return () => removeEventListener(UnityEvents.FINISH_STORY_SESSION_EVENT, onFinishStory);
    }, [addEventListener, removeEventListener, onFinishStory]);

    useEffect(() => {
        addEventListener(UnityEvents.RESTART_STORY_EVENT, onRestartStoryFromUnity);
        return () => removeEventListener(UnityEvents.RESTART_STORY_EVENT, onRestartStoryFromUnity);
    }, [addEventListener, removeEventListener, onRestartStoryFromUnity]);

    useEffect(() => {
        addEventListener(UnityEvents.EXIT_EVENT, onClickExitButton);
        return () => removeEventListener(UnityEvents.EXIT_EVENT, onClickExitButton);
    }, [addEventListener, removeEventListener, onClickExitButton]);

    useEffect(() => {
        addEventListener(UnityEvents.EXITED_TUTORIAL_EVENT, onExitedTutorial);
        return () => removeEventListener(UnityEvents.EXITED_TUTORIAL_EVENT, onExitedTutorial);
    }, [addEventListener, removeEventListener, onExitedTutorial]);

    useEffect(() => {
        window.addEventListener(StoryDetailsEvents.EXIT_EVENT, onClickExitButton);
        return () => window.removeEventListener(StoryDetailsEvents.EXIT_EVENT, onClickExitButton);
    }, [window.addEventListener, window.removeEventListener, onClickExitButton]);

    useEffect(() => {
        addEventListener(UnityEvents.DISPLAY_ERROR_EVENT, onUnityError);
        return () => removeEventListener(UnityEvents.DISPLAY_ERROR_EVENT, onUnityError);
    }, [addEventListener, removeEventListener, onUnityError]);

    function onUnityError(message: string) {
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_ERROR_MESSAGE_EVENT, { detail: message }));
        return message;
    }

    function onStorySet(response: string): string {
        return response;
    }

    useEffect(() => {
        addEventListener(UnityEvents.STORY_SET_EVENT, onStorySet);
        return () => removeEventListener(UnityEvents.STORY_SET_EVENT, onStorySet);
    }, [addEventListener, removeEventListener, onStorySet]);

    function onProgressStateChange(response: string) {
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_LOADING_STATE_EVENT, { detail: response }));
        return response;
    }

    useEffect(() => {
        addEventListener(UnityEvents.PROGRESS_STATE_CHANGE_EVENT, onProgressStateChange);
        return () => removeEventListener(UnityEvents.PROGRESS_STATE_CHANGE_EVENT, onProgressStateChange);
    }, [addEventListener, removeEventListener, onProgressStateChange]);

    function onRestartStoryFromUnity(finished: string): void {
        if (!storySession?.remote_id) {
            endCurrentStorySession(finished === 'true', true).then(() => onRestartStory());
        } else {
            onRestartStory();
        }
    }

    function onRestartStory(event?: React.MouseEvent<HTMLButtonElement>): void {
        invokeStoryControlsMethod('RestartStory');
    }

    function onGoToPreviousChapter(event?: React.MouseEvent<HTMLButtonElement>): void {
        invokeStoryControlsMethod('rkGoToPreviousChapter');
    }

    function onGoToNextChapter(event?: React.MouseEvent<HTMLButtonElement>): void {
        invokeStoryControlsMethod('rkGoToNextChapter');
    }

    function onChangeMusicSwitch(checked: boolean, event?: React.MouseEvent<HTMLButtonElement>): void {
        settings.is_music_on = checked;
        // noinspection JSIgnoredPromiseFromCall
        store.SettingsProvider.update(settings);
        invokeStoryControlsMethod('SetIsMusicOn', isMusicOn() ? 1 : 0);
    }

    function onChangeNarrationSwitch(checked: boolean, event?: React.MouseEvent<HTMLButtonElement>): void {
        settings.is_narration_on = checked;
        // noinspection JSIgnoredPromiseFromCall
        store.SettingsProvider.update(settings);
        invokeStoryControlsMethod('SetIsNarrationOn', isNarrationOn() ? 1 : 0);
    }

    function onChangeLanguage(value: Language): void {
        console.log(`onChangeLanguage(${value}) -> ${getLanguageKey(value)}`);
        settings.language = value;
        // noinspection JSIgnoredPromiseFromCall
        store.SettingsProvider.update(settings);
        invokeStoryControlsMethod('SetLanguage', getLanguageKey(value));
    }

    function onWaitingForChapterToDownload(chapterName: string): void {
        setIsWaiting(true);
        enqueueWaitingFor(chapterName);
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_LOADING_STATE_EVENT, { detail: 'downloading' }));
        Promise.resolve().then(() => downloadWaitingFor());
    }

    function onWaitingForChapterToLoad(chapterName: string): void {
        setIsWaiting(true);
        enqueueWaitingFor(chapterName);
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_LOADING_STATE_EVENT, { detail: 'loading' }));
        Promise.resolve().then(() => downloadWaitingFor());
    }

    function onFinishedWaitingFor(): void {
        setIsWaiting(false);
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_LOADING_STATE_EVENT, { detail: 'completed' }));
    }

    function onStartStorySession(): void {
        if (!story?.id) return;

        if (!storySession?.remote_id) {
            store.StorySessionProvider.start(story.id).then(storySession => updateStorySession(storySession));
        }
    }

    function onStartingStory() {
        console.log('onStartingStory called');
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_LOADING_STATE_EVENT, { detail: 'completed' }));
    }

    function onUpdateBundle(bundle: string): string {
        if (!bundle || !story?.id) return bundle;

        store.StorySessionProvider.update(story.id, { current_asset_bundle: bundle }).then(storySession => updateStorySession(storySession));
        return bundle;
    }

    function onUpdateScene(scene: string): string {
        if (!scene || !story?.id) return scene;

        store.StorySessionProvider.update(story.id, { current_scene: scene }).then(storySession => updateStorySession(storySession));
        return scene;
    }

    function onFinishStory(): void {
        if (!story?.id) return;

        store.StorySessionProvider.finish(story.id).then(storySession => updateStorySession(storySession));
    }

    async function endCurrentStorySession(finished: boolean, newSession: boolean): Promise<void> {
        if (!story?.id) return;

        if (finished) {
            await store.StorySessionProvider.finish(story.id).then(storySession => updateStorySession(storySession));
        } else {
            await store.StorySessionProvider.exit(story.id).then(storySession => updateStorySession(storySession));
        }

        if (newSession) {
            setStorySession(null);
            onStartStorySession();
        }
    }

    function onClickExitButton(): void {
        if (!story?.id) {
            window.location.href = '/';
            return;
        }

        store.StorySessionProvider.exit(story.id).finally(() => (window.location.href = '/'));
    }

    function onExitedTutorial(): void {
        logger.debug('onExitedTutorial', story?.id);
        // TODO
    }

    function renderRestartButton(): React.ReactElement {
        return (
            <StoryDetailsButton label='Restart'
                                icon={<RestartIcon/>}
                                color='blue'
                                cursor='pointer'
                                onClick={onRestartStory} />
        );
    }

    function renderPauseButton(): React.ReactElement {
        if (paused) {
            return (
                <div className='rk-fullscreen-button'
                     onClick={() => window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_PAUSED_STATE_EVENT, { detail: false }))}>
                    <PlayIcon />
                </div>
            );
        } else {
            return (
                <PauseOutlined className='rk-fullscreen-button'
                               onClick={() => window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_PAUSED_STATE_EVENT, { detail: true }))} />
            );
        }
    }

    function renderMusicToggle(): React.ReactElement {
        return (
            <StoryDetailsToggle label='Music'
                                cursor='pointer'
                                isOn={isMusicOn}
                                getValue={(settings: Settings) => settings.is_music_on}
                                onChangeSwitch={onChangeMusicSwitch} />
        );
    }

    function renderNarrationToggle(): React.ReactElement {
        return (
            <StoryDetailsToggle label='Narration'
                                cursor='pointer'
                                isOn={isNarrationOn}
                                getValue={(settings: Settings) => settings.is_narration_on}
                                onChangeSwitch={onChangeNarrationSwitch} />
        );
    }

    function renderLanguageSelection(): React.ReactElement {
        let defaultValue: Language = getLanguage();
        return <LanguageSelection defaultLanguage={defaultValue} onChange={onChangeLanguage} />;
    }

    function renderFullscreenButton(): React.ReactElement {
        return (
            <ExpandOutlined
                className='rk-fullscreen-button'
                onClick={() => {
                    unityWrapper?.current?.requestFullscreen();
                    onRemoteFullscreenChange();
                }}
            />
        );
    }

    function renderRecordButton(): React.ReactElement {
        return (
            <button className='story-view--record-button story-view-button-'>
                <img className='story-view-button--icon' src='/icons/buttons/record-icon.svg' />
                <label className='story-view-button--text'>Record</label>
            </button>
        );
    }

    function checkPausedState(pause: boolean) {
        setPaused(pause);
        if (pause) {
            invokeStoryControlsMethod('rkPauseTimeline');
        } else {
            invokeStoryControlsMethod('rkResumeTimeline');
        }
    }

    return (
        <div className='rk-webgl-app'>
            <div className='rk-webgl-app--desktop'>
                <UnityWrapperElement ref={unityWrapper}
                                     story={story}
                                     onToggleFullscreen={onToggleFullscreen}
                                     onRestart={onRestartStory}
                                     onGoToPrevious={onGoToPreviousChapter}
                                     onGoToNext={onGoToNextChapter}
                                     onChangeMusic={onChangeMusicSwitch}
                                     onChangeNarration={onChangeNarrationSwitch}
                                     onChangeLanguage={onChangeLanguage}
                                     checkPausedState={checkPausedState}>
                    <Unity unityProvider={unityProvider} className='rk-webgl-app--unity' />
                </UnityWrapperElement>
                <div className='rk-webgl-app--button-container'>
                    {renderPauseButton()}
                    <div className={'rk-webgl-app--button-container__content'}>
                        {renderMusicToggle()}
                        {renderNarrationToggle()}
                    </div>
                    <div className={'rk-webgl-app--button-container__end'}>
                        {renderLanguageSelection()}
                        {renderFullscreenButton()}
                    </div>
                </div>
            </div>
        </div>
    );
}
