import React, {ChangeEvent, Component} from 'react';
import {computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Form as AntdForm, Input} from 'antd';
import {FormLayout} from 'antd/es/form/Form';

import Value from 'helpers/Value';
import {BillingAddress} from 'models/billingAddress/BillingAddress';
import CountrySelect from '../modules/CountrySelect';

import "./Form.scss";

interface IFormProps {
    billingAddress: BillingAddress,
    layout?: FormLayout
}

interface IBillingAddressState {
    billingAddress: BillingAddress
}

class Form extends Component<IFormProps, IBillingAddressState> {
    @observable private billingAddress: BillingAddress;
    private readonly layout: FormLayout;

    constructor(props: IFormProps) {
        super(props);
        makeObservable(this);
        this.billingAddress = this.props.billingAddress;
        this.layout = Value.default(this.props.layout, 'vertical');

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.getCountry = this.getCountry.bind(this);
    }

    public componentDidUpdate(prevProps: Readonly<IFormProps>, prevState: Readonly<IBillingAddressState>, snapshot?: any) {
        if (prevProps.billingAddress !== this.props.billingAddress) {
            this.billingAddress = this.props.billingAddress;
        }
    }

    @computed get name() {
        return this.billingAddress?.name
    }

    public getCountry(): string {
        return this.billingAddress.country;
    }

    public onCountryChange(value: string): void {
        this.billingAddress.setValue('country', value);
    }

    private handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
        this.billingAddress.setValue(event.target.name, event.target.value);
    }

    public render(): React.ReactElement {
        return (
            <React.Fragment>
                <h1 className={'rk-billing-address__header'}>Billing Address</h1>

                <AntdForm.Item rules={[{required: true, message: 'Please input your full name'}]}>
                    <Input type='text'
                           className='rk-input'
                           name='name'
                           defaultValue={this.billingAddress.name}
                           placeholder='Full Name ...'
                           onChange={this.handleInputChange}/>
                </AntdForm.Item>
                <AntdForm.Item rules={[{required: true, message: 'Please input your street address'}]}>
                    <Input type='text'
                           className='rk-input'
                           name='line1'
                           defaultValue={this.billingAddress.line1}
                           placeholder='Street Address ...'
                           onChange={this.handleInputChange}/>
                </AntdForm.Item>
                <AntdForm.Item rules={[{required: false}]}>
                    <Input type='text'
                           className='rk-input'
                           name='line2'
                           defaultValue={this.billingAddress.line2}
                           placeholder='Street Address Continued ...'
                           onChange={this.handleInputChange}/>
                </AntdForm.Item>
                <AntdForm.Item rules={[{required: false}]}>
                    <Input type='text'
                           className='rk-input'
                           name='city'
                           defaultValue={this.billingAddress.city}
                           placeholder='City ...'
                           onChange={this.handleInputChange}/>
                </AntdForm.Item>
                <AntdForm.Item rules={[{required: false}]}>
                    <Input type='text'
                           className='rk-input'
                           name='state'
                           defaultValue={this.billingAddress.state}
                           placeholder='State ...'
                           onChange={this.handleInputChange}/>
                </AntdForm.Item>
                <AntdForm.Item rules={[{required: true, message: 'Please input your postal code'}]}>
                    <Input type='text'
                           className='rk-input'
                           name='zip'
                           defaultValue={this.billingAddress.zip}
                           placeholder='Postcode ...'
                           onChange={this.handleInputChange}/>
                </AntdForm.Item>
                <AntdForm.Item rules={[{required: true, message: 'Please input your country'}]}>
                    <CountrySelect country={this.billingAddress.country} onCountryChange={this.onCountryChange}/>
                </AntdForm.Item>
            </React.Fragment>
        );
    }
}

export default observer(Form);
