import React, { Component } from 'react';
import { Col, Row } from 'antd';

import { Story } from 'models/story/Story';
import EmblaCarousel from "components/shared/EmblaCarousel";

import './StoryMultiPanel.scss';
import {observer, Observer} from 'mobx-react';
import {observable} from "mobx";

interface IStoryMultiPanelProps {
  story: Story;
}

interface IStoryMultiPanelState {
}

class StoryMultiPanel extends Component<IStoryMultiPanelProps, IStoryMultiPanelState> {
  private SEPARATOR: string = ' • ';
  @observable private story: Story;

  public constructor(props: IStoryMultiPanelProps) {
    super(props);
    this.story = this.props.story;
  }

  componentDidMount(): void {
    const {story} = this.props;
    if (story !== null && (story.default_localisation === null || story.default_localisation === undefined)) {
      this.story = this.props.story.withDefaultLocalisation();
      this.setState({}); // force re-render
    } else if (story !== null) {
      this.story = story;
    }
  }

  public render(): React.ReactElement {
    if ( this.story == null || this.story.default_localisation == null ) return <React.Fragment/>;

    return (
      <div className='rk-story-multi-panel' key={ `${ this.story.id }` }>
        <Row className='rk-story--title-section'>
          <Col xs={ 24 } sm={ 24 } lg={ { span: 16, offset: 2 } }>
            <p className='rk-story-multi-panel--title-text-container'>
              { this.story.title() }: New Series
            </p>
            <p className='rk-story-multi-panel--description-text-container'>
              { this.story.default_localisation?.biography }
            </p>
          </Col>
        </Row>
        <Row>
        </Row>
        <div className='rk-story-multi-panel--images-row'>
          <EmblaCarousel options={{ align: 'start', loop: true, dragFree: true }}>
            {this.renderStoryPanels().map((url, index) => {
              return (
                <button className='rk-story-multi-panel--image' key={index}
                  onClick={ () => window.location.href = `/story/${ this.story.id }` }>
                  <img src={ url } alt={this.story.title()}/>
                </button>
              )
            })}
          </EmblaCarousel>
        </div>
      </div>
    );
  }

  private renderStoryPanels(): string[] {
    const urls = [
      this.story.default_localisation?.multi_panel_image_url1,
      this.story.default_localisation?.multi_panel_image_url2,
      this.story.default_localisation?.multi_panel_image_url3
    ];
    return urls.concat(urls);
  }
}

export default observer(StoryMultiPanel);
