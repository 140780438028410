import React from 'react';
import Api from 'models/Api';

export interface WebglAppConfig {
  data: string;
  framework: string;
  loader: string;
  wasm: string;
  version: string;
}

export class WebglAppApi {
  public static latest(user_id: number | null): Promise<WebglAppConfig | null> {
    if (user_id == null) {
      return Promise.resolve<WebglAppConfig | null>(null);
    }
    return Api.get('/webgl_app/latest')
      .then(response => response.json());
  }
}
