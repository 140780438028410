import React from 'react';
import {action, makeObservable, observable} from 'mobx';
import * as _ from 'lodash';
import pluralize from 'pluralize'
import numWords from 'num-words'

import {DateCalc} from 'helpers/DateCalc';
import {EnumParser} from 'helpers/EnumParser';
import Format from 'helpers/Format';
import Value from 'helpers/Value';

import {SubscriptionPlan} from 'models/subscriptionPlan/SubscriptionPlan';
import {ApiResponseData} from 'models/ApiResponseData';
import {ISubscriptionApiData} from 'models/subscription/ISubscriptionApiData';
import {IUserApiData} from 'models/user/IUserApiData';
import {User} from 'models/user/User';
import {SubscriptionProvider} from 'models/subscription/SubscriptionProvider';


export enum IBillingPeriod {
    MONTHLY = 'monthly',
    YEARLY = 'yearly'
}

export enum ISubscriptionState {
    ACTIVATED,
    DEACTIVATED,
    CANCELED
}

export class Subscription {
    private readonly provider: SubscriptionProvider;

    @observable public id: number;
    @observable public name: string;

    @observable public subscription_plan: SubscriptionPlan;
    @observable public subscribers: User[];

    @observable public managed_by_id: number;
    @observable public manager: User;

    @observable public subscription_plan_key: string | null;
    @observable public active: boolean;

    @observable public max_number_of_users: number;
    @observable public max_number_of_devices: number;
    @observable public billing_period: IBillingPeriod | null;
    @observable public price: number;
    @observable public currency: string;
    @observable public billing_address_id: number | null;
    @observable public stripe_setup_intent: string | null;
    @observable public credit_card_last_four_digits: string | null;
    @observable public last_successful_charge: Date | null;
    @observable public cancelled_at: Date | null;

    @observable public blocked_stories: number[] | null;

    @observable public created_at: Date | null;
    @observable public updated_at: Date | null;

    constructor(provider: SubscriptionProvider) {
        makeObservable(this);

        this.provider = provider;

        this.subscription_plan = new SubscriptionPlan(this.provider.SubscriptionPlanProvider);
        this.subscribers = [];
        this.manager = new User(this.provider.UserProvider);

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
        this.withSubscribers = this.withSubscribers.bind(this);
        this.withSubscriptionPlan = this.withSubscriptionPlan.bind(this);
    }

    @action
    public withId(id: number): Subscription {
        this.id = id;
        return this;
    }

    @action
    public withData(data: ISubscriptionApiData): Subscription {
        this.load(data);
        return this;
    }

    @action
    public withSubscribers(subscribers: User[]): Subscription {
        this.subscribers = subscribers;
        return this;
    }

    @action
    public withSubscriptionPlan(plan: SubscriptionPlan): Subscription {
        this.subscription_plan = plan;
        return this;
    }

    @action
    public load(data: ISubscriptionApiData): void {
        this.id = data.id;
        this.name = data.name;

        if (data.subscription_plan) this.subscription_plan = this.subscription_plan.withSubscriptionData(data.subscription_plan);
        if (data.subscribers) this.subscribers = data.subscribers.map((data: ISubscriptionApiData.ISubscriberData) => new User(this.provider.UserProvider).withSubscriptionData(data));

        this.managed_by_id = data.managed_by_id;
        if (data.manager) this.manager = this.manager.withSubscriptionData(data.manager);
        this.subscription_plan_key = data.subscription_plan_key;
        this.active = Value.defaultFalse(data.subscription_plan.active);

        this.max_number_of_users = data.max_number_of_users;
        this.max_number_of_devices = data.max_number_of_devices;
        this.billing_period = EnumParser.parseEnum(data.billing_period, IBillingPeriod);
        this.price = Value.defaultZero(data.price);
        this.currency = data.currency;
        this.billing_address_id = data.billing_address_id;
        this.stripe_setup_intent = data.stripe_setup_intent;
        this.credit_card_last_four_digits = data.credit_card?.last_four_digits || '';
        this.last_successful_charge = Value.dateOrNull(data.last_successful_charge);
        this.cancelled_at = Value.dateOrNull(data.cancelled_at);

        this.blocked_stories = data.blocked_stories;

        this.created_at = Value.dateOrNull(data.created_at);
        this.updated_at = Value.dateOrNull(data.updated_at);
    }

    @action
    public setValue(field: string, value: any): void {
        this[field] = value;
    }

    public isManagedByUser(userId: number = this.provider.SessionProvider.userId()): boolean {
        return Number(userId) === Number(this.managed_by_id);
    }

    public canCancel(): boolean {
        return this.isManagedByUser() && this.getState() === ISubscriptionState.ACTIVATED;
    }

    public canManageSubscribers(): boolean {
        return this.isManagedByUser() && this.subscribers?.length > 1;
    }

    public getState(): ISubscriptionState {
        if (!this.active) {
            return ISubscriptionState.DEACTIVATED;
        } else if (this.cancelled_at === null) {
            return ISubscriptionState.ACTIVATED;
        } else {
            return ISubscriptionState.CANCELED;
        }
    }

    public getStateMessage(): string {
        switch (this.getState()) {
            case ISubscriptionState.ACTIVATED:
                return 'Active'
            case ISubscriptionState.DEACTIVATED:
                return 'Deactivated';
            case ISubscriptionState.CANCELED:
                return `Cancelled on ${Format.longDate(this.cancelled_at)}`;
        }
    }

    public getTrialMessage(): string {
        if (!this.subscription_plan?.trial) {
            return '';
        }

        const now: Date = new Date();
        const endDate: Date = this.getTrailEndDate();
        const dateString: string = Format.longDate(endDate);
        const daysBetween: number = DateCalc.daysBetween(endDate, now);

        if (daysBetween === 0) {
            return `Your trial will end today`;
        } else if (daysBetween === 1) {
            return `Your trial will end tomorrow`;
        } else if (daysBetween > 0) {
            return `Your trial will end in ${(daysBetween)} days, on ${dateString}`;
        } else {
            return `Your trial ended on ${dateString}`;
        }
    }

    public getNextChargeMessage(): string {
        const date = this.getNextChargeDate();
        const dateString: string = Format.longDate(date);
        const priceString: string = this.getPriceString();

        switch (this.getState()) {
            case ISubscriptionState.ACTIVATED:
                if (this.price <= 0) {
                    return 'Free subscription';
                }

                if (!this.isManagedByUser()) {
                    return '';
                }

                let message: string = `You will be charged ${priceString} on ${dateString}`;
                if (this.credit_card_last_four_digits) {
                    message += ` to card ending with ${this.credit_card_last_four_digits}`;
                }
                return message;
            case ISubscriptionState.CANCELED:
                return `Your plan will be terminated on ${Format.longDate(date)}`;
            case ISubscriptionState.DEACTIVATED:
                return '';
        }
    }

    public canSetPaymentMethod(): boolean {
        return this.price > 0 &&
            this.getState() === ISubscriptionState.ACTIVATED &&
            this.isManagedByUser()
    }

    private getPriceString(): string {
        return Format.money({amount: this.price, currency: this.currency});
    }

    private getNextChargeDate(): Date {
        if (this.isTrailEndedDateInTheFuture()) return this.getTrailEndDate();

        let date = this.last_successful_charge || this.getTrailEndDate();
        switch (this.billing_period) {
            case IBillingPeriod.MONTHLY:
                date = new Date(date.setMonth(date.getMonth() + 1));
                break;
            case IBillingPeriod.YEARLY:
                date = new Date(date.setFullYear(date.getFullYear() + 1));
                break;
        }

        return date;
    }

    private isTrailEndedDateInTheFuture(): boolean {
        let trailEndDate: Date = this.getTrailEndDate();
        let now: Date = new Date();

        return now < trailEndDate;
    }

    private getTrailEndDate(): Date {
        let date: Date = new Date(this.created_at.valueOf());
        if (this.subscription_plan?.trial) {
            date.setDate(this.created_at.getDate() + this.subscription_plan.trial_days);
        }

        return date;
    }

    public getSubscriberCountMessage(): string {
        return `${_.startCase(numWords(this.subscribers.length))} ${pluralize('subscriber', this.subscribers.length)}`
    }

    public fetchData(): Promise<Subscription> {
        return this.provider.get(this.id).then(this.withData);
    }

    public cancel(isAdmin: boolean = false): Promise<ApiResponseData> {
        if (this.isManagedByUser() || isAdmin) {
            return this.provider.cancelSubscription(this.id);
        } else {
            return Promise.resolve({success: false, message: 'You are not the manager of this subscription'});
        }
    }

    public resume(isAdmin: boolean = false): Promise<ApiResponseData> {
        if (this.isManagedByUser() || isAdmin) {
            return this.provider.resumeSubscription(this.id);
        } else {
            return Promise.resolve({success: false, message: 'You are not the manager of this subscription'});
        }
    }

    public promoteToManager(userId: number, isAdmin: boolean): Promise<ApiResponseData> {
        if (this.isManagedByUser() || isAdmin) {
            return this.provider.promoteToManager(this.id, {user_id: userId});
        } else {
            return Promise.resolve({success: false, message: 'You are not the manager of this subscription'});
        }
    }

    public addSubscribersToSubscription(subscriberIds: string[], isAdmin: boolean = false): Promise<ApiResponseData> {
        if (this.isManagedByUser() || isAdmin) {
            return this.provider.addSubscribersToSubscription(this.id, {subscriber_ids: subscriberIds});
        } else {
            return Promise.resolve({success: false, message: 'You are not the manager of this subscription'});
        }
    }

    public leave(): Promise<ApiResponseData> {
        return this.provider.leaveSubscription(this.id);
    }

    public kickSubscribers(subscriberIds: number[], isAdmin: boolean = false): Promise<ApiResponseData> {
        if (this.isManagedByUser() || isAdmin) {
            return this.provider.kickSubscriber(this.id, {subscriber_ids: subscriberIds});
        } else {
            return Promise.resolve({success: false, message: 'You are not the manager of this subscription'});
        }
    }

    public fetchSubscribers(isAdmin: boolean = false): Promise<Subscription> {
        if (this.isManagedByUser() || isAdmin) {
            return this.provider.getSubscribers(this.id)
                .then((apiSubscribers: IUserApiData[]) => apiSubscribers.map(userData => new User(this.provider.UserProvider).withData(userData)))
                .then(this.withSubscribers);
        } else {
            return Promise.resolve(this);
        }
    }
}
