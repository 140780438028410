import React, {Component} from 'react';

class GooglePlayLink extends Component<any, any> {
    public render(): React.ReactElement {
        return (
            <a href='https://play.google.com/store/apps/details?id=app.rascalkids.rascal' target='_blank'>
                <img src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                     alt='Get it on Google Play'/>
            </a>
        )
    }
}

export default GooglePlayLink;
