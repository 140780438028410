import React, {Component} from 'react';

class PauseMenuForwardIcon extends Component<any, any> {
    public render(): React.ReactElement {
        return (
            <div className='rk-chapter-icon-wrapper'>
                <div className='rk-chapter-icon-container'>
                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265.73 265.73">
                        <path fill='#FFFFFF' d="M4.2,140.94c4.22,56.39,51.21,99.98,107.76,99.97h0c12.59-.32,22.53-10.79,22.21-23.38-.31-12.13-10.07-21.9-22.21-22.21-34.49.01-62.45-27.94-62.46-62.42-.01-34.49,27.94-62.45,62.42-62.46,31.44-.01,56.6,23.41,60.66,54.36.09.72,0,0,0,0,0,0-13.1-13.02-14.34-13.75-2.86-1.98-6.33-3.15-10.08-3.15-9.8,0-17.75,7.95-17.75,17.75,0,5.14,2.2,9.75,5.68,12.99.2.21,60.03,60.27,60.03,60.27h0s0,0,0,0c0,0,59.83-60.06,60.03-60.27,3.49-3.24,5.68-7.86,5.68-12.99,0-9.8-7.95-17.75-17.75-17.75-3.74,0-7.21,1.17-10.08,3.15-1.23.72-14.34,13.75-14.34,13.75-4.46-59.5-56.31-104.12-115.81-99.67C44.36,29.59-.26,81.43,4.2,140.94Z"/>
                    </svg>
                </div>
            </div>
        );
    }
}

export default PauseMenuForwardIcon;