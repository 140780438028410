import React from 'react';
import {SortOrder} from 'antd/lib/table/interface';

import Api from 'models/Api';
import {ApiResponseData} from 'models/ApiResponseData';
import {
    IUserApiData,
    IUserApiDataDeleteRequest,
    IUserApiDataEmailUpdateRequest,
    IUserApiDataNameUpdateRequest,
    IUserApiDataPasswordUpdateRequest, IUserApiDataStatsResponse,
    IUserApiDataUpdateRequest
} from 'models/user/IUserApiData';
import {IUserSearchApiData} from 'models/user/IUserSearchApiData';
import {IBillingAddressApiData} from 'models/billingAddress/IBillingAddressApiData';
import {SearchApi} from 'models/SearchApi';

export type IUserApiSearchOrdering = 'id' | 'first_name' | 'last_name' | 'email';

export interface IUserApiSearchFilterParams {
    id?: number;
    email?: string;
    first_name?: string;
    last_name?: string;
    order_by?: IUserApiSearchOrdering;
    order?: SortOrder;
}

export interface IUserApiSearchParams {
    user_ids?: number[];
    page?: number;
    page_size?: number;
    filters?: IUserApiSearchFilterParams
}

export class UserApi {
    public static resetPasswordTokenIsValid(email, token): Promise<boolean> {
        if (email == null || token == null) {
            return Promise.resolve<boolean>(false);
        }
        return Api.get(`/api/v1/users/password_reset/valid_token?email=${email}&token=${token}`)
            .then(response => response.json())
            .then(json => json.valid);
    }

    public static resetPassword(body): Promise<boolean> {
        return Api.post(`/api/v1/users/password_reset`, {
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(json => json.valid)
    }

    public static registerNewsletter(body): Promise<ApiResponseData> {
        return Api.post(`/api/v1/users/newsletter`, {
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(json => json)
    }

    public static forgotPassword(body): Promise<boolean> {
        return Api.post(`/api/v1/users/forgot_password`, {
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(json => json.valid);
    }

    public static user(user_id: number): Promise<IUserApiData> {
        return Api.get(`/api/v1/users/${user_id}`)
            .then(response => response.json())
            .then(json => json['user']);
    }

    public static getUserSearch(searchParams?: IUserApiSearchParams): Promise<IUserSearchApiData> {
        let query: string[] = [];
        let queryStr = ''
        if (searchParams) {
            const {user_ids} = searchParams;
            if (user_ids && user_ids.length > 0) {
                query.push(`user_ids=${searchParams.user_ids.join(',')}`);
            }
            queryStr = SearchApi.buildQueryFromParams(searchParams, query);
        }

        return Api.get(`/api/v1/users/search${queryStr}`)
            .then(response => response.json());
    }

    public static update(user_id: number, body: IUserApiDataUpdateRequest): Promise<ApiResponseData> {
        return Api.put(`/api/v1/users/${user_id}/`, {
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    public static changeNames(user_id: number, body: IUserApiDataNameUpdateRequest): Promise<ApiResponseData> {
        return Api.put(`/api/v1/users/${user_id}/names`, {
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    public static changeEmail(user_id: number, body: IUserApiDataEmailUpdateRequest): Promise<ApiResponseData> {
        return Api.put(`/api/v1/users/${user_id}/email`, {
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    public static changePassword(user_id: number, body: IUserApiDataPasswordUpdateRequest): Promise<ApiResponseData> {
        return Api.put(`/api/v1/users/${user_id}/password`, {
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    public static deleteSessions(user_id: number): Promise<ApiResponseData> {
        return Api.del(`/api/v1/users/${user_id}/sessions`)
            .then(response => response.json());
    }

    public static deleteUser(user_id: number, body: IUserApiDataDeleteRequest): Promise<ApiResponseData> {
        return Api.del(`/api/v1/users/${user_id}/`, {
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    public static deleteUserData(user_id: number, body: IUserApiDataDeleteRequest): Promise<ApiResponseData> {
        return Api.del(`/api/v1/users/${user_id}/data`, {
            body: JSON.stringify(body)
        }).then(response => response.json());
    }

    public static getBillingAddresses(user_id: number): Promise<IBillingAddressApiData[]> {
        return Api.get(`/api/v1/users/${user_id}/billing_addresses`).then(response => response.json()).then(
            json => json.billing_addresses || []);
    }

    public static getActiveBillingAddress(user_id: number): Promise<IBillingAddressApiData> {
        return Api.get(`/api/v1/users/${user_id}/active_billing_address`).then(response => response.json()).then(
            json => json.billing_address || null);
    }

    public static getStats() {
        return Api.get(`/api/v1/users/stats`).then(response => response.json());
    }

    public static getStatsFor(userId: number): Promise<IUserApiDataStatsResponse> {
        return Api.get(`/api/v1/users/${userId}/stats`).then(response => response.json());
    }

    public static getPlatform(user_id: number): Promise<"android" | "ios" | "webgl"> {
        return Api.get(`/api/v1/users/${user_id}/platform`)
            .then(response => response.json())
            .then(json => json['platform'])
            .then((platform) => {
                console.log(platform)
                return platform
            });
    }
}
