import {IMoney} from 'models/subscriptionPlan/IMoney';

class Format {
  public static capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  public static money(money: IMoney): string {
    if (!money) return 'Free'
    const options: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: money.currency,
      currencyDisplay: 'code',
    }
    const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-NZ', options);
    return money.amount > 0 ? currencyFormatter.format(money.amount) : 'Free'
  }

  public static longDate(date: Date): string {
    let dateFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-NZ', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    return dateFormatter.format(date);
  }

  public static shortDate(date: Date): string {
    let dateFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Pacific/Auckland'
    });

    return dateFormatter.format(date);
  }
}

export default Format;
