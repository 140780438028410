import Api from "models/Api";
import {IDashboardApiData} from "models/dashboard/IDashboardApiData";
import {ICouponApiData} from "models/coupon/ICouponApiData";
import {ICouponUpdateApiData} from "models/coupon/ICouponUpdateApiData";
import {ICouponCreateApiData} from "models/coupon/ICouponCreateApiData";
import {ApiResponseData} from "models/ApiResponseData";

class CouponApi {
    public static getAll(): Promise<Array<ICouponApiData>> {
        return Api.get(`/api/v1/coupons`)
            .then(response => response.json())
            .then(json => json.coupons);
    }

    public static get(id: number): Promise<ICouponApiData> {
        return Api.get(`/api/v1/coupons/${id}`)
            .then(response => response.json())
            .then(json => json.coupon);
    }

    public static create(data: ICouponCreateApiData): Promise<ICouponApiData> {
        return Api.post(`/api/v1/coupons`, {
                body: JSON.stringify({
                    coupon: data
                })
            })
            .then(response => {
                if (response.status === 422) {
                    return response.json().then(json => Promise.reject(json));
                }
                return response.json().then(json => json.coupon);
            });
    }

    public static update(id: number, data: ICouponUpdateApiData): Promise<ICouponApiData> {
        return Api.put(`/api/v1/coupons/${id}`, {
                body: JSON.stringify(data)
            })
            .then(response => {
                if (response.status === 422) {
                    return response.json().then(json => Promise.reject(json));
                }
                return response.json().then(json => json.coupon);
            });
    }

    public static check(code: string, subscriptionPlanId: number): Promise<ICouponApiData> {
        return Api.get(`/api/v1/coupons/check?code=${code}&subscription_plan_id=${subscriptionPlanId}`)
            .then(response => response.json());
    }

    public static getUserCampaignCoupon(subscriptionId: number): Promise<ICouponApiData> {
        return Api.get(`/api/v1/coupon?subscription_plan_ids[]=${subscriptionId}`)
            .then(response => response.json());
    }

    public static getUserCampaignCouponForPlans(subscriptionIds: number[]): Promise<ICouponApiData> {
        return Api.get(`/api/v1/coupon?subscription_plan_ids[]=${subscriptionIds.join(',')}`)
            .then(response => response.json())
    }
}
export default CouponApi;
