import React, {Component} from "react";
import {Button, Checkbox, Col, Input, List, message, Row} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import DefaultLayout from "layouts/DefaultLayout";
import Papa from "papaparse";
import _ from "lodash";
import {SubscriptionApi} from "models/subscription/SubscriptionApi";
import {Subscription} from "models/subscription/Subscription";
import {ComponentWithStore} from "models/RootStore";
import {ApiResponseData} from "models/ApiResponseData";
import MessageApi from "components/hooks/MessageApi";
import {Hash} from "types/hash";
import FileSaver, {saveAs} from "file-saver";

export interface UserInfo {
    email: string;
    first_name: string;
    last_name: string;
    password?: string;
    state?: 'created' | 'added' | 'existing' | 'failed';
}

interface UserInfoResultStub {
    email: string;
    password?: string;
    created: boolean;
}

export interface UserInfoResult {
    result: Hash<UserInfoResultStub>;
}

interface IBulkSubscribeProps {
    routeParams: { subscriptionId: string };
}

interface IBulkSubscribeState {
    userInfoList: UserInfo[];
    submitting: boolean;
    exportable: boolean;
}

class BulkSubscribe extends ComponentWithStore<IBulkSubscribeProps, IBulkSubscribeState> {

    private subscription: Subscription = null;
    private file: File = null;

    constructor(props) {
        super(props);

        this.state = {
            userInfoList: [],
            submitting: false,
            exportable: false
        }

        this.finishedReadingFile = this.finishedReadingFile.bind(this);
        this.handleErrorResponse = this.handleErrorResponse.bind(this);
    }

    componentDidMount() {
        let id: number = Number(this.props.routeParams.subscriptionId);
        this.subscription = new Subscription(this.store.SubscriptionProvider).withId(id);
    }

    changedFile(event) {
        this.file = event.target.files[0];
        Papa.parse(event.target.files[0], {
            header: true,
            transformHeader: this.transformHeader,
        	complete: this.finishedReadingFile
        });
    }

    transformHeader(originalHeader: string) {
        let header = originalHeader.toLowerCase().trim().replace(/\s+/g, '_');
        if (header.includes('email')) {
            return 'email';
        }
        return header;
    }

    finishedReadingFile(results) {
        if (results.data) {
            const data = results.data;
            let userInfoList: UserInfo[] = _.map(data, (result) => {
                return {
                    email: result.email.trim().toLowerCase(),
                    first_name: result.first_name,
                    last_name: result.last_name
                };
            });
            userInfoList = _.filter(userInfoList, (userInfo) => {
                return userInfo['email'].length > 0;
            });
            this.setState({
                userInfoList: userInfoList
            });
        } else {
            console.log('Failed to parse data', results);
        }
    }

    removeUser(index: number) {
        const {userInfoList} = this.state;
        userInfoList.splice(index, 1);
        this.setState({
            userInfoList: [...userInfoList]
        })
    }

    submitUserInfo() {
        const {userInfoList} = this.state;
        this.setState({submitting: true})
        SubscriptionApi.bulkCreateSubscribers(this.subscription.id, { users: userInfoList }).then((response) => {
            if (response.success) {
                _.each(userInfoList, (userInfo: UserInfo) => {
                    const result: UserInfoResultStub = response.result[userInfo.email];
                    if (result == null) {
                        userInfo.state = response.success ? 'existing' : 'failed';
                    } else {
                        if (result.created) {
                            userInfo.state = 'created';
                            userInfo.password = result.password;
                        } else {
                            userInfo.state = 'added';
                            userInfo.password = null;
                        }
                    }
                });
                this.setState({
                    exportable: true,
                    userInfoList: [...userInfoList]
                });
                MessageApi().success(`${response.message}`);
            } else {
                console.log(response);
                MessageApi().error(`${response.message}`);
            }
        }).catch(this.handleErrorResponse)
          .finally(() => this.setState({ submitting: false }));
    }

    downloadUserInfoCSV() {
        const {userInfoList} = this.state;
        const csv: string = Papa.unparse(userInfoList, {
            header: true,
            columns: ["email", "password", "state"]
        });
        const blob = new Blob([csv], {type: 'text/csv'});
        FileSaver.saveAs(blob, "subscribed_" + this.file.name);
    }

    private handleErrorResponse(response): Promise<any> {
        this.setState({submitting: false});
        return response.json()
        .then((body: ApiResponseData) => {
          console.log('Error', body);
          MessageApi().error(`${body.message}`)
        })
        .catch((error) => {
          console.log('Error', error);
          MessageApi().error('An error occurred')
        })
    }

    render() {
        const {userInfoList} = this.state;
        return (
            <DefaultLayout>
                <Row>
                    <Col xs={{offset: 2, span: 20}}>
                        <h3>Bulk Subscribe (Expect input csv file).</h3>
                        <p>(Admin only because it looks bad and is not smart currently)</p>
                        <p>(TODO: Improve bulk subscribe to be in a async worker and then poll the task for updates. This is otherwise likely to timeout when putting in lists greater than 300 Users.)</p>
                        <p>Looks for headers that match (any case): <b>email</b>, <b>first_name</b>, <b>last_name</b></p>
                        <Input type={'file'} onChange={(event) => this.changedFile(event)}/>

                        {this.renderDetails()}
                        {this.renderUserInfoList()}
                    </Col>
                </Row>
            </DefaultLayout>
        )
    }

    renderDetails() {
        const {userInfoList, submitting} = this.state;
        if (userInfoList.length <= 0) { return <React.Fragment />}
        return (
            <div>
                <p>Loaded {userInfoList.length} users</p>
                <Button disabled={submitting} onClick={() => this.submitUserInfo()}>Bulk Add Users</Button>
                {this.renderResponse()}
            </div>
        )
    }

    renderResponse() {
        const {exportable} = this.state;
        if (!exportable) return <React.Fragment />
        return (
            <div>
                <Button onClick={() => this.downloadUserInfoCSV()}>Download CSV</Button>
            </div>
        )
    }

    renderUserInfoList() {
        const {userInfoList} = this.state;
        return (
            <div>
                <List>
                    {userInfoList.map((userInfo, index) => this.renderUserInfo(userInfo, index))}
                </List>
            </div>
        )
    }

    renderUserInfo(userInfo: UserInfo, index: number): JSX.Element {
        const {exportable} = this.state;
        return (
            <List.Item key={`${index}-${userInfo.email}`} actions={[<a key="user-info-delete" onClick={(element) => this.removeUser(index)}><DeleteOutlined /></a>]}>
                <div style={{width: '100%'}}>
                    <Row>
                        <Col xs={16}>
                            <div>
                                <div>{userInfo.first_name} {userInfo.last_name}</div>
                                {userInfo.email}
                            </div>
                        </Col>
                        <Col xs={8}>
                            <div>
                                <div><span>{exportable ? userInfo.state : ''}</span></div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </List.Item>
        );
    }
}

export default BulkSubscribe;
