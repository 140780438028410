import {action, makeObservable, observable} from 'mobx';
import {Story} from 'models/story/Story';
import {StoryLocalisationProvider} from 'models/storyLocalisation/StoryLocalisationProvider';
import {IStoryLocalisationApiData} from 'models/storyLocalisation/IStoryLocalisationApiData';

export class StoryLocalisation {
    private readonly provider: StoryLocalisationProvider;

    @observable public id: number;

    @observable public title: string;
    @observable public biography: string;
    @observable public locale: string;
    @observable public title_image_url: string;
    @observable public image_url: string;
    @observable public portrait_image_url: string;
    @observable public featured_portrait_image_url: string;
    @observable public featured_landscape_image_url: string;
    @observable public multi_panel_image_url1: string;
    @observable public multi_panel_image_url2: string;
    @observable public multi_panel_image_url3: string;
    @observable public featured_text_color: string;

    @observable public story_id: number;
    @observable public story: Story;

    constructor(provider: StoryLocalisationProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withData(data: IStoryLocalisationApiData) {
        this.load(data);
        return this;
    }

    @action.bound
    public load(data: IStoryLocalisationApiData) {
        this.id = data.id;
        this.title = data.title;
        this.biography = data.biography;
        this.locale = data.locale;
        this.title_image_url = data.title_image_url;
        this.image_url = data.image_url;
        this.portrait_image_url = data.portrait_image_url;
        this.featured_portrait_image_url = data.featured_portrait_image_url;
        this.featured_landscape_image_url = data.featured_landscape_image_url;
        this.multi_panel_image_url1 = data.multi_panel_image_url1;
        this.multi_panel_image_url2 = data.multi_panel_image_url2;
        this.multi_panel_image_url3 = data.multi_panel_image_url3;
        this.featured_text_color = data.featured_text_color;
        this.story_id = data.story_id;
    }

    @action.bound
    public setValue(field: string, value: any) {
        this[field] = value;
    }
}
