import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import Cookies from 'universal-cookie';
import {
  AuditOutlined,
  BookOutlined,
  ClusterOutlined,
  GroupOutlined,
  HomeOutlined,
  IdcardOutlined,
  LinkOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;

interface ISideBarProps {

}

interface ISideBarState {
  collapsed: boolean;
}

class AdminSideBar extends Component<ISideBarProps, ISideBarState> {

  private static COOKIE_NAME = 'rascal-sidebar-collapsed';
  private cookies = new Cookies();

  constructor(props: ISideBarProps) {
    super(props);
    let collapsed: string | boolean = this.cookies.get(AdminSideBar.COOKIE_NAME);
    collapsed = collapsed === 'true';

    this.state = {
      collapsed: collapsed
    };

    this.onCollapse = this.onCollapse.bind(this);
  }

  public componentDidMount(): void {
  }

  public onCollapse(collapsed): void {
    this.cookies.set(AdminSideBar.COOKIE_NAME, collapsed, { sameSite: "strict", secure: true });
    this.setState({ collapsed });
  }

  public render(): React.ReactElement {
    const { collapsed } = this.state;

    return (
      <div className='rk-admin-sidebar'>
        <Sider
          breakpoint={ window.innerWidth >= 768 ? null : "xs" }
          collapsedWidth={ window.innerWidth >= 768 ? 80 : 30 }
          collapsible collapsed={ collapsed }
          onCollapse={ this.onCollapse }
          theme='light'
        >
          <div className='logo'/>
          <Menu theme='light' mode='inline'>
            <Menu.Item key='/admin/dashboard' icon={ <AuditOutlined/> }>
              <Link to='/admin/dashboard'>Dashboard</Link>
            </Menu.Item>
            <Menu.Item key='/admin/stories' icon={ <BookOutlined/> }>
              <Link to='/admin/stories'>Stories</Link>
            </Menu.Item>
            <Menu.Item key='/admin/collections' icon={ <ClusterOutlined/> }>
              <Link to='/admin/collections'>Collections</Link>
            </Menu.Item>
            <Menu.Item key='/admin/subscription_plans' icon={ <GroupOutlined/> }>
              <Link to='/admin/subscription_plans'>Subscription Plans</Link>
            </Menu.Item>
            <Menu.Item key='/admin/subscriptions' icon={ <IdcardOutlined/> }>
              <Link to='/admin/subscriptions'>Subscriptions</Link>
            </Menu.Item>
            <Menu.Item key='/admin/users' icon={ <UserOutlined/> }>
              <Link to='/admin/users'>Users</Link>
            </Menu.Item>
            <Menu.Item key='/admin/catalogue/edit' icon={ <HomeOutlined/> }>
              <Link to='/admin/catalogue/edit'>Edit Catalogue</Link>
            </Menu.Item>
            <Menu.Item key='/admin/campaigns' icon={ <LinkOutlined/> }>
              <Link to='/admin/campaigns'>Campaigns</Link>
            </Menu.Item>
            <Menu.Item key='/admin/coupons' icon={ <TagOutlined /> }>
              <Link to='/admin/coupons'>Coupons</Link>
            </Menu.Item>
          </Menu>
        </Sider>
      </div>
    );
  }
}

export default AdminSideBar;
