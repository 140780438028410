import React, {Fragment} from 'react';
import * as _ from 'lodash';
import logger from 'loglevel';
import {observer} from 'mobx-react';
import {loadStripe} from '@stripe/stripe-js/pure';
import {ConfirmCardSetupData, SetupIntent, Stripe, StripeElements, StripeElementsOptions} from '@stripe/stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import {PaymentMethodCreateParams} from '@stripe/stripe-js/types/api/payment-methods';
import {SetupIntentResult} from '@stripe/stripe-js/types/stripe-js/stripe';
import {Button, Radio, Row} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio/interface';

import {ComponentWithStore, withStore} from 'models/RootStore';
import {BillingAddress} from 'models/billingAddress/BillingAddress';
import {Subscription} from 'models/subscription/Subscription';
import {CreditCardApi} from 'models/creditCard/CreditCardApi';
import {ICreditCardApiData} from 'models/creditCard/ICreditCardApiData';

import '../stripe.scss'
import './style.scss'

loadStripe.setLoadParameters({advancedFraudSignals: false});

interface ICreditCardProps {
    stripe: Stripe;
    elements: StripeElements;
    options: StripeElementsOptions;
    subscription: Subscription;
    billingAddress: BillingAddress;
    selectedCreditCardId?: number | null;
    onCreditCardSelection?: (id: number) => void;
}

interface ICreditCardState {
    errorMessage: string;
    submitting: boolean;
    newCreditCard: boolean;
    creditCards: ICreditCardApiData[];
    selectedCreditCardId?: number;
}

class SimpleCreditCardForm extends ComponentWithStore<ICreditCardProps, ICreditCardState> {
    private readonly disableSave: boolean;

    constructor(props: ICreditCardProps) {
        super(props);

        let selectedCreditCardId = {}
        if (props.selectedCreditCardId) {
            selectedCreditCardId['selectedCreditCardId'] = props.selectedCreditCardId;
        }

        this.state = {
            errorMessage: '',
            submitting: false,
            newCreditCard: true,
            creditCards: [],
            ...selectedCreditCardId
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectCreditCard = this.handleSelectCreditCard.bind(this);
    }

    public componentDidMount(): void {
        CreditCardApi.getAll(this.store.SessionProvider.userId())
            .then((creditCards: ICreditCardApiData[]) => {
                const {selectedCreditCardId} = this.state;
                let newSelectedCreditCardId = selectedCreditCardId;
                if (creditCards) {
                    if (creditCards.length > 0 && !selectedCreditCardId) {
                        newSelectedCreditCardId = creditCards[0].id;
                    }
                    this.setState({
                        newCreditCard: creditCards.length === 0,
                        creditCards: creditCards,
                        selectedCreditCardId: newSelectedCreditCardId
                    });
                }
            });
    }

    private handleSelectCreditCard(e: RadioChangeEvent): void {
        const {onCreditCardSelection} = this.props;
        let value = e.target.value;
        if (onCreditCardSelection) {
            onCreditCardSelection(value as number);
        }
        this.setState({
            selectedCreditCardId: e.target.value
        });
    };

    private onSaveCreditCard(stripeSetupIntentId: string, creditCardId: number): Promise<any> {
        const {subscription} = this.props;

        return this.store.SubscriptionProvider.update(subscription.id, {
            stripe_setup_intent: stripeSetupIntentId,
            credit_card_id: creditCardId
        })
            .then((result) => {
            })
            .catch(response => response.json()
                .then((result) => {
                    if (result.codes?.includes('ERK|602') || result.codes?.includes('ERK|604')) {
                        console.log('Invalid key provided');
                    } else if (result.codes?.includes('ERK|603')) {
                    } else {
                        logger.error('An error has occurred while submitting subscription:', result);
                        console.log(result.message);
                    }
                })
                .catch(error => {
                    logger.error('An error has occurred while submitting subscription and it failed to parse:', response, error);
                })
            );
    }

    public async handleSubmit(event) {
        event.preventDefault();

        const {stripe, elements, billingAddress} = this.props;
        const {newCreditCard} = this.state;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        this.setState({submitting: true});

        return this.confirmCardSetup()
            .then(({setupIntent, error}: SetupIntentResult) => {
                if (error) {
                    console.log(error);
                    this.setState({
                        errorMessage: error.message,
                        submitting: false
                    });
                    throw error;
                }

                return setupIntent;
            })
            .then((setupIntent: SetupIntent) => stripe.retrieveSetupIntent(setupIntent.client_secret))
            .then(({setupIntent, error}: SetupIntentResult) => {
                // Inspect the SetupIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

                if (error) {
                    console.log(error);
                    this.setState({
                        errorMessage: error.message,
                        submitting: false
                    });
                    throw error;
                }

                switch (setupIntent.status) {
                    case 'processing':
                        this.setState({errorMessage: 'Processing payment details. We\'ll update you when processing is complete.'});
                        break;

                    case 'requires_payment_method':
                        // Redirect your user back to your payment page to attempt collecting
                        // payment again
                        let message: string = 'Failed to process payment details. Please try another payment method.';
                        this.setState({errorMessage: message});
                        throw new Error(message);
                }

                if (newCreditCard) {
                    return CreditCardApi.create({
                        userId: this.store.SessionProvider.userId(),
                        billingAddressId: billingAddress.id,
                        paymentMethod: setupIntent.payment_method
                    }).then((json: { credit_card: { id: number } }) => this.onSaveCreditCard(setupIntent.id, json.credit_card.id));
                } else {
                    return this.onSaveCreditCard(setupIntent.id, this.getSelectedCreditCard().id);
                }
            })
            .then(() => {
                window.location.href = '/account';
                this.setState({submitting: false});
            })
            .catch(() => this.setState({submitting: false}));
    }

    private getSelectedCreditCard(): ICreditCardApiData | null {
        const {creditCards, selectedCreditCardId} = this.state;

        return _.find(creditCards, value => value.id === selectedCreditCardId);
    }

    private getExistingCardData(): ConfirmCardSetupData | null {
        let creditCard: ICreditCardApiData | null = this.getSelectedCreditCard();

        return creditCard ? {
            payment_method: creditCard.stripe_id
        } : null;

    }

    private getNewCardData(): ConfirmCardSetupData {
        const {elements} = this.props;

        return {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: this.getBillingDetails()
            }
        };
    }

    private getBillingDetails(): PaymentMethodCreateParams.BillingDetails {
        const {billingAddress} = this.props;

        return {
            'name': billingAddress.name,
            'address': {
                'city': billingAddress.city,
                'country': billingAddress.country,
                'line1': billingAddress.line1,
                'line2': billingAddress.line2,
                'postal_code': billingAddress.zip,
                'state': billingAddress.state
            }
        };
    }

    private async confirmCardSetup(): Promise<SetupIntentResult> {
        const {stripe, options} = this.props;
        const {newCreditCard} = this.state;

        let data: ConfirmCardSetupData | null = newCreditCard ? this.getNewCardData() : this.getExistingCardData();

        if (!data) return {
            error: {
                type: 'invalid_request_error',
                message: 'Invalid card data provided'
            }
        }

        return await stripe.confirmCardSetup(options.clientSecret, data);
    }

    private renderSelectCreditCard(): React.ReactElement {
        const {creditCards, selectedCreditCardId} = this.state;

        return (
            <React.Fragment>
                {creditCards.length === 0 ?
                    <span>No existing cards</span>
                    :
                    <Radio.Group onChange={this.handleSelectCreditCard}>
                        {creditCards.map(creditCard => (
                            <Radio key={creditCard.id}
                                   value={creditCard.id}
                                    checked={creditCard.id == selectedCreditCardId}>
                                Card ending with {creditCard.last_four_digits}
                            </Radio>
                        ))}
                    </Radio.Group>
                }

                <span className='rk-link' onClick={() => this.setState({newCreditCard: true})}>
                    New Credit Card
                </span>
            </React.Fragment>
        );
    }

    private renderCardElement(): React.ReactElement {
        return (
            <React.Fragment>
                <CardElement options={{hidePostalCode: true}}/>
                <div className={'rk-use-saved-credit-card'}>
                    <span className='rk-link' onClick={() => this.setState({newCreditCard: false})}>
                        Use Saved Credit Card
                    </span>
                </div>
            </React.Fragment>
        );
    }

    private renderButton(): React.ReactElement {
        const {elements, stripe} = this.props;
        const {submitting} = this.state;

        return (
            <Button className='rk-btn rk-btn-secondary rounded submit-form'
                    type='primary'
                    disabled={this.disableSave || submitting || !stripe || !elements}
                    onClick={this.handleSubmit}>
                Save Payment Details
            </Button>
        );
    }

    public render(): React.ReactElement {
        const {errorMessage, newCreditCard} = this.state;

        return (
            <Fragment>
                <Row>
                    <h1>Payment</h1>
                </Row>
                <Row>{newCreditCard ? this.renderCardElement() : this.renderSelectCreditCard()}</Row>
                <br/>
                {errorMessage && <div>{errorMessage}</div>}
                <Row justify='center'>{this.renderButton()}</Row>
            </Fragment>
        );
    }
}

export default observer(SimpleCreditCardForm);
