import React from 'react';
import {Link} from 'react-router-dom';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Form, Input, Row} from 'antd';
import {Lego} from 'lottie/lottie-assets';
import {Lottie} from '@rascality/react-lottie';

import ScriptLoader from 'helpers/ScriptLoader';
import {ComponentWithStore, withStore} from 'models/RootStore';
import Api from 'models/Api';
import DefaultLayout from 'layouts/DefaultLayout';
import Turnstile from "react-turnstile";

const turnstileSiteKey: string = process.env.TURNSTILE_SITE_KEY;

class ChangeEmail extends ComponentWithStore<any, any> {
  @observable private captchaResponse: string;

  public constructor(props) {
    super(props);
    makeObservable(this);
    this.onFinish = this.onFinish.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
  }

  public componentDidMount(): void {
  }

  public onFinish(values): void {
    // TODO: I think this is supposed to be calling UserProvider.changeEmail
    Api.post('api/v1/users', {
        body: JSON.stringify({
          user: values,
          'cf-turnstile-response': this.captchaResponse
        })
      })
      .then(response => response.json())
      .then(json => {
        let result = this.store.SessionProvider.store(json);
        if (result.success) {
          window.location.href = '/home';
        }
      });
  };

  public onFinishFailed(errorInfo): void {
    console.log('Failed:', errorInfo);
  };


  public errorCallback(): void {
  }

  public verifyCallback(response: string): void {
    this.captchaResponse = response;
  }

  public expiredCallback(): void {
    this.captchaResponse = null;
  }

  public render(): React.ReactElement {
    return (
      <DefaultLayout>
        <div className={'rk-register-content'}>
          <Form onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
            <Row justify='space-around' align='middle'>
              <Col xs={24} sm={12} xl={{span: 9, offset: 3}}>
                <Row>
                  <Col xs={24}>
                    <div>
                      <h1>Change Email</h1>
                      <p>Enter your new email and we will send a change of email address request to confirm. Type it in
                        twice so we can avoid any typos.</p>
                    </div>
                    <div>
                      <Form.Item
                        name='email'
                        rules={[{required: true, message: 'Please input your email'}]}
                      >
                        <Input className={'rk-input'} placeholder='Email' type='email'/>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        name='email-check'
                        rules={[{required: true, message: 'Please input your email again'}]}
                      >
                        <Input className={'rk-input'} placeholder='Email ... again' type='email'/>
                      </Form.Item>
                    </div>
                    <div className={'rk-captcha'}>
                      <Row>
                        <Col xs={12}>
                          <Turnstile
                            theme={'light'}
                            sitekey={turnstileSiteKey}
                            onError={this.errorCallback}
                            onVerify={this.verifyCallback}
                            onExpire={this.expiredCallback}
                            retry={'auto'}
                            retryInterval={8000}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Button className={'rk-btn'} type='primary' htmlType='submit'
                              disabled={this.captchaResponse == null}>Submit</Button>
                    </div>
                    <div className={'rk-redirect-link'}>
                      <p>Feeling lost? <strong><Link to={'/sign_in'}>Sign In here</Link>.</strong></p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <div>
                  <Lottie speed={0.6} config={{animationData: Lego, loop: true}}/>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </DefaultLayout>
    );
  }
}

export default withStore(observer(ChangeEmail));
