import logger from 'loglevel';

import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {IPermanentStore, PermanentStore} from 'models/PermanentStore';

export class IndexedDBWrapper extends AbstractStore implements IPermanentStore {
    private static readonly VERSION: number = 3;

    private _db: IDBDatabase;
    private readonly _json: boolean;
    private readonly _tableNames: string[];

    public constructor(rootStore: RootStore, databaseName: string, tableNames: string[], json: boolean = false) {
        super(rootStore, `IndexedDB.${databaseName}`)
        this._json = json;
        this._tableNames = tableNames;
        logger.debug(`RKDB.init: ${databaseName}`);
    }

    private static get IndexedDB(): IDBFactory {
        return window['indexedDB'] || window['mozIndexedDB'] || window['webkitIndexedDB'] || window['msIndexedDB'];
    }

    private static get IndexedDBTransaction(): IDBTransaction {
        return window['IDBTransaction'] || window['webkitIDBTransaction'] || window['msIDBTransaction'];
    }

    private static get IndexedDBKeyRange(): IDBKeyRange {
        return window['IDBKeyRange'] || window['webkitIDBKeyRange'] || window['msIDBKeyRange'];
    }

    private static expiryKey(key: string): string {
        return `${key}_expiry`;
    }

    public open(): Promise<void> {
        logger.debug(`${this.DatabaseName}.open():`);

        let indexedDB: IDBFactory = IndexedDBWrapper.IndexedDB;

        if (!indexedDB) {
            logger.warn(`${this.DatabaseName}.open(): Your browser doesn't support a stable version of IndexedDB.`);
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {
            let dbOpenDBRequest: IDBOpenDBRequest = indexedDB.open(this.DatabaseName, IndexedDBWrapper.VERSION);

            let _reject = reject;
            setTimeout(() => {
                if (dbOpenDBRequest.readyState === 'pending') {
                    _reject();
                }
            }, 120);

            dbOpenDBRequest.onblocked = () => {
                logger.error(`${this.DatabaseName}.open(): OnBlocked - Failed to load IndexedDB.`);
                reject();
            }

            dbOpenDBRequest.onupgradeneeded = (evt) => {
                logger.debug(`${this.DatabaseName}.open(): OnUpgradeNeeded ${evt.oldVersion} -> ${evt.newVersion}}`);
                this._db = dbOpenDBRequest.result;
                // Create the store only if it is missing... somehow.
                // TODO: We should check with IndexDB standards as the web evolves.
                this._tableNames.forEach(name => {
                    if (!this._db.objectStoreNames.contains(name)) {
                        // Then create a new store.
                        this._db.createObjectStore(name);
                    }
                });
            };

            dbOpenDBRequest.onsuccess = () => {
                logger.debug(`${this.DatabaseName}.open(): OnSuccess`);
                this._db = dbOpenDBRequest.result;
                resolve();
            };

            dbOpenDBRequest.onerror = e => {
                logger.error(`${this.DatabaseName}.open(): OnError - Failed to load IndexedDB. ${JSON.stringify(e)}`);
                reject(e);
            };
        });
    }

    public doStore<T>(table: string, key: string, value: T, expiresInMinutes?: number): Promise<void> {
        logger.debug(`RKDB.store: ${this.DatabaseName}.${key}`);

        return new Promise((resolve, reject) => {
            let transaction: IDBTransaction = this._db.transaction(table, 'readwrite');

            transaction.oncomplete = () => {
                logger.debug(`${this.DatabaseName}.store(${key}): OnSuccess`);
                resolve();
            };

            transaction.onerror = () => {
                logger.error(`${this.DatabaseName}.store(${key}): OnError`);
                reject();
            };

            transaction.objectStore(table).put(this._json ? JSON.stringify(value) : value, key);
            transaction.objectStore(table).put(PermanentStore.getExpiresAt(expiresInMinutes), IndexedDBWrapper.expiryKey(key));
        });
    }

    public doRead<T>(table: string, key: string): Promise<T | null> {
        logger.debug(`RKDB.read: ${this.DatabaseName}.${key}`);

        return new Promise((resolve, reject) => {
            let transaction: IDBTransaction = this._db.transaction(table, 'readwrite');
            let dataRequest: IDBRequest;
            let expiryRequest: IDBRequest;

            transaction.oncomplete = () => {
                logger.debug(`${this.DatabaseName}.read(${key}): OnSuccess`);

                if (PermanentStore.hasExpired(expiryRequest.result)) {
                    resolve(null);
                } else {
                    resolve(dataRequest.result);
                }
            };

            transaction.onerror = () => {
                logger.error(`${this.DatabaseName}.read(${key}): OnError`);
                reject(transaction.error);
            };

            dataRequest = transaction.objectStore(table).get(key);
            expiryRequest = transaction.objectStore(table).get(IndexedDBWrapper.expiryKey(key));
        }).then((result: T | string) => this._json && typeof result === 'string' ? JSON.parse(result) : result);
    }

    public clear(): void {
        let indexedDB: IDBFactory = IndexedDBWrapper.IndexedDB;

        if (!indexedDB) {
            logger.warn(`${this.DatabaseName}.clear: Your browser doesn't support a stable version of IndexedDB.`);
            return;
        }

        indexedDB.deleteDatabase(this._db.name);
    }
}
