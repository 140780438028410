import React from 'react';
import {action, makeObservable, observable} from 'mobx';

import Value from 'helpers/Value';

import {ISubscriptionPlanKeyApiData} from 'models/subscriptionPlan/ISubscriptionPlanKeyApiData';

export class SubscriptionPlanKey {
  @observable public id: number;
  @observable public name: string;

  @observable public key: string;
  @observable public active: boolean;
  @observable public subscription_plan_id: number;
  @observable public subscription: any;
  @observable public used: boolean;

  @observable public created_at: Date;
  @observable public updated_at: Date;

  constructor() {
    makeObservable(this);
    this.withData = this.withData.bind(this);
  }

  public withData(data: ISubscriptionPlanKeyApiData) {
    this.load(data);
    return this;
  }

  @action.bound
  public load(data: ISubscriptionPlanKeyApiData) {
    this.id = data.id;
    this.name = data.name;
    this.key = data.key;
    this.active = Value.defaultFalse(data.active);
    this.subscription_plan_id = data.subscription_plan_id;
    this.subscription = data.subscription;
    this.used = this.subscription !== null;

    this.created_at = Value.dateOrNull(data.created_at);
    this.updated_at = Value.dateOrNull(data.updated_at);
  }

  @action.bound
  public setValue(field: string, value: any) {
    this[field] = value;
  }
}
