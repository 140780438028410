import React, {Component} from 'react';
import {Col, Row} from 'antd';

import {Story} from 'models/story/Story';

import './StoryInfo.scss';

interface IStoryInfoProps {
    story: Story;
}

interface IStoryInfoState {
}

class StoryInfo extends Component<IStoryInfoProps, IStoryInfoState> {
    public constructor(props: IStoryInfoProps) {
        super(props);
    }

    public render(): React.ReactElement {
        const {story} = this.props;
        
        return (
            <Row className='story-info--section'>
                <Col className='story-view--section' xs={24} lg={{span: 20, offset: 2}}>
                    <Row>
                        <Col xs={24} md={12}>
                            <div className='story-info--description-section'>
                                <h3 className='story-info--description-title'>
                                    About
                                </h3>
                                <p className='story-info--description-body'>
                                    {story.default_localisation?.biography}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} md={12}>
                            <div className='story-info--item'>
                                <div className='story-info--title'>
                                    Released
                                </div>
                                <div className='story-info--body'>
                                    {story.released_at?.getFullYear().toString()}
                                </div>
                            </div>
                            <div className='story-info--item'>
                                <div className='story-info--title'>
                                    Rated
                                </div>
                                <div className='story-info--body'>
                                    {`Age appropriate for ${story.minimum_age ? `${story.minimum_age}+` : 'all ages'}.`}
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <div className='story-info--item'>
                                <div className='story-info--title'>
                                    Languages
                                </div>
                                <div className='story-info--body'>
                                    English, Te Reo
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default StoryInfo;
