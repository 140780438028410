import Api from 'models/Api';
import { ICampaignApiData } from "models/campaign/ICampaignApiData";
import { Campaign } from "models/campaign/Campaign";
import { ApiResponseData } from "models/ApiResponseData";
import { SortOrder } from "antd/lib/table/interface";
import { SearchApi } from "models/SearchApi";
import { ICampaignSearchApiData } from "models/campaign/ICampaignSearchApiData";

export type ICampaignApiSearchOrdering = 'id' | 'name' | 'created_at';

export interface ICampaignApiSearchFilterParams {
  id?: number;
  name?: string;
  created_at: Date;
  order_by?: ICampaignApiSearchOrdering;
  order?: SortOrder;
}

export interface ICampaignApiSearchParams {
  campaign_ids?: number[];
  page?: number;
  page_size?: number;
  filters?: ICampaignApiSearchFilterParams
}

export class CampaignAPI {
  public static getAll(): Promise<Array<ICampaignApiData>> {
    return Api.get(`/api/v1/campaigns`)
      .then(response => response.json())
      .then(json => json.campaigns)
  }

  public static create(campaign: Campaign): Promise<ApiResponseData> {
    return Api.post(`/api/v1/campaigns`, {
      body: JSON.stringify({
        campaign: {
          name: campaign.name,
          notes: campaign?.notes
        }
      })
    })
      .then(response => response.json())
  }

  public static getCampaignSearch(searchParams?: ICampaignApiSearchParams): Promise<ICampaignSearchApiData> {
    console.log("Campaign Search API call")
    let query: string[] = [];
    let queryStr = ''
    if ( searchParams ) {
      const { campaign_ids } = searchParams;
      if ( campaign_ids && campaign_ids.length > 0 ) {
        query.push(`campaign_ids=${ searchParams.campaign_ids.join(',') }`);
      }
      queryStr = SearchApi.buildQueryFromParams(searchParams, query);
    }

    return Api.get(`/api/v1/campaigns/search${ queryStr }`)
      .then(response => response.json());
  }
}