import _ from 'lodash';
import {action, makeObservable, observable} from 'mobx';

import Value from 'helpers/Value';

import {Story} from 'models/story/Story';
import {UniverseProvider} from 'models/universe/UniverseProvider';
import {IUniverseApiResponseData} from 'models/universe/IUniverseApiData';

export class Universe {
    private readonly provider: UniverseProvider;

    @observable public id: number;

    @observable public name: string;

    constructor(provider: UniverseProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withData(data: IUniverseApiResponseData) {
        this.load(data);
        return this;
    }

    @action.bound
    public load(data: IUniverseApiResponseData) {
        this.id = data.id;
        this.name = data.name;
    }

    @action.bound
    public setValue(field: string, value: any) {
        this[field] = value;
    }
}
