import {action, makeObservable, observable} from 'mobx';

import {Hash} from 'types/hash';
import {RootStore} from 'models/RootStore';
import {InMemoryStore, IStoreData} from 'models/InMemoryStore';
import {SubscriptionPlanPermanentStore} from 'models/subscriptionPlan/SubscriptionPlanPermanentStore';
import {ISubscriptionPlanApiData} from 'models/subscriptionPlan/ISubscriptionPlanApiData';

export class SubscriptionPlanMemoryStore extends InMemoryStore<ISubscriptionPlanApiData> {
  @observable private _subscriptionPlans: Hash<IStoreData<ISubscriptionPlanApiData>> = {};

  public constructor(rootStore: RootStore) {
    super(rootStore, 'SubscriptionPlan');
    makeObservable(this);
  }

  protected get PermanentStore(): SubscriptionPlanPermanentStore {
    return this.SubscriptionPlanPermanentStore;
  }

  public resetAll(): void {
    super.resetAll();
    this._subscriptionPlans = {};
  }

  @action
  public loadFromPermanentStore(id: number): Promise<void> {
    return this.PermanentStore.read(id)
      .then((plan: ISubscriptionPlanApiData | null) => {
        if (plan) {
          this.set(plan);
        }
      });
  }

  @action
  protected setAll(values: ISubscriptionPlanApiData[]): void {
    values.map(value => this.set(value));
  }

  protected getIStoreData(id: number): IStoreData<ISubscriptionPlanApiData> {
    let data: IStoreData<ISubscriptionPlanApiData> = this._subscriptionPlans[id.toString()];
    if (!data) {
      data = new IStoreData<ISubscriptionPlanApiData>(id, null, this);
      this._subscriptionPlans[id.toString()] = data;
    }

    return data;
  }

  @action
  protected set(value: ISubscriptionPlanApiData): void {
    this.getIStoreData(value.id).setData(value);
  }

  @action
  public storeAll(dataArray: ISubscriptionPlanApiData[]): Promise<void[]> {
    return Promise.all(dataArray.map((data: ISubscriptionPlanApiData) => this.store(data)))
  }

  @action
  public store(data: ISubscriptionPlanApiData): Promise<void> {
    this.set(data);
    return this.PermanentStore.store(data);
  }

  public read(id: number): Promise<ISubscriptionPlanApiData | null> {
      return this.getIStoreData(id).LoadData;
  }
}
