import React from 'react';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Form, Row} from 'antd';
import {FormLayout} from 'antd/es/form/Form';

import Value from 'helpers/Value';
import {ComponentWithStore, withStore} from 'models/RootStore';
import {BillingAddress} from 'models/billingAddress/BillingAddress';

import BillingAddresses from './_BillingAddresses';
import CreditCards from '../credit_cards/_CreditCards';

interface IBillingAddressProps {
    billingAddress?: BillingAddress,
    onSave?: (BillingAddress) => void,
    layout?: FormLayout
}

interface IBillingAddressState {
    disableSave: boolean
}

class SetupForm extends ComponentWithStore<IBillingAddressProps, IBillingAddressState> {
    @observable private readonly billingAddress: BillingAddress;
    private readonly layout: FormLayout;

    constructor(props: IBillingAddressProps) {
        super(props);
        makeObservable(this);
        this.billingAddress = Value.default(this.props.billingAddress, (new BillingAddress(this.store.BillingAddressProvider)).withCurrentUser());
        this.layout = Value.default(this.props.layout, 'vertical');

        this.state = {
            disableSave: false
        };

        this.onSaveCreditCard = this.onSaveCreditCard.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
    }

    public onSaveCreditCard(): void {
        if (this.props.onSave != null) {
            this.props.onSave(this.billingAddress);
        }
    }

    public saveClicked(): void {
        this.setState({disableSave: true});
        this.billingAddress.save()
            .then(() => {
                if (this.props.onSave != null) {
                    this.props.onSave(this.billingAddress);
                }
                this.setState({disableSave: false});
            });
    }

    private renderSubmitButton(): React.ReactElement {
        return (
            <Row justify='center'>
                <Button className='rk-btn rk-btn-dark rounded submit-form'
                        type='primary'
                        disabled={this.state.disableSave || !this.billingAddress.hasRequiredFields}
                        onClick={this.saveClicked}>
                    Save
                </Button>
            </Row>
        );
    }

    public render(): React.ReactElement {
        return (
            <React.Fragment>
                <Form layout={this.layout}>
                    <Col span={24}>
                        <BillingAddresses.Form billingAddress={this.billingAddress}/>
                    </Col>
                    <Col span={24}>
                        {this.renderSubmitButton()}
                    </Col>
                </Form>
            </React.Fragment>
        );
    }
}

export default withStore(observer(SetupForm));
