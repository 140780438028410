// TODO: Work in progress, WindowSize will need to be hooked into a listener on the component that uses it.
// Ideally we would create a singleton global listener that would update all ScalingValues of the change which can then notify
// the components that use them.


export type IScalingNumber = ScalingNumber | number;
export type IScalingString = ScalingString | string;
export type ScalingNumber = ScalingValue<number>;
export type ScalingString = ScalingValue<string>;
export interface ScalingValue<T> {
  xs?: T;
  sm?: T;
  md?: T;
  lg?: T;
  xl?: T;
}

class WindowSize {
  public static xs: number = 0;
  public static sm: number = 576;
  public static md: number = 768;
  public static lg: number = 992;
  public static xl: number = 1200;

  public static isXS(): boolean {
    return window.innerWidth < WindowSize.sm;
  }

  public static isSM(): boolean {
    return window.innerWidth >= WindowSize.sm && window.innerWidth < WindowSize.md;
  }

  public static isMD(): boolean {
    return window.innerWidth >= WindowSize.md && window.innerWidth < WindowSize.lg;
  }

  public static isLG(): boolean {
    return window.innerWidth >= WindowSize.lg && window.innerWidth < WindowSize.xl;
  }

  public static isXL(): boolean {
    return window.innerWidth >= WindowSize.xl;
  }

  public static getScalingNumber(value: IScalingNumber, defaultValue = 0): number {
    if (value == undefined) {
      return defaultValue;
    }
    if (typeof value === "number") {
      return value as number;
    }
    if (typeof value !== "number") {
      WindowSize.getScalingValue<number>(value, defaultValue);
    }
  }

  public static getScalingString(value: IScalingString, defaultValue = ''): string {
    if (value == undefined) {
      return defaultValue;
    }
    if (typeof value === "string") {
      return value as string;
    }
    if (typeof value !== "string") {
      WindowSize.getScalingValue<string>(value, defaultValue);
    }
  }

  private static getScalingValue<T>(value: ScalingValue<T>, defaultValue: T): T {
    if (value == undefined) {
      return defaultValue;
    }

    if (typeof value === typeof defaultValue) {
      return value as T;
    }

    if (WindowSize.isXS()) {
        return value?.xs || defaultValue;
      } else if (WindowSize.isSM()) {
        return value.sm || value.xs || defaultValue;
      } else if (WindowSize.isMD()) {
        return value.md || value.sm || value.xs || defaultValue;
      } else if (WindowSize.isLG()) {
        return value.lg || value.md || value.sm || value.xs || defaultValue;
      } else if (WindowSize.isXL()) {
        return value.xl || value.lg || value.md || value.sm || value.xs || defaultValue;
      }
  }
}

export default WindowSize;
