import React from "react";
import {Button} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";

import './styles.scss';

interface ISuccessButtonProps {
  success: boolean,
  defaultText: string,
  successText: string,
  onClick: () => void
}

class SuccessButton extends React.Component<ISuccessButtonProps> {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.success) {
      return this.renderSuccessButton();
    } else {
      return this.renderDefaultButton();
    }
  }

  renderDefaultButton() {
    return (
      <Button onClick={this.props.onClick}>{this.props.defaultText}</Button>
    );
  }

  renderSuccessButton() {
    return (
      <Button className='btn-success' onClick={this.props.onClick}>{this.props.successText}
        <CheckCircleOutlined/></Button>
    );
  }
}

export default SuccessButton;
