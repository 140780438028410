import {action, makeObservable, observable} from 'mobx';
import _ from 'lodash';

import {Hash} from 'types/hash';
import {RootStore} from 'models/RootStore';
import {InMemoryStore, IStoreData} from 'models/InMemoryStore';
import {StoryReleasePermanentStore} from 'models/storyRelease/StoryReleasePermanentStore';
import {IStoryReleaseApiDataGetResponse} from 'models/storyRelease/IStoryReleaseApiData';

export class StoryReleaseMemoryStore extends InMemoryStore<IStoryReleaseApiDataGetResponse[]> {
    @observable private _releases: Hash<IStoreData<IStoryReleaseApiDataGetResponse[]>> = {};

    public constructor(rootStore: RootStore) {
        super(rootStore, 'StoryRelease');
        makeObservable(this);
    }

    protected get PermanentStore(): StoryReleasePermanentStore {
        return this.StoryReleasePermanentStore;
    }

    public resetAll(): void {
        super.resetAll();
        this._releases = {};
    }

    @action
    public loadFromPermanentStore(storyId: number): Promise<void> {
        return this.PermanentStore.read(storyId)
            .then((releases: IStoryReleaseApiDataGetResponse[] | null) => {
                if (releases) {
                    this.set(releases, storyId);
                }
            });
    }

    protected getIStoreData(storyId: number): IStoreData<IStoryReleaseApiDataGetResponse[]> {
        let data: IStoreData<IStoryReleaseApiDataGetResponse[]> = this._releases[storyId.toString()];
        if (!data) {
            data = new IStoreData<IStoryReleaseApiDataGetResponse[]>(storyId, null, this);
            this._releases[storyId.toString()] = data;
        }

        return data;
    }

    @action
    protected set(value: IStoryReleaseApiDataGetResponse[], storyId: number): void {
        this.getIStoreData(storyId).setData(value);
    }

    @action
    public storeOne(item: IStoryReleaseApiDataGetResponse, storyId: number): Promise<void> {
        return this.PermanentStore.read(storyId)
            .then((data: IStoryReleaseApiDataGetResponse[] | null) => !data ? [] : data)
            .then((data: IStoryReleaseApiDataGetResponse[]) => {
                let index: number = _.findIndex(data, (storedItem: IStoryReleaseApiDataGetResponse) => storedItem.id === item.id);
                if (index < 0) {
                    // Item does not exist, add it
                    data.push(item);
                } else {
                    // Item already exists, delete it and replace it with the new one
                    data.splice(index, 1, item);
                }
                this.set(data, storyId);
                return this.PermanentStore.store(data, storyId);
            });
    }

    @action
    public store(data: IStoryReleaseApiDataGetResponse[], storyId: number): Promise<void> {
        this.set(data, storyId);
        return this.PermanentStore.store(data, storyId);
    }

    public read(storyId: number): Promise<IStoryReleaseApiDataGetResponse[] | null> {
        return this.getIStoreData(storyId).LoadData;
    }
}
