import {action, makeObservable, observable} from 'mobx';

import {Hash} from 'types/hash';
import {RootStore} from 'models/RootStore';
import {InMemoryStore, IStoreData} from 'models/InMemoryStore';
import {CollectionPermanentStore} from 'models/collection/CollectionPermanentStore';
import {ICollectionApiResponseData} from 'models/collection/ICollectionApiData';

export class CollectionMemoryStore extends InMemoryStore<ICollectionApiResponseData> {
    @observable private _collections: Hash<IStoreData<ICollectionApiResponseData>> = {};

    public constructor(rootStore: RootStore) {
        super(rootStore, 'Collection');
        makeObservable(this);
    }

    protected get PermanentStore(): CollectionPermanentStore {
        return this.CollectionPermanentStore;
    }

    public resetAll(): void {
        super.resetAll();
        this._collections = {};
    }

    @action
    public loadFromPermanentStore(collectionId: number): Promise<void> {
        return this.PermanentStore.read(collectionId)
            .then((collections: ICollectionApiResponseData | null) => {
                if (collections) {
                    this.set(collections, collectionId);
                }
            });
    }

    protected getIStoreData(collectionId: number): IStoreData<ICollectionApiResponseData> {
        let data: IStoreData<ICollectionApiResponseData> = this._collections[collectionId.toString()];
        if (!data) {
            data = new IStoreData<ICollectionApiResponseData>(collectionId, null, this);
            this._collections[collectionId.toString()] = data;
        }
        return data;
    }

    @action
    protected set(value: ICollectionApiResponseData, collectionId: number): void {
        this.getIStoreData(collectionId).setData(value);
    }

    @action
    public store(data: ICollectionApiResponseData, collectionId: number): Promise<void> {
        this.set(data, collectionId);
        return this.PermanentStore.store(data, collectionId);
    }

    public read(collectionId: number): Promise<ICollectionApiResponseData> {
        let data = this.getIStoreData(collectionId);

        return data.LoadData;
    }
}
