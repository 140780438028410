import Cookies from 'universal-cookie';
import {ApiResponseData} from 'models/ApiResponseData';
import {SessionApi} from 'models/session/SessionApi';
import * as path from "path";

namespace SessionCookieStore {
    // The session cookie should not hold any identifiable data / functional info as we will leave it unsecure.
    // This is so we can compile assets once and they can run anywhere.
    // It is not like secure is even that strong a protection anyhow.
    const secure = false;

    const cookies = new Cookies();
    const USER_ID = 'rascal-user-id';
    const API_KEY = 'rascal-api-key';
    const BYPASS_TOKEN = 'jwt';
    const ONE_YEAR_IN_SECONDS = 31557600;

    export function userId(): number {
        const userId = cookies.get(USER_ID);
        return !!userId ? Number(userId) : null;
    }

    export function hasCredentials(): boolean {
        return !!userId();
    }

    export function clear(): void {
        cookies.remove(USER_ID);
        cookies.remove(API_KEY);
        cookies.remove(BYPASS_TOKEN);
    }

    export function store(response): ApiResponseData {
        if (response['user_id']) {
            cookies.set(USER_ID, response['user_id'], {
                path: '/',
                secure: secure,
                sameSite: "strict",
                maxAge: ONE_YEAR_IN_SECONDS
            });
        }
        let success = SessionCookieStore.hasCredentials();
        return {success: success, message: success ? 'Signed in successfully' : 'Incorrect email or password'};
    }
}

export default SessionCookieStore;
