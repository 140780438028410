import Api from 'models/Api';
import {
    ICollectionApiCreateRequest,
    ICollectionApiResponseData,
    ICollectionApiUpdateRequest
} from 'models/collection/ICollectionApiData';

export class CollectionApi {
    public static create(data: ICollectionApiCreateRequest): Promise<ICollectionApiResponseData> {
        return Api.post('/api/v1/collections', {
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => json.collection);
    }

    public static update(id: number, data: ICollectionApiUpdateRequest): Promise<ICollectionApiResponseData> {
        return Api.put(`/api/v1/collections/${id}`, {
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => json.collection);
    }

    public static get(collectionId: number): Promise<ICollectionApiResponseData> {
        return Api.get(`/api/v1/collections/${collectionId}`)
            .then(response => response.json())
            .then(json => json.collection);
    }

    public static getAllForStory(storyId: number): Promise<ICollectionApiResponseData[]> {
        return Api.get(`/api/v1/stories/${storyId}/collections`)
            .then(response => response.json())
            .then(json => json.collections);
    }

    public static getAll(): Promise<ICollectionApiResponseData[]> {
        return Api.get(`/api/v1/collections`)
            .then(response => response.json())
            .then(json => json.collections);
    }
}
