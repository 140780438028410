import React, {RefObject} from 'react';
import {Link} from 'react-router-dom';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import logger from 'loglevel';
import * as _ from 'lodash';
import {Button, Col, Divider, Drawer, Form, Input, Popconfirm, Row, Space, Table, Typography} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {ColumnType} from 'antd/lib/table/interface';

import {Hash} from 'types/hash';
import {ComponentWithStore} from 'models/RootStore';
import {ApiResponseData} from 'models/ApiResponseData';
import {IUserApiData, IUserApiDataUpdateRequest} from 'models/user/IUserApiData';
import {Subscription} from 'models/subscription/Subscription';
import {User} from 'models/user/User';
import {BillingAddress} from 'models/billingAddress/BillingAddress';

import DefaultOrAdminLayout from 'layouts/DefaultOrAdminLayout';
import PageTitle from 'components/shared/PageTitle';

import './AccountSettings.scss';
import MessageApi from "components/hooks/MessageApi";
import AppInfo from "models/AppInfo";

const {Text} = Typography;

interface IAccountSettingProps {
    routeParams: { userId: string };
}

interface IAccountSettingState {
    userId: number | null;
    selectedSubscriberRowKeys: any[];
    admin: boolean;
    loading: {
        kick: boolean;
    }
}

class AccountSetting extends ComponentWithStore<IAccountSettingProps, IAccountSettingState> {
    @observable private captchaResponse: string;
    @observable private user: User = new User(this.store.UserProvider);
    @observable private billingAddress: BillingAddress = new BillingAddress(this.store.BillingAddressProvider);
    @observable private subscriptions: Hash<Subscription> = {};

    @observable private editNames: boolean = false;
    @observable private editEmail: boolean = false;
    @observable private editPassword: boolean = false;
    @observable private selectedSubscriptionId: string = undefined;
    @observable private deleteMyAccount: boolean = false;

    private namesForm: RefObject<FormInstance> = React.createRef<FormInstance>();
    private emailForm: RefObject<FormInstance> = React.createRef<FormInstance>();
    private passwordForm: RefObject<FormInstance> = React.createRef<FormInstance>();

    constructor(props) {
        super(props);

        makeObservable(this);

        let userId: number | null;
        let admin: boolean = AppInfo.isAdmin();
        let idParam: string | null = this.props.routeParams.userId;
        if (idParam) {
            userId = Number(idParam);
        } else {
            userId = this.store.SessionProvider.userId();
        }

        this.state = {
            userId: userId,
            admin: admin,
            selectedSubscriberRowKeys: [],
            loading: {
                kick: false
            },
        };

        this.signOut = this.signOut.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
        this.leaveSubscription = this.leaveSubscription.bind(this);
        this.signOutOfAllDevices = this.signOutOfAllDevices.bind(this);
        this.pollUserData = this.pollUserData.bind(this);
        this.setUser = this.setUser.bind(this);
        this.setBillingAddress = this.setBillingAddress.bind(this);
        this.setSubscriptions = this.setSubscriptions.bind(this);
        this.updateUserInfo = this.updateUserInfo.bind(this);
        this.showEditNames = this.showEditNames.bind(this);
        this.hideEditNames = this.hideEditNames.bind(this);
        this.showEditEmail = this.showEditEmail.bind(this);
        this.hideEditEmail = this.hideEditEmail.bind(this);
        this.showEditPassword = this.showEditPassword.bind(this);
        this.hideEditPassword = this.hideEditPassword.bind(this);
        this.showDeleteMyAccount = this.showDeleteMyAccount.bind(this);
        this.hideDeleteMyAccount = this.hideDeleteMyAccount.bind(this);
        this.showManageSubscribers = this.showManageSubscribers.bind(this);
        this.hideManageSubscribers = this.hideManageSubscribers.bind(this);
        this.onFinishNames = this.onFinishNames.bind(this);
        this.onFinishFailedNames = this.onFinishFailedNames.bind(this);
        this.onFinishEmail = this.onFinishEmail.bind(this);
        this.onFinishFailedEmail = this.onFinishFailedEmail.bind(this);
        this.onFinishPassword = this.onFinishPassword.bind(this);
        this.onFinishFailedPassword = this.onFinishFailedPassword.bind(this);
        this.onFinishDeleteMyAccount = this.onFinishDeleteMyAccount.bind(this);
        this.onFinishFailedDeleteMyAccount = this.onFinishFailedDeleteMyAccount.bind(this);
        this.getSelectedSubscription = this.getSelectedSubscription.bind(this);
        this.handleSelectSubscriberChange = this.handleSelectSubscriberChange.bind(this);
        this.handleKickSubscriber = this.handleKickSubscriber.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.displayResponseAndReload = this.displayResponseAndReload.bind(this);
        this.displayResponseAndSignOut = this.displayResponseAndSignOut.bind(this);
    }

    public componentDidMount(): void {
        this.store.SessionProvider.authOnly();
        // noinspection JSIgnoredPromiseFromCall
        this.pollUserData().then(() => this.setState({}))
    }

    public signOut(e: React.MouseEvent<HTMLElement>): void {
        e.preventDefault();
        e.stopPropagation();
        this.store.SessionProvider.signOut().catch(() => 'doesnt matter we are signing out anyway.')
            .then(() => window.location.href = '/');
    }

    private cancelSubscription(subscription: Subscription): void {
        subscription.cancel().then(this.displayResponseAndReload);
    }

    private changeSubscription(subscription: Subscription): void {
        window.location.href = `/change_subscription/${subscription.id}`;
    }

    private changeCard(subscription: Subscription): void {
        window.location.href = `/change_card/${subscription.id}`;
    }

    private leaveSubscription(subscription: Subscription): void {
        subscription.leave().then(this.displayResponseAndReload);
    }

    private signOutOfAllDevices(): void {
        this.store.UserProvider.deleteSessions(this.user.id).then(this.displayResponseAndSignOut);
    }

    private pollUserData(): Promise<any> {
        const {userId} = this.state;

        if (!userId) {
            logger.warn('Could not poll user as no id provided');
            return Promise.resolve();
        }

        return Promise.all([
            this.store.UserProvider.user(userId).then(this.setUser),
            this.store.BillingAddressProvider.getActive(userId).then(this.setBillingAddress),
            this.store.SubscriptionProvider.fetchAllForUser(userId).then(this.setSubscriptions)
        ]);
    }

    @action
    private setUser(user: IUserApiData): void {
        this.user = new User(this.store.UserProvider).withData(user);
    }

    @action
    private setBillingAddress(address: BillingAddress): void {
        this.billingAddress = address;
    }

    @action
    private setSubscriptions(subscriptions: Subscription[]): void {
        let subscriptionHash: Hash<Subscription> = {};
        subscriptions.forEach((subscription: Subscription) => subscriptionHash[subscription.id.toString()] = subscription);
        this.subscriptions = subscriptionHash;
    }

    @action
    private updateUserInfo(values: IUserApiDataUpdateRequest): void {
        this.store.UserProvider.update(this.user.id, values).then(this.displayResponseAndReload);
    }

    private showEditNames(): void {
        this.editNames = true;
    }

    private hideEditNames(): void {
        this.editNames = false;
    }

    private showEditEmail(): void {
        this.editEmail = true;
    }

    private hideEditEmail(): void {
        this.editEmail = false;
    }

    private showEditPassword(): void {
        this.editPassword = true;
    }

    private hideEditPassword(): void {
        this.editPassword = false;
    }

    private showDeleteMyAccount(): void {
        this.deleteMyAccount = true;
    }

    private hideDeleteMyAccount(): void {
        this.deleteMyAccount = false;
    }

    private showManageSubscribers(subscription: Subscription): void {
        this.selectedSubscriptionId = subscription.id.toString();
    }

    private hideManageSubscribers(): void {
        this.selectedSubscriptionId = undefined;
        this.setState({
            selectedSubscriberRowKeys: [],
            loading: {
                kick: false
            }
        });
    }

    private onFinishNames(values): void {
        this.store.UserProvider.changeNames(this.user.id, values)
            .then((response: ApiResponseData) => {
                if (response.success) {
                    this.hideEditNames();
                    this.namesForm.current.resetFields();
                }
                return this.displayResponseAndReload(response);
            });
    }

    private onFinishFailedNames(errorInfo): void {
        MessageApi().error('Failed: Incorrect Password');
    }

    private onFinishEmail(values): void {
        console.log(values)
        this.store.UserProvider.changeEmail(this.user.id, values)
            .then((response: ApiResponseData) => {
                if (response.success) {
                    this.hideEditEmail();
                    this.emailForm.current.resetFields();
                }
                return this.displayResponseAndReload(response);
            });
    }

    private onFinishFailedEmail(errorInfo): void {
        MessageApi().error('Failed:', errorInfo);
    }

    private onFinishPassword(values): void {
        this.store.UserProvider.changePassword(this.user.id, values)
            .then((response: ApiResponseData) => {
                if (response.success) {
                    this.hideEditPassword();
                    this.passwordForm.current.resetFields();
                }
                return this.displayResponseAndReload(response);
            });
    }

    private onFinishFailedPassword(errorInfo): void {
        MessageApi().error('Failed:', errorInfo);
    }

    private onFinishDeleteMyAccount(values): void {
        this.store.UserProvider.deleteUser(this.user.id, values)
            .then((response: ApiResponseData) => {
                if (response.success) this.hideDeleteMyAccount();
                return this.displayResponseAndSignOut(response);
            });
    }

    private onFinishFailedDeleteMyAccount(errorInfo): void {
        MessageApi().error('Failed:', errorInfo);
    }

    private getSelectedSubscription(): Subscription | undefined {
        return this.selectedSubscriptionId ? this.subscriptions[this.selectedSubscriptionId.toString()] : undefined;
    }

    private handleSelectSubscriberChange(selectedRowKeys): void {
        this.setState({selectedSubscriberRowKeys: selectedRowKeys});
    }

    private handleKickSubscriber(): Promise<any> {
        const subscription: Subscription = this.getSelectedSubscription();
        if (!subscription) return Promise.resolve();

        const {loading, selectedSubscriberRowKeys} = this.state;
        loading.kick = true;
        this.setState({loading: loading});

        return subscription.kickSubscribers(selectedSubscriberRowKeys)
            .then((response: ApiResponseData) => {
                if (response.success) this.clearSelection();
                return this.displayResponseAndReload(response);
            });
    }

    private clearSelection(): void {
        this.setState({
            selectedSubscriberRowKeys: [],
            loading: {
                kick: false
            }
        });
    }

    private displayResponseAndReload(response: ApiResponseData): PromiseLike<ApiResponseData> {
        if (response.success) {
            return Promise.all([
                MessageApi().success(response.message),
                this.pollUserData()
            ])
                .then(() => response);
        } else {
            return MessageApi().error(response.message).then(() => response);
        }
    }

    private displayResponseAndSignOut(response: ApiResponseData): PromiseLike<ApiResponseData> {
        if (response.success) {
            return MessageApi().success(response.message)
                .then(() => {
                    this.store.SessionProvider.clear();
                    window.location.href = '';
                    return response;
                });
        } else {
            return MessageApi().error(response.message).then(() => response);
        }
    }

    private renderChangeNames(): React.ReactElement {
        return (
            <Drawer width='' // Need to remove the default width of 256px from antd.
                    title='Change Names'
                    onClose={this.hideEditNames}
                    open={this.editNames}>
                <Form ref={this.namesForm} onFinish={this.onFinishNames} onFinishFailed={this.onFinishFailedNames}>
                    <Form.Item name='first_name' rules={[{required: false, message: 'Please input your first name'}]}>
                        <Input type='text' name='first_name' placeholder='Enter your first name.'/>
                    </Form.Item>
                    <Form.Item name='last_name' rules={[{required: false, message: 'Please input your last name'}]}>
                        <Input type='text' name='last_name' placeholder='Enter your last name.'/>
                    </Form.Item>
                    <Form.Item name='password' rules={[{required: true, message: 'Please input your password'}]}>
                        <Input type='password' name='password'
                               placeholder='Enter your password to authorize this change.'/>
                    </Form.Item>
                    <button className='rk-btn' type='submit'>Save</button>
                </Form>
            </Drawer>
        );
    }

    private renderChangeEmail(): React.ReactElement {
        return (
            <Drawer width='' // Need to remove the default width of 256px from antd.
                    title='Change Email'
                    onClose={this.hideEditEmail}
                    open={this.editEmail}>
                <Form ref={this.emailForm} onFinish={this.onFinishEmail} onFinishFailed={this.onFinishFailedEmail}>
                    <Form.Item name='email' rules={[{required: true, message: 'Please input your email'}]}>
                        <Input type='email' placeholder='Enter your new email'/>
                    </Form.Item>
                    <Form.Item name='email_confirm'
                               rules={[{required: true, message: 'Please input your email again'}]}>
                        <Input type='email' name='email_confirm' placeholder='Confirm your new email.'/>
                    </Form.Item>
                    <Form.Item name='password' rules={[{required: true, message: 'Please input your password'}]}>
                        <Input type='password' name='password'
                               placeholder='Enter your password to authorize this change.'/>
                    </Form.Item>
                    <button className='rk-btn' type='submit'>Save</button>
                </Form>
            </Drawer>
        );
    }

    private renderChangePassword(): React.ReactElement {
        return (
            <Drawer width='' // Need to remove the default width of 256px from antd.
                    title='Change Password'
                    onClose={this.hideEditPassword}
                    open={this.editPassword}>
                <Form ref={this.passwordForm} onFinish={this.onFinishPassword}
                      onFinishFailed={this.onFinishFailedPassword}>
                    <Form.Item name='password' rules={[{required: true, message: 'Please input your password'}]}>
                        <Input type='password'
                               name='password'
                               placeholder='Enter your current password to authorize this change.'/>
                    </Form.Item>
                    <Form.Item name='new_password' rules={[{required: true, message: 'Please input your password'}]}>
                        <Input type='password' name='new_password' placeholder='Enter your new password'/>
                    </Form.Item>
                    <Form.Item name='new_password_confirm'
                               rules={[{required: true, message: 'Please input your password'}]}>
                        <Input type='password' name='new_password_confirm' placeholder='Confirm your new password.'/>
                    </Form.Item>
                    <button className='rk-btn' type='submit'>Save</button>
                </Form>
            </Drawer>
        );
    }

    private renderDeleteMyAccount(): React.ReactElement {
        return (
            <Drawer width='' // Need to remove the default width of 256px from antd.
                    title='Delete My Account'
                    onClose={this.hideDeleteMyAccount}
                    open={this.deleteMyAccount}>
                <Form onFinish={this.onFinishDeleteMyAccount} onFinishFailed={this.onFinishFailedDeleteMyAccount}>
                    <p>
                        Are you sure you want to delete your account?
                        This action is irreversible. Your account will no longer be accessible.
                    </p>
                    <Form.Item name='password' rules={[{required: true, message: 'Please input your password'}]}>
                        <Input type='password'
                               name='password'
                               placeholder='Enter your current password to authorize account deletion.'/>
                    </Form.Item>
                    <p>Please write 'I confirm' in the box below if you are sure you want to delete your account.</p>
                    <Form.Item name='confirm' rules={[{required: true, message: "Please write 'I confirm'"}]}>
                        <Input type='string'
                               placeholder="Please write 'I confirm' if you are sure you want to delete your account"/>
                    </Form.Item>
                    <Button className='rk-btn-danger' htmlType='submit'>Delete My Account</Button>
                </Form>
            </Drawer>
        );
    }

    private getColumnStringProps<K extends keyof User>(dataIndex: K): ColumnType<User> {
        return {
            title: _.startCase(dataIndex),
            dataIndex: dataIndex,
            key: dataIndex,
            sorter: {
                compare: (a: User, b: User) => (a[dataIndex] as string).localeCompare(b[dataIndex] as string),
                multiple: 1
            },
            render: text => text
        };
    }

    private renderManageSubscribers(): React.ReactElement {
        const {loading, selectedSubscriberRowKeys} = this.state;
        const subscription: Subscription = this.getSelectedSubscription();
        const hasSelected: boolean = selectedSubscriberRowKeys.length > 0;
        const planName: string = subscription?.subscription_plan?.name || 'Unknown Plan';
        const subscribers: (User & { key?: number })[] = subscription?.subscribers || [];
        subscribers.forEach((subscriber: User & { key?: number }) => subscriber.key = subscriber.id);

        return (
            <Drawer width='' // Need to remove the default width of 256px from antd.
                    title={`Manage Subscribers for ${planName}`}
                    onClose={this.hideManageSubscribers}
                    open={!!subscription}>
                <Space style={{marginBottom: 16}}>
                    <Button className='rk-btn-danger'
                            size='small'
                            onClick={this.handleKickSubscriber}
                            disabled={!hasSelected || _.some(loading)}
                            loading={loading.kick}>
                        Kick Selected Subscribers
                    </Button>
                    <span>{hasSelected ? `${selectedSubscriberRowKeys.length} user${selectedSubscriberRowKeys.length > 1 ? 's'
                        : ''} selected` : ''}</span>
                </Space>

                <Table
                    columns={[
                        this.getColumnStringProps('first_name'),
                        this.getColumnStringProps('last_name')
                    ]}
                    dataSource={subscribers}
                    rowSelection={{
                        selectedRowKeys: selectedSubscriberRowKeys,
                        onChange: this.handleSelectSubscriberChange,
                        getCheckboxProps: (subscriber: User) => ({disabled: subscription.isManagedByUser(subscriber.id)}),
                        selections: [
                            Table.SELECTION_ALL,
                            Table.SELECTION_INVERT,
                            Table.SELECTION_NONE
                        ]
                    }}/>
            </Drawer>
        );
    }

    private renderBillingAddress(): React.ReactElement {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={20} md={6}><p>Billing Address:</p></Col>
                    <Col><p className='rk-settings-content'>{this.billingAddress.name}</p></Col>
                </Row>
                <Row>
                    <Col xs={24} md={6}></Col>
                    <Col><p className='rk-settings-content'>{this.billingAddress.line1}</p></Col>
                </Row>
                <Row>
                    <Col xs={24} md={6}></Col>
                    <Col><p className='rk-settings-content'>{this.billingAddress.line2}</p></Col>
                </Row>
                <Row>
                    <Col xs={24} md={6}><p>Zip Code:</p></Col>
                    <Col><p className='rk-settings-content'>{this.billingAddress.zip}</p></Col>
                </Row>
                <Row>
                    <Col xs={24} md={6}><p>City:</p></Col>
                    <Col><p className='rk-settings-content'>{this.billingAddress.city}</p></Col>
                </Row>
                <Row>
                    <Col xs={24} md={6}><p>State:</p></Col>
                    <Col><p className='rk-settings-content'>{this.billingAddress.state}</p></Col>
                </Row>
                <Row>
                    <Col xs={24} md={6}><p>Country:</p></Col>
                    <Col flex='auto'><p className='rk-settings-content'>{this.billingAddress.country}</p></Col>
                </Row>
            </React.Fragment>
        );
    }

    private renderAddBillingAddressButton() {
        return (
            <p className='rk-account-btn-text' onClick={() => window.location.href = '/billing?new'}>Add Billing
                Address</p>
        );
    }

    private renderMembershipAndBillingDetails(): React.ReactElement {
        return (
            <div className='rk-details-list'>
                <Row>
                    <Col xs={24} md={12}>
                        <h3 className='rk-settings-h3'>Details</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={6}><p>First Name:</p></Col>
                    <Col xs={16} md={6}><p className='rk-settings-content'>{this.user.first_name}</p></Col>
                    <Col xs={8} md={{span: 7, offset: 5}}>
                        <p className='rk-settings-edit' onClick={this.showEditNames}>Manage Names</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={6}><p>Last Name:</p></Col>
                    <Col xs={24} md={6}><p className='rk-settings-content'>{this.user.last_name}</p></Col>
                    {/*<Col xs={ { span: 6, offset: 6 } } md={ { span: 6, offset: 6 } }>*/}
                    {/*  <p className='rk-settings-edit' onClick={ this.showEditNames }>Manage Names</p>*/}
                    {/*</Col>*/}
                </Row>
                <Row>
                    <Col xs={24} md={6}><p>Email:</p></Col>
                    <Col xs={16} md={6}><p className='rk-settings-content'>{this.user.email}</p></Col>
                    <Col xs={8} md={{span: 7, offset: 5}}>
                        <p className='rk-settings-edit' onClick={this.showEditEmail}>Manage Email</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={6}><p>Password:</p></Col>
                    <Col xs={16} md={6}><p className='rk-settings-content'>*******</p></Col>
                    <Col xs={8} md={{span: 7, offset: 5}} lg={{span: 8, offset: 4}}>
                        <p className='rk-settings-edit' onClick={this.showEditPassword}>Manage Password</p>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={16} md={12}>
                        <h3 className='rk-settings-h3'>Payment Info: </h3>
                    </Col>
                    <Col xs={8} md={{span: 8, offset: 4}}>
                        <a className='rk-settings-edit' onClick={() => window.location.href = '/billing'}>
                            Manage Billing
                        </a>
                    </Col>
                </Row>
                {this.billingAddress?.id ? this.renderBillingAddress() : this.renderAddBillingAddressButton()}
                {/*<p>Billing details:</p>*/}
                {/*<p>Redeem gift card or promo code:</p>*/}
                {/*<p>Where to buy gifts cards:</p>*/}
            </div>
        );
    }

    private renderSubscription(subscription: Subscription, count: number): React.ReactElement {
        return (
            <Row key={subscription.id.toString()} className='rk-details-list'>
                <Col xs={12}>
                    <h3 className='rk-settings-h3'>{subscription.name}</h3>
                    <p>{subscription.getStateMessage()}</p>
                    <p>{subscription.getTrialMessage()}</p>
                    <p>{subscription.getNextChargeMessage()}</p>
                </Col>
                <Col xs={12}>
                    <Space direction='vertical' align='end' className='rk-settings-space-end'>
                        {!subscription.isManagedByUser() &&
                            <Popconfirm placement='top'
                                        title='Are you sure you want to leave this subscription?'
                                        onConfirm={_e => this.leaveSubscription(subscription)}
                                        okText='Yes'
                                        cancelText='No'>
                                <p className='rk-settings-edit'>Leave Subscription</p>
                            </Popconfirm>}
                        {subscription.canCancel() &&
                            <Popconfirm placement='top'
                                        title='Are you sure you want to cancel this subscription?'
                                        onConfirm={_e => this.cancelSubscription(subscription)}
                                        okText='Yes'
                                        cancelText='No'>
                                <p className='rk-settings-edit'>Cancel Plan</p>
                            </Popconfirm>}
                        {subscription.canCancel() &&
                            <Popconfirm placement='top'
                                        title='Are you sure you want to change this subscription?'
                                        onConfirm={_e => this.changeSubscription(subscription)}
                                        okText='Yes'
                                        cancelText='No'>
                                <p className='rk-settings-edit'>Change Plan</p>
                            </Popconfirm>}
                        {subscription.canSetPaymentMethod() &&
                            <Popconfirm placement='top'
                                        title='Are you sure you want to change the card that will be charged subscription?'
                                        onConfirm={_e => this.changeCard(subscription)}
                                        okText='Yes'
                                        cancelText='No'>
                                <p className='rk-settings-edit'>Change Card</p>
                            </Popconfirm>}
                        {subscription.canManageSubscribers() &&
                            <Button className='rk-btn' size='small'
                                    onClick={() => this.showManageSubscribers(subscription)}>
                                Manage Subscribers
                            </Button>}
                        {subscription.isManagedByUser() &&
                            <Link className='rk-btn'
                                    to={`/subscriptions/${subscription.id}/manage`}>
                                Manage Subscription
                            </Link>
                        }
                    </Space>
                </Col>
                {(count + 1) < Object.keys(this.subscriptions).length ? <Divider/> : <div/>}
            </Row>
        );
    }

    private renderSettings(): React.ReactElement {
        const {admin, userId} = this.state;

        return (
            <div className='rk-details-list'>
                {/*<p>Communication settings</p>
         <p>Marketing communications</p>
         <p>Parental Controls</p>

         <p>Test participation</p>
         <p>Manage download devices</p>
         <p>Activate a device</p>
         <p>Recent device streaming activity</p>*/}
                <p className='rk-account-btn-text'>
                    <a href={admin ? `/admin/users/${userId}/invoices` : '/invoices'}>
                        Invoices
                    </a>
                </p>
                <Popconfirm placement='topLeft'
                            title='Are you sure you want to sign out of all your devices?'
                            onConfirm={this.signOutOfAllDevices}
                            okText='Yes'
                            cancelText='No'>
                    <p className='rk-account-btn-text'>Sign out of all devices</p>
                </Popconfirm>
                <p className='rk-account-btn-text' onClick={this.showDeleteMyAccount}>Delete my account</p>
            </div>
        );
    }

    private renderHeader(): React.ReactElement {
        const {admin} = this.state;

        return admin ? this.renderHeaderForAdmin() : this.renderHeaderForUser();
    }

    private renderHeaderForUser(): React.ReactElement {
        let months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
            'November', 'December']
        let month = months[+String(this.user.created_at).split('T')[0].split('-')[1]]
        let year = String(this.user.created_at).split('T')[0].split('-')[0]

        return (
            <Row className='rk-title'>
                <Col xs={24} md={{span: 10, offset: 2}}>
                    <h1>Account Settings</h1>
                </Col>
                <Col xs={24} md={10}>
                    {typeof this.user.created_at !== 'undefined' ?
                        <p className='rk-title-content'>Member since: {`${month}, ${year}`} </p>
                        : <div/>
                    }
                </Col>
                {this.divider()}
            </Row>
        );
    }

    private renderHeaderForAdmin(): React.ReactElement {
        const {userId} = this.state;

        return (
            <Row>
                <PageTitle to={`/admin/users/${userId}`}
                           title='Account'
                           subtitle={`${this.user.FullName}`}/>
                <hr/>
            </Row>
        );
    }

    private divider(): React.ReactElement {
        return (
            <Col xs={24} lg={{span: 20, offset: 2}}>
                <Divider/>
            </Col>
        );
    }

    public render(): React.ReactElement {
        const {admin} = this.state;

        return (
            <DefaultOrAdminLayout admin={admin}>
                <div className='rk-account-content'>
                    <Row align='middle' gutter={[0, 25]}>
                        {this.renderHeader()}

                        <Row className='rk-account-details'>
                            <Col xs={24} md={{span: 8, offset: 2}} lg={{span: 6, offset: 2}}
                                 xl={{span: 6, offset: 2}}>
                                <h2>Membership & Billing</h2>
                            </Col>
                            <Col xs={24} md={{span: 20, offset: 2}} lg={{span: 12, offset: 0}}>
                                {this.renderMembershipAndBillingDetails()}
                            </Col>
                            {this.divider()}
                        </Row>

                        <Row className='rk-account-details'>
                            <Col xs={24} md={{span: 8, offset: 2}} lg={{span: 6, offset: 2}}
                                 xl={{span: 6, offset: 2}}>
                                <h2>Plan Details</h2>
                            </Col>
                            <Col xs={24} md={{span: 20, offset: 2}} lg={{span: 12, offset: 0}}>
                                {_.reverse(_.values(this.subscriptions)).map(
                                    (subscription, count) => this.renderSubscription(subscription, count))}
                                <Divider/>
                                {!_.find(this.subscriptions, s => s.canCancel()) &&
                                    <p className='rk-account-btn-text'>
                                        <a className='rk-link' href='/subscriptions'>
                                            New Subscription
                                        </a>
                                    </p>
                                }
                            </Col>
                            {this.divider()}
                        </Row>

                        <Row className='rk-account-details'>
                            <Col xs={24} md={{span: 8, offset: 2}} lg={{span: 6, offset: 2}}
                                 xl={{span: 6, offset: 2}}>
                                <h2>Settings</h2>
                            </Col>
                            <Col xs={24} md={{span: 20, offset: 2}} lg={{span: 12, offset: 0}}>
                                {this.renderSettings()}
                            </Col>
                        </Row>
                        <Divider/>
                    </Row>
                </div>

                {this.renderChangeNames()}
                {this.renderChangeEmail()}
                {this.renderChangePassword()}
                {this.renderManageSubscribers()}
                {this.renderDeleteMyAccount()}
            </DefaultOrAdminLayout>
        );
    }
}

export default observer(AccountSetting);
