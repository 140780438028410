import React from 'react'
import { Button, Col, Collapse, Row } from 'antd';
import * as _ from 'lodash';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observable, runInAction } from 'mobx';

import { ComponentWithStore, withStore } from 'models/RootStore';
import { Story } from 'models/story/Story';
import { Homepage as HomepageModel } from 'models/homepage/Homepage';
import AppStoreLink from 'components/shared/AppStoreLink';
import Catalogue from 'components/public/pages/home/Catalogue';
import GooglePlayLink from 'components/shared/GooglePlayLink';
import StoryMultiPanel from 'components/public/pages/home/StoryMultiPanel';

import "./pages/home/Home.scss";
import './Homepage.scss';
import { observer } from "mobx-react";
import DefaultFooter from "layouts/footers/DefaultFooter";
import Chevron from 'components/shared/icons/Chevron';
import DefaultHeader from "layouts/headers/DefaultHeader";
import { SubscriptionPlan } from 'models/subscriptionPlan/SubscriptionPlan';
import { IBillingPeriod } from 'models/subscription/Subscription';

const { Panel } = Collapse;

interface IHomepageProps {
}

interface IHomepageState {
  loaded: boolean;
  email: string;
}

// Logged Out Homepage
class Homepage extends ComponentWithStore<IHomepageProps, IHomepageState> {
  @observable private stories: Story[];
  @observable private homepage: HomepageModel;
  @observable private subscriptionPlans: SubscriptionPlan[] = [];
  @observable private subscriptionPlan: SubscriptionPlan = null;
  private mounted = false;

  public constructor(props: IHomepageProps) {
    super(props);

    this.state = {
      loaded: false,
      email: ''
    };
  }

  public componentDidMount(): void {
    history.replaceState({}, '', '/');
    this.mounted = true;

    if (!this.state.loaded) {
      Promise.all([
        new HomepageModel(this.store.StoryProvider).fetchData().then((homepage) => this.homepage = homepage),
        this.store.StoryProvider.getFavourites().then((stories: Story[]) => this.stories = stories),
        this.store.SubscriptionPlanProvider.fetchAllForUser()
          .then((subscriptionPlans: SubscriptionPlan[]) => runInAction(() => this.subscriptionPlans = subscriptionPlans))
          .finally(() => this.finishedLoadingSubscriptions())
      ])
        .then(() => {
          if (this.mounted) {
            this.setState({ loaded: true });
          }
        });
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  private finishedLoadingSubscriptions() {
    if (this.mounted) {
      this.subscriptionPlans.forEach(plan => {
        console.log(!plan.isFree());
        console.log(plan.billing_period);
        if (!plan.isFree() && plan.billing_period === IBillingPeriod.MONTHLY) {
          console.log(plan);
          this.subscriptionPlan = plan;
        }
      })
    }
  }

  static async ScrollDelay(ms) {
    return new Promise(res => setTimeout(res, ms));
  }

  private static async ScrollTo(goal) {
    // Add the goal to the current scroll position to get the
    // actual absolute position of the component to scroll to
    goal += window.scrollY;
    const difference = window.scrollY < goal ? 25 : -25
    for (let location = window.scrollY; location <= goal; location += difference) {
      window.scrollTo({ top: location, behavior: 'smooth' })
      await Homepage.ScrollDelay(1);
    }
  }

  public render(): React.ReactElement {
    if (!this.state.loaded) return <div></div>
    return (
      <div className={'rk-home-layout'}>
        <Helmet>
          <title>Homepage</title>
          <meta name="description" content="Homepage" />
        </Helmet>
        {this.renderHeaderSection()}
        {this.renderBannerSection()}
        {this.renderFavouritesSection()}
        {this.renderTilesSection()}
        {this.renderMultiPanelSection()}
        {this.renderFAQSection()}
        {this.renderDownloadSection()}
        <DefaultFooter />
      </div>
    );
  }

  private gotToRegister(): void {
    const { email } = this.state;

    if (email) {
      window.location.href = `/register?email=${email}`;
    } else {
      window.location.href = '/register'
    }
  }

  private renderHeaderSection(): React.ReactElement {
    const signIn = (
      <Row className='rk-nav-link' justify='end' align="middle">
        <Col className='homepage--rk-nav-link rk-lowercase'>
          <div className='page-buttons'>
            <a href='/about'> About </a>
          </div>
        </Col>
        <Col className='homepage--rk-nav-link'>
          <div className='link-buttons'>
            <a href='/register'> Sign Up </a>
          </div>
        </Col>
        <Col className='homepage--rk-nav-link'>
          <div className='link-buttons'>
            <a href='/sign_in'> Login </a>
          </div>
        </Col>
      </Row>
    );

    return (
      <div className="homepage--nav">
        <DefaultHeader customAccountBanner={signIn} />
      </div>);
  }

  private renderBannerSection() {
    let posterUrl = this.homepage != null ? this.homepage.landscape_banner_image_url || this.homepage.portrait_banner_image_url : ''

    return (
      <div>
        <Col span={24} className='homepage--banner'>
          <div className={'homepage--banner__image'}>
            <video poster={''} playsInline={true} muted={true} loop={true} autoPlay={true} preload={"auto"} style={{ transform: 'translateY(-10%)' }}>
              <source src="https://assets.rascalkids.app/static/homepage.mp4" type="video/mp4" title={"Rascal stories Showreel"} />
            </video>
          </div>
          <Row>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} className='homepage--banner-text'>
              <h1>Interactive Stories for Kids.</h1>
              <div className={"homepage--banner-description"}>
                <h3>The all-new storytelling app with series and standalone stories to Play, Watch, and Read!</h3>
                <div className='homepage--banner-register'>
                  {/*<input type='mail'
                         placeholder='Email'
                         onKeyDown={event => {
                           if (event.key === 'Enter') return this.gotToRegister();
                         }}
                         onChange={(event => this.setState(
                           {email: event.target.value.toString()}))}/>*/}
                  <Button type="primary" className={'rk-cta-btn'}
                    onClick={() => this.gotToRegister()}>
                    {"Accept Free Trial"}
                    {/* <Chevron direction={'right'}/> */}
                  </Button>
                </div>
                <div className='homepage--banner-price'>
                  {this.subscriptionPlan !== null &&
                    <p>{this.subscriptionPlan.getBannerPriceString()}</p>
                  }
                </div>
                {/*<p>Want to send someone a Rascal Subscription?<br/><a>Gift a subscription here!</a></p>*/}
              </div>
            </Col>
          </Row>
        </Col>
        <Row className='homepage--banner-down-button-section' justify="center">
          <Col className='homepage--down-button-wrapper'>
            <Button type="text" className='homepage--down-button'
              onClick={e => {
                const element = document.querySelector('#favourites');
                const top = element.getBoundingClientRect().top;
                Homepage.ScrollTo(top);
              }}>
              <Chevron direction={'down'} />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  private renderFavouritesSection(): React.ReactElement {
    return (
      <Row id='favourites' className='homepage--favourites-catalogue'>
        <Catalogue title='Discover Favourites'
          stories={_.take(this.stories, 3)}
          layout={'centered'}
          maxVisibleSlides={3}
          homepage={true} />
      </Row>
    );
  }

  private renderInteractiveTile(): React.ReactElement {
    return (
      <Col className='homepage--info-tile homepage--info-tile-detail' xs={24} lg={{ span: 20, offset: 2 }}>
        <div className='homepage--info-tile-content homepage--info-tile-background'>
          <Row className={'interactive-tile__row'}>
            <Col className={'interactive-tile__wide-content-block'} xs={24} md={12}>
              <div className={'interactive-tile__wide-content'}>
                <div className={'interactive-tile__content-title'}>
                  <p className={'rascal_yellow'}>Rascal</p>
                </div>
                <div className={'interactive-tile__content-center'}>
                  <h2>
                    Unlimited access to interactive stories.
                  </h2>
                  <div className={'interactive-tile__content-buttons'}>
                    <Link className={'homepage--no-style-btn'} to={'/register'} >TRY FREE</Link>
                    <Link className={'homepage--underline-btn'} to={'/about'}>LEARN MORE</Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12} className={'interactive-tile__image-wrapper'}>
              <div className={'interactive-tile__image'}>
                <img alt={'Phone with Rascal Story'} src={'/images/interactive-tile-image.png'} title={"Leo and Shadow on iphone"} />
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }

  private renderWhyRascalTile(): React.ReactElement {
    return (
      <Col className='homepage--info-tile homepage--info-tile-update' xs={24} md={12} lg={{ span: 10, offset: 2 }}>
        <div className='homepage--info-tile-content homepage--info-tile-background-left'>
          <div className={'interactive-tile__column'}>
            <p className={'rascal_pink'}>What's New</p>
            <h2 className="homepage--tile-title">
              Upcoming Stories
            </h2>
            <div className={'interactive-tile__image-div'}>
              <div className={'interactive-tile__image-wrapper'}>
                <img alt={'Phone with Rascal Story'} src={'/images/interactive-tile-image_01.png'} title={"Ipad with Rascal story characters"} />
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  private renderUnlimitedAccessTile(): React.ReactElement {
    return (
      <Col className='homepage--info-tile homepage--info-tile-update' xs={24} md={12} lg={10}>
        <div className='homepage--info-tile-content homepage--banner-description homepage--info-tile-background-right'>
          <div className={'interactive-tile__column'}>
            <p className={'rascal_blue'}>Safety</p>
            <h2 className='homepage--tile-title-700-weight'>
              No in-app purchasing. <br />
              No advertising.
            </h2>
            <div className={'interactive-tile__image-div'}>
              <div className={'interactive-tile__image-wrapper'}>
                <img alt={'Phone with Rascal Story'} src={'/images/interactive-tile-image-02.png'} title={"Iphone with Rascal homepage"} />
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  private renderTilesSection() {
    return (
      <React.Fragment>
        <div className="homepage--info-tiles homepage--section">
          <Row gutter={[30, 30]}>
            {this.renderInteractiveTile()}
            {this.renderWhyRascalTile()}
            {this.renderUnlimitedAccessTile()}
          </Row>
        </div>
      </React.Fragment>

    );
  }

  private renderMultiPanelSection() {
    return (
      <div className='homepage--section'>
        <StoryMultiPanel story={this.homepage.multi_panel_story} />
      </div>
    );
  }

  private renderFAQHeader(text) {
    return (
      <div className='faq-header'>
        {text}
        <div className='expand-icon'>
          <div className='icon'>+</div>
        </div>
      </div>
    );
  }

  private renderFAQAccordion(): React.ReactElement {
    return (
      <Collapse accordion bordered={false}>
        <Panel header={this.renderFAQHeader('What is Rascal?')} key='1' showArrow={false}>
          <p>
            Rascal is an interactive storytelling platform designed to reignite the joy of reading for children.
            It offers on-demand access to high-quality illustrated and animated stories that kids can Play, Watch and Read simultaneously.
            Stories are streamed through and downloaded to your device, so they’re always ready to enjoy, anytime, anywhere.
            Perfect for kids aged 3–8, Rascal makes reading fun and engaging, tailored to their reading level and comprehension.
          </p>
        </Panel>
        <Panel header={this.renderFAQHeader('Will I be charged for Rascal?')} key='2' showArrow={false}>
          <p>
            When you sign up for Rascal, you’ll need to provide your card details, but don’t worry — you won’t be charged right away.
            We offer a 3-day free trial so you can explore our library of interactive stories.
            If you decide Rascal isn’t for you, you can cancel anytime during the trial, and you won’t be charged.
            After the trial, your monthly or annual subscription will begin automatically.
          </p>
        </Panel>
        <Panel header={this.renderFAQHeader('Is Rascal safe?')} key='3' showArrow={false}>
          <p>
            Absolutely! Rascal is designed to be a safe and secure environment for children to explore.
            With no in-app purchases or advertisements, all our content is hosted on our private servers in a fully controlled environment.
            Only stories reviewed and approved by our team make it onto the platform, ensuring quality and safety.
            Rascal does not stream content from external websites or companies, so you can trust every story your child interacts with.
          </p>
        </Panel>
      </Collapse>
    );
  }

  private renderFAQSection() {
    return (
      <div className='homepage--section'>
        <div className='homepage--faqs-section'>
          <Row>
            <Col xs={24} md={{ span: 20, offset: 2 }}>
              <div className='rk-homepage__title-section'>
                <h2 className={'block-header'}>Frequently Asked Questions</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={{ span: 20, offset: 2 }} lg={{ span: 16, offset: 4 }}
              xl={{ span: 12, offset: 6 }}>
              <div>
                {this.renderFAQAccordion()}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  private renderDownloadSection() {
    return (
      <Row align='middle' className='rk-home--download'>
        <Col xs={24} md={{ span: 20, offset: 2 }} className='rk-home--download-content'>
          <h2>Download Rascal</h2>
          <p>
            Access Rascal on your mobile phone or tablet
          </p>
          <div className='homepage__info-app-badge'>
            <AppStoreLink />
            <GooglePlayLink />
          </div>
        </Col>
      </Row>
    )
  }
}

export default withStore(observer(Homepage));
