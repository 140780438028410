import React from "react";
import {Helmet} from "react-helmet";


class SEO {
    static title: string = 'Rascal – Play, Watch, and Read children stories.'
    static description: string = `Rascal is an interactive children's storytelling platform. Offering stories that delight and entertain.
                            Access them instantly on the web from your personal computer, tablet or smartphone.`


    static DefaultHeaders = (
        <Helmet>
            <title>{SEO.title}</title>
            <meta name="description" content={SEO.description} />
            <meta content={SEO.title} property="og:title"/>
            <meta content={SEO.description} property="og:description"/>
            <meta content="https://rascalkids.app/images/logo.png" property="og:image"/>
            <meta content="summary" name="twitter:card"/>
            <meta content="summary" name="twitter:description"/>
            <meta content="https://rascalkids.app/images/logo.png" name="twitter:image"/>
            <meta content="@RascalApp" name="twitter:site"/>
        </Helmet>
    )

    static BuildHeaders(options) {

    }
}

export default SEO;
