import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {StoryRelease} from 'models/storyRelease/StoryRelease';
import {IStoryReleaseApiSearchParams, StoryReleaseApi} from 'models/storyRelease/StoryReleaseApi';
import {IStoryReleaseSearchApiData, StoryReleaseSearchData} from 'models/storyRelease/IStoryReleaseSearchApiData';
import {
    IStoryReleaseApiDataCreateRequest,
    IStoryReleaseApiDataGetResponse,
    IStoryReleaseApiDataUpdateRequest
} from 'models/storyRelease/IStoryReleaseApiData';

export class StoryReleaseProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'StoryReleaseProvider');

        this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public create(storyReleaseApiData: IStoryReleaseApiDataCreateRequest): Promise<IStoryReleaseApiDataGetResponse> {
        return StoryReleaseApi.create(storyReleaseApiData)
            .then((response: IStoryReleaseApiDataGetResponse) => {
                return this.StoryReleaseMemoryStore.storeOne(response, response.story_id)
                    .then(() => response);
            });
    }

    public getAllForStory(storyId: number | null = null, force: boolean = false): Promise<StoryRelease[]> {
        if (force) {
            this.StoryReleaseMemoryStore.resetAll()
            return this.getAllForStoryFromApiAndUpdateStore(storyId);
        }

        return this.StoryReleaseMemoryStore.read(storyId)
            .then((releases: IStoryReleaseApiDataGetResponse[]) => {
                return releases
                    ? this.apiDataArrayToModelArray(releases)
                    : this.getAllForStoryFromApiAndUpdateStore(storyId);
            });
    }

    private getAllForStoryFromApiAndUpdateStore(storyId: number | null = null): Promise<StoryRelease[]> {
        return StoryReleaseApi.getAll(storyId)
            .then((storyReleases: IStoryReleaseApiDataGetResponse[]) => this.StoryReleaseMemoryStore.store(storyReleases, storyId)
                .then(() => this.apiDataArrayToModelArray(storyReleases)));
    }

    private apiDataArrayToModelArray(storyReleases: IStoryReleaseApiDataGetResponse[]): StoryRelease[] {
        return storyReleases.map(this.apiDataToModel);
    }

    private apiDataToModel(storyRelease: IStoryReleaseApiDataGetResponse): StoryRelease {
        return new StoryRelease(this).withData(storyRelease);
    }

    public getStoryReleaseSearch(searchParams: IStoryReleaseApiSearchParams): Promise<StoryReleaseSearchData> {
        return StoryReleaseApi.getStoryReleaseSearch(searchParams)
            .then((data: IStoryReleaseSearchApiData) => ({
                page: data.page,
                page_size: data.page_size,
                total: data.total,
                story_releases: this.apiDataArrayToModelArray(data.story_releases)
            }));
    }

    public update(id: number, data: IStoryReleaseApiDataUpdateRequest): Promise<IStoryReleaseApiDataGetResponse> {
        return StoryReleaseApi.update(id, data);
    }
}
