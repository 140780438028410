import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {IBillingAddressApiData} from 'models/billingAddress/IBillingAddressApiData';

export class BillingAddressPermanentStore extends PermanentStore implements IExposedPermanentStore<IBillingAddressApiData[]> {
    protected get TableName(): string {
        return PermanentStore.TABLE_NAMES.BillingAddressPermanentStore;
    }

    constructor(rootStore: RootStore) {
        super(rootStore);
    }

    public getKey(id: number): string {
        return `api_billing_addresses_${id}`;
    }

    public store(data: IBillingAddressApiData[], id: number): Promise<void> {
        return this.doStore<IBillingAddressApiData[]>(this.TableName, this.getKey(id), data);
    }

    public read(id: number): Promise<IBillingAddressApiData[] | null> {
        return this.doRead<IBillingAddressApiData[]>(this.TableName, this.getKey(id));
    }
}
