export class Colors {
  public static readonly BLACK: string = '#333333';
  public static readonly WHITE: string = '#FEFEFE';

  public static readonly GREY_LIGHT: string = '#F2F2F2';
  public static readonly GREY_MID: string = '#BFBFBF';
  public static readonly GREY_DARK: string = '#888888';

  public static readonly ORANGE: string = '#FF8C68';
  public static readonly RED: string = '#EB0000';

  public static readonly GREEN: string = '#228B22';
  public static readonly GREEN_HOVER_LIGHT: string = '#63B26D';
}