import Api from 'models/Api';
import {Story} from 'models/story/Story';
import {IStoryApiData} from 'models/story/IStoryApiData';
import {ApiResponseData} from 'models/ApiResponseData';
import {IRole, IStoryUpdateApiData} from 'models/story/IStoryUpdateApiData';

export class StoryApi {

    public static get(id: number): Promise<IStoryApiData> {
        return Api.get(`/api/v1/stories/${id}`).then(response => response.json())
    }

    public static getCredits(id: number): Promise<IRole[]> {
        return Api.get(`/api/v1/stories/${id}/credits`).then(response => response.json())
    }

    public static getAll(): Promise<IStoryApiData[]> {
        return Api.get(`/api/v1/stories/`)
            .then(response => response.json())
            .then(json => json.stories);
    }

    public static getAllIncludingBlocked(): Promise<IStoryApiData[]> {
        return Api.get(`/api/v1/stories/all`)
            .then(response => response.json())
            .then(json => json.stories);
    }

    public static getFavourites(): Promise<IStoryApiData[]> {
        return Api.get(`/api/v1/stories/favourites`)
            .then(response => response.json())
            .then(json => json.stories);
    }

    public static getRelated(id: number): Promise<IStoryApiData[]> {
        return Api.get(`/api/v1/stories/${id}/related`)
            .then(response => response.json())
            .then(json => json.stories);
    }

    public static create(story: Story): Promise<ApiResponseData> {
        return Api.post(`/api/v1/stories`, {
            body: JSON.stringify({
                story: {
                    default_localisation: {
                        locale: story.default_localisation.locale,
                        title: story.default_localisation.title,
                        biography: story.default_localisation.biography,
                        image_url: story.default_localisation.image_url,
                        portrait_image_url: story.default_localisation.portrait_image_url
                    }
                }
            })
        })
            .then(response => response.json())
    }

    public static update(id: number, data: IStoryUpdateApiData): Promise<IStoryApiData> {
        return Api.put(`/api/v1/stories/${id}`, {
            body: JSON.stringify(data)
        })
            .then(response => response.json())
    }
}
