import React, {Component} from "react";
import {observable} from "mobx";
import {Subscription} from "models/subscription/Subscription";
import {ComponentWithStore} from "models/RootStore";
import {StoryApi} from "models/story/StoryApi";
import {IStoryApiData} from "models/story/IStoryApiData";
import {LoadingOutlined} from "@ant-design/icons";
import {Button, Col, Image, Row} from "antd";
import DefaultLayout from "layouts/DefaultLayout";

import "./SubscriptionManage.scss";
import {SubscriptionApi} from "models/subscription/SubscriptionApi";
import _ from "lodash";
import PageTitle from "components/shared/PageTitle";
import AppInfo from "models/AppInfo";

interface ISubscriptionManageState {
    loaded: boolean;
    stories: IStoryApiData[];
    blocked_stories: number[];
}

interface ISubscriptionManageProps {
    routeParams: { subscriptionId: string };
}

export class SubscriptionManage extends ComponentWithStore<ISubscriptionManageProps, ISubscriptionManageState> {
    @observable public subscription: Subscription;
    @observable public stories: IStoryApiData[];

    constructor(props: ISubscriptionManageProps) {
        super(props);
        this.state = {
            loaded: false,
            stories: [],
            blocked_stories: []
        };
    }


    componentDidMount() {
        let id: number = Number(this.props.routeParams.subscriptionId);
        this.subscription = new Subscription(this.store.SubscriptionProvider).withId(id);
        // noinspection JSIgnoredPromiseFromCall
        this.subscription.fetchData()
          .then(() => StoryApi.getAllIncludingBlocked())
          .then((stories) =>this.stories = stories)
          .then(() => this.setState({
              stories: this.stories,
              blocked_stories: this.subscription.blocked_stories,
              loaded: true
          }))
    }

    toggleBlockStory(id: number) {
        const {blocked_stories} = this.state;
        if (blocked_stories.includes(id)) {
            _.remove(blocked_stories, (value) => value == id);
        } else {
            blocked_stories.push(id);
        }
        console.log(blocked_stories);

        //locally save
        this.subscription.blocked_stories = blocked_stories;
        this.setState({
            blocked_stories: [...blocked_stories]
        });

        // update server and propagate
        SubscriptionApi.update(this.subscription.id, {
            blocked_stories: blocked_stories
        }).then((subscriptionApiData) => this.subscription.load(subscriptionApiData))
            .then(() => this.setState({
                blocked_stories: this.subscription.blocked_stories
            }));
    }

    render() {
        const {loaded} = this.state;
        return (
            <DefaultLayout>
                <Row align='middle' gutter={[0, 25]}>
                    <Col xs={24} md={{span: 20, offset: 2}} lg={{span: 20, offset: 2}}>
                        {loaded && <PageTitle to={AppInfo.isAdmin() ? `/admin/subscriptions/${this.props.routeParams.subscriptionId}` : `/account`}
                             title='Subscription'
                             subtitle={`${this.subscription.name}`}/>}
                        <div className='rk-account-content'>
                            <h2>Manage Subscription</h2>
                        </div>
                    </Col>
                </Row>
                <Row align='middle' gutter={[0, 25]}>
                    <Col xs={24} md={{span: 20, offset: 2}} lg={{span: 20, offset: 2}}>
                        {loaded ? this.renderContent() : <LoadingOutlined spin={true} />}
                    </Col>
                </Row>
            </DefaultLayout>
        )
    }

    renderContent() {
        return (
            <div>
                {AppInfo.isAdmin() && <a href={`/subscriptions/${this.subscription.id}/manage/users`}>Bulk add users (Admin Only)</a>}
                {this.renderBlockedStories()}
            </div>
        );
    }

    renderBlockedStories() {
        const {stories, blocked_stories} = this.state;
        console.log(blocked_stories);
        return (
            <div>
                <div>
                    <h3>Blocked Stories</h3>
                </div>
                <div className={'subscription-manage__stories'}>
                    {stories.map((story) => {
                        console.log(blocked_stories);
                        const blocked = blocked_stories.includes(story.id);
                        const storyClass = `story ${blocked ? 'blocked' : ''}`;
                        return (
                            <div className={storyClass} key={`${story.id}-${blocked}`}>
                                <div className={'story__image'} onClick={() => this.toggleBlockStory(story.id)}>
                                    <img alt={story.default_localisation.title} src={story.default_localisation.portrait_image_url} />
                                    <div className={'story__blocked'}></div>
                                    <div className={'story__blocked__text'}>{blocked ? 'BLOCKED' : 'BLOCK?'}</div>
                                </div>
                                <span className={'title'}>{story.default_localisation.title}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default SubscriptionManage;
