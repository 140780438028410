import React, {Component} from 'react';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Col, Drawer, Row} from 'antd';

import {Story} from 'models/story/Story';
import {IPerson, IRole} from 'models/story/IStoryUpdateApiData';

import LoadingIcon from 'components/public/pages/webgl_app/LoadingIcon';
import {StoryDetailsEvents} from 'components/public/pages/webgl_app/StoryDetailsEvents';

import './Credits.scss'

interface ICreditsProps {
    story: Story;
}

interface ICreditsState {
    isLoaded: boolean;
    isCreditsVisible: boolean;
}

class Credits extends Component<ICreditsProps, ICreditsState> {
    @observable private credits: IRole[] = [];

    public constructor(props: ICreditsProps) {
        super(props);

        makeObservable(this);

        this.state = {
            isLoaded: false,
            isCreditsVisible: false
        };

        this.renderRole = this.renderRole.bind(this);
        this.onToggleCreditsVisibility = this.onToggleCreditsVisibility.bind(this);
    }

    public componentDidMount(): void {
        this.getCredits().finally(() => this.setState({isLoaded: true}));
        window.addEventListener(StoryDetailsEvents.TOGGLE_CREDITS_VISIBILITY_EVENT, this.onToggleCreditsVisibility)
    }

    public componentWillUnmount() {
        window.removeEventListener(StoryDetailsEvents.TOGGLE_CREDITS_VISIBILITY_EVENT, this.onToggleCreditsVisibility)
    }

    @action
    private getCredits(): Promise<void> {
        const {story} = this.props;

        return story.getCredits()
            .then(res => {
                this.credits = res;
            });
    }

    private onToggleCreditsVisibility(): void {
        const {isCreditsVisible} = this.state;

        this.setState({isCreditsVisible: !isCreditsVisible})
    }

    private renderRole(role: IRole, i: number): React.ReactElement {
        let people: IPerson[] = role?.people ?? [{id: -1, name: ''}];
        let renderBlank: React.ReactElement = <React.Fragment>&nbsp;</React.Fragment>

        return (
            <React.Fragment key={`${i}`}>
                {people.map((person: IPerson, j: number) =>
                    <Row className='credits-view--row' key={`${i}_${j}`}>
                        <Col className='credits-view--column' span={12}>
                            <div className='credits-view--left-column'>
                                {role?.role && j === 0 ? role.role : renderBlank}
                            </div>
                        </Col>
                        <Col className='credits-view--column' span={12}>
                            <div className='credits-view--right-column'>
                                {person?.name ? person.name : renderBlank}
                            </div>
                        </Col>
                    </Row>
                )}
            </React.Fragment>
        )
    }

    private renderLoading(): React.ReactElement {
        return (
            <div className='credits-view--loading'>
                <LoadingIcon color='white'/>
            </div>
        );
    }

    public render(): React.ReactElement {
        const {story} = this.props;
        const {isCreditsVisible, isLoaded} = this.state;

        return (
            <Drawer className='rk-drawer credits-view-drawer'
                    width='' // Need to remove the default width of 256px from antd.
                    title={`${story?.default_localisation?.title ?? ''} Credits`}
                    onClose={() => this.setState({isCreditsVisible: false})}
                    open={isCreditsVisible}>
                <div className='credits-view'>
                    {isLoaded ? this.credits?.map(this.renderRole) : this.renderLoading()}
                </div>
            </Drawer>
        );
    }
}

export default observer(Credits);
