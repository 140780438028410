import React from 'react';

class PlayStoreRedirect extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    public componentDidMount() : void{
        window.location.replace('https://play.google.com/store/apps/details?id=app.rascalkids.rascal');
    }

    public render(): React.ReactElement {
        return <React.Fragment/>
    }
}

export default PlayStoreRedirect;
