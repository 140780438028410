import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { UnityConfig } from 'react-unity-webgl';
import _ from 'lodash';
import { Col, Row } from 'antd';

import Config from 'config/Config';
import { ComponentWithStore } from 'models/RootStore';
import { StoryRelease } from 'models/storyRelease/StoryRelease';
import { Story } from 'models/story/Story';
import { Settings } from 'models/settings/Settings';
import { Collection } from 'models/collection/Collection';
import { StorySession } from 'models/storySession/StorySession';
import AppInfo from 'models/AppInfo';

import Catalogue from 'components/public/pages/home/Catalogue';
import LoadingIcon from 'components/public/pages/webgl_app/LoadingIcon';
import StoryDetailsHeader from 'components/public/pages/webgl_app/StoryDetailsHeader';
import StoryInfo from 'components/public/pages/webgl_app/StoryInfo';
import { WebglApp } from 'components/public/pages/webgl_app/WebglApp';
import WebglOverlay from 'components/public/pages/webgl_app/WebglOverlay';
import DefaultFooter from 'layouts/footers/DefaultFooter';
import WindowSize from 'components/shared/WindowSize';

import './StoryDetails.scss';
import './WebglApp.scss';

interface IStoryDetailsProps {
    routeParams: { storyId: string };
    play?: boolean;
}

interface IStoryDetailsState {
    isInfoLoaded: boolean;
    isUnityConfigLoaded: boolean;
}

class StoryDetails extends ComponentWithStore<IStoryDetailsProps, IStoryDetailsState> {
    @observable public story: Story;
    @observable private related: Story[];
    @observable private collections: Collection[];
    @observable private releases: StoryRelease[];
    @observable private release: StoryRelease;
    @observable private storySession: StorySession;

    @observable private config: UnityConfig;
    @observable private revokeConfigUrlsCallback: Function;
    @observable private settings: Settings;

    private storyId: number;

    private doPlay: () => void = () => {
        console.log('play not init');
    };

    public constructor(props: IStoryDetailsProps) {
        super(props);

        makeObservable(this);
        let id: number = Number(this.props.routeParams.storyId);
        this.storyId = id;
        this.story = new Story(this.store.StoryProvider).withId(id);

        this.state = {
            isInfoLoaded: false,
            isUnityConfigLoaded: false
        };
    }

    public componentDidMount(): void {
        this.store.SessionProvider.authOnly();
        this.story
            .withDefaultLocalisation()
            .thenGetData()
            .then(() => Promise.all([
                this.getRelated(),
                this.getCollections(),
                this.getReleases(),
                this.getSettings(),
                this.getStorySession()
            ]))
            .finally(() => this.setState({ isInfoLoaded: true }));
        this.getWebgl()
            .finally(() => this.setState({ isUnityConfigLoaded: true }));
    }

    @action
    private async getRelated(): Promise<void> {
        this.related = await this.story.getRelated();
    }

    @action
    private async getCollections(): Promise<void> {
        this.collections = await this.story.getCollections();
    }

    @action
    private async getReleases(): Promise<void> {
        this.releases = await this.story.getStoryReleases();
        this.release = StoryRelease.filterForLatest(this.releases);
    }

    @action
    private async getStorySession(): Promise<void> {
        this.storySession = await this.story.getStorySession(true);
    }

    @action
    private async getSettings(): Promise<void> {
        this.settings = await this.store.SettingsProvider.localSettings();
    }

    @action
    private async getWebgl(): Promise<void> {
        console.log('StoryDetails.getWebgl');
        if (Config.useLocalWebGLBuild) {
            return Promise.resolve();
        }

        let res = await this.store.WebglProvider.latest();
        console.log('StoryDetails.res', res);
        if (res.config) this.config = res.config;
        this.revokeConfigUrlsCallback = res.callback;
    }

    private renderMobileView(): React.ReactElement {
        return (
            <div className='rk-webgl-app--mobile'>
                <Row justify='space-around' align='middle'>
                    <Col xs={24}>
                        <div className='rk-center'>
                            <h1>Download soon:</h1>
                            <button className='rk-btn app-btn'>Apple</button>
                            <button className='rk-btn app-btn'>Google</button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    private renderWebgl(): React.ReactElement {
        const { play } = this.props;
        const { isUnityConfigLoaded } = this.state;

        if (AppInfo.isMobile()) {
            const portrait = window.innerWidth <= WindowSize.sm;
            return (
                <Row className='webgl-overlay--image-portrait-wrapper' align='middle' justify='space-around'>
                    <WebglOverlay story={this.story} storySession={this.storySession} portrait={portrait} play={play} />
                </Row>
            );
        }

        return (
            <Row className='story-view--webgl-wrapper' align='middle' justify='space-around'>
                {isUnityConfigLoaded &&
                    <WebglApp story={this.story}
                              release={this.release}
                              collections={this.collections}
                              setPlay={(doPlay: () => void) => (this.doPlay = doPlay)}
                              config={this.config}
                              settings={this.settings}
                              revokeConfigUrlsCallback={this.revokeConfigUrlsCallback}
                              store={this.store}
                        />}
                <WebglOverlay story={this.story} storySession={this.storySession} play={play} />
            </Row>
        );
    }

    private renderStorySession(): React.ReactElement {
        // TODO: Delete this
        return (
            <Row>
                Story Session:
                <ul>
                    <li>id: {this.storySession?.remote_id}</li>
                    <li>story_id: {this.storySession?.story_id}</li>
                    <li>started_at: {this.storySession?.started_at?.toString()}</li>
                    <li>finished_at: {this.storySession?.finished_at?.toString()}</li>
                    <li>exited_at: {this.storySession?.exited_at?.toString()}</li>
                    <li>duration: {this.storySession?.duration}</li>
                    <li>current_asset_bundle: {this.storySession?.current_asset_bundle}</li>
                    <li>current_scene: {this.storySession?.current_scene}</li>
                </ul>
            </Row>
        );
    }

    private renderCatalogues(): React.ReactElement {
        return (
            <React.Fragment>
                {_.map(
                    _.orderBy(this.collections, collection => _.includes(_.map(collection.story_collections, sc => sc.story_id), this.story.id), 'desc'),
                    collection => (
                        <Row className='story-view--related-section' key={collection.id}>
                            <Col xs={24}>
                                <Catalogue title={collection.name} layout='medium' panelLayout='short' storyIds={_.map(collection.story_collections, sc => sc.story_id)} />
                            </Col>
                        </Row>
                    )
                )}
                {this.related?.length > 0 && (
                    <Row className='story-view--related-section'>
                        <Col xs={24}>
                            <Catalogue title='Related' layout='medium' panelLayout='tall' stories={this.related} align='right' />
                        </Col>
                    </Row>
                )}
            </React.Fragment>
        );
    }

    public render(): React.ReactElement {
        const { isInfoLoaded } = this.state;

        if (!isInfoLoaded) {
            return (
                <div style={{ width: '100vw', height: '100vh' }}>
                    <LoadingIcon color='blue' />
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className='story-view'>
                    <div className='story-view--scroll-view'>
                        <div className='story-view--scroll-container'>
                            <StoryDetailsHeader story={this.story} />
                            {this.renderWebgl()}
                            {this.renderCatalogues()}
                            <StoryInfo story={this.story} />
                        </div>
                    </div>
                </div>
                <DefaultFooter />
            </React.Fragment>
        );
    }
}

export default observer(StoryDetails);
