import {action, makeObservable, observable} from 'mobx';

import {Hash} from 'types/hash';
import {RootStore} from 'models/RootStore';
import {InMemoryStore, IStoreData} from 'models/InMemoryStore';
import {StorySessionPermanentStore} from 'models/storySession/StorySessionPermanentStore';
import {IStorySessionApiDataGetResponse} from 'models/storySession/IStorySessionApiData';

export class StorySessionMemoryStore extends InMemoryStore<IStorySessionApiDataGetResponse> {
    @observable private _sessions: Hash<IStoreData<IStorySessionApiDataGetResponse>> = {};

    public constructor(rootStore: RootStore) {
        super(rootStore, 'StorySession');
        makeObservable(this);
    }

    protected get PermanentStore(): StorySessionPermanentStore {
        return this.StorySessionPermanentStore;
    }

    public resetAll(): void {
        super.resetAll();
        this._sessions = {};
    }

    @action
    public loadFromPermanentStore(storyId: number): Promise<void> {
        return this.PermanentStore.read(storyId)
            .then((session: IStorySessionApiDataGetResponse | null) => {
                if (session) {
                    this.set(session, storyId);
                }
            });
    }

    protected getIStoreData(storyId: number): IStoreData<IStorySessionApiDataGetResponse> {
        let data: IStoreData<IStorySessionApiDataGetResponse> = this._sessions[storyId.toString()];
        if (!data) {
            data = new IStoreData<IStorySessionApiDataGetResponse>(storyId, null, this);
            this._sessions[storyId.toString()] = data;
        }

        return data;
    }

    @action
    protected set(value: IStorySessionApiDataGetResponse, storyId: number): void {
        this.getIStoreData(storyId).setData(value);
    }

    @action
    public store(data: IStorySessionApiDataGetResponse, storyId: number): Promise<void> {
        this.set(data, storyId);
        return this.PermanentStore.store(data, storyId);
    }

    public read(storyId: number): Promise<IStorySessionApiDataGetResponse | null> {
        return this.getIStoreData(storyId).LoadData;
    }
}
