import React, {Component} from "react";

class SoundIcon extends Component {
  render() {
    return (
      <svg className='rk-icon rk-sound-icon' xmlns="http://www.w3.org/2000/svg" width="11.128" height="15" viewBox="0 0 11.128 15">
        <g transform="translate(-1301.898 -237.821)" fill="currentColor">
          <path d="M1312.752,243.129a9.314,9.314,0,0,0-2.834-4.749,2.572,2.572,0,0,0-.63-.462,1.128,1.128,0,0,0-1.163,1.911,7.136,7.136,0,0,1,1.361,9.453,8.465,8.465,0,0,1-1.462,1.61,1.135,1.135,0,0,0,1.429,1.743,4.916,4.916,0,0,0,.574-.477,9.267,9.267,0,0,0,3-6.713A9.932,9.932,0,0,0,1312.752,243.129Z"/>
          <path d="M1306.962,241.311a1.129,1.129,0,0,0-1.85.54,1.184,1.184,0,0,0,.456,1.287,2.868,2.868,0,0,1-.033,4.369,2.244,2.244,0,0,0-.347.335,1.026,1.026,0,0,0,.2,1.412,1.15,1.15,0,0,0,1.514.085,5.032,5.032,0,0,0,1.936-3.718A5.364,5.364,0,0,0,1306.962,241.311Z"/>
          <path d="M1303.443,243.776a1.545,1.545,0,1,0,1.545,1.545A1.545,1.545,0,0,0,1303.443,243.776Z"/>
        </g>
      </svg>
    );
  }
}

export default SoundIcon;
