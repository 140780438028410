import React, {Component} from 'react';
import {Col, Row} from 'antd';

import DefaultLayout from 'layouts/DefaultLayout';

import './PrivacyPolicy.scss';
import {Helmet} from "react-helmet";

// This renders either itself or Logged out Homepage.
class PrivacyPolicy extends Component<any, any> {

  public render(): React.ReactElement {
    return (
      <DefaultLayout>
        <Helmet>
          <title>Rascal - Privacy Policy</title>
        </Helmet>
        {this.renderPolicySection()}
      </DefaultLayout>
    );
  }

  private renderPolicySection() {
    return (
      <div className='policy--pages'>
        <Row>
          <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:17, offset: 4 }} lg={{ span:18, offset: 3 }} xl={{ span:16, offset: 4 }} xxl={{ span:12, offset: 6 }} className='aboutpage--section-text'>
            <h1>Privacy policy</h1>
            <div>
              <h3>Rascal Entertainment Limited, a New Zealand registered company of Lvl 1, 3 Blair Street, Te Aro,
                Wellington, 6011 , New Zealand <b>(Rascal)</b> is firmly committed to respecting the
                confidentiality of the personal information you supply to us and all data will be processed
                in accordance with applicable data privacy laws.
                < br />
                < br />
                The following policy discloses Rascal’s information gathering and dissemination practices for this
                website (the <b>Website</b>) and the associated Rascal mobile application (<b>App</b>) which provide an interactive
                way for family members to read and share stories with younger members of the family (the Website and
                the App, collectively, the <b>Service</b>). If you do not agree with our use of your personal information as
                set out in this Privacy Policy, you should not submit your personal information to us.
                < br />
                < br />
                Rascal reserves the right to make changes to this Privacy Policy at any time by posting a revised Policy on the
                Website and emailing a copy of the revised Policy to users without prior notice and such changes become effective
                once a revised Privacy Policy is posted on Rascal’s Website. Your continued use of Rascal’s Website and/or App
                constitutes your acceptance of such changes. Accordingly, you should review this Privacy Policy from time to time
                for changes. THIS PRIVACY POLICY WAS LAST UPDATED: February 2022.
                < br />
                < br />
                Registered users with the Service must be over the age of 18. Individuals under 18 years of age are only
                authorized to use or access the Website and the Service through the account of a parent or legal guardian
                that is over the age of 18 and is a Paid User.
                < br />
                < br />
                The protection of your privacy is very important and Rascal has adopted the following practices to safeguard
                the confidentiality of your personal information.
              </h3>
            </div>
            <div>
              <h2 className={"custom-list-title"}>
                1. Your Rights in Connection with Personal Information
              </h2>
              <ul className={'no-decoration'}>
                <li className={"custom-list"}>
                  Under certain circumstances, by law you have the right to:
                  <ol className={"ol-letters"}>
                    <li>Object to processing of your personal information where Rascal is relying on a legitimate
                      interest (or that of a third party) and there is something about your particular situation which
                      makes you want to object to processing on this ground. You also have the right to object where
                      Rascal is processing your personal information for direct marketing purposes.</li>

                    <li>Request access to your personal information (commonly known as a “data subject access
                      request”). This enables you to receive a copy of the personal information Rascal holds about you
                      and to check that Rascal is processing it lawfully.
                    </li>

                    <li>Request correction of the personal information that Rascal holds about you. This enables
                      you to have any incomplete or inaccurate information Rascal holds about you corrected.
                    </li>

                    <li>Request erasure of your personal information. This enables you to ask Rascal to delete or
                      remove personal information where there is no good reason for Rascal continuing to process it.
                      You also have the right to ask Rascal to delete or remove your personal information where you have
                      exercised your right to object to processing (see above).
                    </li>

                    <li>Request the restriction of processing of your personal information. This enables you to ask
                      Rascal to suspend the processing of personal information about you, for example if you want Rascal
                      to establish its accuracy or the reason for processing it.
                    </li>

                    <li>Request the transfer of your personal information to another party in a machine-readable
                      commonly used and structured format.
                    </li>
                  </ol>
                </li>
                <li className={"custom-list"}>
                  If you want to exercise any of these rights then please contact Rascal through the Website or using
                  the contact details set out at the end of this Privacy Policy. The various rights are not absolute
                  and each is subject to certain exceptions or qualifications. For example, if you wish to withdraw your
                  consent or object to processing, Rascal may need to discuss with you whether Rascal’s use of your data
                  needs to continue for other lawful purposes, such as fulfilment of a legal or contractual requirement.
                </li>
                <li className={"custom-list"}>
                  Rascal will respond to your request within one month of receipt of your request. In some cases Rascal
                  may not be able to fulfil your request to exercise the right before this date and may need to request
                  more time. Where Rascal cannot provide a full response to you for any reason, Rascal will let you
                  know about this in Rascal’s initial reply to your request.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                2. Your Duty to Inform Rascal of Changes
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  It is important that the personal information Rascal holds about you is accurate and current.
                  Please keep Rascal informed if your personal information changes during your working relationship
                  with Rascal.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                3. Fees
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  You will not have to pay a fee to access your personal information (or to exercise any of the other
                  rights). In some cases, Rascal may charge a reasonable fee if your request for access is clearly
                  unfounded or excessive, or if you request multiple copies of the information. Alternatively, Rascal
                  may refuse to comply with the request in such circumstances.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                4. What Rascal May Need From You
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  Rascal may need to request specific information from you to help Rascal confirm your identity and ensure
                  your right to access the information (or to exercise any of your other rights). This is another
                  appropriate security measure to ensure that personal information is not disclosed to any person who
                  has no right to receive it.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                5. Right To Complain
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  If you wish to request further information about any of the above rights, or if you are unhappy with
                  how Rascal has handled your information, please contact Rascal through the Website or using the
                  contact details set out at the end of this Privacy Policy. If you are not satisfied with Rascal’s
                  response to your complaint or believe Rascal’s processing of your information does not comply with
                  data protection law, you can make a complaint to the relevant supervisory authority in your
                  jurisdiction. For example, the Office of the Privacy Commissioner in New Zealand.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                6. What Information Is Collected?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  Rascal collects two types of information: personally identifiable information (<b>PII</b>) and non-personally
                  identifiable information (<b>Non-PII</b>), which is also referred to as aggregate and anonymous data.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                7. Collection of Information from Children
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  Rascal may collect PII from children under the age of 13 by collecting the content that any person
                  records using, or otherwise uploads to, the Service. A video or audio recording of a person is PII.
                  Rascal does not otherwise require the provision of any PII about any users under the age of 18, who
                  can only access the Website and the App through the account of their parent or legal guardian who is
                  a Paid User. A child may use the Website or App to view content created by other users that are linked
                  to their parent or legal guardian’s account without providing any PII.
                </li>
                <li className={"custom-list"}>
                  Where you are a parent or legal guardian, and you allow any child in your care to use the Service,
                  you consent to Rascal collecting and using content created by, or featuring,  that child in accordance
                  with this Privacy Policy.
                </li>
                <li className={"custom-list"}>
                  You expressly consent to Rascal providing access to that content to other users that you have permitted
                  to access content using your account.
                </li>
                <li className={"custom-list"}>
                  You can access the content that has been created through your account at any time. If you wish to
                  remove any content, including content created by children under your care, please contact us at
                  support@rascalkids.app.
                </li>
                <li className={"custom-list"}>
                  If you wish to prevent further use or collection of a child's PII, you can disable voice recordings
                  under the account settings.
                </li>
                <li className={"custom-list"}>
                  Where you are a parent or legal guardian, and you allow any child in your care to use the Service,
                  you consent to Rascal collecting and using content created by, or featuring,  that child in accordance
                  with this Privacy Policy.
                </li>
                <li className={"custom-list"}>
                  7 Where you are a parent or legal guardian, and you allow any child in your care to use the Service,
                  you consent to Rascal collecting and using content created by, or featuring,  that child in accordance
                  with this Privacy Policy.
                </li>
                <li className={"custom-list"}>
                  You agree that you will not provide access to the Website or App to any child that you:
                  <ol className={"ol-letters"}>
                    <li>are not the parent or legal guardian of; and</li>
                    <li>who you do not have the legal right to provide consent on behalf of.</li>
                  </ol>
                </li>
                <li className={"custom-list"}>
                  You agree that if you are not a Paid User, you will not allow any person under the age of 18 to use the Website or the App using your account.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                8. What information do you gather from visitors?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal gathers information about how you access the Website and use the App, including the type of
                  device you use to access the Website or App, your domain name, your IP address, your phone number,
                  and your internet browser software, operating system and platform. Rascal may also derive your
                  approximate geolocation from your IP address or from your mobile device. Rascal also gathers
                  information about your use of the Website and App, including statistical data about your visit
                  such as the date and time of your visit, the number of links you have clicked on, the pages you
                  have visited and the number of times you have visited.
                </li>
                <li className={"custom-list"} >
                  Rascal uses cookies to facilitate a more user-friendly experience. Cookies are small pieces of
                  information that a web site places on your computer's hard drive so Rascal knows it's you when you
                  visit. This helps Rascal to create a personalized experience for you when you visit the Website or
                  use the App. The use of cookies is a very common practice on the Internet and provides for a more
                  customized user experience of the Website. For further information on Rascal’s use of cookies, please
                  refer to Rascal’s Cookie Policy available here: https://rascalkids.app/cookie-policy
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                9. What information will you ask me for?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  When you establish an account with the Service you will be asked for PII and Non-PII which will
                  enable Rascal to provide you with the Service. Rascal may collect and use information you provide
                  Rascal with, including information such as your account user name, date of birth, email address and
                  any other information you have provided to Rascal through your use of the Website or App or otherwise.
                  If you do not provide Rascal with your email address, Rascal may not be able to set up a profile for you.
                </li>
                <li className={"custom-list"} >
                  You may create recordings, or submit other content, through the Website or App. That content may or
                  may not include PII. Rascal will collect and use that content in accordance with this Privacy Policy.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                10. What information does Rascal collect from third parties?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal may collect information from other sources, including third party sites that you may choose to
                  link to your Rascal account, such as Facebook. Rascal may obtain the information that you have
                  provided to that third party, to the extent allowed by the third-party site and authorised by you.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                11. How will this information be used and who has access to it?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  <b>Non-PII:</b> Your Non-PII is used to personalize your experience, allow other users to access your content
                  where you have provided that access through the Website or the App. Rascal may share some of this
                  information for Rascal’s own analysis and research purposes with Rascal’s professional advisors or
                  consultants and third-party partners in order to deliver, manage, monitor, maintain, develop and
                  improve the Website or the App.
                </li>
                <li className={"custom-list"} >
                  <b>PII:</b> The PII Rascal collects from you is used in the course of providing Rascal’s Website and
                  Service to you and to manage, monitor, maintain, develop or improve the content and functionality of
                  Rascal’s Service and Website. Rascal may use your contact information to send you valuable updates and
                  timely notices of promotions and mailbox status reports. Rascal may also use your PII to send electronic
                  mail to you, for the purpose of informing you of changes or additions to the Service, or of any Rascal
                  products and related services. Rascal will only send you marketing communications where Rascal has
                  your consent to do so. You have the option to withdraw your consent at any time. Rascal does not rent,
                  sell or otherwise provide your PII to third parties for marketing, direct marketing or solicitation
                  purposes. The PII Rascal collects is not released to any non-affiliated third party without your prior consent.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                12. Payment
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Your credit card or payment details will be processed or transmitted through or via the Website.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                13. Advertisers and Third-Parties
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal may disclose your Non-PII to third party organisations, agents and service providers to assist
                  Rascal in providing the Services (for example, content-delivery, trends), as well as conducting market
                  and business research and analysis for any of the purposes set out in this Privacy Policy.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                14. Third-Party Social Networking Sites
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  When you have enabled the use of Rascal’s Services through a third-party social networking site,
                  mobile application, or similar websites or applications (a <b>Social Networking Site</b>), such as
                  Facebook or Twitter, you permit Rascal to access certain information about you that is made available
                  to Rascal through or from that Social Networking Site. The information obtained by Rascal varies by
                  Social Networking Site and may be affected by the privacy settings you establish at that Social
                  Networking Site, but can include information such as your name, profile picture, network, gender,
                  username, user ID, age range or birthday, language, location, country and other information. By
                  accessing or using Rascal’s Services through a Social Networking Site, you are authorizing Rascal to
                  collect, store, retain and use, in accordance with this Policy, any and all of your information that
                  Rascal has obtained from the Social Networking Site, including to create a Rascal profile page and
                  account for you. Your agreement to the foregoing takes place when you "accept" or "allow" or “go to"
                  (or other similar terms) Rascal’s application on a Social Networking Site or the transfer of
                  information to Rascal from such site. If there is information about your “friends" or people you
                  are associated with in your Social Networking Site account, the information Rascal obtains about
                  those persons may also depend on the privacy settings such people have with the applicable Social
                  Networking Site. You acknowledge and agree that Rascal is not responsible for, and has no control
                  over, any applicable privacy settings on any Social Networking Sites (including any settings related
                  to any messages or advertisements about Rascal that the Social Networking Site may send to you or
                  your friends). You should always review, and if necessary, adjust your privacy settings on Social
                  Networking Sites before getting or using applications such as Rascal’s or linking or connecting your
                  Social Networking Site account to the Services. You may also unlink your Social Networking Site
                  account from the Services by adjusting your settings on the Social Networking Site.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                15. To enforce legal rights
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal may disclose your PII to respond to law enforcement requests; or where required by applicable
                  laws, court orders, or government regulations; or to protect against misuse or unauthorized use of
                  our Website, App or Service; or to protect the personal safety or property of Rascal’s users or the
                  public (among other things, this means that if you provide false information or attempt to pose as
                  someone else, information about you may be disclosed as part of any investigation into your actions).
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                16. Sale or Merger
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal may disclose and sell PII in connection with the sale, assignment, or other transfer of the
                  business or a portion of the business of Rascal’s Website, including a corporate merger, consolidation,
                  restructuring, sale of assets or other corporate change of Rascal’s direct or indirect parent
                  companies that affects Rascal.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                17. How Does Rascal Respond to Do Not Track Signals?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  The Website supports the Do Not Track browser setting. Do Not Track (<b>DNT</b>) is a preference you can
                  set in your web browser to let the websites you visit know that you do not want them collecting certain
                  information about you. For more details about Do Not Track, including how to enable or disable this
                  preference, visit the Electronic Frontier Foundation page.
                </li>
                <li className={"custom-list"} >
                  Website users who send Do Not Track signals will not experience an adverse effect on their experience with the Website.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                18. Rascal’s bases for collecting and using the information
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal is entitled to use your PII in the ways set out in this Privacy Policy on the following bases:
                  <ol className={"ol-letters"} >
                    <li>
                      the use of personal information in this way is necessary for the performance of a contract
                      with you for provision of Rascal’s Services;
                    </li>
                    <li>
                      the use of your personal information is necessary for Rascal’s legitimate interests in:
                        <ol type={"i"}>
                          <li>
                            ensuring the quality of the products and services Rascal provides to you;
                          </li>
                          <li>
                            collecting information for marketing purposes;communicating with you; and
                          </li>
                          <li>
                            statistical analysis;
                          </li>
                        </ol>
                      </li>
                    <li>
                      you have consented to such use; and/or
                    </li>
                    <li>
                      to establish, exercise or defend Rascal’s legal rights for the purposes of legal proceedings.
                    </li>
                  </ol>
                </li>
                <li className={"custom-list"} >
                  Rascal does not intend to collect any sensitive and special categories of information (this includes
                  information concerning your health, personal information revealing your racial or ethnic origin,
                  political opinions, religious or philosophical beliefs, or data concerning sexual orientation). If
                  you choose to provide such information to Rascal, Rascal will only use that information  where Rascal
                  has asked for your explicit consent or otherwise where this is necessary for the establishment,
                  exercise or defence of legal claims.
                </li>
                <li className={"custom-list"} >
                  In the case that you have obviously made information public (e.g. on social media) Rascal will
                  process sensitive/special categories of information for the purposes of carrying out Rascal’s legal obligations.
                </li>
                <li className={"custom-list"} >
                  If provision of your PII is a legal or contractual requirement or a requirement necessary to enter
                  into a contract with Rascal, and you choose not to provide it, Rascal may not be able to perform
                  some of the tasks Rascal needs to in order to provide certain services to you.
                </li>
                <li className={"custom-list"} >
                  If you do choose to provide your consent you can withdraw it at any time by contacting Rascal
                  through the Website or using the contact details set out at the end of this Privacy Policy.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                19. How long does Rascal keep the information that users have provided to Rascal?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal keeps the information you have given Rascal for as long as your account stays active and for
                  at least 12 months afterwards.
                </li>
                <li className={"custom-list"} >
                  Your PII may be deleted from our systems (other than records kept as required by applicable law or
                  for general backup, archival or record keeping purposes) if your account is inactive for 24 months.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                20. Updating your preferences
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  By accessing and updating your email notification preferences, personal information and public
                  information you have the opportunity to opt-out of certain communications and modify personal
                  information or other information you have provided to Rascal at any time by going to the 'Settings'
                  section on your profile. In some circumstances there may be a period of time before any changes are implemented.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                21. Unsolicited Contact (SPAM)
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal does not send unsolicited email at any time. You may opt-out of receiving email messages at any
                  time. Any abuse or evidence of unsolicited email that appears to have been initiated from Rascal,
                  directly or indirectly, should be brought to the immediate attention of Rascal by contacting us at
                  support@rascalkids.app.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                22. Does Rascal monitor online behaviour?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal’s users can send content to each other through the Website or the App if the Paid User has
                  granted that ability to connected Other User accounts. While Rascal does not, as a rule, moderate or
                  monitor such content, Rascal reserves the right to do so if Rascal suspects users being in breach of
                  Rascal’s Terms & Conditions or Privacy Policy.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                23. Security
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal treats data as an asset that must be protected against loss and unauthorized access. To
                  safeguard the confidentiality and security of your PII, Rascal uses industry standard practices and
                  technologies including but not limited to "firewalls", encrypted transmission via SSL (Secure Socket
                  Layer) and strong data encryption of sensitive personal information when it is stored to disk.
                  However, because the internet is an open network, Rascal cannot warrant or guarantee that your PII
                  is safe from unauthorised access or use. Non-PII and PII may be kept on computer systems operated by
                  third parties. Rascal will take reasonable precautions against unauthorised access and disclosure
                  by those third parties.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                24. Does Rascal monitor online behaviour?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal is a New Zealand-based company and many of Rascal‘s external third parties are based outside
                  the UK and the European Economic Area (<b>EEA</b>). Accordingly, to the extent you are based within the UK or
                  EEA, the processing of your personal information may involve a transfer of information outside of
                  these jurisdictions. Where your information is transferred to any country outside the UK or EEA,
                  Rascal ensures that this is done using specific legally-approved safeguards. You can request further
                  details and a copy of these safeguards by contacting Rascal using the contact details set out at the
                  end of this Privacy Policy.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                25. Does Rascal monitor online behaviour?
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  If you wish to contact Rascal about this Privacy Policy, including requesting access, amendments to,
                  or deletion of your PII and Non-PII, you can contact Rascal at:
                  <br />
                  Email: support@rascalkids.app
                  <br />
                  By Post:
                  <br />
                  David Sowry, Level 1, 3 Blair Street, Te Aro, 6011, Wellington, New Zealand
                </li>
              </ul>

            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PrivacyPolicy;
