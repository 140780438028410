import SetupForm from './SetupForm';
import Form from './Form';
import Preview from './Preview';

const BillingAddresses = {
  SetupForm: SetupForm,
  Form: Form,
  Preview: Preview
};

export default BillingAddresses;