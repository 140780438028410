import EditValueInPlace, {IEditValueInPlaceProps} from 'components/public/modules/value_editor/EditValueInPlace';

export interface IEditableStringProps extends IEditValueInPlaceProps {
  onChange: (value: string) => void;
}

class EditableString extends EditValueInPlace<IEditableStringProps> {
  constructor(props: IEditableStringProps) {
    super(props);
    this.format = 'string';
    this.onChangeCallback = props.onChange;
  }
}

export default EditableString;
