import React, {Component} from 'react';
import {Col, Row} from 'antd';

import {Story} from 'models/story/Story';

import {StoryDetailsEvents} from 'components/public/pages/webgl_app/StoryDetailsEvents';

import './StoryDetailsHeader.scss';
import Chevron from "components/shared/icons/Chevron";

interface IStoryDetailsHeaderProps {
    story: Story;
}

interface IStoryDetailsHeaderState {
}

class StoryDetailsHeader extends Component<IStoryDetailsHeaderProps, IStoryDetailsHeaderState> {
    private onClickExitButton(): void {
        window.dispatchEvent(new Event(StoryDetailsEvents.EXIT_EVENT));
    }

    public render(): React.ReactElement {
        const {story} = this.props;

        return (
            <Row align='middle' justify='center' className='story-view-header'>
                <Col xs={24}>
                    <Col className='story-view--section' xs={24} lg={{span: 20, offset: 2}}>
                        <Row>
                            <Col xs={1}>
                                <a onClick={e => this.onClickExitButton()}>
                                    <Chevron  direction={'left'}  />
                                </a>
                            </Col>
                            <Col xs={22} style={{textAlign: 'center'}}>
                                {story.title()}
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        );
    }
}

export default StoryDetailsHeader;
