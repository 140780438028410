import React from 'react'
import useEmblaCarousel, {EmblaCarouselType, EmblaOptionsType} from 'embla-carousel-react'

import "./EmblaCarousel.scss";

type PropType = {
  options?: EmblaOptionsType;
  onRender?: (emblaRef: EmblaCarouselType) => void;
  children?: React.ReactNode;
}

const EmblaCarousel: React.FC<PropType> = (props) => {
  const {options, children, onRender } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  if (onRender) onRender(emblaApi);

  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {children}
        </div>
      </div>
    </div>
  )
}

export default EmblaCarousel
