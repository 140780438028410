export class EnumParser {
  public static parseEnum<T>(value: string, enumType: T): T[keyof T] | null {
    if (value) {
      for (const property in enumType) {
        if (enumType.hasOwnProperty(property)) {
          const enumMember: T[keyof T] | null = enumType[property];
          if (typeof enumMember === 'string' && enumMember.toUpperCase() === value.toUpperCase()) {
            const key = enumMember as string as keyof typeof enumType;
            return enumMember;
          }
        }
      }
    }

    return null;
  }
}