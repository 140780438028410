import {AbstractStore} from "models/AbstractStore";
import {RootStore} from "models/RootStore";
import Coupon from "models/coupon/Coupon";
import {ICouponApiData} from "models/coupon/ICouponApiData";
import CouponApi from "models/coupon/CouponApi";
import {ICouponUpdateApiData} from "models/coupon/ICouponUpdateApiData";

export class CouponProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'CouponProvider');

        this.apiDataToModel = this.apiDataToModel.bind(this);
        this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
    }

    public getAll(): Promise<Coupon[]> {
        return CouponApi.getAll()
            .then(this.apiDataArrayToModelArray);
    }

    public get(id: number): Promise<Coupon> {
        return CouponApi.get(id)
            .then(this.apiDataToModel);
    }

    public update(id: number, data: ICouponUpdateApiData): Promise<ICouponApiData> {
        return CouponApi.update(id, data);
    }

    private apiDataToModel(coupon: ICouponApiData): Coupon {
        if (coupon == null) return null;
        return new Coupon().withData(coupon);
    }

    public apiDataArrayToModelArray(apiCoupon?: ICouponApiData[]): Coupon[] {
        return apiCoupon ? apiCoupon.map(this.apiDataToModel) : [];
    }
}

export default CouponProvider;
