import React from 'react';

class AppStoreRedirect extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    public componentDidMount() : void{
        window.location.replace('https://apps.apple.com/us/app/rascal/id1612015226');
    }

    public render(): React.ReactElement {
        return <React.Fragment/>
    }
}

export default AppStoreRedirect;
