import Api from 'models/Api';
import {ISessionSignInRequestApiData, ISessionSignInResponseApiData} from 'models/session/ISessionApiData';
import Cookies from "universal-cookie";
import SessionCookieStore from "models/session/SessionCookieStore";

export class SessionApi {
  public static hasValidCredentials(): Promise<boolean> {
    return Api.get('/api/v1/users/valid_session')
      .then(response => {
        return response;
      })
      .then(response => response.json())
      .then(json => json.valid);
  }

  public static hasValidAdminCredentials(): Promise<boolean> {
    return Api.get('/api/v1/users/valid_admin_session')
      .then(response => response.json())
      .then(json => json.valid);
  }

  public static signIn(body: ISessionSignInRequestApiData): Promise<ISessionSignInResponseApiData> {
    return Api.post('/api/v1/users/sign_in', {
        body: body
      })
      .then(response => response.json())
  }

  public static signOut(): Promise<any> {
    return Api.post('/api/v1/users/sign_out', {}, 'json', false)
      .then(response => response.json())
      .then((_json) => {
        //SessionCookieStore.remove();
      });
  }
}
