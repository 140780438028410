import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {ISubscriptionApiData, ISubscriptionUpdateApiData} from 'models/subscription/ISubscriptionApiData';
import {IUserApiData} from 'models/user/IUserApiData';
import {ApiResponseData} from 'models/ApiResponseData';
import {ISubscriptionSearchApiData, SubscriptionSearchData} from 'models/subscription/ISubscriptionSearchApiData';
import {ISubscriptionApiSearchParams, SubscriptionApi} from 'models/subscription/SubscriptionApi';
import {Subscription} from 'models/subscription/Subscription';

export class SubscriptionProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'SubscriptionProvider');

        this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public get(id: number): Promise<ISubscriptionApiData> {
        return SubscriptionApi.get(id);
    }

    public getAll() {
        return SubscriptionApi.getAll();
    }

    public hasValidSubscription(user_id: number = this.SessionProvider.userId()): Promise<boolean> {
        return SubscriptionApi.hasValidSubscription(user_id);
    }

    public userSubscriptions(user_id: number = this.SessionProvider.userId()): Promise<ISubscriptionApiData[]> {
        return SubscriptionApi.userSubscriptions(user_id);
    }

    public createSubscription(body: {
        user_id: number,
        subscription_plan_id: number,
        subscription_plan_key?: string,
        billing_address_id?: number,
        stripe_setup_intent?: string,
        credit_card_id?: number,
        coupon_id?: number
    }): Promise<any> {
        return SubscriptionApi.createSubscription(body);
    }

    public getSubscribers(subscription_id: number): Promise<IUserApiData[]> {
        return SubscriptionApi.getSubscribers(subscription_id);
    }

    public cancelSubscription(subscription_id: number): Promise<ApiResponseData> {
        return SubscriptionApi.cancelSubscription(subscription_id);
    }

    public resumeSubscription(subscription_id: number): Promise<ApiResponseData> {
        return SubscriptionApi.resumeSubscription(subscription_id);
    }

    public promoteToManager(subscription_id: number, body: { user_id: number }): Promise<ApiResponseData> {
        return SubscriptionApi.promoteToManager(subscription_id, body);
    }

    public addSubscribersToSubscription(subscription_id: number, body: {
        subscriber_ids: string[]
    }): Promise<ApiResponseData> {
        return SubscriptionApi.addSubscribersToSubscription(subscription_id, body);
    }

    public leaveSubscription(subscription_id: number): Promise<ApiResponseData> {
        return SubscriptionApi.leaveSubscription(subscription_id);
    }

    public kickSubscriber(subscription_id: number, body: { subscriber_ids: number[] }): Promise<ApiResponseData> {
        return SubscriptionApi.kickSubscriber(subscription_id, body);
    }

    public countSubscriptions(searchParams?: { subscription_plan_ids?: number[] }): Promise<number[]> {
        return SubscriptionApi.countSubscriptions(searchParams);
    }

    public getSubscriptionSearch(searchParams?: ISubscriptionApiSearchParams): Promise<SubscriptionSearchData> {
        return SubscriptionApi.getSubscriptionSearch(searchParams)
            .then((data: ISubscriptionSearchApiData) => ({
                page: data.page,
                page_size: data.page_size,
                total: data.total,
                subscriptions: this.apiDataArrayToModelArray(data.subscriptions)
            }));
    }

    public getSubscriptions(subscription_ids: number[]) {
        return SubscriptionApi.getSubscriptions(subscription_ids);
    }

    public fetchAll() {
        return SubscriptionApi.getAll().then(this.apiDataArrayToModelArray);
    }

    public fetchAllForUser(user_id: number = this.SessionProvider.userId()): Promise<Subscription[]> {
        if (user_id == null) return this.fetchAll();
        return SubscriptionApi.userSubscriptions(user_id).then(this.apiDataArrayToModelArray);
    }

    public update(id: number, params: ISubscriptionUpdateApiData): Promise<ISubscriptionApiData> {
        return SubscriptionApi.update(id, params);
    }

    public apiDataArrayToModelArray(apiSubscriptions?: ISubscriptionApiData[]): Subscription[] {
        return apiSubscriptions ? apiSubscriptions.map(this.apiDataToModel) : [];
    }

    private apiDataToModel(data: ISubscriptionApiData): Subscription {
        return new Subscription(this).withData(data);
    }
}
