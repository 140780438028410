import {Button, DatePicker, Input, Space} from "antd";
import React, {ReactNode} from "react";
import {
  ColumnType,
  FilterConfirmProps,
  FilterDropdownProps,
  FilterValue,
  SorterResult,
  SortOrder,
  TablePaginationConfig
} from "antd/lib/table/interface";
import {
  CalendarOutlined,
  CalendarTwoTone,
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
  FilterTwoTone,
  SearchOutlined,
  UndoOutlined
} from "@ant-design/icons";
import {Colors} from "stylesheets/globals/Colors";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import Value from "helpers/Value";
import TernarySlider from "components/shared/TernarySlider";
import {IUserApiSearchFilterParams, IUserApiSearchOrdering} from "models/user/UserApi";
import {Hash} from "types/hash";
import {Link} from "react-router-dom";
import Format from "helpers/Format";

interface ISearchDropdownProps<T> extends FilterDropdownProps {
  dataIndex: keyof T;
}

export interface IColumn<T> {
  columnType: Partial<ColumnType<T>>;
  filter?: (filteredInfo: any) => (null | {});
}

export interface IColumnProps<T> {
  key: keyof T,
  type: 'search' | 'date' | 'boolean' | 'text' | 'length' | 'custom',
  getHref?: (object: T) => string,
  getValue?: (object: T) => string,
  render?: (object: T) => ReactNode,
  filter?: 'boolean'
}

export interface ITableProps<T> {
  columns: IColumnProps<T>[];
  data?: T[] | any;
  getTableSearch: Function;
  selectable?: boolean;
}

export interface ITableState<T> {
  filteredInfo: any;
  sortedInfo?: { column: IUserApiSearchOrdering, order: SortOrder };
  searchInfo: Hash<React.Key>;
  selectedRows: any[];
  loading: {
    generate: boolean;
    activate: boolean;
    deactivate: boolean;
  }
  currentPage: number;
  pageSize: number;
  total: number;
  columns: IColumn<T>[];
  data: T[] | any;
}

class Tables {
  private static readonly DATE_DIVIDER: string = '&';
  private static searchInput: any;

  static initialState<T>(data?: T[]): ITableState<T> {
    return {
      filteredInfo: {},
      columns: [],
      searchInfo: {},
      selectedRows: [],
      loading: {
        generate: false,
        activate: false,
        deactivate: false
      },
      currentPage: 1,
      total: 0,
      pageSize: 20,
      data: []
    }
  }

  static getSearchFilters<T>(state: Partial<ITableState<T>>): IUserApiSearchFilterParams {
    const {filteredInfo, sortedInfo, columns} = state;
    const filters: IUserApiSearchFilterParams = {};

    if (filteredInfo) {
      columns.map((column) => {
        _.assign(filters, column.filter(filteredInfo));
      });
    }

    if (sortedInfo) {
      filters.order_by = sortedInfo.column
      filters.order = sortedInfo.order
    }

    return filters;
  }

  static clearSelection<T>(): Partial<ITableState<T>> {
    return {
      selectedRows: [],
      loading: {
        generate: false,
        activate: false,
        deactivate: false
      }
    };
  }

  //region onChange Handlers
  static handleSearch<T>(
    props: ISearchDropdownProps<T>, state, filterConfirmProps?: FilterConfirmProps): Partial<ITableState<T>> {
    let {searchInfo} = state;
    searchInfo[props.dataIndex] = props.selectedKeys[0];

    props.confirm(filterConfirmProps);
    return {searchInfo: searchInfo};
  }

  static handleChangeSearchText<T>(
    props: ISearchDropdownProps<T>, state: any,
    event: React.ChangeEvent<HTMLInputElement>): Partial<ITableState<T>> {
    let {searchInfo} = state;
    let searchText: string = '';
    let selectedKeys: React.Key[] = [];

    if (event.target.value) {
      searchText = event.target.value;
      searchInfo[props.dataIndex] = searchText;
      selectedKeys.push(searchText);
    }

    props.setSelectedKeys(selectedKeys);
    return {searchInfo: searchInfo};
  }

  static handleClearSearch<T>(props: ISearchDropdownProps<T>, state): Partial<ITableState<T>> {
    let {searchInfo} = state;
    delete (searchInfo[props.dataIndex]);

    props.clearFilters();
    props.confirm();
    return {searchInfo: searchInfo};
  }

  static handleOnChange<T>(page: number, size: number, resetPage: boolean): Partial<ITableState<T>> {
    if (resetPage) {
      page = 1;
    }
    return {
      pageSize: size,
      currentPage: page
    };
  }

  static handleChange<T>(
    pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T>): any {
    let sortedInfo = null;
    if (sorter.order != null) {
      sortedInfo = {
        column: sorter.columnKey,
        order: sorter.order
      }
    }

    let filteredInfo = {};
    if (filters) {
      _.keys(filters).forEach((key) => {
        filteredInfo[key] = Value.stripArrayIfSingle(filters[key]);
      });
    }

    return {
      filteredInfo: filteredInfo,
      sortedInfo: sortedInfo,
      currentPage: pagination.current,
      pageSize: pagination.pageSize
    }
  }

  //endregion

  static pagination(
    currentPage: number, pageSize: number, total: number, handleOnChange: Function): TablePaginationConfig {
    return {
      current: currentPage,
      pageSize,
      pageSizeOptions: ['5', '10', '20', '50', '100'],
      onChange: (page, pageSize) => handleOnChange(page, pageSize, false),
      onShowSizeChange: (current, size) => handleOnChange(current, size, true),
      showSizeChanger: true,
      total: total
    }
  }

  static renderSearch<T>(text: any, object: T, column: IColumnProps<T>, searchInfo: Hash<React.Key>) {
    const dataIndex: keyof T = column.key;
    const {getHref} = column;
    if (!getHref) {
      return searchInfo[String(dataIndex)] ? Tables.renderHighlighted(text, searchInfo[dataIndex] as string)
        : text
    }
    return (
      <Link to={getHref(object)}>
        {searchInfo[String(dataIndex)] ? Tables.renderHighlighted(text, searchInfo[dataIndex] as string)
          : text}
      </Link>
    );
  }

  static renderDate<T>(date: Date, object: T, getHref?: (object: T) => string): React.ReactElement {
    if (!date) {
      return (
        <span>-</span>
      );
    }

    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    if (!getHref) {
      return (
        <span>
                    {Format.longDate(date)}
                </span>
      );
    }

    return (
      <Link to={getHref(object)}>
        {Format.longDate(date)}
      </Link>
    );
  }

  static renderBoolean<T>(bool: boolean, object: T, getHref?: (object: T) => string): React.ReactElement {
    if (!getHref) {
      return bool ? <CheckOutlined/> : <CloseOutlined/>;
    }

    return (
      <Link to={getHref(object)}>
        {bool ? <CheckOutlined/> : <CloseOutlined/>}
      </Link>
    );
  }

  static renderHighlighted(text: string, searchText: string): React.ReactElement {
    return (
      <Highlighter textToHighlight={text.toString()}
                   searchWords={[searchText]}
                   highlightStyle={{backgroundColor: Colors.ORANGE, padding: 0}}
                   autoEscape/>
    );
  }

  static renderBooleanFilterBox<T>(props: ISearchDropdownProps<T>, clear: Function, search: Function): React.ReactElement {
    return (
      <Space style={{padding: 8}}>
        <TernarySlider handleStateChange={(state: boolean | null) => {
          if (state === null) {
            clear(props);
          } else {
            props.setSelectedKeys([state.toString()]);
            search(props);
          }
        }}/>
      </Space>
    );
  }

  static renderDateFilterBox<T>(props: ISearchDropdownProps<T>, clear: Function, search: Function): React.ReactElement {
    return (
      <Space style={{padding: 8}}>
        <DatePicker.RangePicker
          allowEmpty={[true, true]}
          onChange={(_moments: [any, any], [start, end]: [string, string]) => {
            if (start.length === 0 && end.length === 0) {
              clear(props);
            } else {
              props.setSelectedKeys([`${start}${Tables.DATE_DIVIDER}${end}`]);
              search(props, {closeDropdown: start.length > 0 && end.length > 0})
            }
          }}/>
      </Space>
    );
  }

  static renderSearchBox<T>(
    props: ISearchDropdownProps<T>, changeSearch: Function, search: Function, clear: Function): React.ReactElement {
    return (
      <React.Fragment>
        <Space direction='vertical' style={{padding: 8}}>
          <Space>
            <Input ref={node => this.searchInput = node}
                   placeholder={`Search ${String(props.dataIndex)}`}
                   value={props.selectedKeys[0]}
                   onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeSearch(props, event)}
                   onPressEnter={() => search(props)}
                   style={{width: 298}}/>
          </Space>
          <Space>
            <Button className='rk-btn'
                    size='small'
                    icon={<SearchOutlined/>}
                    onClick={() => search(props)}>
              Search
            </Button>
            <Button className='rk-btn-secondary'
                    size='small'
                    icon={<UndoOutlined/>}
                    onClick={() => clear(props)}>
              Clear
            </Button>
          </Space>
        </Space>
      </React.Fragment>
    );
  }


  // Columns Type
  public static SearchColumnType<T>(): Partial<ColumnType<T>> {
    return {
      filterIcon: (filtered: boolean) => <SearchOutlined style={{color: filtered && Colors.ORANGE}}/>,
      onFilterDropdownOpenChange: (visible: boolean) => visible ? setTimeout(
        () => this.searchInput.select(),
        100
      ) : void 0,
      sorter: true,
    }
  }

  public static filterSearchColumnType<K>(dataIndex: K) {
    return (filtered: any) => {
      let obj = null;
      if (filtered.hasOwnProperty(dataIndex)) {
        obj = {};
        obj[dataIndex] = filtered[dataIndex];
      }
      return obj;
    };
  }

  public static filterBooleanColumnType<K>(dataIndex: K) {
    return this.filterSearchColumnType<K>(dataIndex)
  }

  public static BooleanColumnType<T>(): Partial<ColumnType<T>> {
    return {
      filterIcon: (filtered: boolean) => filtered ? <FilterTwoTone twoToneColor={Colors.ORANGE}/> :
        <FilterOutlined/>,
      sorter: true,
    }
  }

  public static DateColumnType<T>(): Partial<ColumnType<T>> {
    return {
      filterIcon: (filtered: boolean) => filtered ?
        <CalendarTwoTone twoToneColor={Colors.ORANGE}/> : <CalendarOutlined/>,
      defaultSortOrder: 'descend',
      sorter: true,
    }
  }

  public static filterDateColumnType<K>(dataIndex: K) {
    return (filtered: any) => {
      let obj = null;
      if (filtered.hasOwnProperty(dataIndex) && filtered[dataIndex] != null) {
        obj = {};
        const value = filtered[dataIndex];
        const filterDates: string[] = value.toString().split(Tables.DATE_DIVIDER);
        if (filterDates.length !== 2) {
          return null;
        }
        obj[dataIndex] = filterDates.join(',');
      }
      return obj;
    };
  }

  public static TextColumnType<T>(): Partial<ColumnType<T>> {
    return {
      render: text => text,
    }
  }

  public static filterTextColumnType() {
    return (filtered: any) => {
      return null;
    };
  }

  public static LengthColumnType<T>(): Partial<ColumnType<T>> {
    return {
      render: text => text.length,
    }
  }

  public static filterLengthColumnType() {
    return (filtered: any) => {
      return null;
    };
  }
}

export default Tables;
