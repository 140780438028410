import {IEditValueInPlaceProps} from "components/public/modules/value_editor/EditValueInPlace";
import React, {Component} from "react";
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './EditableRichText.scss';

export interface IEditableRichTextProps extends IEditValueInPlaceProps {
  onChange: (value: string) => void;
}

interface IEditableRichTextState {
  editing: boolean;
}

class EditableRichText extends Component<IEditableRichTextProps, IEditableRichTextState>{

  private className: string;
  private onChangeCallback: (value: string) => void;
  private value: string;
  private notifyOnChange: NodeJS.Timeout = null;

  constructor(props: IEditableRichTextProps) {
    super(props);

    this.sanitizeParagraph = this.sanitizeParagraph.bind(this);
    this.className = 'rk-edit-rich-text-in-place'
    this.onChangeCallback = this.sanitizeParagraph;
    this.value = this.props.value?.toString() || null;

    this.state = {
      editing: false
    }
  }

  public sanitizeParagraph(value: string) {
    this.value = value;
    if (this.props.onChange) {
      clearTimeout(this.notifyOnChange);
      this.notifyOnChange = setTimeout(() => {
        this.props.onChange(value);
      }, 500);
    }
  }

  renderEditor(): React.ReactElement {
    return (
      <div className={`${this.className} editing`}>
        <ReactQuill theme="snow" value={this.value} onChange={this.onChangeCallback} />
        <div>
          <button onClick={() => this.setState({editing: false})}>Close</button>
        </div>
      </div>
    )
  }

  renderDisplay(): React.ReactElement {
    return (
      <div className={`${this.className} displaying`}>
        <div dangerouslySetInnerHTML={{__html: this.value}}></div>
        <div>
          <button onClick={() => this.setState({editing: true})}>Edit</button>
        </div>
      </div>
    )
  }

  render(): React.ReactElement {
    const {editing} = this.state;
    if (editing) {
      return this.renderEditor();
    } else {
      return this.renderDisplay();
    }
  }
}

export default EditableRichText;
