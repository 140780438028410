import {IHomepageApiData} from 'models/homepage/IHomepageApiData';
import {action, makeObservable, observable} from 'mobx';
import {Story} from 'models/story/Story';
import {StoryProvider} from 'models/story/StoryProvider';
import Value from 'helpers/Value';
import {HomepageApi} from 'models/homepage/HomepageApi';
import {IStoryApiData} from 'models/story/IStoryApiData';
import {IHomepageUpdateApiData} from 'models/homepage/IHomepageUpdateApiData';

export class Homepage {

    @observable provider: StoryProvider;

    @observable landscape_banner_image_url: string;
    @observable portrait_banner_image_url: string;
    @observable android_featured_image_url: string;
    @observable ios_featured_image_url: string;
    @observable multi_panel_story: Story;
    @observable featured_story: Story;

    constructor(provider: StoryProvider) {
        this.provider = provider;
        makeObservable(this);
        this.withData = this.withData.bind(this);
    }

    public withData(data: IHomepageApiData) {
        this.load(data);
        return this;
    }

    public fetchData(): Promise<Homepage> {
        return fetch('/api/v1/homepage')
            .then(response => response.json())
            .then(data => this.withData(data));
    }

    @action.bound
    private load(data: IHomepageApiData) {
        this.portrait_banner_image_url = Value.ifDefined<string>(this.portrait_banner_image_url, data.portrait_banner_image_url);
        this.landscape_banner_image_url = Value.ifDefined<string>(this.landscape_banner_image_url, data.landscape_banner_image_url);
        this.android_featured_image_url = Value.ifDefined<string>(this.android_featured_image_url, data.android_featured_image_url);
        this.ios_featured_image_url = Value.ifDefined<string>(this.ios_featured_image_url, data.ios_featured_image_url);
        this.multi_panel_story = Value.ifDefinedCallback<IStoryApiData, Story>(data.multi_panel_story, () => {
            return new Story(this.provider).withData(data.multi_panel_story)
        });
        this.featured_story = Value.ifDefinedCallback<IStoryApiData, Story>(data.featured_story, () => {
            return new Story(this.provider).withData(data.featured_story)
        });
    }

    public update(data: IHomepageUpdateApiData): Promise<Homepage> {
        return HomepageApi.update(data).then(this.withData);
    }
}

export default Homepage;
