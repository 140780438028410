// 1. Steps users take to request data deletion
// 2. Types of data that are deleted or kept including any additional retention period.
//
//
// Manage Account Data
// Personal Data
// First name, Last name, Billing Address,
//
// Usage Data
//    Start Time, End Time, Duration, Platform (Android, iOS, Web)
//
import React from "react";
import {makeObservable, observable} from "mobx";
import AppInfo from "models/AppInfo";
import {User} from "models/user/User";
import {ComponentWithStore} from "models/RootStore";
import DefaultLayout from "layouts/DefaultLayout";
import {Col, Row} from "antd";

interface IManageDataProps {
    routeParams: { userId: string };
}

class ManageData extends ComponentWithStore<IManageDataProps, any> {

    @observable private user: User = new User(this.store.UserProvider);


    constructor(props: IManageDataProps) {
        super(props)

        makeObservable(this);

        let userId: number | null;
        let admin: boolean = AppInfo.isAdmin();
        let idParam: string | null = this.props.routeParams.userId;
        if (idParam) {
            userId = Number(idParam);
        } else {
            userId = this.store.SessionProvider.userId();
        }

        this.state = {
            userId: userId,
            admin: admin
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <DefaultLayout>
                <Row>
                    <Col  xs={24} sm={{ span:22, offset:1 }} md={{ span:17, offset: 4 }} lg={{ span:18, offset: 3 }} xl={{ span:16, offset: 4 }} xxl={{ span:12, offset: 6 }} >
                        <h2>Manage Account Data</h2>
                        <p>
                            We collect data to understand the content that is enjoyed and platforms people use.
                            We are building out a way for you to see your data, so we can provide insights into how your family is interacting with our content.
                        </p>
                        <p>
                            The current data we collect is around user sessions, and what device was used.
                        </p>
                        <p>
                            As we build out a stunning new dashboard... <br />
                            If you wish to see your data you can send us an email at <a href={"mailto:support@rascalkids.app"}>support@rascalkids.app</a> and we will answer any further requests.
                        </p>
                        <p>
                            You can delete your account completely by going to your <a href={'/account'}>account page</a> and entering your password after selecting 'delete my account'.
                            This will permanently close your account and delete all identifiable information except for details we are legally obligated to maintain.
                        </p>
                    </Col>
                </Row>
            </DefaultLayout>
        )
    }

    private deleteMyUsageData() {
        // Tell them that we are deleting set information.
        // We will not remove their payment information
        // Get them to write in that they want to delete it.
    }
}

export default ManageData;
