import _ from 'lodash';
import {action, makeObservable, observable} from 'mobx';

import Value from 'helpers/Value';

import {Story} from 'models/story/Story';
import {CollectionProvider} from 'models/collection/CollectionProvider';
import {ICollectionApiResponseData, IStoryCollectionApiResponseData} from 'models/collection/ICollectionApiData';

export class StoryCollection {
    private readonly provider: CollectionProvider;

    @observable public order: number;
    @observable public story_id: number;

    constructor(provider: CollectionProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withData = this.withData.bind(this);
    }

    public withData(data: IStoryCollectionApiResponseData): StoryCollection {
        this.load(data);
        return this;
    }

    @action.bound
    public load(data: IStoryCollectionApiResponseData): void {
        this.order = data.order;
        this.story_id = data.story_id;
    }
}

export class Collection {
    private readonly provider: CollectionProvider;

    @observable public id: number;

    @observable public name: string;
    @observable public story_collections: StoryCollection[];

    @observable public published_at: Date;

    constructor(provider: CollectionProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withData(data: ICollectionApiResponseData) {
        this.load(data);
        return this;
    }

    @action.bound
    public load(data: ICollectionApiResponseData) {
        this.id = data.id;
        this.name = data.name;
        this.story_collections = _.orderBy(_.map(data.story_collections, sc => new StoryCollection(this.provider).withData(sc)), value => value.order);
        this.published_at = Value.dateOrNull(data.published_at);
    }

    @action.bound
    public setValue(field: string, value: any) {
        this[field] = value;
    }

    public static getStories(stories: Story[], story_collections: StoryCollection[]): Story[] {
        return _.map(_.orderBy(story_collections, value => value.order), value => _.find(stories, s => s.id === value.story_id));
    }
}
