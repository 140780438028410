import React from 'react';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Col, Row} from 'antd';

import {ComponentWithStore, withStore} from 'models/RootStore';
import {User} from 'models/user/User';

import InvoicesTable from 'components/public/pages/invoices/InvoicesTable';
import DefaultLayout from 'layouts/DefaultLayout';

import './InvoiceIndex.scss';

interface IInvoiceProps {
}

interface IInvoiceState {
    userId: number | null;
}

class InvoiceIndex extends ComponentWithStore<IInvoiceProps, IInvoiceState> {
    @observable private user: User = new User(this.store.UserProvider);

    public constructor(props: IInvoiceProps) {
        super(props);

        makeObservable(this);

        let userId: number | null = this.store.SessionProvider.userId();

        this.state = {
            userId: userId
        };
    }


    public componentDidMount(): void {
        const {userId} = this.state;

        this.store.UserProvider.user(userId)
            .then(user => this.user = new User(this.store.UserProvider).withData(user))
    }

    private renderHeader(): React.ReactElement {
        return (
            <Row className='rk-title'>
                <Col xs={12}>
                    <div>
                        <h1>Invoices</h1>
                    </div>
                </Col>
            </Row>
        );
    }

    public render(): React.ReactElement {
        const {userId} = this.state;

        return (
            <DefaultLayout>
                <div className='rk-invoices'>
                    {this.renderHeader()}
                    <InvoicesTable userId={userId}/>
                </div>
            </DefaultLayout>
        );
    }
}

export default withStore(observer(InvoiceIndex));
