import _ from 'lodash';
import {action, makeObservable, observable} from 'mobx';

import {
    IAssetBundle,
    IAssetBundleManifest,
    ISceneBundle,
    IStoryBundle,
    JsonStoryBundle
} from 'models/storyRelease/IAssetBundleManifest';
import {IAssetBundleInfo, IYamlManifest} from 'models/storyRelease/Manifest';

export enum ProgressState {
    ERROR = -1,
    NOT_STARTED = 0,
    IN_PROGRESS = 1,
    COMPLETED = 2,
    PROGRESS_LOST = 3,
}

export class AssetBundle {
    @observable public release_id: number;
    @observable public name: string;
    @observable public index: number;
    @observable public is_scene: boolean;
    @observable public url: string;
    @observable public dependencies: string[];

    @observable public progress_state: ProgressState = ProgressState.NOT_STARTED;

    public get DataPath(): string {
        return `${this.url.split('/').at(-1)}_asset_data`
    }

    public get PointerPath(): string {
        return `${this.url.split('/').at(-1)}_asset_pointer`
    }

    constructor() {
    }

    private withData(data: IAssetBundle): AssetBundle {
        this.load(data);
        return this;
    }

    @action.bound
    private load(data: IAssetBundle): void {
        this.name = data.name;
        let index: number = parseInt(data.name.replace(/\D/g, ''));
        this.index = isNaN(index) ? 0 : index;
        this.is_scene = !data.name.includes('assets');
        this.url = data.url;
        this.dependencies = data.dependencies;
    }

    public withYamlData(yaml: IAssetBundleInfo): AssetBundle {
        this.loadFromYaml(yaml);
        return this;
    }

    @action.bound
    public loadFromYaml(yaml: IAssetBundleInfo): void {
        console.log(yaml);
        this.name = yaml.Name;
        this.dependencies = _.values(yaml.Dependencies);
    }

    public isDownloadComplete(): boolean {
        return this.progress_state == ProgressState.COMPLETED; //TODO: && fileExists()
    }

    public static LoadAllFromArray(array: IAssetBundle[]): AssetBundle[] {
        return !array ? [] : array.map(bundle => new AssetBundle().withData(bundle));
    }

    public static LoadAllFromYamlObject(obj: IYamlManifest): AssetBundle[] {
        let bundleInfos: Record<string, IAssetBundleInfo> = obj.AssetBundleManifest.AssetBundleInfos;
        return _.map(_.values(bundleInfos), (value: IAssetBundleInfo) => new AssetBundle().withYamlData(value));
    }
}

export class AssetBundleManifest {
    @observable public asset_bundles: AssetBundle[];


    constructor() {
        makeObservable(this);

        this.withData = this.withData.bind(this);
    }

    public withData(data: IAssetBundleManifest): AssetBundleManifest {
        this.load(data);
        return this;
    }

    @action.bound
    public load(data: IAssetBundleManifest): void {
        if (data) this.asset_bundles = AssetBundle.LoadAllFromArray(data.asset_bundles);
    }

    public withYamlData(yaml: IYamlManifest): AssetBundleManifest {
        this.loadFromYaml(yaml);
        return this;
    }

    @action.bound
    public loadFromYaml(yaml: IYamlManifest): void {
        this.asset_bundles = AssetBundle.LoadAllFromYamlObject(yaml)
    }

    public setReleaseId(id: number): void {
        _.map(this.asset_bundles, bundle => bundle.release_id = id);
    }

    public getBundleByName(name: string): AssetBundle | undefined {
        return _.find(this.asset_bundles, bundle => bundle.name === name);
    }

    public getStoryBundle(): IStoryBundle {
        let orderedScenes: AssetBundle[] = _.orderBy(_.filter(this.asset_bundles, bundle => bundle.is_scene), bundle => bundle.index);

        let orderedBundles: IStoryBundle = {
            scene_bundles: [],
            current_scene_bundle: ''
        };

        _.map(orderedScenes, scene => {

            let sceneBundle: ISceneBundle = {name: scene.name, asset_bundles: []};
            _.map(scene.dependencies, dependencyName => {
                let bundleByName = this.getBundleByName(dependencyName);
                if (bundleByName) sceneBundle.asset_bundles.push(bundleByName);
            });
            sceneBundle.asset_bundles.push(scene);

            orderedBundles.scene_bundles.push(sceneBundle);
        });

        return orderedBundles;
    }

    public static toJsonStoryBundle(storyBundle: IStoryBundle): JsonStoryBundle {
        return {
            scene_bundles: storyBundle.scene_bundles.map(scene => ({
                name: scene.name,
                asset_bundles: scene.asset_bundles.map(bundle => bundle.url)
            })),
            current_scene_bundle: storyBundle.current_scene_bundle
        };
    }
}
