import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Checkbox, Col, Form, Input, message, Row } from 'antd';

import { ComponentWithStore, withStore } from 'models/RootStore';
import { ApiResponseData } from 'models/ApiResponseData';

import DefaultLayout from 'layouts/DefaultLayout';
import './styles.scss';
import {MessageInstance} from "antd/lib/message/interface";
import MessageApi from "components/hooks/MessageApi";

class SignIn extends ComponentWithStore<any, any> {
  private messageApi: MessageInstance = window.messageApi;
  public constructor(props: any) {
    super(props);

    this.onFinish = this.onFinish.bind(this);
    this.onFinishFailed = this.onFinishFailed.bind(this);
  }

  public componentDidMount(): void {
    this.store.SessionProvider.hasValidCredentials()
        .then(result => {
          if ( result ) {
            this.redirect();
          }
        });
  }

  public onFinish(values): void {
    this.store.SessionProvider.signIn(JSON.stringify(values))
        .then((result: ApiResponseData) => {
          if ( result.success ) {
            MessageApi().success(result.message);
            this.redirect();
          } else {
            MessageApi().error(result.message);
          }
        }).catch((result) => {
      console.log(result);
      if ( result.status === 401 ) {
        MessageApi().error("Incorrect email or password.");
      }
    });
  }

  public onFinishFailed(errorInfo): void {
    console.log('Failed:', errorInfo);
    // if (errorInfo) {
    //   messageApi.error(errorInfo.message);
    // }
  };

  public render(): React.ReactElement {
    return (
      <DefaultLayout>
        <div className='rk-signup-content'>
          <Form onFinish={ this.onFinish } onFinishFailed={ this.onFinishFailed }>
            <Row justify='space-around' align='middle'>
              <Col xs={ 24 }>
                <div className={ "rk-form-container" }>
                  <div>
                    <h1>Login</h1>
                  </div>
                  <div>
                    <Form.Item
                      name='email'
                      rules={ [{ required: true, message: 'Please input your email' }] }
                    >
                      <Input className='rk-input' placeholder='Email' type='email'/>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      name='password'
                      rules={ [{ required: true, message: 'Please input your password' }] }
                    >
                      <Input className='rk-input' placeholder='Password' type='password'/>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item name='remember_me'>
                      <label>
                        <Checkbox>Remember me</Checkbox>
                      </label>
                    </Form.Item>
                  </div>
                  <div className={ "submit-form-container" }>
                    <button className='rk-btn rk-btn-dark rounded submit-form' type='submit'>Sign
                      In
                    </button>
                  </div>
                  <div className='rk-redirect-link'>
                    <p className='rk-help-link'><Link to='/forgot_password'>Forgot Password</Link>?
                    </p>
                    <p>New to Rascal? <strong><Link className={ "orange-underline solid" }
                                                    to='/register'>Sign up now</Link>.</strong></p>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </DefaultLayout>
    );
  }

  private redirect(): void {
    this.store.SubscriptionProvider.hasValidSubscription().then(result => {
      window.location.href = result ? '/home' : '/register';
    });
  }
}

export default observer(SignIn);
