import Api from 'models/Api';
import {IHomepageApiData} from 'models/homepage/IHomepageApiData';
import {IHomepageUpdateApiData} from 'models/homepage/IHomepageUpdateApiData';

export class HomepageApi {
    public static get(): Promise<IHomepageApiData> {
        return Api.get(`/api/v1/homepage`)
            .then(response => response.json());
    }

    public static update(updateData: IHomepageUpdateApiData): Promise<IHomepageApiData> {
        return Api.post(`/api/v1/homepage`, {
            body: JSON.stringify(updateData)
          }).then(response => response.json());
    }
}
