import Api from 'models/Api';
import {BillingAddress} from 'models/billingAddress/BillingAddress';
import {IBillingAddressApiData} from 'models/billingAddress/IBillingAddressApiData';

export class BillingAddressApi {

    public static get(id: number): Promise<IBillingAddressApiData> {
        return Api.get(`/api/v1/billing_addresses/${id}`)
            .then(response => response.json())
            .then(json => json.billing_address);
    }

    public static create(billingAddress: BillingAddress): Promise<IBillingAddressApiData> {
        return Api.post('/api/v1/billing_addresses', {
            body: JSON.stringify({
                billing_address: {
                    user_id: billingAddress.user_id,
                    name: billingAddress.name,
                    line1: billingAddress.line1,
                    line2: billingAddress.line2,
                    state: billingAddress.state,
                    zip: billingAddress.zip,
                    city: billingAddress.city,
                    country: billingAddress.country
                }
            })
        }).then(response => response.json())
    }

    public static update(billingAddress: BillingAddress): Promise<IBillingAddressApiData> {
        return Api.put(`/api/v1/billing_addresses/${billingAddress.id}`, {
            body: JSON.stringify({
                billing_address: {
                    user_id: billingAddress.user_id,
                    name: billingAddress.name,
                    line1: billingAddress.line1,
                    line2: billingAddress.line2,
                    state: billingAddress.state,
                    zip: billingAddress.zip,
                    city: billingAddress.city,
                    country: billingAddress.country
                }
            })
        }).then(response => response.json()).then(json => json.billing_address);
    }
}
