import Api from 'models/Api';
import {
    IUniverseApiCreateRequest,
    IUniverseApiResponseData,
    IUniverseApiUpdateRequest
} from 'models/universe/IUniverseApiData';

export class UniverseApi {
    public static create(data: IUniverseApiCreateRequest): Promise<IUniverseApiResponseData> {
        return Api.post('/api/v1/universes', {
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => json.universe);
    }

    public static update(id: number, data: IUniverseApiUpdateRequest): Promise<IUniverseApiResponseData> {
        return Api.put(`/api/v1/universes/${id}`, {
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => json.universe);
    }

    public static getAll(): Promise<IUniverseApiResponseData[]> {
        return Api.get(`/api/v1/universes`)
            .then(response => response.json())
            .then(json => json.universes);
    }
}
