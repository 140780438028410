import { ThemeConfig } from "antd/es/config-provider";
import {number} from "prop-types";

const Color = {
  black: '#1C2238',
  white: '#FEFEFE',
  greyLight: '#F2F2F2',

  orange: '#FF8C68',
  purple: '#7D4DD8',
  aquamarine: '#05EDBF',

  yellow: '#FCDE4C',
  pink: '#F8B6FD',
  red: '#F94141',
  blue: '#0074FF'
}


export { Color as ColorTheme }

const RascalTheme: ThemeConfig = {
  token: {
    //Colors
    colorPrimary: Color.orange,
    colorInfo: Color.orange,
    fontFamily: 'SFProDisplay, sans-serif',
    colorBorder: Color.black,
    colorBgBase: Color.white,
    colorTextBase: Color.black,

    colorPrimaryBorder: Color.black,

    colorWarningText: Color.yellow,
    colorError: Color.red,
    colorSuccess: Color.aquamarine,
    colorLink: Color.orange,
    colorBgContainerDisabled: Color.greyLight,
    colorErrorOutline: Color.red,
    colorTextLightSolid: Color.greyLight,
    colorPrimaryBg: Color.orange,
    colorPrimaryText: Color.white,


    //fonts
    fontSize: 16,

    fontSizeHeading1: 55,
    lineHeightHeading1: 60,

    fontSizeHeading2: 30,
    lineHeightHeading2: 35,

    fontSizeHeading3: 20,
    lineHeightHeading3: 35,

    //boxes
    borderRadius: 0,
    lineType: 'solid',
    opacityImage: 1,
    controlHeight: 50,
  },

};

export default RascalTheme;
