import React from 'react';
import {action, makeObservable, observable, toJS} from 'mobx';
import * as _ from 'lodash';
import pluralize from 'pluralize'
import numWords from 'num-words'

import Format from 'helpers/Format';
import {Hash} from 'types/hash';
import Value from 'helpers/Value';

import {IMoney} from 'models/subscriptionPlan/IMoney';
import {ISubscriptionPlanApiData} from 'models/subscriptionPlan/ISubscriptionPlanApiData';
import {SubscriptionPlanKey} from 'models/subscriptionPlan/SubscriptionPlanKey';
import {ISubscriptionPlanKeyApiData} from 'models/subscriptionPlan/ISubscriptionPlanKeyApiData';
import {ISubscriptionApiData} from 'models/subscription/ISubscriptionApiData';
import {IBillingPeriod, Subscription} from 'models/subscription/Subscription';
import {ISubscriptionPlanUpdateApiData} from 'models/subscriptionPlan/ISubscriptionPlanUpdateApiData';
import {SubscriptionPlanProvider} from 'models/subscriptionPlan/SubscriptionPlanProvider';
import Coupon from 'models/coupon/Coupon';

export class SubscriptionPlan {
    private readonly provider: SubscriptionPlanProvider;

    @observable public id: number;

    @observable public name: string;
    @observable public trial: boolean;
    @observable public trial_days: number;
    @observable public max_number_of_users: number;
    @observable public max_number_of_devices: number;
    @observable public billing_period: string;
    @observable public country_pricing: Hash<IMoney> | null;
    @observable public pricing: IMoney;

    @observable public active: boolean;
    @observable public is_public: boolean;
    @observable public key_required: boolean;

    @observable public created_at: Date;
    @observable public updated_at: Date;

    @observable public image_url: string | null;
    @observable public subscribed_success_message: string | null;

    @observable public order: number;

    // Loaded with extra requests.
    @observable public subscriptions: Hash<Subscription> = {};
    @observable public keys: Hash<SubscriptionPlanKey> = {};

    constructor(provider: SubscriptionPlanProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
        this.withKeys = this.withKeys.bind(this);
        this.withKeysResponse = this.withKeysResponse.bind(this);
    }

    @action
    public withDefault(): SubscriptionPlan {
        this.active = false;
        this.trial = true;
        this.trial_days = 30;
        this.max_number_of_users = 1;
        this.max_number_of_devices = 3;
        this.country_pricing = {'world': {amount: 0, currency: 'NZD'}};
        this.order = 0;
        return this;
    }

    @action
    public withId(id: number): SubscriptionPlan {
        this.id = id;
        return this;
    }

    @action
    public withData(data: ISubscriptionPlanApiData): SubscriptionPlan {
        this.load(data);
        return this;
    }

    @action
    public withSubscriptionData(data: ISubscriptionApiData.IPlanData): SubscriptionPlan {
        this.id = data.id;

        this.name = data.name;
        this.trial = Value.defaultFalse(data.trial);
        this.trial_days = Value.defaultZero(data.trial_days);
        this.image_url = data.image_url;

        return this;
    }

    @action
    public withKeys(apiKeys: ISubscriptionPlanKeyApiData[]): SubscriptionPlan {
        let newKeys: Hash<SubscriptionPlanKey> = {};
        _.values(this.keys).forEach((oldKey: SubscriptionPlanKey) => newKeys[oldKey.key] = oldKey);
        apiKeys.forEach((apiKey: ISubscriptionPlanKeyApiData) => newKeys[apiKey.key] = new SubscriptionPlanKey().withData(apiKey));

        this.keys = newKeys;
        return this;
    }

    @action
    public withSubscriptions(apiSubscriptions: ISubscriptionApiData[]): SubscriptionPlan {
        let newSubscriptions: Hash<Subscription> = {};
        _.values(this.subscriptions).forEach((oldSubscription: Subscription) => newSubscriptions[oldSubscription.id.toString()] = oldSubscription);
        apiSubscriptions.forEach((apiSubscription: ISubscriptionApiData) => newSubscriptions[apiSubscription.id.toString()] = new Subscription(this.provider.SubscriptionProvider).withData(apiSubscription));

        this.subscriptions = newSubscriptions;
        return this;
    }

    @action
    private withKeysResponse(response: { keys: ISubscriptionPlanKeyApiData[], key_required: boolean }): SubscriptionPlan {
        this.key_required = response.key_required;
        return this.withKeys(response.keys)
    }

    @action
    public load(data: ISubscriptionPlanApiData): void {
        this.id = data.id;

        this.name = data.name;
        this.trial = Value.defaultFalse(data.trial);
        this.trial_days = Value.defaultZero(data.trial_days);
        this.max_number_of_users = data.max_number_of_users;
        this.max_number_of_devices = data.max_number_of_devices;
        this.billing_period = data.billing_period;
        this.country_pricing = data.country_pricing;
        this.pricing = data.pricing;
        this.active = Value.defaultFalse(data.active);
        this.is_public = Value.defaultFalse(data.is_public);
        this.key_required = Value.defaultFalse(data.key_required);
        this.image_url = data.image_url;
        this.subscribed_success_message = data.subscribed_success_message;
        this.order = data.order;

        this.created_at = Value.dateOrNull(data.created_at);
        this.updated_at = Value.dateOrNull(data.updated_at);
    }

    @action
    public setValue(field: string, value: any): void {
        this[field] = value;
    }

    public getImageURL(): string {
        return this.image_url || '/images/default_subscription_plan_icon.png';
    }

    public getPrice(country_code = null): number {
        if (country_code == null && this.pricing != null) {
            return this.pricing.amount;
        }
        if (this.country_pricing == null) { return 0 }
        let pricing: IMoney = this.getPricing(country_code);
        return pricing.amount;
    }

    public getPricing(country_code = null): IMoney {
        let defaultPricing: IMoney = { amount: 0, currency: 'NZD' }
        if (country_code == null && this.pricing) {
            return this.pricing;
        }
        if (this.country_pricing == null) { return defaultPricing }
        let pricing: IMoney = this.country_pricing[country_code];
        pricing ||= this.country_pricing['world'];
        return pricing || defaultPricing;
    }

    public getPriceString(country_code = null): string {
        return Format.money(this.getPricing(country_code));
    }

    public getCouponPricing(coupon: Coupon | null, country_code = null): IMoney {
        return coupon.apply(this.getPricing(country_code));
    }

    public getCouponPriceString(coupon: Coupon | null, country_code = null): string {
        if (coupon == null || !coupon.applicableTo(this.id)) return this.getPriceString(country_code);

        return Format.money(this.getCouponPricing(coupon, country_code)); 
    }

    public getPricePeriod(): string {
        switch (this.billing_period) {
            case IBillingPeriod.MONTHLY:
                return ' / month'
            case IBillingPeriod.YEARLY:
                return ' / year'
        }
    }

    public getBannerPriceString(): string {
        const trailPriceString = this.trial ? `${this.trial_days} days free, then ` : '';
        return `${trailPriceString}${this.getPriceString()}${this.getPricePeriod()}`;
    }

    public isFree(country_code: string | null = null, coupon: Coupon | null = null): boolean {
        if (country_code != null) {
            return this.getCouponPricing(coupon, country_code).amount > 0;
        }
        // console.log('isFree?', this.id, this.pricing, this);
        return this.pricing.amount === 0;
    }

    public getPriceStringMonthly(country_code: string | null = null): string {
        if (this.isFree(country_code)) return this.getPriceString(country_code);

        switch (this.billing_period) {
            case IBillingPeriod.YEARLY:
                let yearlyPrice: IMoney = this.getPricing(country_code);
                let monthlyPrice = {amount: yearlyPrice.amount / 12.0, currency: yearlyPrice.currency};
                return Format.money(monthlyPrice) + ' p/month'

        }
        return this.getPriceString();
    }

    public getCouponPriceStringMonthly(coupon: Coupon | null, country_code: string | null = null) {
        if (coupon == null) return this.getPriceStringMonthly(country_code);
        if (this.isFree(country_code)) return this.getCouponPriceString(coupon, country_code);

        switch (this.billing_period) {
            case IBillingPeriod.YEARLY:
                let yearlyPrice: IMoney = this.getCouponPricing(coupon, country_code);
                let monthlyPrice = {amount: yearlyPrice.amount / 12.0, currency: yearlyPrice.currency};
                return Format.money(monthlyPrice) + ' p/month'

        }
        return this.getCouponPriceString(coupon, country_code);
    }

    public toAPIData(): ISubscriptionPlanUpdateApiData {
        return {
            name: this.name,
            trial: this.trial,
            trial_days: this.trial_days,
            max_number_of_users: this.max_number_of_users,
            max_number_of_devices: this.max_number_of_devices,
            country_pricing: this.country_pricing,
            active: this.active,
            key_required: this.key_required,
            image_url: this.image_url,
            subscribed_success_message: this.subscribed_success_message
        }
    }

    public static getMaxUsersMessage(maxUsers: number): string {
        return `Maximum of ${numWords(maxUsers)} ${pluralize('user', maxUsers)}`
    }

    public static getMaxDevicesMessage(maxDevices: number): string {
        return maxDevices > 1 ?
            `${_.startCase(numWords(maxDevices))} different devices` :
            `${_.startCase(numWords(maxDevices))} device`
    }

    public fetchData(force: boolean = false): Promise<SubscriptionPlan> {
        return this.provider.get(this.id, force).then(this.withData);
    }

    public fetchKeys(): Promise<SubscriptionPlan> {
        return this.provider.getKeys(this.id).then(this.withKeys);
    }

    public generateKeys(amount: number): Promise<SubscriptionPlan> {
        return this.provider.generateKeys(this.id, amount).then(this.withKeysResponse);
    }

    public activateKeys(keys: any[]): Promise<SubscriptionPlan> {
        return this.provider.activateKeys(this.id, keys).then(this.withKeys);
    }

    public deactivateKeys(keys: any[]): Promise<SubscriptionPlan> {
        return this.provider.deactivateKeys(this.id, keys).then(this.withKeys);
    }
}
