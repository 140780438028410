export class UnityEvents {
    public static readonly STARTUP_COMPLETE_EVENT: string = 'rkStartupComplete';
    public static readonly VERSION_MISMATCH_EVENT: string = 'rkVersionMismatch';
    public static readonly SPLASH_SCREEN_GONE_EVENT: string = 'rkSplashScreenGone';
    public static readonly WAITING_FOR_CHAPTER_TO_DOWNLOAD_EVENT: string = 'rkWaitingForChapterToDownload';
    public static readonly WAITING_FOR_CHAPTER_TO_LOAD_EVENT: string = 'rkWaitingForChapterToLoad';
    public static readonly FINISHED_WAITING_FOR_EVENT: string = 'rkFinishedWaitingFor';
    public static readonly START_STORY_SESSION_EVENT: string = 'rkStartStorySession';
    public static readonly STARTING_STORY_EVENT: string = 'rkStartingStory';
    public static readonly UPDATE_BUNDLE_EVENT: string = 'rkUpdateBundle';
    public static readonly UPDATE_SCENE_EVENT: string = 'rkUpdateScene';
    public static readonly FINISH_STORY_SESSION_EVENT: string = 'rkFinishStorySession';
    public static readonly RESTART_STORY_EVENT: string = 'rkRestartStory';
    public static readonly DISPLAY_ERROR_EVENT: string = 'rkDisplayError';
    public static readonly STORY_SET_EVENT: string = 'rkStorySet';
    public static readonly PROGRESS_STATE_CHANGE_EVENT: string = 'rkProgressStateChange';
    public static readonly EXIT_EVENT: string = 'rkExit';
    public static readonly EXITED_TUTORIAL_EVENT: string = 'rkExitedTutorial';
}