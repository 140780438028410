import React, {Component, Key} from "react";
import {Path, useLocation, useParams} from "react-router-dom";

const withRouteParams = (Component) => (props) => {
    let params = useParams();
    let history = window.history;
    return <Component routeParams={params} history={history} {...props} />
}

export default withRouteParams;
