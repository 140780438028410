import React, {Component} from 'react';
import DefaultFooter from 'layouts/footers/DefaultFooter';
import DefaultHeader from 'layouts/headers/DefaultHeader';

class DefaultLayout extends Component<any, any> {
  public render(): React.ReactElement {
    return (
      <div className='rk-default-layout'>
        <div className='rk-header'>
          <DefaultHeader/>
        </div>
        <div className='rk-content'>
            {this.props.children}
        </div>
        <div className='rk-footer'>
          <DefaultFooter/>
        </div>
      </div>
    );
  }
}

export default DefaultLayout;
