import { action, makeObservable, observable } from "mobx";
import { ICampaignApiData } from "models/campaign/ICampaignApiData";
import Value from "helpers/Value";
import { CampaignProvider } from "models/campaign/CampaignProvider";
import Coupon from "models/coupon/Coupon";

export class Campaign {
  private readonly provider: CampaignProvider | null;
  @observable public id: number;
  @observable public coupon_id: number;
  @observable public coupon: Coupon;
  @observable public key: number;
  @observable public name: string;
  @observable public notes: string;
  @observable public created_at: Date;

  constructor(provider: CampaignProvider) {
    makeObservable(this);

    this.provider = provider;

    this.withData = this.withData.bind(this);
    this.withName = this.withName.bind(this);
  }

  @action
  public withData(data: ICampaignApiData): this {
    this.load(data);
    return this;
  }

  @action.bound
  public load(data: ICampaignApiData) {
    this.id = data.id;
    this.coupon_id = data.coupon_id;
    if (data.coupon != null) {
      this.coupon = new Coupon().withData(data.coupon);
    }
    this.key = data.id;
    this.name = data.name;
    this.notes = data.notes;
    this.created_at = Value.dateOrNull(data.created_at);
  }

  @action
  withName(name: string, notes?: string) {
    this.name = name;
    if ( typeof notes !== "undefined" ) {
      this.notes = notes
    }
    return this;
  }

  @action
  public withCoupon(): Campaign {
    if (this.coupon_id !== null) {
      this.provider.CouponProvider.get(this.coupon_id).then((coupon) => {
        this.coupon = coupon;
      });
    }
    return this;
  }
}
