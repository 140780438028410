import {LogLevelDesc} from 'loglevel';

interface IConfig {
  useLocalWebGLBuild: boolean;
  forceCacheUpdate: boolean;
  loglevel: LogLevelDesc;
}

let config: IConfig = require('./config.json');
let localConfig: any = {};
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  try {
    localConfig = require('./config.local.json');
  } catch (e) {
    console.log("'config.local.json' does not exist, using default");
  }
}

for (let key in config) {
  if (config.hasOwnProperty(key) && localConfig.hasOwnProperty(key)) {
    config[key] = localConfig[key];
  }
}

export default config;
