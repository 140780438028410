import React, {Component} from "react";
import {ColorTheme} from "components/shared/RascalTheme";

interface ICheckCircleProps {
  checked: boolean;
  color: string;
  selectedColor?: string;
  backgroundColor?: string;
}

class CheckCircle extends Component<ICheckCircleProps> {

  public currentColor(): string {
    return this.props.checked ? this.props.selectedColor || this.props.color : this.props.color;
  }

  public backgroundColor(): string {
    return this.props.backgroundColor || ColorTheme.white;
  }

  public colorIfChecked() {
    return this.props.checked ? this.currentColor() : 'none';
  }

  public

  render(): React.ReactElement {
    const {checked} = this.props;
    const colorIfChecked = this.colorIfChecked();
    const checkedColor = this.currentColor();
    return (
      <svg className={`rk-check-circle`} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g className={'circle'} strokeWidth="1">
          <circle className={'inside'} fill={colorIfChecked} stroke="none" cx="10" cy="10" r="10"/>
          <circle className={'outline'} fill={colorIfChecked} stroke={checkedColor} cx="10" cy="10" r="9"/>
        </g>
        {checked && <path className={'check'} fill={this.backgroundColor()} d="M698.466,532.28a1.234,1.234,0,0,0-1.745,0l-5.515,5.585-2.381-2.1a1.155,1.155,0,0,0-1.6.1,1.126,1.126,0,0,0-.143,1.562l4.147,3.824,7.237-7.229A1.234,1.234,0,0,0,698.466,532.28Z" transform="translate(-682.828 -526.918)"/> }
      </svg>
    );
  }
}

export default CheckCircle;
