import {RootStore} from 'models/RootStore';

import {AssetBundleProvider} from 'models/assetBundle/AssetBundleProvider';
import {AssetBundleIDB} from 'models/assetBundle/AssetBundleIDB';

import {BillingAddressProvider} from 'models/billingAddress/BillingAddressProvider';
import {BillingAddressMemoryStore} from 'models/billingAddress/BillingAddressMemoryStore';
import {BillingAddressPermanentStore} from 'models/billingAddress/BillingAddressPermanentStore';

import {CampaignProvider} from "models/campaign/CampaignProvider";

import {CollectionProvider} from 'models/collection/CollectionProvider';
import {CollectionMemoryStore} from 'models/collection/CollectionMemoryStore';
import {CollectionPermanentStore} from 'models/collection/CollectionPermanentStore';

import {CouponProvider} from 'models/coupon/CouponProvider';

import {InvoiceProvider} from 'models/invoice/InvoiceProvider';
import {InvoiceMemoryStore} from 'models/invoice/InvoiceMemoryStore';
import {InvoicePermanentStore} from 'models/invoice/InvoicePermanentStore';

import {SessionProvider} from 'models/session/SessionProvider';

import {SettingsProvider} from 'models/settings/SettingsProvider';
import {SettingsMemoryStore} from 'models/settings/SettingsMemoryStore';
import {SettingsPermanentStore} from 'models/settings/SettingsPermanentStore';

import {StoryLocalisationProvider} from 'models/storyLocalisation/StoryLocalisationProvider';
import {StoryLocalisationMemoryStore} from 'models/storyLocalisation/StoryLocalisationMemoryStore';
import {StoryLocalisationPermanentStore} from 'models/storyLocalisation/StoryLocalisationPermanentStore';

import {StoryProvider} from 'models/story/StoryProvider';
import {StoryMemoryStore} from 'models/story/StoryMemoryStore';
import {StoryPermanentStore} from 'models/story/StoryPermanentStore';

import {StoryReleaseProvider} from 'models/storyRelease/StoryReleaseProvider';
import {StoryReleaseMemoryStore} from 'models/storyRelease/StoryReleaseMemoryStore';
import {StoryReleasePermanentStore} from 'models/storyRelease/StoryReleasePermanentStore';

import {StorySessionProvider} from 'models/storySession/StorySessionProvider';
import {StorySessionMemoryStore} from 'models/storySession/StorySessionMemoryStore';
import {StorySessionPermanentStore} from 'models/storySession/StorySessionPermanentStore';

import {SubscriptionProvider} from 'models/subscription/SubscriptionProvider';
// import {SubscriptionMemoryStore} from 'models/subscription/SubscriptionMemoryStore';
// import {SubscriptionPermanentStore} from 'models/subscription/SubscriptionPermanentStore';
import {SubscriptionPlanProvider} from 'models/subscriptionPlan/SubscriptionPlanProvider';
import {SubscriptionPlanMemoryStore} from 'models/subscriptionPlan/SubscriptionPlanMemoryStore';
import {SubscriptionPlanPermanentStore} from 'models/subscriptionPlan/SubscriptionPlanPermanentStore';

import {UniverseProvider} from 'models/universe/UniverseProvider';

import {UserProvider} from 'models/user/UserProvider';
import {UserMemoryStore} from 'models/user/UserMemoryStore';
import {UserPermanentStore} from 'models/user/UserPermanentStore';

import {WebglProvider} from 'models/webgl/WebglProvider';
import {WebglIDB} from 'models/webgl/WebglIDB';

export abstract class AbstractStore {
    private readonly _rootStore: RootStore;
    private readonly _databaseName: string;

    protected constructor(rootStore: RootStore, databaseName: string) {
        this._rootStore = rootStore;
        this._databaseName = `RK.${databaseName}`;
    }

    protected resetAll(): void {
        this.BillingAddressMemoryStore.resetAll();
        this.InvoiceMemoryStore.resetAll();
        this.StoryMemoryStore.resetAll();
        this.StoryLocalisationMemoryStore.resetAll();
        this.StoryReleaseMemoryStore.resetAll();
        this.StorySessionMemoryStore.resetAll();
        this.SubscriptionPlanMemoryStore.resetAll();
        this.UserMemoryStore.resetAll();
    }

    protected get RootStore(): RootStore {
        return this._rootStore;
    }

    protected get AssetBundleProvider(): AssetBundleProvider {
        return this.RootStore.AssetBundleProvider;
    }

    protected get AssetBundleIDB(): AssetBundleIDB {
        return this.RootStore.AssetBundleIDB;
    }

    public get BillingAddressProvider(): BillingAddressProvider {
        return this.RootStore.BillingAddressProvider;
    }

    protected get BillingAddressMemoryStore(): BillingAddressMemoryStore {
        return this.RootStore.BillingAddressMemoryStore;
    }

    protected get BillingAddressPermanentStore(): BillingAddressPermanentStore {
        return this.RootStore.BillingAddressPermanentStore;
    }

    public get CampaignProvider(): CampaignProvider {
        return this.RootStore.CampaignProvider;
    }

    public get CollectionProvider(): CollectionProvider {
        return this.RootStore.CollectionProvider;
    }

    protected get CollectionMemoryStore(): CollectionMemoryStore {
        return this.RootStore.CollectionMemoryStore;
    }

    protected get CollectionPermanentStore(): CollectionPermanentStore {
        return this.RootStore.CollectionPermanentStore;
    }

    public get CouponProvider(): CouponProvider {
        return this.RootStore.CouponProvider;
    }

    public get InvoiceProvider(): InvoiceProvider {
        return this.RootStore.InvoiceProvider;
    }

    protected get InvoiceMemoryStore(): InvoiceMemoryStore {
        return this.RootStore.InvoiceMemoryStore;
    }

    protected get InvoicePermanentStore(): InvoicePermanentStore {
        return this.RootStore.InvoicePermanentStore;
    }

    public get SessionProvider(): SessionProvider {
        return this.RootStore.SessionProvider;
    }

    public get SettingsProvider(): SettingsProvider {
        return this.RootStore.SettingsProvider;
    }

    protected get SettingsMemoryStore(): SettingsMemoryStore {
        return this.RootStore.SettingsMemoryStore;
    }

    protected get SettingsPermanentStore(): SettingsPermanentStore {
        return this.RootStore.SettingsPermanentStore;
    }

    public get StoryProvider(): StoryProvider {
        return this.RootStore.StoryProvider;
    }

    protected get StoryMemoryStore(): StoryMemoryStore {
        return this.RootStore.StoryMemoryStore;
    }

    protected get StoryPermanentStore(): StoryPermanentStore {
        return this.RootStore.StoryPermanentStore;
    }

    public get StoryLocalisationProvider(): StoryLocalisationProvider {
        return this.RootStore.StoryLocalisationProvider;
    }

    protected get StoryLocalisationMemoryStore(): StoryLocalisationMemoryStore {
        return this.RootStore.StoryLocalisationMemoryStore;
    }

    protected get StoryLocalisationPermanentStore(): StoryLocalisationPermanentStore {
        return this.RootStore.StoryLocalisationPermanentStore;
    }

    public get StoryReleaseProvider(): StoryReleaseProvider {
        return this.RootStore.StoryReleaseProvider;
    }

    protected get StoryReleaseMemoryStore(): StoryReleaseMemoryStore {
        return this.RootStore.StoryReleaseMemoryStore;
    }

    protected get StoryReleasePermanentStore(): StoryReleasePermanentStore {
        return this.RootStore.StoryReleasePermanentStore;
    }

    public get StorySessionProvider(): StorySessionProvider {
        return this.RootStore.StorySessionProvider;
    }

    protected get StorySessionMemoryStore(): StorySessionMemoryStore {
        return this.RootStore.StorySessionMemoryStore;
    }

    protected get StorySessionPermanentStore(): StorySessionPermanentStore {
        return this.RootStore.StorySessionPermanentStore;
    }

    public get SubscriptionProvider(): SubscriptionProvider {
        return this.RootStore.SubscriptionProvider;
    }

    /*
    protected get SubscriptionMemoryStore(): SubscriptionMemoryStore {
      return this.RootStore.SubscriptionMemoryStore;
    }

    protected get SubscriptionPermanentStore(): SubscriptionPermanentStore {
      return this.RootStore.SubscriptionPermanentStore;
    }
    */

    public get SubscriptionPlanProvider(): SubscriptionPlanProvider {
        return this.RootStore.SubscriptionPlanProvider;
    }

    protected get SubscriptionPlanMemoryStore(): SubscriptionPlanMemoryStore {
        return this.RootStore.SubscriptionPlanMemoryStore;
    }

    protected get SubscriptionPlanPermanentStore(): SubscriptionPlanPermanentStore {
        return this.RootStore.SubscriptionPlanPermanentStore;
    }

    public get UniverseProvider(): UniverseProvider {
        return this.RootStore.UniverseProvider;
    }

    public get UserProvider(): UserProvider {
        return this.RootStore.UserProvider;
    }

    protected get UserMemoryStore(): UserMemoryStore {
        return this.RootStore.UserMemoryStore;
    }

    protected get UserPermanentStore(): UserPermanentStore {
        return this.RootStore.UserPermanentStore;
    }

    protected get WebglProvider(): WebglProvider {
        return this.RootStore.WebglProvider;
    }

    protected get WebglIDB(): WebglIDB {
        return this.RootStore.WebglIDB;
    }

    protected get DatabaseName(): string {
        return this._databaseName;
    }
}
