import React, {Component} from 'react';
import LoadingIcon from 'components/public/pages/webgl_app/LoadingIcon';
import './StoryDetailsButton.scss'

export interface IStoryDetailsButtonProps {
    label: string;
    icon?: React.ReactElement;
    loading?: boolean;
    color: 'blue' | 'white' | 'inactive';
    cursor: 'pointer' | 'progress' | 'not-allowed'
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

class StoryDetailsButton extends Component<IStoryDetailsButtonProps, any> {
    public render(): React.ReactElement {
        const {onClick, label, icon, loading, color, cursor} = this.props;

        return (
            <button className={`story-view-button story-view-button--${color}`}
                    onClick={e => onClick?.(e)}
                    style={{cursor: cursor}}>
                {loading && <div className='story-view-button--icon'>
                    <LoadingIcon color={color == 'blue' ? 'white' : 'blue'}/>
                </div>}
                {icon ? icon : <React.Fragment />}
                <label className='story-view-button--text'
                       style={{cursor: cursor}}>
                    {label}
                </label>
            </button>
        );
    }
}

export default StoryDetailsButton;
