import {Row} from 'antd';
import React from 'react';

import {ComponentWithStore} from 'models/RootStore';
import {Story} from 'models/story/Story';

import './StoryTitleImage.scss'

interface IStoryTitleImageProps {
    story: Story;
    center?: boolean;
}

interface IStoryTitleImageState {
}

class StoryTitleImage extends ComponentWithStore<IStoryTitleImageProps, IStoryTitleImageState> {
    public constructor(props: IStoryTitleImageProps) {
        super(props);

        this.state = {};
    }

    public render(): React.ReactElement {
        const {story, center} = this.props;

        let color: string = story.default_localisation?.featured_text_color || '#000';

        return (
            <Row className={`story-title-image story-title-image--${center ? 'center' : 'left'}`}
                 justify={center ? 'center' : 'start'}
                 align={center ? 'middle' : 'bottom'}
                 style={{color: color}}>
                {story.default_localisation?.title_image_url
                    ? (
                        <div className='story-title-image--container'>
                            <img className='story-title-image--img'
                                 src={story.default_localisation?.title_image_url}/>
                        </div>
                    )
                    : story.title()}
            </Row>
        );
    }
}

export default StoryTitleImage;
