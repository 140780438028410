import React, {Component} from 'react';

class PauseMenuPlayIcon extends Component<any, any> {
    public render(): React.ReactElement {
        return (
            <div className='rk-chapter-icon-wrapper'>
                <div className='rk-chapter-icon-container'>
                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265.73 265.73">
                        <path fill='#FFFFFF' d="M52.89,52.49v161.31c0,24.16,25.55,32.37,42.69,19.17l107.08-83.11c13.57-9.82,13.57-20.94,0-30.63,0,0-106.9-85.81-107.2-86.04-18.6-14.27-42.57-4.86-42.57,19.3Z"/>
                    </svg>
                </div>
            </div>
        );
    }
}

export default PauseMenuPlayIcon;