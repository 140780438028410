import EditValueInPlace, {IEditValueInPlaceProps} from "components/public/modules/value_editor/EditValueInPlace";

export interface IEditableNumberProps extends IEditValueInPlaceProps {
  onChange: (value: number) => void;
  allowNegative?: boolean;
}

class EditableNumber extends EditValueInPlace<IEditableNumberProps> {
  constructor(props: IEditableNumberProps) {
    super(props);
    this.format = 'number';
    this.allowNegative = props.allowNegative || false;
    this.onChangeCallback = props.onChange;
  }
}

export default EditableNumber;
