import React, {Component} from 'react';
import DefaultLayout from 'layouts/DefaultLayout';
import AdminLayout from 'layouts/AdminLayout';

interface IDefaultOrAdminLayout {
  admin: boolean;
  children: any;
}

class DefaultOrAdminLayout  extends Component<IDefaultOrAdminLayout, any> {
  public render(): React.ReactElement {
    const {admin} = this.props

    if (admin) {
      return (
        <AdminLayout>
          {this.props.children}
        </AdminLayout>
      );
    }

    return (
      <DefaultLayout>
        {this.props.children}
      </DefaultLayout>
    );
  }
}

export default DefaultOrAdminLayout;
