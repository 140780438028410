import {ComponentWithStore} from "models/RootStore";
import Tables, {IColumn, IColumnProps, ITableProps, ITableState} from "helpers/Tables";
import {
  ColumnType,
  FilterConfirmProps,
  FilterDropdownProps,
  FilterValue,
  SorterResult,
  TablePaginationConfig
} from "antd/lib/table/interface";
import React from "react";
import _ from "lodash";
import {Table} from "antd";
import {IUserApiSearchParams} from "models/user/UserApi";

interface ISearchDropdownProps<T> extends FilterDropdownProps {
  dataIndex: keyof T;
}

export class RascalTable<T> extends ComponentWithStore<Partial<ITableProps<T>>, Partial<ITableState<T>>> {
  private mounted: boolean = false;

  constructor(props: Partial<ITableProps<T>>) {
    super(props);

    this.state = {
      ...Tables.initialState<T>(this.props.data)
    }

    this.updateDataWithQuery = this.updateDataWithQuery.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.switchColumnProps = this.switchColumnProps.bind(this);
  }

  componentDidMount() {
    this.mounted = true;

    this.updateDataWithQuery().then(() => {
      if (this.mounted) {
        this.setState({});
      }
    });

    this.setState({
      columns: this.props.columns.map((column) => this.switchColumnProps(column))
    })
  }

  componentWillUnmount() {
    this.mounted = false
  }

  public render(): React.ReactElement {
    const {selectedRows, currentPage, pageSize, total, columns, data} = this.state;

    const pagination: TablePaginationConfig = Tables.pagination(
      currentPage, pageSize, total, this.handleOnChange)

    return (
      <div className='rk-subscriptions-table'>
        <Table
          rowKey='id'
          columns={columns.map((col: any) => col.columnType)}
          dataSource={data}
          rowSelection={this.props.selectable ? {
            selectedRowKeys: selectedRows,
            onChange: this.handleSelectChange,
            selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
              Table.SELECTION_NONE
            ]
          } : null}
          pagination={pagination}
          onChange={this.handleChange}/>
      </div>
    );
  }

  private async updateDataWithQuery(): Promise<void> {
    const {currentPage, pageSize} = this.state;

    const searchParams: IUserApiSearchParams = {
      page: currentPage,
      page_size: pageSize,
      filters: Tables.getSearchFilters(this.state)
    };
    if (this.props.getTableSearch) {
      this.setState(await this.props.getTableSearch(searchParams));
    } else {
      this.setState({ data: this.props.data });
    }
  }

  private switchColumnProps(
    // TODO: Add the filter boolean to the columns from the AdminInvoicesTable
    column: IColumnProps<T>,
    filter?: 'boolean'): IColumn<T> {
    switch (column.type) {
      case 'search': {
        return this.getColumnSearchProps(column);
      }
      case 'boolean': {
        return this.getColumnBooleanProps(column);
      }
      case 'date': {
        return this.getColumnDateProps(column);
      }
      case 'text': {
        return this.getColumnTextProps(column);
      }
      case 'length': {
        return this.getColumnLengthProps(column);
      }
      case 'custom' : {
        return this.getColumnCustomProps(column);
      }
    }
  }

  //region Handle Change Functions
  private handleChange<T>(
    pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<T>): void {
    this.setState(Tables.handleChange<T>(pagination, filters, sorter), this.updateDataWithQuery);
  }

  private handleSelectChange(selectedRows): void {
    this.setState({selectedRows: selectedRows});
  }

  private clearSelection(): void {
    this.setState(Tables.clearSelection<T>());
  }

  private handleChangeSearchText(props: ISearchDropdownProps<T>, event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState(Tables.handleChangeSearchText<T>(props, this.state, event));
  }

  private handleSearch(props: ISearchDropdownProps<T>, filterConfirmProps?: FilterConfirmProps): void {
    this.setState(Tables.handleSearch<T>(props, this.state, filterConfirmProps));
  }

  private handleClearSearch(props: ISearchDropdownProps<T>): void {
    this.setState(Tables.handleClearSearch<T>(props, this.state));
  }

  private handleOnChange(page: number, size: number, resetPage: boolean) {
    this.setState(Tables.handleOnChange<T>(page, size, resetPage));
  }

  //endregion


  //region Render Search and filter boxes
  private renderSearchBox<T>(props: ISearchDropdownProps<T>): React.ReactElement {
    return Tables.renderSearchBox<T>(
      props, this.handleChangeSearchText, this.handleSearch, this.handleClearSearch);
  }

  private renderBooleanFilterBox<T>(props: ISearchDropdownProps<T>): React.ReactElement {
    return Tables.renderBooleanFilterBox(props, this.handleClearSearch, this.handleSearch);
  }

  private renderDateFilterBox<T>(props: ISearchDropdownProps<T>): React.ReactElement {
    return Tables.renderDateFilterBox(props, this.handleClearSearch, this.handleSearch);
  }

  //endregion


  //region Partial Column Options
  private partialColumnOptions(dataIndex: keyof T): Partial<ColumnType<T>> {
    return {
      title: _.startCase(String(dataIndex)),
      dataIndex: String(dataIndex),
      key: String(dataIndex),
    }
  }

  private partialColumnSearchOptions(dataIndex: keyof T): Partial<ColumnType<T>> {
    return {
      ...this.partialColumnOptions(dataIndex),
      filterDropdown: (props: FilterDropdownProps) => this.renderSearchBox<T>({
        ...props, ...{dataIndex: dataIndex}
      }),
    }
  }

  private partialColumnBooleanOptions(dataIndex: keyof T): Partial<ColumnType<T>> {
    return {
      ...this.partialColumnOptions(dataIndex),
      filterDropdown: (props: FilterDropdownProps) => this.renderBooleanFilterBox<T>({
        ...props, ...{dataIndex: dataIndex}
      }),
    }
  }

  private partialColumnDateOptions(dataIndex: keyof T): Partial<ColumnType<T>> {
    return {
      ...this.partialColumnOptions(dataIndex),
      filterDropdown: (props: FilterDropdownProps) => this.renderDateFilterBox<T>({
        ...props, ...{dataIndex: dataIndex}
      }),
    }
  }

  //endregion


  //region Column Props
  private getColumnSearchProps(column: IColumnProps<T>): IColumn<T> {
    const {searchInfo} = this.state;
    const dataIndex: keyof T = column.key;
    const {getValue} = column;

    const columnType: Partial<ColumnType<T>> = {
      ...this.partialColumnSearchOptions(dataIndex),
      ...Tables.SearchColumnType<T>(),
      render: (text, object: T) => Tables.renderSearch<T>(getValue ? getValue(object) : text, object, column, searchInfo)
    };

    return {
      columnType: columnType,
      filter: Tables.filterSearchColumnType(dataIndex)
    }
  }

  private getColumnBooleanProps(column: IColumnProps<T>): IColumn<T> {
    const dataIndex: keyof T = column.key;
    const columnType: Partial<ColumnType<T>> = {
      ...this.partialColumnBooleanOptions(dataIndex),
      ...Tables.BooleanColumnType(),
      render: (bool: boolean, object: T) => Tables.renderBoolean<T>(bool, object, column.getHref),
    };

    return {
      columnType: columnType,
      filter: Tables.filterBooleanColumnType<keyof T>(dataIndex)
    }
  }

  private getColumnDateProps(column: IColumnProps<T>): IColumn<T> {
    const dataIndex: keyof T = column.key;
    const columnType: Partial<ColumnType<T>> = {
      ...this.partialColumnDateOptions(dataIndex),
      ...Tables.DateColumnType<T>(),
      render: (date: Date, object: T) => Tables.renderDate<T>(date, object, column.getHref)
    };

    return {
      columnType: columnType,
      filter: Tables.filterDateColumnType(dataIndex)
    }
  }

  private getColumnTextProps(column: IColumnProps<T>): IColumn<T> {
    const dataIndex: keyof T = column.key;
    const {getValue} = column;
    const columnType: Partial<ColumnType<T>> = {
      ...this.partialColumnOptions(dataIndex),
      ...Tables.TextColumnType<T>(),
      render: (text: string, object: T) => getValue ? getValue(object) : text
    };

    return {
      columnType: columnType,
      filter: Tables.filterTextColumnType()
    }
  }

  private getColumnLengthProps(column: IColumnProps<T>): IColumn<T> {
    const dataIndex: keyof T = column.key;
    const columnType: Partial<ColumnType<T>> = {
      ...this.partialColumnOptions(dataIndex),
      ...Tables.LengthColumnType<T>()
    };

    return {
      columnType: columnType,
      filter: Tables.filterLengthColumnType()
    }
  }

  private getColumnCustomProps(column: IColumnProps<T>): IColumn<T> {
    const dataIndex: keyof T = column.key;
    const {render} = column;
    const columnType: Partial<ColumnType<T>> = {
      ...this.partialColumnOptions(dataIndex),
      ...Tables.TextColumnType<T>(),
      render: (text: string, object: T) => render(object)
    };

    return {
      columnType: columnType,
      filter: Tables.filterTextColumnType()
    }
  }

  //endregion
}
