import React from 'react';
import {DashboardModal} from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import {Button} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons'
import SuccessButton from 'components/public/modules/success_button/SuccessButton';

interface ISingleUploaderProps {
    onUploadSuccess: (response: any) => void;
    onUploadClear: () => void;
}

interface ISingleUploaderState {
    modalOpen: boolean;
    uploaded: boolean;
    uploadedFile: any;
}

class Single extends React.Component<ISingleUploaderProps, ISingleUploaderState> {
    private readonly uppy;

    constructor(props) {
        super(props);
        this.uppy = new Uppy({
            restrictions: {
                minNumberOfFiles: null,
                maxNumberOfFiles: 1
            }
        }).use(AwsS3Multipart, {
            limit: 4,
            companionUrl: '/'
        });

        this.uppy.on('upload-success', (file, response) => {
            response.url = response.uploadURL.replace(/\?.*/, '');
            this.props.onUploadSuccess(response);
            this.setState({
                uploaded: true,
                uploadedFile: file
            })
        });

        this.state = {
            modalOpen: false,
            uploaded: false,
            uploadedFile: null
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    private handleOpen(): void {
        this.setState({modalOpen: true});
    }

    private handleClose(): void {
        this.setState({modalOpen: false});
    }

    private handleClear(): void {
        this.uppy.reset();
        this.setState({
            uploaded: false,
            uploadedFile: null
        });
        this.props.onUploadClear();
    }

    public componentWillUnmount(): void {
        this.uppy.close();
    }

    private renderClearButton(): React.ReactElement {
        if (!this.state.uploaded) return <React.Fragment/>;

        return (
            <Button danger style={{marginLeft: '20px'}}
                    onClick={this.handleClear}>Remove <ExclamationCircleOutlined/></Button>
        );
    }

    public render(): React.ReactElement {
        return (
            <React.Fragment>
                <SuccessButton onClick={this.handleOpen}
                               success={this.state.uploaded}
                               defaultText='Upload'
                               successText='Uploaded'/>
                {this.renderClearButton()}
                <DashboardModal
                    uppy={this.uppy}
                    closeModalOnClickOutside
                    open={this.state.modalOpen}
                    proudlyDisplayPoweredByUppy={false}
                    onRequestClose={this.handleClose}
                />
            </React.Fragment>
        );
    }
}

const Uploader = {
    Single
};

export default Uploader;
