import Api from 'models/Api';
import {ISubscriptionPlanApiData} from 'models/subscriptionPlan/ISubscriptionPlanApiData';
import {ISubscriptionPlanKeyApiData} from 'models/subscriptionPlan/ISubscriptionPlanKeyApiData';
import {ISubscriptionPlanUpdateApiData} from 'models/subscriptionPlan/ISubscriptionPlanUpdateApiData';

export class SubscriptionPlanApi {
  public static get(id: number): Promise<ISubscriptionPlanApiData> {
    return Api.get(`/api/v1/subscription_plans/${id}`)
      .then(response => response.json())
      .then(json => json.subscription_plan);
  }

  public static getCurrentPlan(): Promise<ISubscriptionPlanApiData> {
    return Api.get(`/api/v1/subscription_plans/current`)
      .then(response => response.json())
      .then(json => json.subscription_plan);
  }

  public static getAll(): Promise<ISubscriptionPlanApiData[]> {
    return Api.get('/api/v1/subscription_plans')
      .then(response => response.json())
      .then(json => json.subscription_plans);
  }

  public static getAllValid(): Promise<ISubscriptionPlanApiData[]> {
    return Api.get('/api/v1/subscription_plans?valid=true')
      .then(response => response.json())
      .then(json => json.subscription_plans);
  }

  public static getAllAdmin(): Promise<ISubscriptionPlanApiData[]> {
    return Api.get('/api/v1/subscription_plans/all')
      .then(response => response.json())
      .then(json => json.subscription_plans);
  }

  public static create(data: { subscription_plan: ISubscriptionPlanUpdateApiData }): Promise<ISubscriptionPlanApiData> {
    return Api.post(`/api/v1/subscription_plans`, {
      body: JSON.stringify(data)
    }).then(response => response.json())
      .then(json => json.subscription_plan);
  }

  public static update(id: number, data: ISubscriptionPlanUpdateApiData): Promise<ISubscriptionPlanApiData> {
    console.log('Updating with data', data);
    return Api.put(`/api/v1/subscription_plans/${id}`, {
      body: JSON.stringify(data)
    }).then(response => response.json())
      .then(json => json.subscription_plan);
  }

  public static getKeys(id: number): Promise<ISubscriptionPlanKeyApiData[]> {
    return Api.get(`/api/v1/subscription_plans/${id}/keys`)
      .then(response => response.json())
      .then(json => json.keys);
  }

  public static generateKeys(id: number, amount: number): Promise<{ keys: ISubscriptionPlanKeyApiData[], key_required: boolean }> {
    return Api.post(`/api/v1/subscription_plans/${id}/keys/generate`, {
        body: JSON.stringify({
          amount: amount
        })
      })
      .then(response => response.json());
  }

  public static activateKeys(id: number, keys: string[]): Promise<ISubscriptionPlanKeyApiData[]> {
    return Api.post(`/api/v1/subscription_plans/${id}/keys/activate`, {
        body: JSON.stringify({
          keys: keys
        })
      })
      .then(response => response.json())
      .then(json => json.keys);
  }

  public static deactivateKeys(id: number, keys: string[]): Promise<ISubscriptionPlanKeyApiData[]> {
    return Api.post(`/api/v1/subscription_plans/${id}/keys/deactivate`, {
        body: JSON.stringify({
          keys: keys
        })
      })
      .then(response => response.json())
      .then(json => json.keys);
  }
}
