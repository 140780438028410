import React, {Component} from "react";

class RestartIcon extends Component {
  render() {
    return (
      <svg className={'rk-icon rk-restart-icon'} xmlns="http://www.w3.org/2000/svg" width="15" height="12.619" viewBox="0 0 15 12.619">
        <path d="M1060.681,392.166a1.332,1.332,0,0,0,0,2.663,3.646,3.646,0,1,1-3.628,4l.738.746a1.044,1.044,0,0,0,1.479,0,1.047,1.047,0,0,0,0-1.479l-3.5-3.471-3.47,3.482a1.045,1.045,0,1,0,1.484,1.473l.611-.626a6.309,6.309,0,1,0,6.29-6.78Z" transform="translate(-1051.991 -392.166)" fill="currentColor"/>
      </svg>
    );
  }
}

export default RestartIcon;
