import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {Invoice} from 'models/invoice/Invoice';
import {IInvoiceApiData} from 'models/invoice/IInvoiceApiData';
import {IInvoiceApiUpdateData} from 'models/invoice/IInvoiceUpdateApiData';
import {IInvoiceApiSearchParams, InvoiceApi} from 'models/invoice/InvoiceApi';
import {IInvoiceSearchApiData, InvoiceSearchData} from 'models/invoice/IInvoiceSearchApiData';
import {ApiResponseData} from 'models/ApiResponseData';

export class InvoiceProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'InvoiceProvider');

        this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public get(id: number): Promise<IInvoiceApiData> {
        return InvoiceApi.get(id);
    }

    public getInvoice(id: number): Promise<Invoice> {
        return InvoiceApi.get(id)
            .then((data: IInvoiceApiData) => this.apiDataToModel(data));
    }

    public getAllForSubscription(subscriptionId: number, force: boolean = false): Promise<Invoice[]> {
        if (subscriptionId == null) return Promise.reject('No subscription id specified for loading all Invoices.');

        return InvoiceApi.getAllForSubscription(subscriptionId)
            .then((invoices: IInvoiceApiData[]) => this.apiDataArrayToModelArray(invoices));
    }

    public getAllForUser(userId: number, force: boolean = false): Promise<Invoice[]> {
        if (userId == null) return Promise.reject('No user id specified for loading all Invoices.');

        if (force) return this.getAllForUserFromApiAndUpdateStore(userId);

        return this.InvoiceMemoryStore.read(userId)
            .then((invoices: IInvoiceApiData[] | null) => {
                return invoices
                    ? this.apiDataArrayToModelArray(invoices)
                    : this.getAllForUserFromApiAndUpdateStore(userId);
            });
    }

    private getAllForUserFromApiAndUpdateStore(userId: number): Promise<Invoice[]> {
        return InvoiceApi.getAllForUser(userId)
            .then((invoices: IInvoiceApiData[]) => this.InvoiceMemoryStore.store(invoices, userId)
                .then(() => this.apiDataArrayToModelArray(invoices)));
    }

    public getAllForBillingAddress(billingAddress: number, force: boolean = false): Promise<Invoice[]> {
        if (billingAddress == null) return Promise.reject('No billing address id specified for loading all Invoices.');

        return InvoiceApi.getAllForSubscription(billingAddress)
            .then((invoices: IInvoiceApiData[]) => this.apiDataArrayToModelArray(invoices));
    }

    public getInvoiceSearch(searchParams?: IInvoiceApiSearchParams): Promise<InvoiceSearchData> {
        return InvoiceApi.getInvoiceSearch(searchParams)
            .then((data: IInvoiceSearchApiData) => ({
                page: data.page,
                page_size: data.page_size,
                total: data.total,
                invoices: this.apiDataArrayToModelArray(data.invoices)
            }));
    }

    public deleteInvoice(id: number): Promise<ApiResponseData> {
        return InvoiceApi.deleteInvoice(id);
    }

    public editInvoice(id: number, params: IInvoiceApiUpdateData): Promise<Invoice> {
        return InvoiceApi.editInvoice(id, params)
            .then(invoice => this.apiDataToModel(invoice));
    }

    private apiDataArrayToModelArray(invoices: IInvoiceApiData[]): Invoice[] {
        return invoices.map(this.apiDataToModel);
    }

    private apiDataToModel(invoice: IInvoiceApiData): Invoice {
        return new Invoice(this).withData(invoice);
    }
}
