import {SubscriptionPlan} from "models/subscriptionPlan/SubscriptionPlan";
import {Campaign} from "models/campaign/Campaign";
import {ICouponApiData} from "models/coupon/ICouponApiData";
import {RootStore} from "models/RootStore";
import {observer} from "mobx-react";
import {computed, observable} from "mobx";
import Value from "helpers/Value";
import CouponApi from "models/coupon/CouponApi";
import { IMoney } from "models/subscriptionPlan/IMoney";

class Coupon {
  @observable public id: number;
  @observable public name: string;
  @observable public code: string;
  @observable public description: string;
  @observable public discount_amount: number;
  @observable public discount_percentage: number;
  @observable public expired: boolean;
  @observable public years: number;
  @observable public months: number;
  @observable public days: number;

  @observable public expires_at: Date | null;
  @observable public campaign: Campaign;
  @observable public subscription_plans: SubscriptionPlan[];

  private readonly store: RootStore;

  constructor() {
    this.store = RootStore.getStore();
  }

  public withData(data: ICouponApiData): Coupon {
    this.load(data);
    return this;
  }

  load(data: ICouponApiData): void {
    this.id = Value.numberOrNull(data.id);
    this.name = data.name;
    this.code = data.code;
    this.description = data.description;
    this.discount_amount = data.discount_amount;
    this.discount_percentage = data.discount_percentage;
    this.expired = data.expired;
    this.years = data.years;
    this.months = data.months;
    this.days = data.days;

    this.expires_at = Value.dateOrNull(data.expires_at);

    if (data.campaign) {
      this.campaign = new Campaign(this.store.CampaignProvider).withData(data.campaign);
    } else if (data.campaign === null) {
      // only set to null if it really is null. If it's undefined, leave it alone.
      this.campaign = null;
    }

    if (data.subscription_plans) {
      this.subscription_plans = data.subscription_plans.map((plan) => new SubscriptionPlan(this.store.SubscriptionPlanProvider).withData(plan));
    }
  }

  public getCampaignName(): string {
    return this.campaign ? this.campaign.name : '';
  }

  public static getAll(): Promise<Coupon[]> {
    return CouponApi.getAll().then((couponApiDataArray) => {
      return couponApiDataArray.map((coupon) => new Coupon().withData(coupon));
    });
  }

  public applicableTo(subscriptionPlanId): boolean {
    return this.subscription_plans.findIndex((plan) => plan.id == subscriptionPlanId) >= 0;
  }

  public apply(price: IMoney): IMoney {
    let amount = Math.max(0, price.amount - this.discount_amount);
    if (this.discount_percentage > 0) {
      amount = price.amount * ((100 - this.discount_percentage) / 100);
    }

    return {
      amount: amount,
      currency: price.currency
    }
  }
}

export default Coupon;
