import React from 'react';
import {Link} from 'react-router-dom';
import {Row} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import {ComponentWithStore} from 'models/RootStore';
import {SubscriptionPlan} from 'models/subscriptionPlan/SubscriptionPlan';

import DefaultLayout from 'layouts/DefaultLayout';
import GooglePlayLink from 'components/shared/GooglePlayLink';
import AppStoreLink from 'components/shared/AppStoreLink';

import './SubscriptionNew.scss'

interface ISummaryPageProps {
    embedded?: boolean;
    subscriptionPlan?: SubscriptionPlan
}

interface ISummaryPageState {
    subscriptionPlan?: SubscriptionPlan
    platform?: 'android' | 'ios' | 'webgl'
}

class SummaryPage extends ComponentWithStore<ISummaryPageProps, ISummaryPageState> {
    private mounted = false;

    public constructor(props) {
        super(props);
        if (this.props.subscriptionPlan) {
            this.state = {
                subscriptionPlan: this.props.subscriptionPlan
            };
        } else {
            this.state = {
                subscriptionPlan: null,
                platform: null
            };
        }
    }

    public componentDidMount(): void {
        this.mounted = true;
        const {subscriptionPlan} = this.props;
        if (!subscriptionPlan) {
            this.store.SubscriptionPlanProvider.current().then((subscriptionPlan: SubscriptionPlan) => {
                this.setState({
                    subscriptionPlan: subscriptionPlan
                });
            });
            this.store.UserProvider.getPlatform().then(platform => this.setState({platform: platform}))
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    public render(): React.ReactElement {
        const {embedded} = this.props;
        if (embedded) {
            return this.renderComponent();
        }
        return this.renderWithLayout();
    }

    public renderWithLayout(): React.ReactElement {
        return (
            <DefaultLayout>
                <div className='rk-subscription-content'>
                    <div className='rk-form-container'>
                        {this.renderComponent()}
                    </div>
                </div>
            </DefaultLayout>
        );
    }

    public renderComponent(): React.ReactElement {
        const {subscriptionPlan, platform} = this.state;
        if (!subscriptionPlan) {
            return (
                <div>
                    <LoadingOutlined spin/>
                </div>
            )
        }

        return (
            <div>
                <Row>
                    <h2>Welcome to Rascal!</h2>
                    <div dangerouslySetInnerHTML={{__html: subscriptionPlan.subscribed_success_message}}></div>
                </Row>

                <Row justify='center'>
                    <Link className='rk-btn rk-btn-dark rounded submit-form' to='/'>Explore</Link>
                </Row>

                <Row justify='center'>
                    <div className='summary--info-app-badge'>
                        {platform == 'android' &&
                            <React.Fragment>
                                <a href='rascal://rascalkids.app/home'>Start Watching</a>
                                <GooglePlayLink/>
                            </React.Fragment>
                        }
                        {platform == 'ios' && <AppStoreLink/>}
                    </div>
                </Row>
            </div>
        );
    }
}

export default SummaryPage;
