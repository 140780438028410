import {action, makeObservable, observable} from 'mobx';
import _ from 'lodash';

import {Hash} from 'types/hash';
import {RootStore} from 'models/RootStore';
import {InMemoryStore, IStoreData} from 'models/InMemoryStore';
import {BillingAddressPermanentStore} from 'models/billingAddress/BillingAddressPermanentStore';
import {IBillingAddressApiData} from 'models/billingAddress/IBillingAddressApiData';

export class BillingAddressMemoryStore extends InMemoryStore<IBillingAddressApiData[]> {
    @observable private _billingAddresses: Hash<IStoreData<IBillingAddressApiData[]>> = {};

    public constructor(rootStore: RootStore) {
        super(rootStore, 'BillingAddress');
        makeObservable(this);
    }

    protected get PermanentStore(): BillingAddressPermanentStore {
        return this.BillingAddressPermanentStore;
    }

    public resetAll(): void {
        super.resetAll();
        this._billingAddresses = {};
    }

    @action
    public loadFromPermanentStore(userId: number): Promise<void> {
        return this.PermanentStore.read(userId)
            .then((billingAddresses: IBillingAddressApiData[] | null) => {
                if (billingAddresses) {
                    this.set(billingAddresses, userId);
                }
            });
    }

    protected getIStoreData(userId: number): IStoreData<IBillingAddressApiData[]> {
        let data: IStoreData<IBillingAddressApiData[]> = this._billingAddresses[userId.toString()];
        if (!data) {
            data = new IStoreData<IBillingAddressApiData[]>(userId, null, this);
            this._billingAddresses[userId.toString()] = data;
        }

        return data;
    }

    @action
    protected set(value: IBillingAddressApiData[], userId: number): void {
        this.getIStoreData(userId).setData(value);
    }

    @action
    public storeOne(item: IBillingAddressApiData, userId: number): Promise<void> {
        return this.PermanentStore.read(userId)
            .then((data: IBillingAddressApiData[] | null) => !data ? [] : data)
            .then((data: IBillingAddressApiData[]) => {
                let index: number = _.findIndex(data, (storedItem: IBillingAddressApiData) => storedItem && item && storedItem.id === item.id);
                if (index < 0) {
                    // Item does not exist, add it
                    data.push(item);
                } else {
                    // Item already exists, delete it and replace it with the new one
                    data.splice(index, 1, item);
                }
                this.set(data, userId);
                return this.PermanentStore.store(data, userId);
            });
    }

    @action
    public store(data: IBillingAddressApiData[], userId: number): Promise<void> {
        this.set(data, userId);
        return this.PermanentStore.store(data, userId);
    }

    public read(userId: number): Promise<IBillingAddressApiData[] | null> {
        return this.getIStoreData(userId).LoadData;
    }
}
