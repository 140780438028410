export class QueueSet<T> {
    private elements: T[] = []

    public enqueue(value: T) {
        if (!this.elements.includes(value)) {
            this.elements.push(value);
        }
    }

    public dequeue(): T | null {
        if (this.elements.length <= 0) return null;
        return this.elements.shift();
    }

    public insertAtHead(value: T) {
        if (!this.elements.includes(value)) {
            this.elements.unshift(value);
        }
    }

    public get Head(): T | null {
        return this.elements.at(0);
    }

    public get Tail(): T | null {
        return this.elements.at(-1);
    }
}
