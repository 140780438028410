import {action, makeObservable, observable} from 'mobx';

import {Hash} from 'types/hash';
import {RootStore} from 'models/RootStore';
import {InMemoryStore, IStoreData} from 'models/InMemoryStore';
import {UserPermanentStore} from 'models/user/UserPermanentStore';
import {IUserApiData} from 'models/user/IUserApiData';

export class UserMemoryStore extends InMemoryStore<IUserApiData> {
  @observable private _users: Hash<IStoreData<IUserApiData>> = {};

  public constructor(rootStore: RootStore) {
    super(rootStore, 'User');
    makeObservable(this);
  }

  protected get PermanentStore(): UserPermanentStore {
    return this.UserPermanentStore;
  }

  public resetAll(): void {
    super.resetAll();
    this._users = {};
  }

  public loadFromPermanentStore(id: number): Promise<void> {
    return this.PermanentStore.read(id)
      .then((user: IUserApiData | null) => {
        if (user) {
          this.set(user);
        }
      });
  }

  protected getIStoreData(id: number): IStoreData<IUserApiData> {
    let data: IStoreData<IUserApiData> = this._users[id.toString()];
    if (!data) {
      data = new IStoreData<IUserApiData>(id, null, this);
      this._users[id.toString()] = data;
    }

    return data;
  }

  @action
  protected set(value: IUserApiData): void {
    this.getIStoreData(value.id).setData(value);
  }

  @action
  public store(data: IUserApiData): Promise<void> {
    this.set(data);
    return this.PermanentStore.store(data);
  }

  public read(id: number): Promise<IUserApiData | null> {
    return this.getIStoreData(id).LoadData;
  }
}
