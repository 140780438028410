import React, {Component} from 'react';
import {Button, Col, Row} from 'antd';

import DefaultLayout from 'layouts/DefaultLayout';

import './PrivacyPolicy.scss';
import {Helmet} from "react-helmet";

// This renders either itself or Logged out Homepage.
class TermsAndConditions extends Component<any, any> {

  public render(): React.ReactElement {
    return (
      <DefaultLayout>
        <Helmet>
          <title>Rascal - Terms and Conditions</title>
        </Helmet>
        {this.renderTermsAndConditionsSection()}
      </DefaultLayout>
    );
  }

  private renderTermsAndConditionsSection() {
    return (
      <div className='policy--pages'>
        <Row>
          <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:17, offset: 4 }} lg={{ span:18, offset: 3 }} xl={{ span:16, offset: 4 }} xxl={{ span:12, offset: 6 }} className='aboutpage--section-text'>
            <h1>Terms and Conditions</h1>
            <div>
              <h3>Rascal Entertainment Limited, a New Zealand registered company of 94C Tinakori Road, Thorndon,
                Wellington, 6011 , New Zealand (<b>Rascal</b>) operates the website rascalkids.app (<b>Website</b>) and the
                associated RascalKids mobile application (<b>App</b>) which provide an interactive way for family members
                to read and share stories with younger members of the family (the Website and the App, collectively,
                the <b>Service</b>).
                < br />
                < br />
                Your use of the Service is subject to the following Terms and Conditions of Use (these <b>Terms</b>).
                The terms <b>you, your</b> and <b>yourself</b> each mean the person who accesses or uses the Service whether or
                not such person is a paid member.
              </h3>
            </div>
            <div>
              <h2 className={"custom-list-title"}>
                1. Acceptance of Agreement
              </h2>
              <ul className={'no-decoration'}>
                <li className={"custom-list"}>
                  By creating an account on the Service, (whether free or paid, through the App of the Website) you, as a user,
                  are a registered member of the Service and agree to be bound by these Terms.
                </li>
                <li className={"custom-list"}>
                  These Terms are a legal contract made between you and Rascal. Your use of the Service (whether or not you are a
                  paid member) will be governed by these Terms and the Privacy Policy.
                </li>
                <li className={"custom-list"}>
                  IF YOU DO NOT AGREE WITH THESE TERMS, DO NOT COMPLETE THE REGISTRATION PROCESS, USE OR OTHERWISE
                  ACCESS THE SERVICE.
                  <br />
                  <br />
                  RASCAL RESERVES THE RIGHT TO MAKE CHANGES ON REASONABLE GROUNDS TO THESE TERMS AT ANY TIME, AND ANY
                  SUCH CHANGES WILL BE POSTED ON THE WEBSITE. YOU WILL BE GIVEN REASONABLE TIME TO ACCESS, READ AND
                  CONSIDER ANY CHANGES. YOUR CONTINUED USE OF OUR SERVICE CONSTITUTES YOUR ACCEPTANCE OF SUCH CHANGES.
                  YOU ARE RESPONSIBLE FOR REVIEWING THE WEBSITE REGULARLY TO OBTAIN TIMELY NOTICE OF ANY SUCH CHANGES.
                  IF YOU DO NOT AGREE TO THESE TERMS, AS AMENDED AND UPDATED FROM TIME TO TIME, DO NOT CONTINUE TO USE OR
                  ACCESS THE SERVICE. YOU CAN TERMINATE THIS AGREEMENT IMMEDIATELY ON NOTICE TO RASCAL WITHOUT PENALTY
                  IF YOU DO NOT AGREE WITH THE UPDATED TERMS.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                2. Eligibility
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  In order to create an account with the Service, you must meet the following requirements:
                  <ol className={"ol-letters"}>
                    <li>
                      you are at least 18 years old,
                    </li>
                    <li>
                      you are able to form a legally binding contract,
                    </li>
                    <li>
                      you have never been convicted of a violent or sexually related criminal or civil offence in any country and/or not required to register as a sex offender with any government entity, and
                    </li>
                    <li>
                      you have never been banned from using the Service.
                    </li>
                  </ol>
                </li>
                <li className={"custom-list"}>
                  If you are a Paid User (as defined below) you can allow children in your care to use the Service
                  through your account, provided you comply with these Terms and the Privacy Policy. If you allow
                  children in your care to use the Service, and create content, then you consent to us collecting
                  and using that content.
                </li>
                <li className={"custom-list"}>
                  By registering as a member, you warrant to Rascal that you comply with all of these requirements.
                  If you breach this warranty, without limitation to Rascal’s other rights and remedies, Rascal is
                  entitled to terminate this Agreement under clause 8 below.
                </li>
                <li className={"custom-list"}>
                  Without limiting any other remedies available to Rascal (including Rascal’s rights to terminate in
                  clause 8 below), RASCAL RESERVES THE RIGHT AT ANY TIME TO SUSPEND OR REMOVE YOUR ACCOUNT AND ANY
                  CONTENT YOU SUBMIT FROM THE SERVICE AT RASCAL’S SOLE DISCRETION. TO THE MAXIMUM EXTENT PERMITTED
                  BY LAW, IF RASCAL DOES REMOVE YOUR ACCOUNT AND/OR ANY CONTENT YOU SUBMIT RASCAL WILL NOT BE LIABLE
                  TO YOU IN ANY WAY.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                3. Code of Conduct
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  By using the Service, you agree to comply with the following;
                  <ol className={"ol-letters"}>
                    <li>Your account is for your personal use only. You may not authorise others to use your account,
                      except that where you are a parent or legal guardian you may authorise your children to use your
                      account. You may not assign or otherwise transfer your account to any other person or entity.
                    </li>
                    <li>You must not enter any contact or identifying information into the Service or communicate and
                      contact or identifying information through the Services except where expressly required when
                      registering or when otherwise specifically requested by Rascal. This includes, but is not limited
                      to, your email address, phone number or physical address.
                    </li>
                    <li>You must not disclose any details of any other user or disclose personal information of others
                      through the Service or otherwise.
                    </li>
                    <li>You must provide accurate information regarding your identity when you register to become a
                      user and use the Service and you must not use the Service under false pretenses.
                    </li>
                    <li>You must not interfere with or damage the Service, including, without limitation, through the
                      use of viruses, registration bots, Trojan horses, harmful code, flood pings, denial of service
                      attacks, packet or IP spoofing, forged routing or electronic mail address information or similar
                      methods or technology, including methods that in any way reproduces or circumvents the
                      navigational structure or presentation of the Service or its contents.
                    </li>
                  </ol>
                </li>
                <li className={"custom-list"}>
                  If you breach these provisions, Rascal may terminate your account and your access to the Service for
                  breach in accordance with clause 8 of these Terms. Upon request by Rascal, you agree to provide any
                  further information to verify your identity and the accuracy of the personal information that you
                  have provided. If you do not provide sufficient information for Rascal’s verification purposes, Rascal
                  may terminate or suspend your account or access to the Service in accordance with clause 8.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                4. User Content
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  By submitting or posting any content to our Service, you represent and warrant to us that the content
                  is posted by you, that you have created the content and are the exclusive owner of the content and
                  use of your content by us will not infringe or violate the rights of any third party. By submitting
                  any content to the Service, you automatically grant, and you represent and warrant that you have the
                  right to grant, to Rascal, and Rascal’s licensees, affiliates and successors, a perpetual, worldwide,
                  non-exclusive, royalty-free right and license to use, reproduce, display, and modify such content or
                  incorporate into other works such content, for the purposes of the operation of the Service.
                </li>
                <li className={"custom-list"}>
                  RASCAL IS NOT RESPONSIBLE FOR ANY CONTENT CREATED, POSTED OR ENTERED ON OR THROUGH RASCAL’S WEBSITE,
                  APP, OR OTHERWISE THROUGH THE SERVICE. You are solely responsible for any content that you create,
                  submit or transmit via the Service, and you shall be solely liable for any content created or uploaded
                  via the Service that is deemed to infringe or violate any third party’s intellectual property rights.
                  You agree not to include any illegal or unlawful information in your profile. You also agree not to
                  post or submit any content that:
                  <ol className={"ol-letters"}>
                    <li>is offensive or promotes racism, bigotry, hatred or physical harm of any kind against any group or individual;</li>
                    <li>harasses or advocates harassment of another person;</li>
                    <li>exploits people in a sexual or violent manner;</li>
                    <li>contains excessive violence, or offensive subject matter;</li>
                    <li>contains any personal information about any person or solicits any personal information from any person;</li>
                    <li>
                      constitutes or promotes information that you know is false or misleading or promotes illegal activities
                      or conduct that is abusive, threatening, obscene, defamatory or libellous;
                    </li>
                    <li>constitutes or promotes an illegal or unauthorized copy of another person's copyrighted work;</li>
                    <li>
                      involves the transmission of "junk mail," "chain letters," or unsolicited mass mailing, instant
                      messaging, "spimming," or "spamming" or unsolicited electronic commercial messages;
                    </li>
                    <li>furthers or promotes any criminal activity or enterprise;</li>
                    <li>solicits passwords or personal identifying information for commercial or unlawful purposes from other members;</li>
                    <li>
                      involves commercial activities and/or sales without prior written consent from Rascal such as
                      contests, sweepstakes, barter, advertising, or pyramid schemes;
                    </li>
                    <li>
                      includes a sound recording, photograph or video of another person that you have posted
                      without that person's consent; or
                    </li>
                    <li>
                      violates the privacy rights, publicity rights, defamation rights, copyrights, trademark rights,
                      contract rights or any other rights of any person or entity.
                    </li>
                  </ol>
                </li>
                <li className={"custom-list"}>
                  The foregoing is a partial list of the kind of content that is illegal or prohibited on Rascal’s
                  Service. Rascal reserves the right to investigate and take appropriate legal action in Rascal’s
                  sole discretion against anyone who violates this clause 4 or any other provision of these Terms,
                  including without limitation, removing the offending communication from the Service, terminating
                  the violator's account and access to the Service (in accordance with clause 8) and contacting and
                  disclosing information to the relevant authorities.
                </li>
                <li className={"custom-list"}>
                  YOU SHALL BE SOLELY RESPONSIBLE FOR THE CONTENT OF MATERIALS YOU MAY CREATE THROUGH OR UPLOAD TO THE
                  SERVICE. RASCAL CANNOT GUARANTEE, AND ASSUMES NO RESPONSIBILITY FOR, VERIFYING THE CONTENT CREATED
                  OR PROVIDED BY OTHER USERS OF THE SERVICE.
                </li>
                <li className={"custom-list"}>
                  Without limiting any right that Rascal may have, Rascal may, at its sole discretion, edit, modify,
                  or delete content from your account, delete any other content or material you have created through
                  or uploaded to Rascal’s Website, App, or the Service, suspend or cancel your account if we believe
                  that content entered creates, or may create, liability for Rascal, if Rascal believes the information
                  to be misleading or inappropriate or if Rascal believes you have breached these Terms. To the maximum
                  extent permitted by law, Rascal shall not be liable to you if Rascal exercises any of these rights,
                  including cancellation of any type of account, or a permanent ban from using the Service.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                5. Subscriptions and Accounts
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  In order to download and use the Service someone must purchase a paid subscription to the Service
                  (<b>Paid User</b>). The Paid User can then provide access to the Service to other members of their
                  family or close friends (<b>Other Users</b>), in accordance with the rules set by Rascal from time
                  to time. Subject to those rules, the Other Users will be able to create an account with the Service
                  without making any payment. The accounts of the Other Users are linked to the account of the Paid User,
                  and if the Paid User ceases to be a user or otherwise has their account removed or suspended under
                  these Terms, all Other Users will also cease to have access to the Service.
                </li>
                <li className={"custom-list"}>
                  SUBSCRIPTIONS OF PAID USERS AUTOMATICALLY RENEW, AND YOU ENTER INTO THIS AGREEMENT ACKNOWLEDGING
                  THAT IF YOU PURCHASE A SUBSCRIPTION IT WILL AUTOMATICALLY RENEW AT THE END OF EVERY PERIOD UNLESS
                  YOU CANCEL IT THROUGH YOUR APPLE APP STORE, GOOGLE PLAY, OR EQUIVALENT APP MARKETPLACE ACCOUNT, OR IF
                  YOU HAVE PAID FOR YOUR SUBSCRIPTION THROUGH CREDIT CARD, REMOVING YOUR CREDIT CARD FROM YOUR ACCOUNT
                  WILL STOP AUTO-RENEWAL CHARGES.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                6. Cancellations and Refunds
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  <b>Cancellation:</b> You may cancel your account at any time, subject to these Terms. However, if you
                  are a Paid User and do not wish to continue being charged, you must cancel your account at least 24
                  hours prior to the end of the current period. There is no additional cancellation fee. UNINSTALLING
                  THE SERVICE WILL NOT CANCEL A SUBSCRIPTION. You have to manually cancel your subscriptions through the Website.
                </li>
                <li className={"custom-list"}>
                  <b>Refunds</b>
                  <ol className={"ol-letters"}>
                    <li className={"custom-list"}>
                      ALL CHARGES FOR PURCHASES ARE NON-REFUNDABLE, AND THERE ARE NO REFUNDS OR CREDITS FOR PARTIALLY
                      USED PERIODS. Exceptions may be made if the laws applicable in your jurisdiction require refunds
                      in certain limited circumstances.
                    </li>
                    <li className={"custom-list"}>
                      If you are a resident of California, you may be entitled to a refund provided you cancel your
                      subscription within 10 business days after your subscription begins. If you have cancelled
                      your subscription within 10 business days and you are a resident of California, please send
                      us an email at support@rascalkids.app with your username and details in order to sort your refund.
                    </li>
                    <li className={"custom-list"}>
                      If you are a resident of the United Kingdom or the European Union, you may be entitled to a refund
                      provided you cancel your subscription within 14 days after your subscription begins. If you are a
                      resident of the United Kingdom or European Union, please send us an email at support@rascalkids.app
                      with your username and details in order to sort your refund.
                    </li>
                  </ol>
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                7. Account Information and Data Deletion
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  <b>Username and password:</b> YOU ARE RESPONSIBLE FOR KEEPING YOUR PASSWORD SECRET AND SECURE. WE ARE NOT
                  RESPONSIBLE FOR THIRD PARTY ACCESS TO YOUR ACCOUNT THAT RESULTS FROM THEFT OR MISAPPROPRIATION OF YOUR
                  USERNAME(S) OR PASSWORD(S). Without limiting the foregoing, you agree that:
                  <ol className={"ol-letters"}>
                    <li>
                      you will not permit any other person to use your user name and password (except for the ability
                      to allow your children to use your account if you are a Paid User);
                    </li>
                    <li>you will not disclose, or provide to any other person, your password;</li>
                    <li>you will immediately notify Rascal of any unauthorized use of your username and password or any other breach of security;</li>
                    <li>you will ensure that you log off from your account at the end of each session; and</li>
                    <li>you acknowledge and agree that you are responsible for all use of the Service under your user name and password.</li>
                  </ol>
                </li>
                <li className={"custom-list"}>
                  <b>Account Deletion:</b> You have the right to terminate your account if you do not wish to use the Service
                  any longer. You may delete your account any time you wish by choosing the option to ‘Delete Account’
                  in your profile settings. This will remove your profile and delete all your information from the Service.
                  IF YOU CHOOSE TO DELETE YOUR ACCOUNT, WE WILL RETAIN YOUR CONTENT FOR A PERIOD OF 12 MONTHS FROM
                  THE DATE OF DELETION. AFTER THAT PERIOD HAS EXPIRED, WE MAY DELETE YOUR CONTENT. PLEASE CONTACT
                  US IF YOU ARE INTERESTED IN DOWNLOADING YOUR CONTENT, FOR WHICH THERE MAY BE A SEPARATE CHARGE.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                8. Termination
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Rascal reserves the right, and may exercise its right to terminate your subscription or account and your access to the Service if:
                  <ol className={"ol-letters"}>
                    <li>you breach these Terms;</li>
                    <li>Rascal considers (in its sole discretion) that you have violated or are likely to violate any applicable laws;</li>
                    <li>a serious complaint or multiple complaints are received about you from any person;</li>
                    <li>you impersonate another member or person; or</li>
                    <li>Rascal, in its sole discretion, deems your behaviour whilst using the Service or in connection with your use of the Service, to be unacceptable.</li>
                  </ol>
                </li>
                <li className={"custom-list"} >
                  YOU AGREE THAT ANY TERMINATION OF YOUR ACCOUNT AND ACCESS TO THE SERVICE UNDER THESE TERMS PURSUANT
                  TO CLAUSE 8.1 MAY BE EFFECTED WITHOUT PRIOR NOTICE, AND ACKNOWLEDGE AND AGREE THAT RASCAL MAY
                  IMMEDIATELY DEACTIVATE YOUR ACCOUNTS (INCLUDING ANY ACCOUNTS OF OTHER USERS THAT ARE TIED TO YOUR
                  PAID USER ACCOUNT) AND/OR BAR ANY FURTHER ACCESS TO THE SERVICE. FURTHER, YOU AGREE THAT, TO THE
                  MAXIMUM EXTENT PERMITTED BY LAW, RASCAL WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY COSTS
                  OR DAMAGES OF ANY KIND FOR OR RESULTING FROM ANY SUCH TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE
                  SERVICE.
                </li>
                <li className={"custom-list"}>
                  You may terminate your access to the Service at any time via Rascal’s Website or by sending Rascal
                  written or email notice of termination to the contact information provided below. IN THE EVENT THAT
                  YOU TERMINATE YOUR ACCOUNT AND SUBSCRIPTION, YOU WILL NOT BE ENTITLED TO ANY REFUND OF ANY PAID
                  SUBSCRIPTION FEES, unless as specified otherwise in these Terms or required under applicable law.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                9. Links, Advertisements and Non-commercial Use
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  Rascal Service may occasionally contain links and advertisements to third-party websites, companies
                  and products. These links and advertisements are provided solely as a convenience to you and not as
                  an endorsement of the contents, value, quality, safety, products, or accuracy on such third-party websites.
                </li>
                <li className={"custom-list"}>
                  Rascal is not responsible for the content, value, quality, safety, products or accuracy of linked
                  third-party websites, companies and products; and do not make any representations regarding the content,
                  value, quality, safety, products, or accuracy of materials on such third party websites. If you decide to
                  access, use, or purchase from linked third-party websites, companies or products, you do so at your own risk.
                </li>
                <li className={"custom-list"}>
                  Rascal’s Service is for personal use only and must not be used in connection with any commercial
                  purposes. Organizations, companies, and/or businesses must not use the Service for any purpose.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                10. Disclaimer
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  You expressly understand and agree that YOUR USE OF RASCAL’S SERVICE IS AT YOUR SOLE RISK. RASCAL
                  EXPRESSLY DISCLAIMS, TO THE MAXIMUM EXTENT PERMITTED BY LAW, ALL REPRESENTATIONS, WARRANTIES OR
                  GUARANTEES, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY REPRESENTATIONS,
                  WARRANTIES OR GUARANTEES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                </li>
                <li className={"custom-list"}>
                  Rascal makes no representation, warranty or guarantee, to the maximum extent permitted by law, that
                  <ol className={"ol-letters"}>
                    <li>the Service provided will be uninterrupted, timely, secure, or error free;</li>
                    <li>the content provided on the Website or in the Service is error-free or appropriate for any particular age or person; or</li>
                    <li>the quality of any products or services obtained through the Service will meet your requirements.</li>
                  </ol>
                </li>
                <li className={"custom-list"}>
                  YOU DO NOT ENTER INTO THIS AGREEMENT IN RELIANCE ON ANY REPRESENTATION, WARRANTY, GUARANTEE OR
                  OTHER TERM, EXCEPT AS EXPRESSLY SET OUT IN THESE TERMS.
                </li>
                <li className={"custom-list"}>
                  No advice or information that is obtained by you from Rascal or otherwise shall create any
                  representation, warranty or guarantee not expressly stated in these Terms.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                11. Limitation of Liability
              </h2>
              <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW:</p>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  Neither party shall be liable to the other under any circumstances for any indirect, incidental,
                  special, consequential or exemplary loss or damages, including but not limited to damages for loss of
                  profit, goodwill, use, data, or other intangible losses that result from the Service, or the
                  inability to access the Service.
                </li>
                <li className={"custom-list"} >
                  Rascal shall not be liable for the cost of procurement of substitute goods or services resulting from
                  services provided by Rascal.
                </li>
                <li className={"custom-list"}>
                  Rascal shall not be liable for the conduct or statements of other users or third parties in relation
                  to the Service.
                </li>
                <li className={"custom-list"}>
                  None of the disclaimers, limitations or exclusions set out in these Terms will limit or exclude
                  any form of liability to the extent such liability cannot be limited or excluded under applicable
                  law, for example, under the Consumer Guarantees Act 1993 (New Zealand), the Fair Trading Act 1986
                  (New Zealand) or any other applicable consumer protection legislation.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                12. Indemnity
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"} >
                  YOU AGREE TO INDEMNIFY RASCAL FULLY FROM AND AGAINST ALL CLAIMS, LIABILITY, LOSS, COSTS AND EXPENSES
                  (INCLUDING THE FULL AMOUNT OF LEGAL COSTS) INCURRED OR SUFFERED BY RASCAL IN RESPECT OF OR IN CONNECTION WITH:
                  <ol className={"ol-letters"}>
                    <li>information contained in your account;</li>
                    <li>any material, including (without limitation) recordings, you enter on the Website or App or that is created using the Service;</li>
                    <li>your communications with or behaviour towards other users;</li>
                    <li>any death, personal injury or damage to property you may cause; or</li>
                    <li>your breach of these Terms or any applicable laws.</li>
                  </ol>
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                13. Binding Arbitration and Class Action Waiver
              </h2>
              <p>Except for members residing within the UK, EU or European Economic Area and elsewhere where prohibited by applicable law:</p>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  You acknowledge that any disputes pursued against Rascal will be on an individual basis. Any dispute,
                  controversy or claim arising out of, relating to or in connection with this Agreement, including the
                  breach, termination or validity thereof, shall be finally resolved by binding arbitration.
                </li>
                <li className={"custom-list"}>
                  Neither you nor Rascal, will bring nor participate in any class action or other class proceeding in
                  connection with any dispute with the other party. Further, neither you nor Rascal agrees to class
                  arbitration or any arbitration where a person brings a dispute as a representative of the other person.
                  <br />
                  BY USING THE SERVICE YOU WAIVE ANY RIGHT TO ASSERT ANY CLAIMS AGAINST RASCAL AS A REPRESENTATIVE OR
                  MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION, EXCEPT WHERE SUCH WAIVER IS PROHIBITED BY LAW OR
                  DEEMED BY A COURT OF LAW TO BE AGAINST PUBLIC POLICY.
                </li>
                <li className={"custom-list"}>
                  TO THE EXTENT EITHER PARTY IS PERMITTED BY LAW OR COURT OF LAW TO PROCEED WITH A CLASS OR
                  REPRESENTATIVE ACTION AGAINST THE OTHER, THE PARTIES AGREE THAT: (a) THE PREVAILING PARTY SHALL NOT BE
                  ENTITLED TO RECOVER ATTORNEYS’ FEES OR COSTS ASSOCIATED WITH PURSUING THE CLASS OR REPRESENTATIVE
                  ACTION (NOT WITHSTANDING ANY OTHER PROVISION IN THIS AGREEMENT); AND (b) THE PARTY WHO INITIATES OR
                  PARTICIPATES AS A MEMBER OF THE CLASS WILL NOT SUBMIT A CLAIM OR OTHERWISE PARTICIPATE IN ANY
                  RECOVERY SECURED THROUGH THE CLASS OR REPRESENTATIVE ACTION.
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                14. General Provisions
              </h2>
              <ul className={"no-decoration"}>
                <li className={"custom-list"}>
                  <b>Compliance with applicable laws:</b> The Service is intended for use in a number of jurisdictions,
                  including in the United States, the European Union and the United Kingdom. You agree to comply with
                  all applicable laws when using the Service and dealing with other users. This includes compliance with
                  any and all applicable local, state, national and international laws and regulations, including,
                  but not limited to, United States export control laws.
                </li>
                <li className={"custom-list"}>
                  <b>System integrity:</b> Rascal will use its reasonable endeavours to ensure the availability of the
                  Service, subject to any downtime required for maintenance. However, to the maximum extent permitted by
                  law, Rascal takes no responsibility for any system unavailability, or for any loss that is incurred as
                  a result of the Service being unavailable. Further, to the maximum extent permitted by law, Rascal
                  assumes no responsibility for the corruption of any content, data or information held by Rascal.
                </li>
                <li className={"custom-list"}>
                  <b>Force Majeure:</b> Rascal shall have no liability for any lack of performance, damages, unavailability or
                  failure of our Service, or for any failure on Rascal’s part to comply with these Terms where the same
                  arises from any cause reasonably beyond the control of Rascal.
                </li>
                <li className={"custom-list"}>
                  <b>No Waiver:</b> If Rascal does not exercise or enforce any right available to Rascal under these
                  Terms, it does not constitute a waiver of those rights.
                </li>
                <li className={"custom-list"}>
                  <b>Partial Invalidity:</b> If any provision of these Terms become or are held to be invalid,
                  unenforceable or illegal for any reason and in any respect, that provision shall be severed from the
                  remaining terms and conditions, which shall continue in full force and effect.
                </li>
                <li className={"custom-list"}>
                  <b>Governing Law:</b> These Terms and your membership are governed by the laws of New Zealand. You submit to
                  the non-exclusive jurisdiction of the Courts of New Zealand. You agree that the United Nations Convention
                  on Contracts for the International Sale of Goods does not apply.
                </li>
                <li className={"custom-list"}>
                  <b>Intellectual Property Rights:</b> Rascal owns all intellectual property rights and other proprietary
                  rights in the Website and the App (including content, text, graphics, logos, icons and sound recordings,
                  but excluding content recorded by users) and the software and other material underlying and forming
                  part of the Service and the Website or App. You may not without Rascal’s prior written permission,
                  in any form or by any means:
                  <ol className={"ol-letters"}>
                    <li>
                      adapt, reproduce, copy, store, distribute, print, display, perform, publish or create derivative
                      works from any part of the Website, App or Service; or
                    </li>
                    <li>
                      commercialise, copy or on-sell any content, information or items obtained from any part of the
                      Website, App or Service.
                    </li>
                  </ol>
                </li>
                <li className={"custom-list"}>
                  <b>Entire agreement:</b> These Terms, Rascal’s Privacy Policy and the Cookie Policy supersede all previous
                  conditions, understandings, commitments, agreements and representations whatsoever, whether oral or
                  written; and constitutes the entire agreement between the parties relating to the subject matter of
                  these Terms.
                </li>
                <li className={"custom-list"}>
                  <b>Privacy:</b> Please refer to our Privacy Policy available at the following link: <a href={'https://rascalkids.app/privacy-policy'}>https://rascalkids.app/privacy-policy</a>.
                </li>
                <li className={"custom-list"}>
                  Emails and newsletters: Rascal will send you emails relating to your account, changes to these Terms,
                  transactions and other activities on our Website or App, and for promoting and marketing other Rascal
                  products and services to you. Rascal may also send regular electronic newsletters to members.
                  Newsletters will contain clear and obvious instructions for how you can unsubscribe from the mailing list.
                </li>
                <li className={"custom-list"}>
                  <b>Acknowledgement:</b> You represent and warrant that you:
                  <ol className={"ol-letters"}>
                    <li>
                      are not located in a country that is subject to a US Government embargo, or that has been
                      designated by the US Government as a 'terrorist supporting' country; and
                    </li>
                    <li>
                      are not listed on any US Government list of prohibited or restricted persons.
                    </li>
                  </ol>
                </li>
                <p>
                  If you have any questions or concerns about these Terms, the Services, or the Website, you may contact
                  a representative of our company by contacting us by email at support@rascalkids.app.
                </p>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TermsAndConditions;

