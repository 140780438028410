/* PrivateRoute component definition */
import React from 'react';
import {Route, Navigate} from 'react-router';
import SessionCookieStore from 'models/session/SessionCookieStore';

function RequireAuth({children}: { children: React.ReactElement }): React.ReactElement {
    if (SessionCookieStore.hasCredentials()) {
        return children;
    } else {
        return <Navigate to={'/sign_in'} replace={true}/>;
    }
}

function PrivateRoute(element: React.ReactElement): React.ReactElement {
    return <RequireAuth>{element}</RequireAuth>;
}

export default PrivateRoute;
