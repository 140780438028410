import React, {Component} from 'react';
import {notification, Typography} from 'antd';
import {EditOutlined} from '@ant-design/icons';

import './EditValueInPlace.scss';

const {Text} = Typography;

export interface IEditValueInPlaceProps {
  value: string | number | null;
  name: string;
  inline?: boolean;
  nullEmptyString?: boolean;
  error?: string;
  postText?: string | React.ReactElement;
}

export interface IEditValueInPlaceState {
  error: string | null;
  editing: boolean;
}

class EditValueInPlace<T extends IEditValueInPlaceProps> extends Component<T, IEditValueInPlaceState> {
  protected onChangeCallback: (value: string | number | null) => void;
  protected format: 'string' | 'number';
  protected allowNegative: boolean = false;
  protected className: string = 'rk-edit-value-in-place';

  constructor(props: T) {
    super(props);
    this.state = {
      error: this.props.error,
      editing: false
    };

    this.startedEditing = this.startedEditing.bind(this);
    this.onChange = this.onChange.bind(this);
    this.endedEditing = this.endedEditing.bind(this);
  }

  public componentDidUpdate(prevProps: Readonly<IEditValueInPlaceProps>, prevState: Readonly<IEditValueInPlaceState>, snapshot?: any): void {
    if (this.props.error !== prevProps.error) {
      this.setState({
        error: this.props.error
      });

      if (this.props.error && this.props.error.length > 0) {
        this.openErrorNotification(this.props.error);
      }
    }
  }

  public openErrorNotification(errorDescription): void {
    const {name} = this.props;
    console.log('Opening an error notification!')
    notification['error']({
      message: `Error Changing ${name}`,
      description: errorDescription
    });
  }

  private signalError(errorMsg): void {
    this.setState({error: errorMsg});
    this.openErrorNotification(errorMsg);
  }

  public onChange(value: string): void {
    if (this.props.value == value) return; //allow coercion so we dont have to worry about numbers and strings.

    const {nullEmptyString} = this.props;
    this.setState({error: null});
    let newValue: string | number = value;

    if (this.format === 'string') {
      // Force the output value to be a string.
      newValue = `${value}`;
    } else if (this.format === 'number') {
      newValue = Number(newValue);
      if (Number.isNaN(newValue)) {
        this.signalError('Input must be a valid number');
        return;
      }

      if (!this.allowNegative && newValue < 0) {
        this.signalError('Input must be greater than 0');
        return;
      }
    }

    if (nullEmptyString && newValue) {
      // Force strings that are empty to be null.
      newValue = `${newValue}`.length > 0 ? newValue : null;
    }

    this.onChangeCallback(newValue);
  }

  public startedEditing(): void {
    this.setState({editing: true});
  }

  public endedEditing(): void {
    this.setState({editing: false});
  }

  public render(): React.ReactElement {
    const {value, inline, postText} = this.props;
    let className = this.className;
    if (inline) {
      className = `${className} rk-editable-inline-block`;
    }

    let icon = <EditOutlined style={{color: '#FF8C68'}}/>;
    if (postText) {
      icon = <React.Fragment><span style={{color: 'black'}}>{postText}</span> <EditOutlined/></React.Fragment>
    }

    const formattedValue = value === undefined ? '' : `${value}`;
    return (
      <React.Fragment>
        <Text className={className}
              editable={{
                onStart: this.startedEditing,
                onChange: this.onChange,
                onEnd: this.endedEditing,
                onCancel: this.endedEditing,
                autoSize: false,
                icon: icon
              }}
              ellipsis={false}>
          {formattedValue}
        </Text>
      </React.Fragment>
    );
  }
}

export default EditValueInPlace;
