import React from 'react';
import {observer} from 'mobx-react';
import {ComponentWithStore, withStore} from 'models/RootStore';
import {Invoice} from 'models/invoice/Invoice';
import './InvoicesTable.scss';
import {RascalTable} from "components/shared/RascalTable";
import {ITableProps, ITableState} from "helpers/Tables";

interface IInvoiceTableProps extends Partial<ITableProps<Invoice>> {
    userId: number
}

interface IInvoiceTableState extends Partial<ITableState<Invoice>> {
}

class InvoicesTable extends ComponentWithStore<IInvoiceTableProps, IInvoiceTableState> {
    constructor(props) {
        super(props);

        this.getInvoiceSearch = this.getInvoiceSearch.bind(this);
    }

    public render(): React.ReactElement {
        return (
            <div className='rk-invoices-table'>
                <RascalTable
                    columns={[
                        {
                            key: 'period_start',
                            type: 'date',
                            getHref: (invoice: Invoice) => `/invoices/${invoice.id}`
                        },
                        {key: 'period_end', type: 'date', getHref: (invoice: Invoice) => `/invoices/${invoice.id}`},
                        {key: 'paid_at', type: 'date'},
                        {key: 'customer_name', type: 'search'},
                        {key: 'plan_name', type: 'search'},
                        {key: 'last_four_digits', type: 'text'},
                        {key: 'Price', type: 'text'},
                    ]}
                    getTableSearch={this.getInvoiceSearch}/>
            </div>
        );
    }

    private async getInvoiceSearch(searchParams) {
        const {userId} = this.props;

        searchParams.user_ids = [userId];

        return await this.store.InvoiceProvider.getInvoiceSearch(searchParams)
            .then((data) => {
                return {data: data.invoices, total: data.total, currentPage: data.page, pageSize: data.page_size}
            });
    }
}

export default withStore(observer(InvoicesTable));
