import {SortOrder} from 'antd/lib/table/interface';
import Api from 'models/Api';
import {SearchApi} from 'models/SearchApi';
import {IInvoiceApiData} from 'models/invoice/IInvoiceApiData';
import {IInvoiceSearchApiData} from 'models/invoice/IInvoiceSearchApiData';
import {IInvoiceApiUpdateData} from 'models/invoice/IInvoiceUpdateApiData';
import {ApiResponseData} from 'models/ApiResponseData';

export type IInvoiceApiSearchOrdering = 'id';

export interface IInvoiceApiSearchFilterParams {
    id?: string;
    customer_name?: string;
    plan_name?: string;
    period_start?: string;
    period_end?: string;
    paid_at?: string;
    deleted_at?: boolean;
    next_revision_id?: boolean;
    order_by?: IInvoiceApiSearchOrdering;
    order?: SortOrder;
}

export interface IInvoiceApiSearchParams {
    subscription_ids?: number[];
    user_ids?: number[];
    billing_address_ids?: number[];
    page?: number;
    page_size?: number;
    filters?: IInvoiceApiSearchFilterParams
}

export class InvoiceApi {
    public static getAllForSubscription(subscriptionId: number): Promise<IInvoiceApiData[]> {
        return Api.get(`/api/v1/subscriptions/${subscriptionId}/invoices`)
            .then(response => response.json())
    }

    public static getAllForUser(userId: number): Promise<IInvoiceApiData[]> {
        return Api.get(`/api/v1/users/${userId}/invoices`)
            .then(response => response.json())
    }

    public static getAllForBillingAddresses(addressId: number): Promise<IInvoiceApiData[]> {
        return Api.get(`/api/v1/users/${addressId}/invoices`)
            .then(response => response.json())
    }

    public static getInvoiceSearch(searchParams?: IInvoiceApiSearchParams): Promise<IInvoiceSearchApiData> {
        let query: string[] = [];
        let queryStr = ''
        if (searchParams) {
            const {subscription_ids, user_ids, billing_address_ids} = searchParams;
            if (subscription_ids && subscription_ids.length > 0) {
                query.push(`subscription_ids=${subscription_ids.join(',')}`);
            }
            if (user_ids && user_ids.length > 0) {
                query.push(`user_ids=${user_ids.join(',')}`);
            }
            if (billing_address_ids && billing_address_ids.length > 0) {
                query.push(`billing_address_ids=${billing_address_ids.join(',')}`);
            }
            queryStr = SearchApi.buildQueryFromParams(searchParams, query);
        }

        return Api.get(`/api/v1/invoices/search${queryStr}`)
            .then(response => response.json());
    }

    public static get(id: number): Promise<IInvoiceApiData> {
        return Api.get(`/api/v1/invoices/${id}`)
            .then(response => response.json())
            .then(json => json.invoice);
    }

    public static deleteInvoice(id: number): Promise<ApiResponseData> {
        return Api.del(`/api/v1/invoices/${id}`)
            .then(response => response.json());
    }

    public static editInvoice(id: number, params: IInvoiceApiUpdateData): Promise<IInvoiceApiData> {
        return Api.put(`/api/v1/invoices/${id}`, {
            body: JSON.stringify({
                invoice: params
            })
        })
            .then(response => response.json())
            .then(json => json.invoice);
    }

    static getPaymentIntent(id: number) {
        return Api.get(`/api/v1/invoices/${id}/payment_intent`)
            .then(response => response.json());
    }

    static pay(id: number, param: { billing_address_id: number; stripe_setup_intent: string; credit_card_id: number }) {
        return Api.put(`/api/v1/invoices/${id}/pay`, {
            body: JSON.stringify(param)
        }).then(response => response.json());
    }
}
