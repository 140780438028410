import React, {Component} from 'react';
import LoadingIcon from 'components/public/pages/webgl_app/LoadingIcon';
import './LoadingOverlay.scss'

export interface IStoryDetailsButtonProps {
}

class LoadingOverlay extends Component<IStoryDetailsButtonProps, any> {
    public render(): React.ReactElement {

        return (
            <div className='rk-loading-overlay'>
                <LoadingIcon color='white'/>
            </div>
        );
    }
}

export default LoadingOverlay;