import Api from 'models/Api';
import {PaymentMethod} from '@stripe/stripe-js/types/api/payment-methods';

import {ICreditCardApiData} from 'models/creditCard/ICreditCardApiData';

export class CreditCardApi {
    public static getAll(userId: number): Promise<ICreditCardApiData[]> {
        return Api.get(`/api/v1/users/${userId}/credit_cards`)
            .then(response => response.json())
            .then(json => json.credit_cards);
    }

    public static newIntent(userId: number): Promise<{ client_secret: any }> {
        return Api.get(`/api/v1/users/${userId}/credit_cards/new`)
            .then(response => response.json());
    }

    public static create(body: { userId: number, billingAddressId: number, paymentMethod: string | PaymentMethod }): Promise<{ credit_card: { id: number } }> {
        return Api.post(`/api/v1/credit_cards`, {
            body: JSON.stringify({
                user_id: body.userId,
                payment_method: body.paymentMethod,
                billing_address_id: body.billingAddressId
            })
        }).then(response => response.json());
    }
}