import React, {useState} from "react";
import {Col, Row, Button, Modal, Select} from "antd";
import {Logger} from "sass";
import logger from "loglevel";
import {observer} from "mobx-react";
import CouponCampaignModal from "components/admin/pages/coupons/CouponCampaignModal";
import NewsletterModal from "components/public/pages/home/public/NewsletterModal";


class DefaultFooter extends React.Component<any, any> {

  private newsletterModal: Boolean = false;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      submitting: false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this.setState({
      open: false,
      submitting: false
    });
  }

  public onSubmit() {
    logger.debug("Trying to submit the Newsletter modal.")
  }

  public onCancel() {
    this.setState({
      open: false
    });
  }

  private renderRascalCol(): React.ReactElement {
    return (
      <Col xs={ 12 } sm={ 6 }>
        <div className='rk-footer-link'>
          <p>Rascal</p>
        </div>
        <div className='rk-footer-link'>
          <a href="/faq#creator-enquiries" rel="noopener noreferrer">Creator Enquires</a>
        </div>
        <div className='rk-footer-link'>
          <a href="https://apps.apple.com/us/app/rascal/id1612015226" rel="noopener noreferrer">iOS App</a>
        </div>
        <div className='rk-footer-link'>
          <a href="https://play.google.com/store/apps/details?id=app.rascalkids.rascal" rel="noopener noreferrer">Android App</a>
        </div>
      </Col>
    );
  }

  private openNewsletterModal(open) {
    this.setState({
      open: open
    });
  }

  private renderForParentsCol(): React.ReactElement {
    const {open} = this.state;

    return (
      <Col xs={ 12 } sm={ 6 }>
        <div className='rk-footer-link'>
          <p>For Parents</p>
        </div>
        <div className='rk-footer-link'>
          <a type="primary" onClick={() => this.openNewsletterModal(true)}>
            Newsletter
          </a>
        </div>
        <div className='rk-footer-link'>
          <a href="/about" rel="noopener noreferrer"> Features </a>
        </div>
        {open && <NewsletterModal onClose={() => this.setState({open: false})} /> }
      </Col>
    );
  }

  private renderAboutCol(): React.ReactElement {
    return (
      <Col xs={ 12 } sm={ 6 }>
        <div className='rk-footer-link'>
          <p>ABOUT</p>
        </div>
        <div className='rk-footer-link'>
          <a href="/faq" rel="noopener noreferrer"> FAQ </a>
        </div>
        <div className='rk-footer-link'>
          <a href="/about" rel="noopener noreferrer"> About Rascal </a>
        </div>
      </Col>
    );
  }

  private renderContactUsCol(): React.ReactElement {
    return (
      <Col xs={ 12 } sm={ 6 }>
        <div className='rk-footer-link'>
          <p>CONTACT US</p>
        </div>
        <div className='rk-footer-link'>
          <a href="mailto:contact@rascalkids.app?subject=Customer%20Support"
             rel="noopener noreferrer"> Customer Support </a>
        </div>
      </Col>
    );
  }

  private renderTermsRow(): React.ReactElement {
    return (
      <Row className='rk-footer-links'>
        <Col xs={ 24 } sm={ 12 }>
          <div className='rk-footer-link'>
            <p> © Copyright Rascal Entertainment Limited. All rights reserved. </p>
          </div>
        </Col>
        <Col xs={ 8 } sm={ 5 }>
          <div className='rk-footer-link'>
            <a href="/terms-and-conditions" rel="noopener noreferrer"> Terms & Conditions </a>
          </div>
        </Col>
        <Col xs={ 8 } sm={ 4 }>
          <div className='rk-footer-link'>
            <a href="/privacy-policy" rel="noopener noreferrer"> Privacy Policy </a>
          </div>
        </Col>
        <Col xs={ 8 } sm={ 3 }>
          <div className='rk-footer-link'>
            <a href="/cookie-policy" rel="noopener noreferrer"> Cookie Policy </a>
          </div>
        </Col>
      </Row>
    );
  }

  public render(): React.ReactElement {
    return (
      <Col>
        <div className='rk-default-footer'>
          <Row>
            <Col className='rk-footer-content' xs={ 24 } md={ { span: 20, offset: 2 } }>
              <Row className='rk-footer-links' gutter={ [0, 15] }>
                { this.renderRascalCol() }
                { this.renderAboutCol() }
                { this.renderForParentsCol() }
                { this.renderContactUsCol() }
              </Row>
              { this.renderTermsRow() }
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
}

export default DefaultFooter;
