import React from 'react';
import {Link} from 'react-router-dom';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Form, Input, Row} from 'antd';
import {Lottie} from '@rascality/react-lottie';
import {Lego} from 'lottie/lottie-assets';

import ScriptLoader from 'helpers/ScriptLoader';
import {ComponentWithStore, withStore} from 'models/RootStore';
import DefaultLayout from 'layouts/DefaultLayout';
import Turnstile from "react-turnstile";

const turnstileSiteKey: string = process.env.TURNSTILE_SITE_KEY;

class ForgotPassword extends ComponentWithStore<any, any> {
  @observable private captchaResponse: string;
  @observable private requestedEmail: string;
  @observable private finished: boolean = false;
  @observable private missing: boolean = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.onFinish = this.onFinish.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
  }

  public componentDidMount(): void {
  }

  public onFinish(values) {
    this.requestedEmail = values['email'];
    values['cf-turnstile-response'] = this.captchaResponse;
    this.store.UserProvider.forgotPassword(values)
      .then(result => {
        if (result) {
          this.finished = true;
        } else {
          this.missing = true;
        }
      });
  };

  public onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  };

  public errorCallback() {
  }

  public verifyCallback(response: string) {
    this.captchaResponse = response;
  }

  public expiredCallback() {
    this.captchaResponse = null;
  }

  private renderFormOrError(): React.ReactElement {
    if (this.finished) {
      return (
        <React.Fragment>
          <p>Successfully requested password reset.</p>
          <p>You should receive an email to {this.requestedEmail} in 5-10 minutes with a password reset link.</p>
        </React.Fragment>
      );
    }
    if (this.missing) {
      return (
        <React.Fragment>
          <div style={{color: 'red'}}>
            <p>Entered Email Address was not found!</p>
          </div>
          {this.renderForm()}
        </React.Fragment>
      );
    }
    return this.renderForm();
  }

  private renderForm(): React.ReactElement {
    return (
      <React.Fragment>
        <div>
          <p>Enter your email and we will send a password reset request to your email. It's that easy.</p>
        </div>
        <div>
          <Form.Item name='email' rules={[{required: true, message: 'Please input your email'}]}>
            <Input className='rk-input' placeholder='Email' type='email'/>
          </Form.Item>
        </div>
        <div className='rk-captcha'>
          <Row>
            <Col xs={12}>
              <Turnstile
                theme={'light'}
                sitekey={turnstileSiteKey}
                onError={this.errorCallback}
                onVerify={this.verifyCallback}
                onExpire={this.expiredCallback}
                retry={'auto'}
                retryInterval={8000}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Button className='rk-btn' type='primary' htmlType='submit'
                  disabled={this.captchaResponse == null}>Submit</Button>
        </div>
        <div className='rk-redirect-link'>
          <p>Feeling lost? <strong><Link to='/sign_in'>Sign In here</Link>.</strong></p>
        </div>
      </React.Fragment>
    );
  }

  public render(): React.ReactElement {
    return (
      <DefaultLayout>
        <div className='rk-register-content'>
          <Form onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
            <Row justify='space-around' align='middle'>
              <Col xs={24} sm={12} xl={{span: 9, offset: 3}}>
                <Row>
                  <Col xs={24}>
                    <div>
                      <h1>Forgot Password</h1>
                    </div>
                    {this.renderFormOrError()}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <div>
                  <Lottie speed={0.6} config={{animationData: Lego, loop: true}}/>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </DefaultLayout>
    );
  }
}

export default withStore(observer(ForgotPassword))
