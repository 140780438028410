import React, { Component } from 'react';
import './Parallelogram.scss';

export interface IParallelogramProps {
    label: string;
    background: string;
    textColor: 'white' | 'black'
}

class Parallelogram extends Component<IParallelogramProps, any> {
    public render(): React.ReactElement {
        const { label, background, textColor } = this.props;

        return (
            <div className='rk-parallelogram' style={{ background: background }}>
                <div className='rk-parallelogram-label' style={{color: textColor}}>
                    {label}
                </div>
            </div>
        );
    }
}

export default Parallelogram;
