import React, {Component} from 'react';
import './LoadingIcon.scss'

export interface IStoryDetailsButtonProps {
    color: 'blue' | 'white';
}

class LoadingIcon extends Component<IStoryDetailsButtonProps, any> {
    public render(): React.ReactElement {
        const {color} = this.props;
        let colorHex: string
        switch (color) {
            case 'blue':
                colorHex = '#1c2039'
                break;
            case 'white':
                colorHex = '#f2f2f2'
                break;
        }

        return (
            <div className='rk-spinner-wrapper'>
                <div className='rk-spinner-container'>
                    <svg className='rk-spinner' xmlns='http://www.w3.org/2000/svg'
                         width='22' height='22'
                         viewBox='0 0 22 22'>
                        <g>
                            <circle cx='6' cy='6' r='6' transform='translate(0 0)' fill={colorHex}/>
                            <circle cx='6' cy='6' r='6' transform='translate(10 10)' fill={colorHex}/>
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}

export default LoadingIcon;